import { useState } from 'react';
import Spinner from '../../../components/Spinner';

function UserActionMenuButton({ text, onClick }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const wrappedOnClick = () => {
    setIsSubmitting(true);
    onClick().finally(() => {
      setIsSubmitting(false);
    });
  };
  return (
    <div className="flex w-full flex-row items-baseline justify-between">
      <button
        type="button"
        className="w-full px-4 pb-2 pt-1 text-sm hover:bg-gray-5 hover:bg-opacity-25 hover:bg-blend-multiply ltr:text-left ltr:font-light rtl:text-right rtl:font-normal"
        onClick={wrappedOnClick}
      >
        {text}
      </button>
      {isSubmitting && <Spinner className="self-baseline" />}
    </div>
  );
}

export default UserActionMenuButton;
