import React from 'react';
import { useSelector } from 'react-redux';
import { transactionTabs } from '../../../../../../helpers/constants';
import DeclineTabPopupContent from './DeclineTabPopupContent';
import ExpensesAndRefundPopupContent from './ExpensesAndRefundPopupContent';
import LoadTabPopupContent from './LoadTabPopupContent';

function TransactionsPopupContent({ cardDetails }) {
  const { selectedActiveTab } = useSelector((state) => state.cardDetails);

  return (
    <div className="grid gap-4">
      {/* Expenses and Refund Tabs Content */}
      {(selectedActiveTab === transactionTabs.EXPENSES ||
        selectedActiveTab === transactionTabs.REFUNDS) && <ExpensesAndRefundPopupContent />}

      {/* Decline Tab Content */}
      {selectedActiveTab === transactionTabs.DECLINES && (
        <DeclineTabPopupContent cardDetails={cardDetails} />
      )}

      {/* Load Tab Content */}
      {selectedActiveTab === transactionTabs.LOADS && (
        <LoadTabPopupContent cardDetails={cardDetails} />
      )}
    </div>
  );
}

export default TransactionsPopupContent;
