import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import { getServerErrMessage } from '../../../../helpers/Utils';
import { createNewCardSteps } from '../../../../helpers/constants';
import { http } from '../../../../http';
import { showToasterAction } from '../../../../redux/AppToastersReducer/AppToastersActions';
import {
  setActiveCreateNewCardStepAction,
  setShippingAddressAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import CancelBtn from './CancelBtn';
import ShippingAddress from './ShippingAddress';

function ShippingDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation('cardsV2');
  const [agree, setAgree] = useState(false);

  const { loaders } = useSelector((state) => state);
  const { createCardUserData } = useSelector((state) => state.createNewCard);

  const getShippingAddress = useCallback(() => {
    http
      .get(`shipping_address/${createCardUserData.id}`, {
        params: { is_account: 1 },
        loader: 'getShippingAddress',
      })
      .then((res) => {
        if (res?.data) {
          dispatch(setShippingAddressAction(res.data));
        }
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, []);

  useEffect(() => {
    getShippingAddress();
  }, []);

  return (
    <div className="mx-auto mt-[16vh] w-full max-w-[410px] px-5 lg:w-2/3 lg:px-0 xl:w-1/2">
      <div className="mb-8">
        <AppHeader h="h3" className="mb-1">
          {t('Shipping Details')}
        </AppHeader>

        <AppBody pClass="Body2Medium" className="text-gray-6">
          <span>{t('Your card is projected to be delivered within a timeframe of')}</span>{' '}
          <span className="font-semibold">{t('7 to 10 days')}</span>
        </AppBody>
      </div>

      <ShippingAddress />

      <div className="mb-4">
        <label className="flex gap-2">
          <input
            type="checkbox"
            className="mt-1 h-5 w-5 rounded checked:bg-black focus:ring-0"
            checked={agree}
            onChange={(e) => {
              setAgree(e.target.checked);
            }}
          />
          <span className="text-sm">
            {t('I Certify that the card will be shipped to the above company address')}
          </span>
        </label>
      </div>

      <div className="mt-4 flex flex-col items-center gap-3 pb-5">
        <AppButton
          disabled={!agree || loaders.getShippingAddress}
          onClick={() => {
            dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.sendOTP));
          }}
          isLoading={false}
          button="black"
          className="w-full"
          size="md"
        >
          {t('Request', { ns: 'commonV2' })}
        </AppButton>

        <CancelBtn />
      </div>
    </div>
  );
}

export default ShippingDetails;
