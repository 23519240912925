import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import ActionButton from '../../../components/Button/ActionButton';
import BackButton from '../../../components/Button/BackButton';
import LinkButton from '../../../components/Button/LinkButton';
import CustomPhone from '../../../components/FormInputs/CustomPhone';
import {SelectInputCustom, TextInput} from '../../../components/FormInputs/Inputs';
import LoadingError from '../../../components/LoadingError';
import {LargerTitle2} from '../../../components/Titles';
import {apiGetRequest, apiPostFormDataRequest} from '../../../helpers/APIHelper';
import {setAction} from '../../../helpers/ActionsHelper';
import {EmailValidationPattern, NameValidationPattern} from '../../../helpers/RegexPatterns';
import useNotifications from '../../../helpers/useNotifications';
import {SET_USERS, UPDATE_EMPLOYEE} from '../../../redux/EmployeesReducer';
import Head from '../../Head';
import {AppBody} from "../../../components/html/html";

const UserProfileInfoItem = function (props) {
  const { title, info } = props;
  return (
    <div className="col-span-2 flex flex-col justify-start pb-5 lg:col-span-1 ltr:text-left rtl:text-right">
      <span className="pb-2">{title}</span>
        <AppBody type="span" pClass="Body1Bold">{info || 'N/A'}</AppBody>
    </div>
  );
};

const EditUser = function () {
  const { users } = useSelector((state) => state.employees);
  const params = useParams();
  const [selectedUser, setSelectedUser] = useState(null);
  const { t, i18n } = useTranslation(['users', 'common']);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    control,
    setValue,
  } = useForm({ mode: 'onBlur' });
  const [userTypes, setUserTypes] = useState([]);
  const [userTypesOptions, setUserTypesOptions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [departmentOptions, setDepartmentsOptions] = useState([]);
  const [errored, setErrored] = useState(false);
  const [showTypesHint, setShowTypesHint] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [, addNotification] = useNotifications();
  const [cookies] = useCookies(['token']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [paginationLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const fetchUser = (userId) => {
    setIsLoading(true);
    apiGetRequest(
      'employees',
      cookies.token,
      {
        paginate: paginationLimit,
        page: 1,
        id: userId,
      },
      [],
      {},
      true,
    )
      .then(([response]) => {
        if (response.length === 0) {
          setNotFound(true);
        } else {
          setNotFound(false);
          setSelectedUser(response[0]);
        }
        setErrored(false);
        setIsLoading(false);
        dispatch(setAction(SET_USERS, response));
      })
      .catch(() => {
        setErrored(true);
        setIsLoading(false);
      });
  };

  const loadUserTypes = () => {
    apiGetRequest('employees/types', cookies.token)
      .then((response) => {
        setUserTypes(response);
        setUserTypesOptions(
          response.map(({ key: value, value: label, ...rest }) => ({
            value,
            label,
            ...rest,
          })),
        );
      })
      .catch(() => setUserTypesOptions([]));
  };

  const loadDepartment = () => {
    apiGetRequest('departments', cookies.token)
      .then((response) => {
        setDepartments(response);
        setDepartmentsOptions(
          i18n.language === 'ar'
            ? response.map(({ id: value, name_ar: label, ...rest }) => ({
                value,
                label,
                ...rest,
              }))
            : response.map(({ id: value, name: label, ...rest }) => ({
                value,
                label,
                ...rest,
              })),
        );
      })
      .catch(() => setDepartmentsOptions([]));
  };

  useEffect(() => {
    loadUserTypes();
    loadDepartment();
    clearErrors();
  }, [params, selectedUser, i18n.language]);

  useEffect(() => {
    fetchUser(params.id && parseInt(params.id, 10));
  }, [i18n.language]);

  useEffect(() => {
    if (selectedUser) {
      setValue('email', selectedUser.email);
      setValue('mobile', selectedUser.mobile);
      setValue('job_title', selectedUser.job_title);
      setValue('department_id', selectedUser.department && selectedUser.department.id);
      setValue('type', selectedUser.roles ? selectedUser.roles.join(', ') : '');
    }
  }, [selectedUser]);
  const onSubmit = (data) => {
    setIsSaving(true);

    const form = new URLSearchParams();
    form.append('job_title', data.job_title);
    if (selectedUser.roles.includes('Account-Owner')) {
      form.append('role', 'Account-Owner');
    } else {
      form.append('role', data.type);
    }
    form.append('department_id', data.department_id);
    // form.append('email', data.email);
    form.append('mobile', data.mobile);

    apiPostFormDataRequest(`employees/${selectedUser.id}`, form, cookies.token, {}, 'put')
      .then((response) => {
        navigate(-1);
        addNotification(t('edit.edit_success'));
        dispatch(setAction(UPDATE_EMPLOYEE, response));
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  if (notFound && !isLoading) {
    return (
      <div className="m-auto flex w-full max-w-sm flex-col text-center lg:w-7/12">
        <img src="/images/NotFound.png" className="m-auto max-w-xs" />
      </div>
    );
  }

  if (!selectedUser) {
    return (
      <div className="mx-auto flex w-full flex-col lg:w-10/12">
        <Head title="Edit User" />
        <div className="grid grid-cols-6 items-start justify-between py-10">
          <div className="col-span-6 flex flex-col justify-start">
            {isLoading && <Skeleton containerClassName="w-full" count={3} />}
            <LoadingError show={errored && !isLoading} retryCallback={fetchUser} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="mx-auto flex w-full flex-col lg:w-10/12 ltr:text-left rtl:text-right">
      <Head title="Edit User" />
      <div className="grid grid-cols-6 items-start  justify-between">
        <div className="col-span-6 flex flex-col justify-start">
          <BackButton />
          <LargerTitle2>
            {t('edit.title')} {selectedUser.first_name} {selectedUser.last_name}
          </LargerTitle2>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-span-6 pt-10">
          <div className="grid grid-cols-2 gap-5 pb-5">
              <AppBody type="label" pClass="Body2Bold"
              htmlFor="user_email"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('add.inputs.email')}
              <TextInput
                disabled
                error={errors.email}
                id="user_email"
                {...register('email', {
                  required: t('add.validation.required.email'),
                  pattern: {
                    value: EmailValidationPattern,
                    message: t('add.validation.patterns.email'),
                  },
                })}
              />
              </AppBody>
              <AppBody type="label" pClass="Body2Bold"
              htmlFor="mobile"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('add.inputs.mobile')}
              <CustomPhone
                defaultValue={selectedUser.mobile}
                name="mobile"
                control={control}
                errors={errors.mobile}
                patternError={t('add.validation.required.mobile')}
                requiredString={t('add.validation.patterns.mobile')}
              />
              </AppBody>
          </div>

          <div className="grid grid-cols-2 gap-5 pb-5">
              <AppBody type="label" pClass="Body2Bold"
              htmlFor="user_job_title"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('add.inputs.job_title')}
              <TextInput
                error={errors.job_title}
                id="user_job_title"
                {...register('job_title', {
                  required: t('add.validation.required.job_title'),
                  pattern: {
                    value: NameValidationPattern,
                    message: t('add.validation.patterns.job_title'),
                  },
                })}
              />
              </AppBody>
              <AppBody type="label" pClass="Body2Bold"
              htmlFor="department_id"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('add.inputs.department')}
              {departmentOptions && (
                <SelectInputCustom
                  onChange={(e) => {
                    setValue('department_id', e);
                  }}
                  defaultValue={
                    departmentOptions.filter(
                      (dept) => dept.value === selectedUser.department?.id,
                    )[0] || null
                  }
                  error={errors.department_id}
                  name="department_id"
                  id="department_id"
                  control={control}
                  rules={{
                    required: t('add.validation.required.department'),
                  }}
                  options={departmentOptions}
                />
              )}
              {departmentOptions.length === 0 && (
                <span className="pt-5 font-normal">{t('add.inputs.loading')}</span>
              )}
              </AppBody>
          </div>

          {!selectedUser.roles.includes('Account-Owner') && (
            <div className="grid grid-cols-2 gap-5 pb-5">
                <AppBody type="label" pClass="Body2Bold"
                htmlFor="user_type"
                className="col-span-2 flex flex-col"
              >
                {t('add.inputs.user_type')}
                {userTypesOptions && (
                  <SelectInputCustom
                    onChange={(e) => {
                      setValue('type', e);
                    }}
                    defaultValue={
                      userTypesOptions.filter(
                        (type) => type.value === selectedUser.roles.join(', '),
                      )[0] || null
                    }
                    error={errors.type}
                    name="type"
                    id="type"
                    control={control}
                    rules={{ required: t('add.validation.required.type') }}
                    options={userTypesOptions}
                  />
                )}
                {userTypes.length === 0 && (
                  <span className="pt-5 font-normal">{t('add.inputs.loading')}</span>
                )}
                <LoadingError show={errored} retryCallback={loadUserTypes} />
                {userTypes.length > 0 && (
                  <div className="flex flex-col">
                    {!showTypesHint && (
                      <div className="flex flex-row items-baseline justify-end pb-5">
                        <LinkButton
                          className="flex flex-row text-primary"
                          text={t('add.user_types_hint')}
                          onClick={() => {
                            setShowTypesHint(true);
                          }}
                        />
                      </div>
                    )}
                    {showTypesHint && (
                      <div className="pt-5">
                        {userTypes.map((type) => (
                          <div className="grid w-full grid-cols-3 pb-3 ">
                            <div className="col-span-1">{type.value}:</div>
                            <AppBody type="div" pClass="Body2Regular" className="col-span-2 text-gray-6">
                              {type.description}
                            </AppBody>
                          </div>
                        ))}

                        <div className="flex flex-row items-baseline justify-end pb-5">
                          <LinkButton
                            className="flex flex-row text-primary"
                            text={t('add.user_types_hint_hide')}
                            onClick={() => {
                              setShowTypesHint(false);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                </AppBody>
            </div>
          )}
        </div>
        <div className="col-span-8 mt-10 flex flex-row items-baseline justify-end pt-2">
          <ActionButton
            className="flex flex-row"
            text={t('cancel', { ns: 'common' })}
            onClick={() => {
              navigate(-1);
            }}
          />
          <ActionButton
            className="flex flex-row"
            isLoading={isSaving}
            primary
            text={t('save', { ns: 'common' })}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default EditUser;
