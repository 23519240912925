import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppCloseButton from '../../../../../../components/AppCloseButton';
import { transactionTabs } from '../../../../../../helpers/constants';
import { hideModalAction } from '../../../../../../redux/AppModalReducer/AppModalActions';
import { hideReceiptPopupAction } from '../../../../../../redux/CardDetailsReducer/CardDetailsActions';
import RequestIcons from './RequestIcons';
import TransactionsIcons from './TransactionsIcons';

function ReceiptPopupHeader({ requestPrintWrapperRef, transactionPrintWrapperRef }) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { selectedActiveTab } = useSelector((state) => state.cardDetails);
  const dispatch = useDispatch();

  return (
    <div className="sticky top-0 z-30 flex justify-between bg-white py-5 print:hidden">
      <div className="flex gap-5">
        {/* ============= [Transactions] Icons ============= */}
        {selectedActiveTab !== transactionTabs.REQUESTS && (
          <TransactionsIcons transactionPrintWrapperRef={transactionPrintWrapperRef} />
        )}

        {/* ============= [Requests] Icons ============= */}
        {selectedActiveTab === transactionTabs.REQUESTS && (
          <RequestIcons requestPrintWrapperRef={requestPrintWrapperRef} />
        )}
      </div>

      {/* Close Icon */}
      <div>
        <AppCloseButton
          onClick={() => {
            dispatch(hideReceiptPopupAction());
            if (isMobile) {
              dispatch(hideModalAction());
            }
          }}
        />
      </div>
    </div>
  );
}

export default ReceiptPopupHeader;
