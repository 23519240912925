import { useDispatch } from 'react-redux';
import successCheckAnimation from '../../../../assets/lotties/successCheckAnimation.json';
import AppCloseButton from '../../../../components/AppCloseButton';
import AppLottie from '../../../../components/AppLottie/index';
import { AppBody } from '../../../../components/html/html';
import { hideModalAction } from '../../../../redux/AppModalReducer/AppModalActions';

function InvitationSentPopup({ message }) {
  const dispatch = useDispatch();

  return (
    <div className="w-96 rounded-2xl bg-white shadow">
      <div className="mr-9 mt-9 text-right">
        <AppCloseButton
          onClick={() => {
            dispatch(hideModalAction());
          }}
        />
      </div>
      <div className="flex flex-col items-center justify-center p-8">
        <div className="mb-6">
          <AppLottie loop={false} animationData={successCheckAnimation} width={180} />
        </div>
        <AppBody pClass="Body1Bold" className="mx-auto text-center">
          {message}
        </AppBody>
      </div>
    </div>
  );
}

export default InvitationSentPopup;
