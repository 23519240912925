import { randomNumber } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import AppNoData from '../../../../components/AppNoData';
import { cardStatusKeys } from '../../../../helpers/constants';
import NqoodCardProgram from './NqoodCardProgram';

function CardProgramColumn() {
  const { t } = useTranslation('walletV2');

  const mobileToggleInactiveCardProgramOptions = useMemo(() => {
    return [
      { id: 'ActivePrograms', value: t('Active Programs'), showFlag: false },
      { id: 'InactivePrograms', value: t('Inactive Programs'), showFlag: true },
    ];
  }, [t]);

  const { loaders } = useSelector((state) => state);
  const { walletCardPrograms } = useSelector((state) => state.wallet);
  const [cardPrograms, setCardPrograms] = useState(null);
  const [showInactiveCardPrograms, setShowInactiveCardPrograms] = useState(false);
  const [inactiveCardPrograms, setInactiveCardPrograms] = useState([]);
  const [activeCardPrograms, setActiveCardPrograms] = useState([]);
  const [activeMobileInactiveCardsTab, setActiveMobileInactiveCardsTab] = useState(
    mobileToggleInactiveCardProgramOptions[0],
  );

  const showToggleInactiveCardsButton = useMemo(() => {
    return (
      !!inactiveCardPrograms.length &&
      !loaders.getWalletCardProgramListAction &&
      Boolean(inactiveCardPrograms.length)
    );
  }, [inactiveCardPrograms, inactiveCardPrograms, loaders]);

  const handleToggleInactiveCardPrograms = useCallback(() => {
    setShowInactiveCardPrograms(!showInactiveCardPrograms);
  }, [showInactiveCardPrograms]);

  useEffect(() => {
    if (walletCardPrograms?.length) {
      const active = [];
      const inActive = [];

      walletCardPrograms.forEach((ele) => {
        const isClosedCardProgram = [cardStatusKeys.SUSPENDED, cardStatusKeys.DEACTIVATED].includes(
          ele.status,
        );

        if (ele.status === cardStatusKeys.ACTIVE) {
          active.push(ele);
        }
        if (isClosedCardProgram) {
          inActive.push(ele);
        }
      });

      setActiveCardPrograms(active);
      setInactiveCardPrograms(inActive);
    }
  }, [walletCardPrograms]);

  useEffect(() => {
    setCardPrograms(showInactiveCardPrograms ? inactiveCardPrograms : activeCardPrograms);
  }, [showInactiveCardPrograms, inactiveCardPrograms, activeCardPrograms]);

  return (
    <div className="flex flex-col gap-4">
      {/* Mobile Toggle Tabs */}
      <div className="mb-4 flex items-center justify-around gap-2 rounded-lg bg-gray-5 p-1 font-bold lg:hidden">
        {mobileToggleInactiveCardProgramOptions.map((ele) => {
          const activeClasses =
            ele.id === activeMobileInactiveCardsTab.id ? 'text-white bg-primary' : '';
          return (
            <button
              onClick={() => {
                setActiveMobileInactiveCardsTab(ele);
                setShowInactiveCardPrograms(ele.showFlag);
              }}
              key={ele.id}
              type="button"
              className={`w-full rounded-xl py-2 ${activeClasses}`}
            >
              {ele.value}
            </button>
          );
        })}
      </div>

      <div className="app-thin-scrollbar overflow-y-scroll py-1 pe-3 ps-1 hover:pe-1 lg:max-h-[65vh]">
        {/* ========== Card Programs ========== */}
        <div className="flex w-full flex-col items-center gap-3">
          {!!cardPrograms?.length &&
            !loaders.getWalletCardProgramListAction &&
            cardPrograms.map((ele) => <NqoodCardProgram fromList key={ele.id} cardProgram={ele} />)}
        </div>

        {/* ========== loaders========== */}
        {loaders.getWalletCardProgramListAction && (
          <div className="w-full">
            <SkeletonTheme baseColor="#c2c2c2" highlightColor="#e6e6e6">
              <Skeleton height={90} count={randomNumber(3, 5)} className="mb-2" />
            </SkeletonTheme>
          </div>
        )}

        {/* ========== no cards ========== */}
        {!loaders.getWalletCardProgramListAction && !cardPrograms?.length && (
          <div className="flex h-full w-full flex-col items-center justify-center gap-3">
            <AppNoData />
          </div>
        )}
      </div>

      {/* ========== Card Program Buttons ========== */}
      {showToggleInactiveCardsButton && (
        <button
          className="mb-3 hidden w-full justify-center gap-1 rounded-lg bg-gray-3 px-3 py-2 text-center text-[11px] font-semibold text-black transition-all ease-in-out hover:bg-gray-2 disabled:cursor-not-allowed disabled:opacity-50 lg:flex"
          type="button"
          onClick={handleToggleInactiveCardPrograms}
        >
          {!showInactiveCardPrograms && <span>{t('Show Inactive Programs')} </span>}
          {showInactiveCardPrograms && <span>{t('Hide  Inactive Programs')} </span>}
          <span className="text-gray-6">{inactiveCardPrograms.length}</span>
        </button>
      )}
    </div>
  );
}

export default CardProgramColumn;
