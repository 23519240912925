import { useSelector } from 'react-redux';
import { settingsPopupContent } from '../../../../../../helpers/constants';

import CardControl from './CardControl';
import ChangeColorOrName from './ChangeColorOrName';
import ChangeColorNameSuccess from './ChangeColorOrName/ChangeColorNameSuccess';
import ChangePinNumber from './ChangePinNumber';
import ChangeStatus from './ChangeStatus';
import ActivateCard from './ChangeStatus/ActivateCard';
import RequestSent from './ChangeStatus/RequestSent';
import SimplifiActivate from './ChangeStatus/SimplifiActivate';
import SuspendCard from './ChangeStatus/SuspendCard';
import TerminateCard from './ChangeStatus/TerminateCard';
import VerifyPlasticLastFour from './ChangeStatus/VerifyPlasticLastFour';
import MobileSettingsList from './MobileSettingsList';
import SettingsList from './SettingsList';
import ShowPinNumber from './ShowPinNumber';

function CardSettingsPopupContent() {
  const { currentSettingsPopupContent } = useSelector((state) => state.cardDetails);

  return (
    <>
      {/* Mobile Settings List */}
      {currentSettingsPopupContent === settingsPopupContent.mobileSettingsList && (
        <MobileSettingsList />
      )}

      {/* Settings List */}
      {currentSettingsPopupContent === settingsPopupContent.settingsList && <SettingsList />}

      {/* Change Color Or Name */}
      {currentSettingsPopupContent === settingsPopupContent.changeColorOrName && (
        <ChangeColorOrName />
      )}
      {currentSettingsPopupContent === settingsPopupContent.changeColorNameSuccess && (
        <ChangeColorNameSuccess />
      )}

      {/* Change Status */}
      {currentSettingsPopupContent === settingsPopupContent.changeStatus && <ChangeStatus />}

      {/* Activate Card */}
      {currentSettingsPopupContent === settingsPopupContent.activateCard && <ActivateCard />}

      {/* Verify Plastic Card Last Four */}
      {currentSettingsPopupContent === settingsPopupContent.verifyLastFour && (
        <VerifyPlasticLastFour />
      )}

      {/* Simplifi Activate Card */}
      {currentSettingsPopupContent === settingsPopupContent.simplifiActivate && (
        <SimplifiActivate />
      )}

      {/* Suspend Card */}
      {currentSettingsPopupContent === settingsPopupContent.suspendCard && <SuspendCard />}

      {/* Terminate Card */}
      {currentSettingsPopupContent === settingsPopupContent.terminateCard && <TerminateCard />}

      {/* Request Sent */}
      {currentSettingsPopupContent === settingsPopupContent.requestSent && <RequestSent />}

      {/* Card Control */}
      {currentSettingsPopupContent === settingsPopupContent.cardControl && <CardControl />}

      {/* Show Pin Number */}
      {currentSettingsPopupContent === settingsPopupContent.showPinNumber && <ShowPinNumber />}

      {/* Change Pin Number */}
      {currentSettingsPopupContent === settingsPopupContent.changePinNumber && <ChangePinNumber />}
    </>
  );
}

export default CardSettingsPopupContent;
