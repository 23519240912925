import { useSelector } from 'react-redux';
import LoginBgImage from '../../assets/png/login-bg.png';
import { uploadDocumentSteps } from '../../helpers/constants';
import DocumentCardDetails from './DocumentCardDetails';
import DocumentCardsList from './DocumentCardsList';
import DocumentsOTP from './DocumentsOTP';
import DocumentUploadSuccess from './DocumentUploadSuccess';

function UploadDocuments() {
  const { activeDocumentStep } = useSelector((state) => state.uploadDocuments);
  return (
    <div className="h-full py-2">
      <div
        className={` ${
          activeDocumentStep === uploadDocumentSteps.OTP ? 'bg-slate-50' : 'bg-white'
        } h-full overflow-y-auto rounded-xl border border-gray-3 bg-cover bg-center`}
        style={{
          backgroundImage:
            activeDocumentStep === uploadDocumentSteps.documentUploadSuccess
              ? `url(${LoginBgImage})`
              : 'none',
        }}
      >
        {activeDocumentStep === uploadDocumentSteps.OTP && <DocumentsOTP />}
        {activeDocumentStep === uploadDocumentSteps.documentList && <DocumentCardsList />}
        {activeDocumentStep === uploadDocumentSteps.documentCardDetails && <DocumentCardDetails />}
        {activeDocumentStep === uploadDocumentSteps.documentUploadSuccess && (
          <DocumentUploadSuccess />
        )}
      </div>
    </div>
  );
}

export default UploadDocuments;
