import { React, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, toIntAndDecimalNumber } from '../../helpers/Utils';
import { inPages, requestStatusIds } from '../../helpers/constants';
import {
  getSingleRequestDetailsAction,
  hideCardActionsPopup,
  hideRequestFormPopupAction,
  showReceiptPopupAction,
} from '../../redux/CardDetailsReducer/CardDetailsActions';

import {
  getSingleWalletRequestDetailsAction,
  handleReloadRequestAction,
  showWalletReceiptPopupAction,
} from '../../redux/WalletReducer/WalletActions';
import TranslatedData from '../TranslatedData';
import { AppIcon, AppTd, AppTr } from '../html/html';

function RequestTableRow({ item, inPage }) {
  const { lang, isMobile } = useSelector((state) => state.appConfig);
  const { loaders } = useSelector((state) => state);
  const { t } = useTranslation('commonV2');
  const dispatch = useDispatch();

  const handleViewReceipt = useCallback(
    (e) => {
      if (inPage === inPages.myCards) {
        dispatch(hideRequestFormPopupAction());
        dispatch(showReceiptPopupAction());
        dispatch(getSingleRequestDetailsAction(item.id));
        dispatch(hideCardActionsPopup());
      }

      if (inPage === inPages.wallet) {
        if (!e.target?.closest('[data-id="reload-btn"]')) {
          dispatch(showWalletReceiptPopupAction());
          dispatch(getSingleWalletRequestDetailsAction(item.id));
        }
      }
    },
    [item],
  );

  return (
    <>
      {/* ================= Desktop Row ================= */}
      {!isMobile && (
        <AppTr
          className="cursor-pointer border-b last:border-0 [&>*]:max-w-[100px] [&>*]:py-3"
          onClick={handleViewReceipt}
        >
          <AppTd>{formatDate(item.created_at, 'HH:mm')}</AppTd>
          <AppTd className="min-w-[50px]">{item.id}</AppTd>

          {inPage === inPages.myCards && <AppTd>{item.reason || '-'}</AppTd>}

          <AppTd>
            <div
              className={`flex gap-1 ${lang === 'ar' ? 'justify-end' : ''}`}
              style={{ direction: 'ltr' }}
            >
              <div style={{ direction: 'ltr' }}>
                {toIntAndDecimalNumber(item.amount).integerPart}
                <span className="text-[13px] font-normal">
                  {toIntAndDecimalNumber(item.amount).decimalPart}
                </span>
              </div>
              <span>{item.currency}</span>
            </div>
          </AppTd>

          <AppTd>{`${item.card_id?.card_name || ''} ${item.card_id?.last_four || ''}`}</AppTd>

          {inPage === inPages.wallet && (
            <AppTd>
              <TranslatedData
                en={`${item.requested_by?.first_name || ''} ${item.requested_by?.last_name || ''}`}
                ar={`${item.requested_by?.first_name_ar || ''} ${
                  item.requested_by?.last_name_ar || ''
                }`}
              />
            </AppTd>
          )}

          {inPage === inPages.wallet && (
            <AppTd>
              <TranslatedData
                en={item?.department_id.name || ''}
                ar={item?.department_id.name_ar || ''}
              />
            </AppTd>
          )}

          <AppTd>
            {item.status_id === requestStatusIds.PENDING && t('Pending')}
            {item.status_id === requestStatusIds.APPROVED && t('Approved')}
            {item.status_id === requestStatusIds.REJECTED && t('Rejected')}
          </AppTd>

          <AppTd>
            {!item.ref_id && item.status_id === requestStatusIds.PENDING && (
              <span className="text-gray-6">{t('Pending Approval')}</span>
            )}

            {item.status_id === requestStatusIds.APPROVED &&
              item.ref_id?.status?.key === 'SUCCESS' && (
                <span style={{ color: item.ref_id?.status?.color }}>{t('Successful')}</span>
              )}

            {item.status_id === requestStatusIds.APPROVED &&
              item.ref_id?.status?.key !== 'FAILURE' &&
              item.ref_id?.status?.key !== 'SUCCESS' && (
                <span style={{ color: item.ref_id?.status?.color }}>
                  {item.ref_id?.status?.name}
                </span>
              )}

            {item.status_id === requestStatusIds.REJECTED && (
              <span className="break-words text-red-500">{item.rejection_reason}</span>
            )}

            {inPage === inPages.myCards && item.ref_id?.status?.key === 'FAILURE' && (
              <span className="text-red-500">{item.ref_id?.status?.name}</span>
            )}

            {inPage === inPages.wallet && item.ref_id?.status?.key === 'FAILURE' && (
              <button
                onClick={() => {
                  dispatch(handleReloadRequestAction(item));
                }}
                disabled={loaders[`handleReloadRequestAction[${item.id}]`]}
                data-id="reload-btn"
                type="button"
                className="flex items-center gap-2 rounded-full border border-danger px-2 py-1 text-danger hover:bg-danger hover:text-white"
              >
                <span>{t('Reload')}</span>
                <AppIcon
                  iClass="Default"
                  className={`${
                    loaders[`handleReloadRequestAction[${item.id}]`] ? 'animate-spin' : ''
                  } fa fa-arrows-rotate`}
                />
              </button>
            )}
          </AppTd>
        </AppTr>
      )}

      {/* ================= Mobile Row ================= */}
      {isMobile && (
        <div
          onClick={handleViewReceipt}
          className="mb-2 flex cursor-pointer items-center gap-2 rounded-xl p-4 font-semibold shadow-lg"
        >
          <div className="flex-1">
            {inPage === inPages.myCards && <div>{item.reason || '-'}</div>}

            {inPage === inPages.wallet && (
              <div>
                <TranslatedData
                  en={`${item.requested_by?.first_name || ''} ${
                    item.requested_by?.last_name || ''
                  }`}
                  ar={`${item.requested_by?.first_name_ar || ''} ${
                    item.requested_by?.last_name_ar || ''
                  }`}
                />
              </div>
            )}

            {item.status_id === requestStatusIds.PENDING && t('Pending')}
            {item.status_id === requestStatusIds.APPROVED && t('Approved')}
            {item.status_id === requestStatusIds.REJECTED && t('Rejected')}
          </div>

          <div
            className="flex flex-1 flex-col items-center justify-center gap-1"
            style={{ direction: 'ltr' }}
          >
            <div style={{ direction: 'ltr' }}>
              {toIntAndDecimalNumber(item.amount).integerPart}
              <span className="text-[13px] font-normal">
                {toIntAndDecimalNumber(item.amount).decimalPart}
              </span>
            </div>
            <div>{item.currency}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default RequestTableRow;
