/* eslint-disable react/button-has-type */
import React from 'react';
import { useTranslation } from 'react-i18next';

export function DynamicElement({ element, className, children, ...rest }) {
  return React.createElement(element, { className, ...rest }, children);
}

export function AppHeader({ children, h = 'h2', className = '', ...rest }) {
  const hClasses = {
    h1: `text-4xl font-extrabold`,
    h2: `text-4xl font-medium`,
    h3: `text-4xl font-light`,
    h4: `text-3xl font-bold`,
    h5: `text-2xl font-bold`,
    h6: `text-xl font-bold`,
  };
  return React.createElement(h, { className: `${hClasses[h]} ${className}`, ...rest }, children);
}

export function AppBody({
  children,
  pClass = 'Body1Regular',
  type = 'p',
  className = '',
  ...rest
}) {
  const pClasses = {
    // Body1
    Body1Bold: `text-base font-bold`,
    Body1Medium: `text-base font-medium`,
    Body1Regular: `text-base font-normal`,
    // Body2
    Body2Heavy: `text-sm font-extrabold`,
    Body2Bold: `text-sm font-bold`,
    Body2Medium: `text-sm font-medium`,
    Body2Regular: `text-sm font-normal`,
    Body2Light: `text-sm font-light`,
    // Caption1
    Caption1Regular: `text-xs font-normal`,
    Caption1Medium: `text-xs font-medium`,
    Caption1Bold: `text-xs font-bold`,
    // Caption2
    Caption2Regular: `text-[10px] font-normal`,
    Caption2Medium: `text-[10px] font-medium`,
    Caption2Bold: `text-[10px] font-bold`,
    Caption2Body2Heavy: `text-[9px] font-extrabold`,
  };
  return React.createElement(
    type,
    { className: `${pClasses[pClass]} ${className}`, ...rest },
    children,
  );
}

export function AppIcon({ children, iClass = 'Default', className = '', ...rest }) {
  const iClasses = {
    Default: `text-sm font-normal`,
    MainIcons: `text-4xl xs:text-5xl sm:text-6xl lg:text-sm`,
    EyeIcon: `text-3xl lg:text-xxs`,
    XSmall: `text-xs`,
    Small: `text-sm`,
    BaseFont: `text-base`,
    LargeFont: `text-lg`,
    XLargeFont: `text-xl`,
    XXLargeFont: `text-2xl`,
    XXXLargeFont: `text-3xl`,
  };
  return React.createElement(
    'i',
    { className: `${iClasses[iClass]} ${className}`, ...rest },
    children,
  );
}

export function AppLi({ children, className = '', ...rest }) {
  const elClasses = 'text-xs font-semibold list-none';

  return React.createElement('li', { className: `${elClasses} ${className}`, ...rest }, children);
}

export function AppSmall({ children, className = '', ...rest }) {
  const elClasses = 'text-xs text-black';

  return React.createElement(
    'small',
    { className: `${elClasses} ${className}`, ...rest },
    children,
  );
}

export function AppTr({ children, className = '', ...rest }) {
  const elClasses = 'hover:bg-stone-50';

  return React.createElement('tr', { className: `${elClasses} ${className}`, ...rest }, children);
}

export function AppTd({ children, className = '', ...rest }) {
  const elClasses = 'text-sm font-semibold';

  return React.createElement('td', { className: `${elClasses} ${className}`, ...rest }, children);
}

export function AppButton({
  children,
  className = '',
  button = 'black',
  size = 'sm',
  type = 'button',
  rounded = 'full',
  loadingLabel = 'Loading...',
  isLoading,
  disabled,
  onClick,
  bClass = 'Button1Bold',
  ...rest
}) {
  const { t } = useTranslation('commonV2');

  const bClasses = {
    Button1Bold: 'text-base',
    Button2Bold: 'text-xs',
    ButtonHeader1: 'text-xl',
  };

  const roundedCorners = {
    md: 'rounded-md',
    full: 'rounded-full',
  };

  const btnSize = {
    sm: 'px-3 py-1 font-bold',
    md: 'px-4 py-1.5 font-bold',
    lg: 'px-6 py-3 font-bold',
  };

  const sharedClasses = `${bClasses[bClass]} ${btnSize[size]} ${roundedCorners[rounded]} text-center disabled:cursor-not-allowed disabled:bg-gray-3 disabled:text-white disabled:border-0`;

  const buttonTypeClasses = {
    link: `hover:underline disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:no-underline ${bClasses[bClass]}`,
    primary: `bg-primary hover:bg-primary-dark text-white ${sharedClasses}`,
    black: `bg-black hover:bg-stone-700 text-white ${sharedClasses}`,
    danger: `border border-danger text-danger hover:bg-danger hover:text-white ${sharedClasses}`,
    success: `border border-success text-success hover:bg-success hover:text-white ${sharedClasses}`,
  };

  return (
    <button
      className={`${buttonTypeClasses[button]} ${className}`}
      type={type}
      disabled={disabled || isLoading}
      onClick={onClick}
      {...rest}
    >
      {isLoading && t(loadingLabel)}
      {!isLoading && children}
    </button>
  );
}
