import { useFormik } from 'formik';
import { getURLParams } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { history } from '../../../../AppRouter/history';
import AppInput from '../../../../components/AppFormInputs/AppInput';
import AppSpinner from '../../../../components/AppSpinner';
import AppSelect from '../../../../components/FormInputs/AppSelect';
import { AppBody, AppButton, AppHeader, AppIcon } from '../../../../components/html/html';
import { supportedCountries } from '../../../../helpers/constants';
import { getServerErrMessage, translatedDataFunction } from '../../../../helpers/Utils';
import { http } from '../../../../http';
import { showModalAction } from '../../../../redux/AppModalReducer/AppModalActions';
import { showToasterAction } from '../../../../redux/AppToastersReducer/AppToastersActions';
import { getSingleUserDetailsAction } from '../../../../redux/UsersReducer/UsersActions';
import EditUserSuccessOrFail from './EditUserSuccessOrFail';
import EditUserSuccessOrFailPopup from './EditUserSuccessOrFailPopup';

function EditSingleUserForm() {
  const { t } = useTranslation('usersV2');
  const [serializedCountries, setSerializedCountries] = useState([]);
  const { singleUserDetails } = useSelector((state) => state.users);
  const { loaders } = useSelector((state) => state);
  const { dir, lang, isMobile } = useSelector((state) => state.appConfig);
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [showPermissionDetails, setShowPermissionDetails] = useState(false);
  const [showEditAnimation, setShowEditAnimation] = useState({
    show: false,
    isSuccess: null,
  });
  const [userPermissions, setUserPermissions] = useState([]);
  const [failedError, setFailedError] = useState(null);

  const mobileCountryCodeStyleConfigs = useMemo(() => {
    return {
      control: (provided, state) => ({
        ...provided,
        borderRadius: '.5rem 0 0 .5rem',
        opacity: state.isDisabled ? '.5' : '1',
        '&:hover': {},
        height: '48px',
        border: `1px solid #C1CBDE`,
        boxShadow: 'none',
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
      }),
    };
  }, [dir]);

  const handleBack = useCallback(() => {
    if (getURLParams('backToList')) {
      history.push(`/dashboard/users-v2`);
    } else {
      history.push(`/dashboard/users-v2/${singleUserDetails.id}`);
    }
  }, []);
  const initialValues = useMemo(() => {
    return {
      email: singleUserDetails?.email || '',
      mobileCountryCode: singleUserDetails?.mobile?.slice(0, 4) || '+966',
      mobileNumber: `${singleUserDetails?.mobile.slice(4)}` || '',
      jobTitle: singleUserDetails?.job_title || '',
      department: singleUserDetails?.department.id || '',
      userType: singleUserDetails?.roles[0] || '',
    };
  }, [singleUserDetails]);

  const onSubmit = useCallback(
    (values) => {
      http
        .put(`employees/${singleUserDetails.id}`, null, {
          params: {
            email: values.email,
            mobile: values.mobileCountryCode + values.mobileNumber,
            job_title: values.jobTitle,
            department_id: values.department,
            role: initialValues?.userType === 'Account-Owner' ? 'Account-Owner' : values.userType,
          },
          loader: 'handleEditUser',
        })
        .then(() => {
          if (isMobile) {
            dispatch(
              showModalAction(<EditUserSuccessOrFailPopup isSuccess handleBack={handleBack} />),
            );
          } else {
            setShowEditAnimation({ show: true, isSuccess: true });
            dispatch(getSingleUserDetailsAction({ userId: singleUserDetails.id }));
          }
        })
        .catch((err) => {
          setFailedError(err.status);
          if (isMobile) {
            dispatch(
              showModalAction(
                <EditUserSuccessOrFailPopup isSuccess={false} errCode={err.status} />,
              ),
            );
          } else {
            setShowEditAnimation({ show: true, isSuccess: false });
          }
        });
    },
    [singleUserDetails],
  );

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t('Please enter a valid Email.', { ns: 'commonV2' }))
      .required(t('This field is required!', { ns: 'commonV2' })),
    mobileNumber: yup
      .string()
      .min(7, t('The Mobile must be at least 10 numbers.', { ns: 'commonV2' }))
      .required(t('This field is required!', { ns: 'commonV2' })),
    jobTitle: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    department: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    userType: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({ initialValues, onSubmit, validationSchema, enableReinitialize: true });

  const getDepartments = useCallback(() => {
    http
      .get(`departments`, {
        loader: 'getDepartments',
      })
      .then((res) => {
        const serialized = res.data.map((ele) => {
          return {
            label: translatedDataFunction({ lang, en: ele.name, ar: ele.name_ar }),
            value: ele.id,
          };
        });
        setDepartments(serialized);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [lang]);

  const getUserTypes = useCallback(() => {
    http
      .get('employees/types', { loader: 'getUserTypes' })
      .then((res) => {
        const serializedTypes = res.data.map((ele) => {
          return {
            label: ele.value,
            value: ele.key,
          };
        });

        const serializedPermissions = res.data.map((ele) => {
          return {
            label: ele.value,
            value: ele.description,
          };
        });
        setUserTypes(serializedTypes);
        setUserPermissions(serializedPermissions);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [t]);

  useEffect(() => {
    setSerializedCountries(
      supportedCountries.map((ele) => ({
        label: lang === 'en' ? ele.name : ele.name_ar,
        value: ele.dial_code,
        abbr: ele.code,
      })),
    );
  }, [lang]);

  useEffect(() => {
    getDepartments();
    getUserTypes();
  }, []);

  return (
    <>
      {isMobile && (
        <button
          className="mt-8"
          type="button"
          onClick={() => {
            handleBack();
          }}
        >
          <AppIcon className="fa-regular fa-angle-left text-primary" iClass="XXLargeFont" />
        </button>
      )}

      {!loaders.getSingleUserDetailsAction && (
        <>
          {showEditAnimation.show && showEditAnimation.isSuccess && (
            <EditUserSuccessOrFail isSuccess />
          )}

          {showEditAnimation.show && !showEditAnimation.isSuccess && (
            <EditUserSuccessOrFail isSuccess={false} errCode={failedError} />
          )}

          {!showEditAnimation.show && (
            <>
              <AppHeader h="h1" className="mb-11 text-center">
                {t('Edit User')}
              </AppHeader>

              <form onSubmit={formik.handleSubmit}>
                {/* ============= Form Inputs ============= */}
                <div className="mt-1.5 lg:px-36">
                  <div className="mb-5">
                    <AppHeader h="h6" className="mb-1">
                      {t('Email', { ns: 'commonV2' })}
                    </AppHeader>
                    <AppInput
                      disabled
                      placeholder="solo@nqoodlet.com"
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <small className="text-danger">
                        {t(formik.errors.email, { ns: 'commonV2' })}
                      </small>
                    )}
                  </div>

                  <div className="mb-5">
                    <AppHeader h="h6" className="mb-1">
                      {t('Phone Number', { ns: 'commonV2' })}
                    </AppHeader>
                    <div className="flex">
                      <AppSelect
                        mobileSelect={false}
                        style={{ direction: 'ltr' }}
                        options={serializedCountries}
                        name="mobileCountryCode"
                        className="w-2/3 rounded-r-none border-r-0 rtl:order-3"
                        onChange={({ value }) => {
                          formik.setFieldValue('mobileCountryCode', value);
                        }}
                        value={serializedCountries.find(
                          (ele) => formik.values.mobileCountryCode === ele.value,
                        )}
                        customStyleConfigs={mobileCountryCodeStyleConfigs}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        formatOptionLabel={({ label, abbr }, { context }) =>
                          context === 'menu' ? label : abbr
                        }
                      />
                      <input
                        style={{ direction: 'ltr' }}
                        name="mobileCountryCode"
                        className="w-[10%] border-b border-t border-gray-3 ps-1 lg:w-[20%] rtl:order-2"
                        value={formik.values.mobileCountryCode}
                        readOnly
                      />
                      <AppInput
                        style={{ direction: 'ltr' }}
                        name="mobileNumber"
                        className="w-full rounded-s-none border-s-0 ps-0 focus:border-s-0 focus:shadow-none rtl:order-1"
                        value={formik.values.mobileNumber}
                        onChange={(e) => {
                          const { value } = e.target;
                          formik.setFieldValue('mobileNumber', value);
                        }}
                        onBlur={formik.handleBlur}
                        placeholder="05xxxxxxxx"
                      />
                    </div>

                    {formik.errors.mobileNumber && formik.touched.mobileNumber && (
                      <span className="mb-2 text-sm text-danger">{formik.errors.mobileNumber}</span>
                    )}
                  </div>

                  <div className="mb-5">
                    <AppHeader h="h6" className="mb-1">
                      {t('Job Title', { ns: 'commonV2' })}
                    </AppHeader>
                    <AppInput
                      placeholder={t('Junior product designer')}
                      type="text"
                      name="jobTitle"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.jobTitle}
                    />
                    {formik.errors.jobTitle && formik.touched.jobTitle && (
                      <small className="text-danger">
                        {t(formik.errors.jobTitle, { ns: 'commonV2' })}
                      </small>
                    )}
                  </div>

                  <div className="mb-5">
                    <AppHeader h="h6" className="mb-1">
                      {t('Department', { ns: 'commonV2' })}
                    </AppHeader>
                    <AppSelect
                      isLoading={loaders.getDepartments}
                      options={departments}
                      value={departments.find((ele) => ele.value === formik.values.department)}
                      name="department"
                      onChange={({ value }) => {
                        formik.setFieldValue('department', value);
                      }}
                      styleType="inForm"
                    />
                    {formik.errors.department && formik.touched.department && (
                      <small className="text-danger">
                        {t(formik.errors.department, { ns: 'commonV2' })}
                      </small>
                    )}
                  </div>

                  <div className="mb-5">
                    <AppHeader h="h6" className="mb-1">
                      {t('User Type', { ns: 'commonV2' })}
                    </AppHeader>

                    {initialValues?.userType === 'Account-Owner' && (
                      <AppInput
                        disabled
                        type="text"
                        name="userType"
                        onBlur={formik.handleBlur}
                        value={t('Account Owner')}
                      />
                    )}
                    {initialValues?.userType !== 'Account-Owner' && (
                      <AppSelect
                        isLoading={loaders.getUserTypes}
                        options={userTypes}
                        value={userTypes.find((ele) => ele.value === formik.values.userType)}
                        name="userType"
                        onChange={({ value }) => {
                          formik.setFieldValue('userType', value);
                        }}
                        styleType="inForm"
                      />
                    )}
                    {formik.errors.userType && formik.touched.userType && (
                      <small className="text-danger">
                        {t(formik.errors.userType, { ns: 'commonV2' })}
                      </small>
                    )}
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      setShowPermissionDetails(!showPermissionDetails);
                    }}
                  >
                    <AppBody pClass="Body2Bold" className="text-primary">
                      {!showPermissionDetails && t('Show User Permission details')}
                      {showPermissionDetails && t('Hide User Permission details')}
                    </AppBody>
                  </button>
                </div>

                {/* ============= User Permissions ============= */}
                {showPermissionDetails && (
                  <div className="mt-9 lg:px-9">
                    <div className="grid gap-10 rounded-3xl border border-gray-5 px-6 py-9">
                      {loaders.getUserPermissions && <AppSpinner />}
                      {!loaders.getUserPermissions &&
                        userPermissions.map((ele) => {
                          return (
                            <div>
                              <AppHeader h="h6" className="mb-3">
                                {ele.label}
                              </AppHeader>
                              <AppBody pClass="Body1Bold" className="text-gray-6">
                                {ele.value}
                              </AppBody>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}

                <div className="mt-9 px-36 pb-14">
                  <AppButton
                    isLoading={loaders.handleEditUser}
                    type="submit"
                    button="primary"
                    rounded="md"
                    size="lg"
                    className="w-full"
                  >
                    {t('Confirm', { ns: 'commonV2' })}
                  </AppButton>
                </div>
              </form>
            </>
          )}
        </>
      )}

      {loaders.getSingleUserDetailsAction && <AppSpinner />}
    </>
  );
}

export default EditSingleUserForm;
