import { Alert } from 'flowbite-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useCapabilities from '../../helpers/useCapabilities';
import { http } from '../../http';

function ExpireDocumentsMessage() {
  const { auth } = useSelector((state) => state);
  const [hasExpireDocuments, setHasExpireDocument] = useState(false);
  const { t } = useTranslation(['common']);
  const [currentUserCan] = useCapabilities();

  const location = useLocation();

  const showExpireMessage = useMemo(() => {
    return (
      currentUserCan('update_own_expire_document') &&
      auth.user &&
      !location.pathname.includes('expire-documents') &&
      !location.pathname.includes('my_cards') &&
      !location.pathname.includes('cards') &&
      !location.pathname.includes('create-new-card') &&
      !location.pathname.includes('wallet') &&
      !location.pathname.includes('profile') &&
      !location.pathname.includes('users')
    );
  }, [auth, location]);

  const fetchExpireDocuments = useCallback(() => {
    http
      .get('expire', {
        params: {
          paginate: 10,
          page: 0,
          user_id: auth.user.id,
        },
      })
      .then((res) => {
        if (res.data.length) {
          setHasExpireDocument(true);
        }
      });
  }, [auth]);

  useEffect(() => {
    if (showExpireMessage) {
      fetchExpireDocuments();
    }
  }, [showExpireMessage]);

  return (
    hasExpireDocuments &&
    showExpireMessage && (
      <div className="mb-3 flex flex-col items-stretch">
        <Alert color="warning" rounded>
          <span className="ltr:pl-2 rtl:pr-2">
            {t('expire_message_part1')}
            <a href="/dashboard/expire-documents" className="font-bold underline">
              {t('expire_message_here')}
            </a>
            {t('expire_message_part2')}
          </span>
        </Alert>
      </div>
    )
  );
}

export default ExpireDocumentsMessage;
