import React, { useCallback, useMemo } from 'react';
import Select, { components } from 'react-select';
import TranslatedData from '../../TranslatedData';
import { customStyles } from './customStyles';

function MobileSelect({ value, styleType, className, options, isLoading, loadingValue, onChange }) {
  return (
    <select
      style={{ color: value?.color }}
      className={`${customStyles[`${styleType}-mobile`]} ${className} w-full`}
      defaultValue={value?.value || ''}
      value={isLoading ? loadingValue.value : value?.value || ''}
      onChange={(e) => {
        const val = options.find((ele) => {
          if (typeof ele.value === 'number') {
            return ele.value === +e.target.value;
          }
          return ele.value === e.target.value;
        });
        onChange(val);
      }}
    >
      <option disabled value="">
        <TranslatedData en="Select" ar="اختر" />
      </option>

      {isLoading && (
        <option disabled value={loadingValue.value}>
          {loadingValue.label}
        </option>
      )}
      {options.map((ele) => {
        return (
          <option style={{ color: ele.color }} value={ele.value} key={ele.value}>
            {ele.label}
          </option>
        );
      })}
    </select>
  );
}

function DesktopSelect({
  isSearchable,
  isClearable,
  placeholder,
  className,
  loadingValue,
  isLoading,
  styleType,
  value,
  onChange,
  onBlur,
  customStyleConfigs,
  name,
  onMenuClose,
  onMenuOpen,
  isDisabled,
  isMulti,
  options,
  defaultValue,
  getOptionLabel,
  formatOptionLabel,
  getOptionValue,
}) {
  const MultiOption = useCallback((props) => {
    return (
      <components.Option {...props}>
        <div className="flex items-center gap-2">
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />
          <label>{props.label}</label>
        </div>
      </components.Option>
    );
  }, []);

  const MultiValue = useCallback((props) => {
    return `${props.data.label}, `;
  }, []);

  return (
    <Select
      isSearchable={isSearchable}
      classNamePrefix="APP-SELECT"
      placeholder={placeholder || <TranslatedData en="Select" ar="اختر" />}
      className={className}
      value={isLoading ? loadingValue : value}
      options={options}
      styles={{ ...customStyles[styleType], ...customStyleConfigs }}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      onMenuClose={onMenuClose}
      isDisabled={isLoading || isDisabled}
      isClearable={isClearable}
      onMenuOpen={onMenuOpen}
      isMulti={isMulti}
      components={isMulti && { Option: MultiOption, MultiValue }}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={getOptionValue}
    />
  );
}

function AppSelect({
  options,
  onChange,
  value,
  isDisabled,
  isLoading,
  styleType = 'normal',
  onBlur,
  name,
  onMenuClose,
  isClearable,
  onMenuOpen,
  placeholder,
  className,
  customStyleConfigs,
  mobileSelect = true,
  isSearchable = true,
  isMulti = false,
  defaultValue,
  getOptionLabel,
  formatOptionLabel,
  getOptionValue,
}) {
  const loadingValue = useMemo(() => {
    return { label: <TranslatedData ar="تحميل..." en="Loading..." />, value: 'loading' };
  }, []);

  if (window.innerWidth <= 1023 && mobileSelect) {
    return (
      <MobileSelect
        className={className}
        isLoading={isLoading}
        loadingValue={loadingValue}
        onChange={onChange}
        options={options}
        styleType={styleType}
        value={value}
      />
    );
  }

  return (
    <DesktopSelect
      className={className}
      customStyleConfigs={customStyleConfigs}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={isMulti}
      isSearchable={isSearchable}
      loadingValue={loadingValue}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      options={options}
      placeholder={placeholder}
      styleType={styleType}
      value={value}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={getOptionValue}
    />
  );
}

export default AppSelect;
