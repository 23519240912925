import { randomId } from 'javascript-functions';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppSpinner from '../../../../../../components/AppSpinner';
import PreviewButton from '../../../../../../components/FormInputs/AppUpload/PreviewButton';
import SingleAttachmentFilePrint from '../../../../../../components/SharedComponents/SingleAttachmentFilePrint';
import TranslatedData from '../../../../../../components/TranslatedData';
import { AppBody, AppHeader } from '../../../../../../components/html/html';
import { formatDate, isFilePDF } from '../../../../../../helpers/Utils';
import { FundMethodDetails } from './FundMethodDetails';
import { TargetIBANDetails } from './TargetIBANDetails';

function WalletFundActivityPopupContent() {
  const {
    showWalletFundsActivityFormPopup,
    singleWalletFundActivityDetails,
    singleSelectedCardProgramDetails,
    selectedCardProgramDetailsBalance,
  } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const { loaders } = useSelector((state) => state);

  const { t } = useTranslation(['walletV2', 'commonV2']);
  const renderSectionTitle = useCallback((title) => {
    return (
      <AppBody pClass="Body2Medium" className="border-b border-gray-4">
        {title}
      </AppBody>
    );
  }, []);

  const renderSingleTransferContent = useCallback(({ title, value }) => {
    return (
      <div>
        <AppBody pClass="Caption1Regular" className="text-gray-6">
          {title}
        </AppBody>
        <AppBody pClass="Caption1Regular" className="break-words">
          {value}
        </AppBody>
      </div>
    );
  }, []);
  const fundType = useMemo(() => {
    return singleWalletFundActivityDetails?.type.toLowerCase();
  });

  return (
    <div>
      {!showWalletFundsActivityFormPopup && (
        <div className="mt-2 grid gap-2">
          <div className="mb-2 flex items-center justify-center gap-0.5">
            <AppHeader h="h5" className="text-center">
              {fundType === 'deposit' && t('Deposit Details')}
              {fundType === 'transfer' && t('Transfer Details')}
              {fundType === 'withdraw' && t('Withdraw Details')}
            </AppHeader>
          </div>
          <AppBody pClass="Body2Medium" type="span" className="text-center">
            {singleWalletFundActivityDetails?.id}
          </AppBody>
          <div className="mx-auto w-2/3 break-words text-center font-bold">
            <AppBody
              pClass="Body2Medium"
              type="span"
              className="block"
              style={{ color: singleWalletFundActivityDetails?.status?.color }}
            >
              {singleWalletFundActivityDetails?.status?.name}
            </AppBody>
          </div>

          <div className="flex flex-col justify-between self-stretch border-b-4 border-gray-2 border-opacity-40 px-1 py-1">
            <div className="my-1">
              <div className="flex flex-col">
                {fundType === 'deposit' && renderSectionTitle(t('Deposit Details'))}
                {fundType === 'transfer' && renderSectionTitle(t('Transferred From'))}
                {fundType === 'withdraw' && renderSectionTitle(t('Withdraw Details'))}
              </div>

              <div className="my-2 grid w-full grid-cols-3 gap-3">
                <div className="col-span-2">
                  {renderSingleTransferContent({
                    title: t(
                      // eslint-disable-next-line no-nested-ternary
                      fundType === 'transfer'
                        ? 'Card program'
                        : fundType === 'withdraw'
                        ? 'Withdraw debited from ( Card Program )'
                        : 'Deposit Credited in (Card Program)',
                    ),
                    value: (
                      <AppBody pClass="Caption1Regular">
                        {singleSelectedCardProgramDetails.currency} •{' '}
                        {singleSelectedCardProgramDetails.scheme} -{' '}
                        <TranslatedData
                          en={singleSelectedCardProgramDetails.issuer_country.name}
                          ar={singleSelectedCardProgramDetails.issuer_country.name_ar}
                        />
                      </AppBody>
                    ),
                  })}
                </div>

                <div className="col-span-1">
                  {renderSingleTransferContent({
                    title: t('Amount', { ns: 'commonV2' }),
                    value: `${singleWalletFundActivityDetails?.amount} ${singleWalletFundActivityDetails?.currency}`,
                  })}
                  <div className="mt-2">
                    {renderSingleTransferContent({
                      title: t('Date', { ns: 'commonV2' }),
                      value: formatDate(singleWalletFundActivityDetails?.created_at, 'DD.MM.YYYY'),
                    })}
                  </div>
                </div>
              </div>

              <div className="my-1 p-1">
                <div className="flex flex-col">
                  {singleWalletFundActivityDetails?.type?.toLowerCase() === 'deposit' &&
                    renderSectionTitle(t('Deposit Transferred From'))}
                  {singleWalletFundActivityDetails?.type?.toLowerCase() === 'deposit' && (
                    <FundMethodDetails selectedItem={singleWalletFundActivityDetails} />
                  )}
                </div>
                <div className="flex flex-col pt-3">
                  {singleWalletFundActivityDetails?.type?.toLowerCase() === 'deposit' &&
                    renderSectionTitle(t('Deposit Transferred To'))}
                  {singleWalletFundActivityDetails?.type?.toLowerCase() === 'deposit' && (
                    <TargetIBANDetails selectedItem={singleWalletFundActivityDetails} />
                  )}
                </div>
                <div className="flex flex-col">
                  {singleWalletFundActivityDetails?.type?.toLowerCase() === 'withdraw' &&
                    renderSectionTitle(t('Withdraw Transferred To'))}
                  {singleWalletFundActivityDetails?.type?.toLowerCase() === 'withdraw' && (
                    <FundMethodDetails
                      selectedItem={singleWalletFundActivityDetails}
                      type={singleWalletFundActivityDetails?.type?.toLowerCase()}
                    />
                  )}

                  {singleWalletFundActivityDetails?.type?.toLowerCase() === 'transfer' &&
                    renderSectionTitle(t('Transferred To'))}
                  {singleWalletFundActivityDetails?.type?.toLowerCase() === 'transfer' && (
                    <div className="grid grid-cols-3 gap-10 font-light lg:gap-4">
                      <div className="col-span-2">
                        {renderSingleTransferContent({
                          title: t('Card Program'),
                          value: (
                            <AppBody pClass="Caption1Regular">
                              {singleWalletFundActivityDetails?.fundable.currency} •{' '}
                              {singleWalletFundActivityDetails?.fundable.scheme} -{' '}
                              <TranslatedData
                                en={singleWalletFundActivityDetails?.fundable.issuer_country.name}
                                ar={
                                  singleWalletFundActivityDetails?.fundable.issuer_country.name_ar
                                }
                              />
                            </AppBody>
                          ),
                        })}
                      </div>
                      <div className="grid gap-2">
                        {renderSingleTransferContent({
                          title: t('Amount', { ns: 'commonV2' }),
                          value: (
                            <>
                              {!loaders.calculateToAmount && (
                                <AppBody pClass="Caption1Regular" type="span">
                                  {singleWalletFundActivityDetails?.estimated_amount || 'NV'}
                                </AppBody>
                              )}
                              {loaders.calculateToAmount && (
                                <div className="w-12 py-2">
                                  <AppSpinner size={20} />
                                </div>
                              )}
                            </>
                          ),
                        })}

                        {renderSingleTransferContent({
                          title: t('Currency', { ns: 'commonV2' }),
                          value: singleWalletFundActivityDetails?.fundable.currency,
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {singleWalletFundActivityDetails?.type?.toLowerCase() === 'deposit' &&
                singleWalletFundActivityDetails?.attachment_path && (
                  <>
                    <div className="no_print my-1 p-1">
                      <div className="flex flex-col">
                        {renderSectionTitle(t('Uploaded Deposit Transfer Copy'))}
                      </div>
                      {singleWalletFundActivityDetails?.attachment_path && (
                        <div className="mt-4 h-auto w-3/4 overflow-hidden border-gray-3">
                          <PreviewButton
                            href={singleWalletFundActivityDetails?.attachment_path}
                            isPDF={isFilePDF(singleWalletFundActivityDetails?.attachment_type)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="hidden print:block">
                      <SingleAttachmentFilePrint
                        key={randomId()}
                        isPDF={isFilePDF(singleWalletFundActivityDetails?.attachment_type)}
                        path={singleWalletFundActivityDetails?.attachment_path}
                        noTitle
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WalletFundActivityPopupContent;
