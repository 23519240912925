import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppTabs2 from '../../../components/AppTabs2';
import { AppHeader } from '../../../components/html/html';
import { setActiveProfileTabIndexAction } from '../../../redux/ProfileReducer/ProfileActions';
import AgreementContent from './AgreementContent';
import NotificationsContent from './NotificationsContent';
import PersonalInformationContent from './PersonalInformationContent';
import PrivacyContent from './PrivacyContent';

function UserProfile() {
  const { t } = useTranslation('profileV2');
  const dispatch = useDispatch();
  const { activeProfileTabIndex } = useSelector((state) => state.profile);

  const userProfileTabs = useMemo(() => {
    return [
      {
        label: t('Personal Information', { ns: 'commonV2' }),
        content: <PersonalInformationContent />,
        disabled: false,
        show: true,
      },
      {
        label: t('Notifications', { ns: 'commonV2' }),
        content: <NotificationsContent />,
        disabled: true,
        show: true,
      },
      {
        label: t('Privacy', { ns: 'commonV2' }),
        content: <PrivacyContent />,
        disabled: false,
        show: true,
      },
      {
        label: t('Agreement', { ns: 'commonV2' }),
        content: <AgreementContent />,
        disabled: false,
        show: true,
      },
    ];
  }, [t]);

  useEffect(() => {
    dispatch(setActiveProfileTabIndexAction(0));
  }, []);

  return (
    <div className="my-2 h-[98%] overflow-y-auto rounded-lg border border-gray-5 p-6 lg:my-0">
      <AppHeader h="h6" className="mb-4">
        {t('Profile')}
      </AppHeader>

      <div className="flex flex-col gap-8 lg:flex-row lg:gap-2">
        <div className="lg:w-[20%]">
          <AppTabs2
            tabs={userProfileTabs}
            defaultActiveTabIndex={activeProfileTabIndex}
            onTabChange={(tabIndex) => {
              dispatch(setActiveProfileTabIndexAction(tabIndex));
            }}
          />
        </div>
        <div className="lg:w-[80%]">{userProfileTabs[activeProfileTabIndex]?.content}</div>
      </div>
    </div>
  );
}

export default UserProfile;
