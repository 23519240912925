import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {history} from '../../../../AppRouter/history';
import {AppIcon} from "../../../../components/html/html";

function CreateNewCardButton() {
  const { t } = useTranslation('cardsV2');

  const handleNavigateToCreateNewCard = useCallback(() => {
    history.push('/dashboard/my_cards/create-new-card');
  }, []);

  return (
    <>
      <hr className="my-3 h-[2px] bg-gray-6" />

      <button
        type="button"
        className="disabled:opacity-50; mx-auto flex items-center justify-center gap-2 pb-4 text-xs font-semibold hover:underline disabled:cursor-not-allowed"
        onClick={handleNavigateToCreateNewCard}
      >
        <AppIcon iClass="XSmall" className="fa-kit fa-credit-card" />
        <span>{t('Create a New Card')}</span>
      </button>
    </>
  );
}
export default CreateNewCardButton;
