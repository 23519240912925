import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../AppRouter/history';
import { AppIcon } from '../../../../components/html/html';

function CardProgramListButtons() {
  const { t } = useTranslation('walletV2');

  const handleRequestCardProgram = useCallback(() => {}, []);

  return (
    <div className="w-full">
      <button
        type="button"
        className="mx-auto mb-8 flex items-center justify-center gap-2 text-xs font-semibold hover:underline disabled:cursor-not-allowed disabled:opacity-50 lg:mb-0"
        onClick={() => {
          history.push('/dashboard/wallet/add-funding-method?fromUrl=/dashboard/wallet');
        }}
      >
        <AppIcon iClass="Default" className="fa-kit fa-funding" />
        <span>{t('Add Funding Method')}</span>
      </button>

      <hr className="mx-auto my-3 hidden h-[2px] w-[90%] bg-gray-6 lg:block" />

      {false && (
        <button
          type="button"
          className="mx-auto mb-3 flex items-center justify-center gap-2 text-xs font-semibold hover:underline disabled:cursor-not-allowed disabled:opacity-50"
          onClick={() => {}}
        >
          <AppIcon iClass="Default" className="fa-kit fa-credit-card" />
          <span>{t('Request Card Program')}</span>
        </button>
      )}
    </div>
  );
}

export default CardProgramListButtons;
