// eslint-disable-next-line import/no-extraneous-dependencies
import Intercom from '@intercom/messenger-js-sdk';
import { deleteLocalData } from '../../helpers/Utils';

export const setRedirectURLAction = (url) => (dispatch) => {
  dispatch({
    type: 'SET_REDIRECT_URL',
    payload: url,
  });
};

export const setActiveLoginStepAction = (step) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_LOGIN_STEP',
    payload: step,
  });
};

export const setCachedLoginFormDataAction = (loginFormData) => (dispatch) => {
  dispatch({
    type: 'SET_CACHED_LOGIN_STEP_DATA',
    payload: loginFormData,
  });
};

export const setLoggedInUserAction = (userData) => (dispatch) => {
  dispatch({
    type: 'SET_LOGGED_IN_USER',
    payload: userData,
  });
};

export const logOutAction = () => (dispatch) => {
  Intercom({
    app_id: process.env.REACT_APP_INTERCOM_ID,
    hide_default_launcher: true,
  });
  deleteLocalData('userData');
  dispatch(setLoggedInUserAction(null));
};

export const setFinishQuickSignUpAction = (flag) => (dispatch) => {
  dispatch({
    type: 'SET_FINISH_QUICK_SIGN_UP',
    payload: flag,
  });
};
