import { getURLParams } from 'javascript-functions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppSpinner from '../../../../../components/AppSpinner';
import CardDetailsCards from '../../../../../components/SharedComponents/CardDetailsCards';
import CardDetailsHeader from '../../../../../components/SharedComponents/CardDetailsHeader';
import CardDetailsTable from '../../../../../components/SharedComponents/CardDetailsTable';
import { devLog } from '../../../../../helpers/Utils';
import { cardDetailsSectionIds, inPages, transactionTabs } from '../../../../../helpers/constants';
import {
  clearCardRequestsAction,
  clearCardTransactionsAction,
  clearDepartmentsAction,
  clearDocTypesAction,
  clearSelectedCardDetailsAction,
  clearTagsAction,
  getCardDetailsBalanceAction,
  getSelectedCardDetailsAction,
  getUserDetailsDataAction,
  hideAllPopups,
  setActiveTabAction,
} from '../../../../../redux/CardDetailsReducer/CardDetailsActions';
import {
  clearSingleCardAction,
  selectSingleCardAction,
} from '../../../../../redux/CardListReducer/CardListActions';

function CardDetailsPage() {
  const { singleSelectedCardData } = useSelector((state) => state.cardList);
  const { selectedCardDetailsData, selectedCardDetailsBalance } = useSelector(
    (state) => state.cardDetails,
  );
  const { loaders } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { cardId } = useParams();

  useEffect(() => {
    if (cardId) {
      devLog(`[Details Page]`);
      if (
        getURLParams('physicalCardId') &&
        cardId !== getURLParams('physicalCardId') &&
        getURLParams('physicalCardId') !== 'null'
      ) {
        devLog(`[Two Cards Selected]`);
        dispatch(
          selectSingleCardAction({
            virtualCard: { id: cardId },
            physicalCard: { id: getURLParams('physicalCardId') },
          }),
        );
      } else {
        const singleCard = { id: cardId };
        if (getURLParams('physicalCardId') && getURLParams('physicalCardId') !== 'null') {
          devLog(`Display Data for [Physical] Card`);
          dispatch(selectSingleCardAction({ virtualCard: null, physicalCard: singleCard }));
        } else {
          dispatch(selectSingleCardAction({ virtualCard: singleCard, physicalCard: null }));
          devLog(`Display Data for [Virtual] Card`);
        }
      }
    }
  }, [cardId]);

  useEffect(() => {
    if (singleSelectedCardData) {
      dispatch(
        getSelectedCardDetailsAction({
          physicalCard: singleSelectedCardData?.physicalCard,
          virtualCard: singleSelectedCardData?.virtualCard,
        }),
      );
    }
  }, [singleSelectedCardData]);

  // Getting Balance
  useEffect(() => {
    if (selectedCardDetailsData) {
      const singleCardId =
        selectedCardDetailsData.physicalCard?.id || selectedCardDetailsData.virtualCard?.id;
      dispatch(getCardDetailsBalanceAction(singleCardId));
    }
  }, [selectedCardDetailsData]);

  useEffect(() => {
    // using logged in user ID, or target user ID (Details Page without Sidebar)
    dispatch(getUserDetailsDataAction(+getURLParams('userDetailsId')));

    return () => {
      dispatch(clearSelectedCardDetailsAction());
      dispatch(clearTagsAction());
      dispatch(clearDepartmentsAction());
      dispatch(clearDocTypesAction());
      dispatch(clearSingleCardAction());
      dispatch(clearCardTransactionsAction());
      dispatch(clearCardRequestsAction());
      dispatch(hideAllPopups());
      dispatch(setActiveTabAction(transactionTabs.EXPENSES));
    };
  }, []);

  return (
    <div
      className="h-full overflow-y-auto md:px-6 2xl:px-12"
      id={cardDetailsSectionIds.RIGHT_SIDE_CONTAINER}
    >
      {!loaders.getSelectedCardDetailsAction && selectedCardDetailsData && (
        <>
          <div
            className="top-0 z-20 bg-white pb-1 lg:sticky lg:px-0"
            id={cardDetailsSectionIds.CARD_DETAILS_HEADER}
          >
            <CardDetailsHeader
              cardDetails={selectedCardDetailsData}
              cardDetailsBalance={selectedCardDetailsBalance}
              inPage={inPages.myCards}
            />
            <CardDetailsCards cardDetails={selectedCardDetailsData} />
          </div>
          <div>
            <CardDetailsTable inPage={inPages.myCards} cardDetails={selectedCardDetailsData} />
          </div>
        </>
      )}

      {loaders.getSelectedCardDetailsAction && <AppSpinner />}
    </div>
  );
}

export default CardDetailsPage;
