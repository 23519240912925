import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ActionButton from '../../../../components/Button/ActionButton';
import LinkButton from '../../../../components/Button/LinkButton';
import {TextArea} from '../../../../components/FormInputs/Inputs';
import {Details, Info} from '../../../../components/Titles';
import {apiPostRequest} from '../../../../helpers/APIHelper';
import useCapabilities from '../../../../helpers/useCapabilities';
import useNotifications from '../../../../helpers/useNotifications';
import ReceiptPreview from '../Receipt/ReceiptPreview';
import TagsDropdown from '../TagsDropdown';
import {AppBody} from "../../../../components/html/html";

function SplitAttachmentPart({ split, tags, setEditTags }) {
  const { user } = useSelector((state) => state.auth);
  const [currentUserCan] = useCapabilities();
  const [cookies] = useCookies(['token']);
  const { t, i18n } = useTranslation(['transactions', 'common']);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const [, addNotification] = useNotifications();
  const [reason, setReason] = useState(split.reason ?? '');
  const [receipt, setReceipt] = useState(null);
  const [editReceipt, setEditReceipt] = useState(false);
  const [editDescriptionLength, setEditDescriptionLength] = useState(0);
  const [editDescription, setEditDescription] = useState(false);
  const editDescriptionHandler = () => {
    setValue('reason', split && split.reason);
    setEditDescriptionLength(split && split.reason.length);
    setEditDescription(true);
  };

  const onSubmit = (data) => {
    apiPostRequest(
      `transactions/${data.transactionId}`,
      {
        reason: data.reason,
      },
      cookies.token,
      {},
      false,
      'put',
    )
      .then((response) => {
        addNotification(t('des_added'));
        setEditDescription(false);
        setReason(response.reason);
      })
      .catch((err) => {
        setEditDescription(false);
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      });
  };

  useEffect(() => {
    setEditDescription(false);
    if (split.receipts.length > 0) {
      split.receipts.forEach((attachment) => {
        if (attachment?.doc_type === 'Receipt') {
          setReceipt(attachment);
        }
      });
    }
  }, [split]);

  return (
    <div className="my-2 grid w-full grid-cols-2 gap-3">
      <div className="col-span-2 lg:col-span-1">
        <div className="mb-4 mt-6 block">
          <Details className="block text-gray-6">{t('department_input')}</Details>
          <Info className="block">
            {!split.department_id && '---'}
            {i18n.language === 'ar' ? split.department_id?.name_ar : split.department_id?.name}
          </Info>
        </div>
        <div className="mb-4 block">
          <Details className="block text-gray-6">{t('transaction_tag')}</Details>
          <Info className="block">
            {(currentUserCan('add_account_transaction_tag') ||
              currentUserCan('edit_account_transaction_tag')) && (
              <TagsDropdown
                className="w-full"
                transactionID={split.id}
                tags={tags}
                selectedTags={split.tags}
                noTagsEdit={false}
                setEditTags={setEditTags}
              />
            )}
            {!currentUserCan('add_account_transaction_tag') &&
              currentUserCan('add_own_transaction_tag') &&
              user.id === split.user_id?.id &&
              !split.tags && (
                <TagsDropdown
                  className="w-full"
                  transactionID={split.id}
                  tags={tags}
                  selectedTags={split.tags}
                  noTagsEdit={false}
                  setEditTags={setEditTags}
                />
              )}
            {!currentUserCan('edit_account_transaction_tag') &&
              currentUserCan('edit_own_transaction_tag') &&
              user.id === split.user_id?.id &&
              split.tags && (
                <TagsDropdown
                  className="w-full"
                  transactionID={split.id}
                  tags={tags}
                  selectedTags={split.tags}
                  noTagsEdit={false}
                  setEditTags={setEditTags}
                />
              )}
            {!currentUserCan('add_account_transaction_tag') &&
              !currentUserCan('edit_account_transaction_tag') &&
              !currentUserCan('add_own_transaction_tag') &&
              !currentUserCan('edit_own_transaction_tag') && (
                <TagsDropdown
                  className="w-full"
                  transactionID={split.id}
                  tags={tags}
                  selectedTags={split.tags}
                  noTagsEdit
                  setEditTags={setEditTags}
                />
              )}
          </Info>
        </div>
        <div className="block ">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="no_print flex flex-grow flex-col items-start justify-start self-stretch"
          >
            <Details className="relative block flex flex-row text-gray-6">
              {t('description')}{' '}
              {(currentUserCan('add_account_transaction_description') ||
                currentUserCan('edit_account_transaction_description') ||
                (currentUserCan('add_own_transaction_description') &&
                  user.id === split.user_id?.id) ||
                (currentUserCan('edit_own_transaction_description') &&
                  user.id === split.user_id?.id)) &&
                !editDescription && (
                  <LinkButton
                    className="no_print flex flex-row px-2 text-primary"
                    text={
                      split.reason === '' || !split.reason
                        ? t('add', { ns: 'common' })
                        : t('edit', { ns: 'common' })
                    }
                    onClick={editDescriptionHandler}
                  />
                )}
              {editDescription && (
                <ActionButton
                  className="no_print -mt-0.5 flex flex-row px-2 py-0 text-sm font-normal text-primary"
                  text={t('save', { ns: 'common' })}
                  type="submit"
                />
              )}
            </Details>
            <div className="block w-full max-w-xs">
              {!editDescription && <Info>{reason}</Info>}
              {editDescription && (
                <div className="flex w-full flex-col justify-start">
                  <input
                    type="hidden"
                    name="transactionId"
                    id="transactionId"
                    value={split.id}
                    {...register('transactionId')}
                  />
                  <div className="flex flex-grow flex-row self-start self-stretch">
                    <AppBody pClass="Body1Bold" type="label"
                      htmlFor="reason"
                      className="flex flex-grow flex-col"
                    >
                      <TextArea
                        {...register('reason', {
                          required: t('reason_required'),
                        })}
                        existValue={editDescriptionLength}
                        maxLength="150"
                        error={errors.reason}
                        id="reason"
                        className={`${
                          errors.reason ? 'border-red-400' : 'border-gray-3'
                        } mt-0.5 w-full resize-none self-stretch rounded-lg border  p-2`}
                      />
                    </AppBody>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="col-span-2 lg:col-span-1">
        <div className="no_print relative flex flex-col justify-center rounded-lg text-center">
          <ReceiptPreview
            transaction={split}
            setEditReceipt={setEditReceipt}
            editReceipt={editReceipt}
            receipt={receipt}
            setReceipt={setReceipt}
          />
        </div>
      </div>
    </div>
  );
}

export default SplitAttachmentPart;
