import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { history } from '../../../../../AppRouter/history';
import HoverableFontIcon from '../../../../../components/HoverableFontIcon';
import { generateReplaceCardURL } from '../../../../../helpers/Utils';

function SingleCardDetailsReplace({ card, cardDetails }) {
  const { lang } = useSelector((state) => state.appConfig);

  const cardStatus = useMemo(() => {
    return card.status?.key.toUpperCase();
  }, [card, cardDetails]);

  const style = useMemo(() => {
    if (card.is_physical) {
      return { color: 'white' };
    }
    return { color: card?.design?.color_hex ?? '#000000' };
  }, [card, cardStatus]);

  const iconClass = useMemo(() => {
    return lang === 'ar' ? 'fa-kit fa-repleace-ar' : 'fa-kit fa-repleace';
  }, [card, lang, cardStatus]);

  const hoverIconClass = useMemo(() => {
    return lang === 'ar' ? 'fa-kit fa-click-repleace-ar' : 'fa-kit fa-click-repleace';
  }, [card, lang, cardStatus]);

  return (
    <button
      type="button"
      className="absolute bottom-12 left-4"
      onClick={() => {
        history.push(generateReplaceCardURL(card.id));
      }}
    >
      <HoverableFontIcon
        iconClass={iconClass}
        hoverIconClass={hoverIconClass}
        className="text-3xl"
        style={style}
      />
    </button>
  );
}

export default SingleCardDetailsReplace;
