/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {},
  ar: {},
};

const languages = ['en', 'ar'];

const modules = [
  'usersV2',
  'uploadDocuments',
  'quickSignUp',
  'profileV2',
  'walletV2',
  'loginV2',
  'cardsV2',
  'commonV2',
  'common',
  'login',
  'forget',
  'reset',
  'signup',
  'logout',
  'dashboard',
  'funds',
  'users',
  'topup',
  'cards',
  'footer',
  'transactions',
  'profile',
  'expire',
  'wallet',
];

languages.forEach((lang) => {
  modules.forEach((module) => {
    resources[lang][module] = require(`./locales/${lang}/${module}.json`);
  });
});

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources,
});

i18n.languages = languages;

export default i18n;
