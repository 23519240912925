import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {Link, useNavigate} from 'react-router-dom';
import LinkButton from '../../../components/Button/LinkButton';
import LoadingError from '../../../components/LoadingError';
import Tab from '../../../components/Tab';
import {SubTitle} from '../../../components/Titles';
import {apiGetRequest} from '../../../helpers/APIHelper';
import useCapabilities from '../../../helpers/useCapabilities';
import CardTotalBalance from '../Cards/CardTotalBalance';
import CreditCard from '../Cards/CreditCard';
import CardStatusBadge from './CardStatusBadge';
import {AppBody} from "../../../components/html/html";

const UserCards = function (props) {
  const { user, withUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [activeCards, setActiveCards] = useState([]);
  const [archivedCards, setArchivedCards] = useState([]);
  const [errored, setErrored] = useState(false);
  const [cookies] = useCookies(['token']);
  const { t, i18n } = useTranslation(['users']);
  const [currentUserCan] = useCapabilities();
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [showMoreArchived, setShowMoreArchived] = useState(false);
  const [filterStatusID, setFilterStatusID] = useState(1);

  const fetchCards = () => {
    setIsLoading(true);
    apiGetRequest(`cardmanager/cards/${user.id}`, cookies.token, {
      'with[0]': 'cardproduct',
    })
      .then((response) => {
        setCards(response);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchCards();
  }, [user.status, i18n.language]);

  useEffect(() => {
    if (cards.length > 0) {
      setActiveCards(
        cards.filter(
          (card) =>
            card.status &&
            card.status.key.toUpperCase() !== 'REPLACED' &&
            card.cardproduct?.status.toUpperCase() !== 'HIDDEN',
        ),
      );
      setArchivedCards(
        cards.filter(
          (card) =>
            card.status &&
            card.status.key.toUpperCase() === 'REPLACED' &&
            card.cardproduct?.status.toUpperCase() !== 'HIDDEN',
        ),
      );
    }
  }, [cards]);

  return (
    <div className="flex flex-col justify-start pt-10 ltr:text-left rtl:text-right">
      <SubTitle>{t('details.cards')}</SubTitle>

      {!isLoading && !errored && cards.length > 0 && (
        <div className="block flex flex-col justify-end lg:hidden">
          {(currentUserCan('create_account_cards') || currentUserCan('create_own_cards')) && (
            <div>
              {user.status !== 'Suspended' &&
                ((currentUserCan('create_own_cards') && withUser) ||
                  currentUserCan('create_account_cards')) && (
                  <LinkButton
                    className="flex flex-row rounded-xl border border-gray-3 bg-transparent p-2 font-bold ltr:float-right rtl:float-left"
                    text={t('details.create_card')}
                    onClick={() => {
                      navigate(
                        `../${withUser ? 'my_cards' : 'users'}/create-new-card${
                          withUser ? '' : `?createCardUserId=${user.id}`
                        }`,
                      );
                    }}
                  />
                )}
            </div>
          )}
        </div>
      )}
      {isLoading && <Skeleton containerClassName="pt-5" className="pt-5" count={3} />}
      {!isLoading && <LoadingError show={errored} retryCallback={fetchCards} />}
      {!isLoading && !errored && (
        <div className="flex flex-row pt-5">
          <div className="flex flex-grow flex-col">
            {cards.length === 0 &&
              user.status !== 'Suspended' &&
              ((currentUserCan('create_own_cards') && withUser) ||
                currentUserCan('create_account_cards')) && (
                <AppBody type="div" pClass="Body2Regular" className="mb-5 flex flex-row justify-between rounded-b-2xl bg-gray-2 bg-opacity-20 px-2 py-5 bg-blend-multiply ltr:mr-10 rtl:ml-10">
                  <LinkButton
                    className="flex flex-row text-primary"
                    text={t('details.create_card')}
                    onClick={() => {
                      navigate(
                        `../${withUser ? 'my_cards' : 'users'}/create-new-card${
                          withUser ? '' : `?createCardUserId=${user.id}`
                        }`,
                      );
                    }}
                  />
                </AppBody>
              )}

            {cards.length > 0 && (
              <div className="mx-0 block flex w-full flex-col lg:hidden lg:ltr:ml-10 lg:rtl:mr-10 ">
                <CreditCard name={cards.length > 0 ? cards[0].name_on_card : ''} />
              </div>
            )}

            {cards.length > 0 && (
              <div className="mb-4 flex flex-row ltr:text-left rtl:text-right">
                <Tab
                  currentStatusID={filterStatusID}
                  setFilterStatusID={setFilterStatusID}
                  statusID={1}
                  text={t('tab.active')}
                />
                <Tab
                  currentStatusID={filterStatusID}
                  setFilterStatusID={setFilterStatusID}
                  statusID={2}
                  text={t('tab.archived')}
                />
              </div>
            )}

            {filterStatusID === 1 &&
              activeCards.map((card, index) => (
                <div
                  className={`${
                    !showMore && index > 2 ? 'hidden' : 'visible'
                  } hover:bg-gray-5 hover:bg-opacity-25 hover:bg-blend-multiply`}
                  key={card.id}
                >
                  <AppBody type="div" pClass="Body2Regular" className="mb-5 flex flex-row justify-between rounded-b-2xl bg-gray-2 bg-opacity-20 px-2 py-5 bg-blend-multiply">
                    <div>
                      <Link
                        to={`/dashboard/users/my_cards/${card.id}?userDetailsId=${
                          user.id
                        }&physicalCardId=${card.is_physical ? card.id : null}`}
                      >
                        <AppBody type="span" pClass="Body1Bold" className="block ltr:pr-3 rtl:pl-3">
                          {card.is_physical ? t('details.plastic_card') : t('details.virtual_card')}
                          <span className="hidden font-normal lg:inline">
                            {' '}
                            **
                            {card.last_four}
                          </span>
                        </AppBody>
                        <span className="block lg:hidden ltr:text-left rtl:text-right">
                          {' '}
                          **
                          {card.last_four}
                        </span>
                      </Link>
                    </div>
                    <div>
                      <span className="px-4" style={{ minWidth: '100px' }}>
                        <CardTotalBalance cardId={card.id} />
                      </span>
                      {card.status && (
                        <CardStatusBadge status={card.status.name} color={card.status.color} />
                      )}
                    </div>
                  </AppBody>
                </div>
              ))}
            {filterStatusID === 1 && activeCards.length > 3 && (
              <LinkButton
                text={showMore ? t('view_less') : t('view_more')}
                className="text-left text-primary underline"
                onClick={() => setShowMore(!showMore)}
              />
            )}

            {filterStatusID === 2 &&
              archivedCards.map((card, index) => (
                <div
                  className={!showMoreArchived && index > 2 ? 'hidden' : 'visible'}
                  key={card.id}
                >
                  <AppBody type="div" pClass="Body2Regular" className="mb-5 flex flex-row justify-between rounded-b-2xl bg-gray-2 bg-opacity-20 px-2 py-5 bg-blend-multiply">
                    <div>
                      <Link
                        to={`/dashboard/users/my_cards/${card.id}?userDetailsId=${
                          user.id
                        }&physicalCardId=${card.is_physical ? card.id : null}`}
                      >
                        <AppBody type="span" pClass="Body1Bold" className="block ltr:pr-3 rtl:pl-3">
                          {card.is_physical ? t('details.plastic_card') : t('details.virtual_card')}
                          <span className="hidden font-normal lg:inline">
                            {' '}
                            **
                            {card.last_four}
                          </span>
                        </AppBody>
                        <span className="block lg:hidden ltr:text-left rtl:text-right">
                          {' '}
                          **
                          {card.last_four}
                        </span>
                      </Link>
                    </div>
                    <div>
                      <span className="px-4" style={{ minWidth: '100px' }}>
                        <CardTotalBalance cardId={card.id} />
                      </span>
                      {card.status && (
                        <CardStatusBadge status={card.status.name} color={card.status.color} />
                      )}
                    </div>
                  </AppBody>
                </div>
              ))}
            {filterStatusID === 2 && archivedCards.length > 3 && (
              <LinkButton
                text={showMoreArchived ? t('view_less') : t('view_more')}
                className="text-left text-primary underline"
                onClick={() => setShowMoreArchived(!showMoreArchived)}
              />
            )}
          </div>
          {cards.length > 0 && (
            <div className="flex hidden flex-col lg:block ltr:ml-10 rtl:mr-10 ">
              <CreditCard name={cards.length > 0 ? cards[0].name_on_card : ''} />
              {(currentUserCan('create_account_cards') || currentUserCan('create_own_cards')) && (
                <div className=" m-auto text-center">
                  {user.status !== 'Suspended' &&
                    ((currentUserCan('create_own_cards') && withUser) ||
                      currentUserCan('create_account_cards')) && (
                      <LinkButton
                        className="m-auto flex flex-row rounded-xl border border-gray-3 bg-transparent p-2 text-center font-bold"
                        text={t('details.create_card')}
                        onClick={() => {
                          navigate(
                            `../${withUser ? 'my_cards' : 'users'}/create-new-card${
                              withUser ? '' : `?createCardUserId=${user.id}`
                            }`,
                          );
                        }}
                      />
                    )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserCards;
