import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../AppRouter/history';
import { AppBody, AppIcon } from '../../../components/html/html';
import { usersStatus } from '../../../helpers/constants';
import useCapabilities from '../../../helpers/useCapabilities';
import useClickOutside from '../../../hooks/useClickOutside';
import { showModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import {
  changeUserStatusAction,
  getSingleUserDetailsAction,
  resendUserActivationEmailAction,
} from '../../../redux/UsersReducer/UsersActions';
import EditSingleUserPopup from './SingleUserDetails/EditSingleUserPopup';
import SingleUserStatusBadge from './SingleUserDetails/SingleUserStatusBadge';

function SingleUserRow({ item, isLastEl, setShowOptionsPopup, showOptionsPopup }) {
  const [currentUserCan] = useCapabilities();
  const { t } = useTranslation('usersV2');
  const { isMobile } = useSelector((state) => state.appConfig);
  const { loaders, auth } = useSelector((state) => state);
  const popupRef = useRef();
  const dispatch = useDispatch();

  useClickOutside(popupRef, () => {
    setShowOptionsPopup({});
  });

  const navigateToSingleUserDetails = useCallback(() => {
    history.push(`/dashboard/users-v2/${item.id}`);
  }, [item]);

  const renderOptionsPopup = useCallback(() => {
    const isActive = item.status === 'Active';
    const isPending = item.status === usersStatus.Pending;
    const isSuspended = item.status === usersStatus.Suspended;
    const isDeactivated = item.status === usersStatus.Deactivate;
    const isBanned = item.status === usersStatus.Banned;

    const notSameLoggedInUser = item.id !== auth.user.id;
    const notAccountOwner = !item.roles.includes('Account-Owner');

    const canChangeStatus =
      currentUserCan('update_account') && notAccountOwner && notSameLoggedInUser;

    return (
      <button
        type="button"
        className="relative me-1.5"
        onClick={() => {
          setShowOptionsPopup({
            [item.id]: !showOptionsPopup[item.id],
          });
        }}
      >
        <AppIcon className="fa-regular fa-ellipsis-vertical h-6 w-6" iClass="LargeFont" />

        {showOptionsPopup[item.id] && (
          <div ref={popupRef}>
            <AppBody
              pClass="Caption1Regular"
              type="div"
              className={`absolute end-0 z-10 grid min-w-36 rounded bg-white p-2 text-start shadow ${
                isLastEl ? 'bottom-8' : ''
              }`}
            >
              {!isBanned && (
                <span
                  className="block py-1 ps-2 hover:bg-gray-1"
                  onClick={() => {
                    if (isMobile) {
                      history.push(`/dashboard/users-v2/${item.id}/edit?backToList=true`);
                    } else {
                      dispatch(getSingleUserDetailsAction({ userId: item.id }));
                      dispatch(showModalAction(<EditSingleUserPopup />));
                    }
                  }}
                >
                  {t('Edit', { ns: 'commonV2' })}
                </span>
              )}
              <span
                className="block py-1 ps-2 hover:bg-gray-1"
                onClick={navigateToSingleUserDetails}
              >
                {t('View Details')}
              </span>

              {canChangeStatus && (isSuspended || isDeactivated) && (
                <span
                  className="block py-1 ps-2 hover:bg-gray-1"
                  onClick={() => {
                    dispatch(changeUserStatusAction({ userId: item.id, status: 'Active' }));
                  }}
                >
                  {t('Reactivate')}
                </span>
              )}

              {canChangeStatus && (isActive || isDeactivated) && (
                <span
                  className="block py-1 ps-2 hover:bg-gray-1"
                  onClick={() => {
                    dispatch(changeUserStatusAction({ userId: item.id, status: 'Suspended' }));
                  }}
                >
                  {t('Suspend')}
                </span>
              )}

              {canChangeStatus && (isActive || isSuspended) && (
                <span
                  className="block py-1 ps-2 hover:bg-gray-1"
                  onClick={() => {
                    dispatch(changeUserStatusAction({ userId: item.id, status: 'Deactivate' }));
                  }}
                >
                  {t('Deactivate(v)')}
                </span>
              )}

              {isPending && (
                <span
                  className="block py-1 ps-2 hover:bg-gray-1"
                  onClick={() => {
                    dispatch(resendUserActivationEmailAction({ userId: item.id }));
                  }}
                >
                  {t('Resend Activation')}
                </span>
              )}
            </AppBody>
          </div>
        )}
      </button>
    );
  }, [item, showOptionsPopup, auth, isMobile]);

  return (
    <div
      className={`flex justify-between border-gray-3 py-4 pe-4 ps-2 xxs:ps-4 lg:py-6 lg:pe-3 lg:ps-9 ${
        !isLastEl ? 'border-b' : 'border-0'
      }`}
    >
      <div onClick={navigateToSingleUserDetails} className="cursor-pointer">
        <AppBody pClass="Body1Bold" className="mb-0.5 capitalize">
          <span>{item.first_name}</span> <span>{item.last_name}</span>
        </AppBody>
        <AppBody pClass="Caption1Medium" className="mb-0.5 capitalize">
          {item.job_title}
        </AppBody>
        <AppBody pClass="Caption1Medium" className="text-gray-6">
          <span>{item.mobile}</span> - <span>{item.email}</span>
        </AppBody>
      </div>

      <div className="flex items-center justify-center">
        <div>
          <SingleUserStatusBadge
            userDetails={item}
            isLoading={
              loaders[`changeUserStatusAction[${item.id}]`] ||
              loaders[`resendUserActivationEmailAction[${item.id}]`]
            }
          />
        </div>
        <div>{renderOptionsPopup()}</div>
        <a
          href={`${window.location.origin}/dashboard/users-v2/${item.id}`}
          target="_blank"
          rel="noreferrer"
          className="hidden lg:inline"
        >
          <AppIcon
            className="fa-regular fa-arrow-up-right-from-square text-primary"
            iClass="BaseFont"
          />
        </a>
      </div>
    </div>
  );
}

export default SingleUserRow;
