import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import LinkButton from '../../../components/Button/LinkButton';
import TableDropDown, {useDropdown} from '../../../components/TableDropDown';
import useCapabilities from '../../../helpers/useCapabilities';
import {AppBody} from "../../../components/html/html";

function DropdownHeader({ collapsed, setCollapsed, className, setShowOnTop, text }) {
  return (
    <button
      type="button"
      onClick={() => {
        if (!collapsed) {
          setShowOnTop(false);
        }
        setCollapsed(!collapsed);
      }}
      className={`flex flex-row ${className}`}
    >
      <AppBody pClass="Body1Bold" type="span">{text}</AppBody>
    </button>
  );
}

function TransactionDropdown({ text, transaction, className, inDetails }) {
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation(['transactions']);
  const navigate = useNavigate();
  const { collapsed, setCollapsed, wrapperRef, setShowOnTop } = useDropdown();

  const [currentUserCan] = useCapabilities();

  return (
    <div ref={wrapperRef} className="relative inline-block">
      <TableDropDown
        header={
          <DropdownHeader
            text={text}
            setCollapsed={setCollapsed}
            setShowOnTop={setShowOnTop}
            collapsed={collapsed}
            className={className}
          />
        }
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        wrapperRef={wrapperRef}
        innerWrapperClassName="py-5 border border-2 bg-white border-white w-40 ltr:right-0 rtl:left-0 transition-all shadow-md ltr:text-left rtl:text-right z-40"
      >
        <div className="w-full">
          {!inDetails && (
            <LinkButton
              className="flex w-full flex-row px-4 pb-2 pt-1 text-sm hover:bg-gray-5 hover:bg-opacity-25 hover:bg-blend-multiply ltr:font-light rtl:font-normal"
              text={t('view_details')}
              onClick={() => {
                navigate(`../transactions/details/${transaction.id}`);
              }}
            />
          )}

          {(currentUserCan('create_account_transaction_splits') ||
            (currentUserCan('create_own_transaction_splits') &&
              user.id === transaction.user_id?.id)) &&
            Array.isArray(transaction.splits) &&
            transaction.splits.length === 0 &&
            transaction.transaction_type.toLowerCase() === 'auth' &&
            transaction.status.toLowerCase() !== 'declined' && (
              <LinkButton
                className="flex w-full flex-row px-4 pb-2 pt-1 text-sm hover:bg-gray-5 hover:bg-opacity-25 hover:bg-blend-multiply ltr:font-light rtl:font-normal"
                text={t('split_transaction')}
                onClick={() => {
                  navigate(`../transactions/split/${transaction.id}`);
                }}
              />
            )}

          {(currentUserCan('edit_account_transaction_splits') ||
            (currentUserCan('edit_own_transaction_splits') &&
              user.id === transaction.user_id?.id)) &&
            Array.isArray(transaction.splits) &&
            transaction.splits.length > 0 &&
            transaction.transaction_type.toLowerCase() === 'auth' &&
            transaction.status.toLowerCase() !== 'declined' && (
              <LinkButton
                className="flex w-full flex-row px-4 pb-2 pt-1 text-sm hover:bg-gray-5 hover:bg-opacity-25 hover:bg-blend-multiply ltr:font-light rtl:font-normal"
                text={t('edit_split')}
                onClick={() => {
                  navigate(`../transactions/split/edit/${transaction.id}`);
                }}
              />
            )}
        </div>
      </TableDropDown>
    </div>
  );
}

export default TransactionDropdown;
