import {useCookies} from 'react-cookie';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import LinkButton from '../../../components/Button/LinkButton';
import TableDropDown, {useDropdown} from '../../../components/TableDropDown';
import {apiGetRequest} from '../../../helpers/APIHelper';
import {setAction} from '../../../helpers/ActionsHelper';
import {userStatusHandler} from '../../../helpers/CardHelper';
import useCapabilities from '../../../helpers/useCapabilities';
import useNotifications from '../../../helpers/useNotifications';
import {UPDATE_EMPLOYEE} from '../../../redux/EmployeesReducer';
import UserActionMenuButton from './Common';
import {AppBody} from "../../../components/html/html";

function DropdownHeader({ collapsed, setCollapsed, className, setShowOnTop, text }) {
  return (
    <button
      type="button"
      onClick={() => {
        if (!collapsed) {
          setShowOnTop(false);
        }
        setCollapsed(!collapsed);
      }}
      className={`flex flex-row ${className}`}
    >
        <AppBody type="span" pClass="Body1Bold" className="-mt-1.5 inline-block h-full w-full">{text}</AppBody>
    </button>
  );
}

function UserDropdown({ text, user, className, inDetails, authUser }) {
  const { t } = useTranslation(['users']);
  const navigate = useNavigate();
  const [cookies] = useCookies(['token']);
  const dispatch = useDispatch();
  const { collapsed, setCollapsed, showOnTop, wrapperRef, setShowOnTop } = useDropdown();
  const [, addNotification] = useNotifications();
  const [currentUserCan] = useCapabilities();

  return (
    <div ref={wrapperRef} className="relative">
      <TableDropDown
        header={
          <DropdownHeader
            text={text}
            setCollapsed={setCollapsed}
            setShowOnTop={setShowOnTop}
            collapsed={collapsed}
            className={className}
          />
        }
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        wrapperRef={wrapperRef}
        innerWrapperClassName="py-5 border border-2 bg-white border-white w-40 ltr:right-0 rtl:left-0 transition-all shadow-md ltr:text-left rtl:text-right z-40"
      >
        <div className="w-full">
          <LinkButton
            className="flex w-full flex-row px-4 pb-2 pt-1 text-sm hover:bg-gray-5 hover:bg-opacity-25 hover:bg-blend-multiply ltr:font-light rtl:font-normal"
            text={t('details.edit')}
            onClick={() => {
              navigate(`../users/edit/${user.id}`);
            }}
          />
          {!inDetails && (
            <LinkButton
              className="flex w-full flex-row px-4 pb-2 pt-1 text-sm hover:bg-gray-5 hover:bg-opacity-25 hover:bg-blend-multiply ltr:font-light rtl:font-normal"
              text={t('details.view_details')}
              onClick={() => {
                navigate(`../users/${user.id}`);
              }}
            />
          )}
          {user.status === 'Pending' && (
            <UserActionMenuButton
              text={t('details.resend_activation')}
              onClick={() => apiGetRequest(`employees/email/resend/${user.id}`, cookies.token)}
            />
          )}
          {user.status !== 'Pending' &&
            user.id !== authUser.id &&
            !user.roles.includes('Account-Owner') &&
            currentUserCan('update_account') && (
              <>
                {(user.status === 'Deactivate' || user.status === 'Suspended') && (
                  <UserActionMenuButton
                    text={t('details.active')}
                    onClick={() =>
                      userStatusHandler(user.id, 'Active', cookies.token)
                        .then((response) => {
                          setCollapsed(!collapsed);
                          dispatch(setAction(UPDATE_EMPLOYEE, response));
                          addNotification(t('status_success'));
                        })
                        .catch((err) => {
                          addNotification(
                            (err && err.data && err.data.error) ||
                              t('unknown_error', { ns: 'common' }),
                            false,
                          );
                        })
                    }
                  />
                )}
                {(user.status === 'Active' || user.status === 'Deactivate') && (
                  <UserActionMenuButton
                    text={t('details.suspend')}
                    onClick={() =>
                      userStatusHandler(user.id, 'Suspended', cookies.token)
                        .then((response) => {
                          setCollapsed(!collapsed);
                          dispatch(setAction(UPDATE_EMPLOYEE, response));
                          addNotification(t('status_success'));
                        })
                        .catch((err) => {
                          addNotification(
                            (err && err.data && err.data.error) ||
                              t('unknown_error', { ns: 'common' }),
                            false,
                          );
                        })
                    }
                  />
                )}
                {(user.status === 'Active' || user.status === 'Suspended') && (
                  <UserActionMenuButton
                    text={t('details.deactivate')}
                    onClick={() =>
                      userStatusHandler(user.id, 'Deactivate', cookies.token)
                        .then((response) => {
                          setCollapsed(!collapsed);
                          dispatch(setAction(UPDATE_EMPLOYEE, response));
                          addNotification(t('status_success'));
                        })
                        .catch((err) => {
                          addNotification(
                            (err && err.data && err.data.error) ||
                              t('unknown_error', { ns: 'common' }),
                            false,
                          );
                        })
                    }
                  />
                )}
              </>
            )}
        </div>
      </TableDropDown>
    </div>
  );
}

export default UserDropdown;
