import { ReactComponent as BlueSpinner } from '../../assets/svg/BlueLoading.svg';
import { ReactComponent as Plus } from '../../assets/svg/plus.svg';
import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';

const NewButton = function (props) {
  const { text, primary, onClick, type, className, isLoading, disabled } = props;
  return (
    <button
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={type || 'button'}
      className={`${className || ''}  ${
        text === '' ? 'h-10 w-10 px-1' : 'lg:h:auto h-10 w-10 px-1 lg:w-auto  lg:px-5'
      } rounded-full py-1 font-bold ${primary ? 'disabled:bg-gray-3' : ''} ${
        className && className.includes('text-primary') ? 'text-primary' : 'text-black'
      }  ${primary ? 'bg-black text-white' : 'bg-none'}`}
      disabled={isLoading || disabled}
    >
      <p className={`${isLoading ? 'pr-5' : ''} lg:text-md m-0 w-full p-0 text-center`}>
        <span className="inline-block text-3xl lg:text-xl" style={{ lineHeight: '1.8rem' }}>
          <Plus className="m-auto pt-2" />{' '}
        </span>
        <span className="hidden lg:inline">{text}</span>
      </p>
      {isLoading && className && className.includes('text-primary') && (
        <BlueSpinner className="h-6 w-6 animate-spin fill-white" />
      )}
      {isLoading && !className && (
        <Spinner
          className={`${
            className && className.includes('text-primary') ? 'fill-lightGray' : 'fill-white'
          } h-6 w-6 animate-spin`}
        />
      )}
      {isLoading && className && !className.includes('text-primary') && (
        <Spinner className="h-6 w-6 animate-spin fill-white" />
      )}
    </button>
  );
};

export default NewButton;
