/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip } from 'react-tooltip';

function AppTooltip({ children, id, text, place = 'top' }) {
  return (
    <>
      <a data-tooltip-id={id}>{children}</a>
      <Tooltip
        id={id}
        place={place}
        className="appTooltip z-50 bg-slate-50 text-sm font-normal leading-4 shadow-md"
        style={{
          color: 'rgb(71 85 105)',
          backgroundColor: 'rgb(248 250 252)',
          fontSize: '.75rem',
        }}
      >
        {text}
      </Tooltip>
    </>
  );
}

export default AppTooltip;
