import { AppHeader } from '../html/html';
import CardListMockContent from './CardListMockContent';
import CreateNewCardMockContent from './CreateNewCardMockContent';

function UseMockButtonContent() {
  return (
    <div className="max-h-[90vh] max-w-[90%] overflow-y-auto rounded-lg border bg-stone-100 p-5 shadow">
      {/* // ============== Create New Card Mocks ============== // */}
      <AppHeader h="h6">Create New Card Mocks</AppHeader>
      <div className="mb-5 rounded-xl border bg-white p-5">
        <CreateNewCardMockContent />
      </div>

      {/* // ============== Card List Mocks ============== // */}
      <AppHeader h="h6">Card List Mocks</AppHeader>
      <div className="rounded-xl border bg-white p-5">
        <CardListMockContent />
      </div>
    </div>
  );
}

export default UseMockButtonContent;
