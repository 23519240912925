import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ActionButton from '../../../components/Button/ActionButton';
import BackButton from '../../../components/Button/BackButton';
import LinkButton from '../../../components/Button/LinkButton';
import CustomPhone from '../../../components/FormInputs/CustomPhone';
import {SelectInputCustom, TextInput} from '../../../components/FormInputs/Inputs';
import LoadingError from '../../../components/LoadingError';
import {LargerTitle2} from '../../../components/Titles';
import {apiGetRequest, apiPostFormDataRequest} from '../../../helpers/APIHelper';
import {EmailValidationPattern, NameValidationPattern} from '../../../helpers/RegexPatterns';
import useNotifications from '../../../helpers/useNotifications';
import Head from '../../Head';
import {AppBody} from "../../../components/html/html";

const AddUser = function () {
  const { t, i18n } = useTranslation(['users', 'common']);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    control,
    setValue,
  } = useForm({ mode: 'onBlur' });

  const [userTypes, setUserTypes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [errored, setErrored] = useState(false);
  const [showTypesHint, setShowTypesHint] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [, addNotification] = useNotifications();
  const [cookies] = useCookies(['token']);
  const navigate = useNavigate();

  const loadUserTypes = () => {
    apiGetRequest('employees/types', cookies.token)
      .then((response) => {
        setUserTypes(response);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      });
  };

  const loadDepartment = () => {
    apiGetRequest('departments', cookies.token)
      .then((response) => {
        setDepartments(response);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      });
  };

  useEffect(() => {
    loadUserTypes();
    loadDepartment();
    clearErrors();
  }, [i18n.language]);

  const onSubmit = (data) => {
    setIsSaving(true);

    const form = new FormData();
    form.append('first_name', data.first_name);
    form.append('last_name', data.last_name);
    form.append('email', data.email);
    form.append('mobile', data.mobile);
    form.append('job_title', data.job_title);
    form.append('role', data.type);
    form.append('department_id', data.department_id);

    apiPostFormDataRequest('employees', form, cookies.token)
      .then((response) => {
        navigate(-1);
        addNotification(t('add.add_success'));
      })
      .catch((err) => {
        addNotification(
          (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
          false,
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div className="mx-auto flex w-full flex-col lg:w-10/12 ltr:text-left rtl:text-right">
      <Head title="Add User" />

      <div className="grid grid-cols-6">
        <div className="col-span-6 flex flex-col justify-start">
          <BackButton />
          <LargerTitle2>{t('add.title')}</LargerTitle2>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-6">
        <div className="col-span-6 pt-10">
          <div className="grid grid-cols-2 gap-5 pb-5">
            <AppBody type="label" pClass="Body2Bold"
              htmlFor="user_first_name"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('add.inputs.first_name')}
              <TextInput
                error={errors.first_name}
                id="user_first_name"
                {...register('first_name', {
                  required: t('add.validation.required.first_name'),
                  pattern: {
                    value: NameValidationPattern,
                    message: t('add.validation.patterns.first_name'),
                  },
                })}
              />
            </AppBody>
            <AppBody type="label" pClass="Body2Bold"
              htmlFor="user_last_name"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('add.inputs.last_name')}
              <TextInput
                error={errors.last_name}
                id="user_last_name"
                {...register('last_name', {
                  required: t('add.validation.required.last_name'),
                  pattern: {
                    value: NameValidationPattern,
                    message: t('add.validation.patterns.last_name'),
                  },
                })}
              />
            </AppBody>
          </div>
          <div className="grid grid-cols-2 gap-5 pb-5">
            <AppBody type="label" pClass="Body2Bold"
              htmlFor="user_email"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('add.inputs.email')}
              <TextInput
                error={errors.email}
                id="user_email"
                {...register('email', {
                  required: t('add.validation.required.email'),
                  pattern: {
                    value: EmailValidationPattern,
                    message: t('add.validation.patterns.email'),
                  },
                })}
              />
            </AppBody>
            <AppBody type="label" pClass="Body2Bold"
              htmlFor="mobile"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('add.inputs.mobile')}
              <CustomPhone
                name="mobile"
                control={control}
                errors={errors.mobile}
                patternError={t('add.validation.required.mobile')}
                requiredString={t('add.validation.patterns.mobile')}
              />
            </AppBody>
          </div>
          <div className="grid grid-cols-2 gap-5 pb-5">
            <AppBody type="label" pClass="Body2Bold"
              htmlFor="user_job_title"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('add.inputs.job_title')}
              <TextInput
                error={errors.job_title}
                id="user_job_title"
                {...register('job_title', {
                  required: t('add.validation.required.job_title'),
                  pattern: {
                    value: NameValidationPattern,
                    message: t('add.validation.patterns.job_title'),
                  },
                })}
              />
            </AppBody>
            <AppBody type="label" pClass="Body2Bold"
              htmlFor="department_id"
              className="col-span-2 flex flex-col lg:col-span-1"
            >
              {t('add.inputs.department')}
              {departments.length > 0 && (
                <SelectInputCustom
                  onChange={(e) => {
                    setValue('department_id', e);
                  }}
                  error={errors.department_id}
                  name="department_id"
                  id="department_id"
                  control={control}
                  rules={{
                    required: t('add.validation.required.department'),
                  }}
                  options={
                    i18n.language === 'ar'
                      ? departments.map(({ id: value, name_ar: label, ...rest }) => ({
                          value,
                          label,
                          ...rest,
                        }))
                      : departments.map(({ id: value, name: label, ...rest }) => ({
                          value,
                          label,
                          ...rest,
                        }))
                  }
                />
              )}
              {departments.length === 0 && !errored && (
                <span className="pt-5 font-normal">{t('add.inputs.loading')}</span>
              )}
            </AppBody>
          </div>
          <div className="grid grid-cols-2 gap-5 pb-5">
            <AppBody type="label" pClass="Body2Bold"
              htmlFor="user_type"
              className="col-span-2 flex flex-col"
            >
              {t('add.inputs.user_type')}
              {userTypes.length > 0 && (
                <SelectInputCustom
                  onChange={(e) => {
                    setValue('type', e);
                  }}
                  error={errors.type}
                  name="type"
                  id="type"
                  control={control}
                  rules={{ required: t('add.validation.required.type') }}
                  options={userTypes.map(({ key: value, value: label, ...rest }) => ({
                    value,
                    label,
                    ...rest,
                  }))}
                />
              )}
              {userTypes.length === 0 && !errored && (
                <span className="pt-5 font-normal">{t('add.inputs.loading')}</span>
              )}
              <LoadingError show={errored} retryCallback={loadUserTypes} />
              {userTypes.length > 0 && (
                <div className="flex flex-col">
                  {!showTypesHint && (
                    <div className="flex flex-row items-baseline justify-end pb-5">
                      <LinkButton
                        className="flex flex-row text-primary"
                        text={t('add.user_types_hint')}
                        onClick={() => {
                          setShowTypesHint(true);
                        }}
                      />
                    </div>
                  )}
                  {showTypesHint && (
                    <div className="pt-5">
                      {userTypes.map((type) => (
                        <div className="grid w-full grid-cols-3 pb-3 ">
                          <div className="col-span-1">{type.value}:</div>
                          <AppBody type="div" pClass="Body2Regular" className="col-span-2 text-gray-6">
                            {type.description}
                          </AppBody>
                        </div>
                      ))}

                      <div className="flex flex-row items-baseline justify-end pb-5">
                        <LinkButton
                          className="flex flex-row text-primary"
                          text={t('add.user_types_hint_hide')}
                          onClick={() => {
                            setShowTypesHint(false);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </AppBody>
          </div>
        </div>
        <div className="col-span-8 mt-10 flex flex-row items-baseline justify-end pt-2">
          <ActionButton
            className="flex flex-row"
            text={t('cancel', { ns: 'common' })}
            onClick={() => {
              navigate(-1);
            }}
          />
          <ActionButton
            className="flex flex-row"
            isLoading={isSaving}
            primary
            text={t('save', { ns: 'common' })}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default AddUser;
