function NqoodCardDesign({ name, expiry, number, isBlack, nqoodCardDesign }) {
  const textColor = isBlack ? 'text-white' : 'text-black';
  return (
    <div
      className={`m-auto flex h-[181px] w-72 flex-col justify-end rounded-2xl bg-contain bg-no-repeat p-4 shadow ${
        isBlack ? 'border-black bg-black ' : ''
      } `}
      style={{ backgroundImage: !isBlack ? `url(${nqoodCardDesign})` : null }}
    >
      <div className="w-full" style={{ direction: 'ltr' }}>
        <div
          className={`mb-3 flex justify-between uppercase ${textColor} `}
          style={{ direction: 'ltr' }}
        >
          <span>{name}</span>
          <span>{expiry}</span>
        </div>
        <span className="block w-full text-left" style={{ direction: 'ltr' }}>
          <span className={` ${textColor}`}>*</span>
          <span className={` ${textColor} ps-1`}>{number}</span>
        </span>
      </div>
    </div>
  );
}

export default NqoodCardDesign;
