import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeCardSelection } from '../../../../helpers/Utils';
import useCapabilities from '../../../../helpers/useCapabilities';
import {
  getCardListAction,
  selectSingleCardAction,
} from '../../../../redux/CardListReducer/CardListActions';
import CardListColumn from './CardListColumn';
import CreateNewCardButton from './CreateNewCardButton';

function CardList() {
  const { auth } = useSelector((state) => state);
  const [currentUserCan] = useCapabilities();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCardListAction());
  }, []);

  useEffect(() => {
    const { virtualCard, physicalCard } = initializeCardSelection();

    if (virtualCard) dispatch(selectSingleCardAction({ virtualCard, physicalCard }));
  }, []);

  const canCreateNewCard = useMemo(() => {
    const notSuspendedUser = auth.status !== 'Suspended';
    const userCanCreateNewCard =
      currentUserCan('create_own_cards') || currentUserCan('create_account_cards');

    return notSuspendedUser && userCanCreateNewCard;
  }, [auth]);

  return (
    <>
      <div className="flex h-full flex-col justify-between">
        <CardListColumn />
      </div>
      <div>{canCreateNewCard && <CreateNewCardButton />}</div>
    </>
  );
}

export default CardList;
