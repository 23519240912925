import { randomId } from 'javascript-functions';
import React from 'react';
import { useSelector } from 'react-redux';
import TransactionTableRow from '../../../../../components/SharedComponents/TransactionTableRow';
import { DynamicElement } from '../../../../../components/html/html';
import { formatDate } from '../../../../../helpers/Utils';
import { inPages } from '../../../../../helpers/constants';

const WalletTransactionsTableBody = ({ transactionsDates, walletTransactions, activeTab }) => {
  const { isMobile } = useSelector((state) => state.appConfig);

  return Object.values(transactionsDates).map((tDate) => {
    return (
      <React.Fragment key={randomId()}>
        <DynamicElement element={isMobile ? 'div' : 'tr'} className="hover:bg-transparent">
          <DynamicElement element={isMobile ? 'div' : 'td'} className="min-w-28 py-3 font-semibold">
            {tDate}
          </DynamicElement>
        </DynamicElement>

        {walletTransactions?.data?.map((item) => {
          const isSplit = Boolean(item.splits?.length);

          return (
            formatDate(item.transaction_timestamp, 'DD MMM YYYY') === tDate && (
              <React.Fragment key={item.id}>
                <TransactionTableRow item={item} inPage={inPages.wallet} activeTab={activeTab} />

                {isSplit &&
                  item.splits.map((splitItem, splitItemIndex) => {
                    return (
                      <TransactionTableRow
                        activeTab={activeTab}
                        inPage={inPages.wallet}
                        key={splitItem.id}
                        item={splitItem}
                        isSplit
                        isLast={splitItemIndex === item.splits.length - 1}
                        splitItemIndex={splitItemIndex}
                      />
                    );
                  })}
              </React.Fragment>
            )
          );
        })}
      </React.Fragment>
    );
  });
};

export default WalletTransactionsTableBody;
