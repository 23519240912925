import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import AppSpinner from '../../../../components/AppSpinner';
import BackBtn from '../../../../components/BackBtn';
import CardDetailsCards from '../../../../components/SharedComponents/CardDetailsCards';
import CardDetailsHeader from '../../../../components/SharedComponents/CardDetailsHeader';
import CardDetailsTable from '../../../../components/SharedComponents/CardDetailsTable';
import { cardDetailsSectionIds, inPages, transactionTabs } from '../../../../helpers/constants';
import {
  clearCardRequestsAction,
  clearCardTransactionsAction,
  clearDepartmentsAction,
  clearDocTypesAction,
  clearSelectedCardDetailsAction,
  clearTagsAction,
  hideAllPopups,
  setActiveTabAction,
} from '../../../../redux/CardDetailsReducer/CardDetailsActions';
import { clearSingleCardAction } from '../../../../redux/CardListReducer/CardListActions';
import { getSingleUserCardDetailsBalanceAction } from '../../../../redux/UsersReducer/UsersActions';

function SingleUserCardDetailsPage() {
  const { loaders } = useSelector((state) => state);
  const { selectedSingleUserCardDetails, selectedSingleUserCardDetailsBalance } = useSelector(
    (state) => state.users,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation('usersV2');

  // Getting Balance
  useEffect(() => {
    if (selectedSingleUserCardDetails) {
      const singleCardId =
        selectedSingleUserCardDetails.physicalCard?.id ||
        selectedSingleUserCardDetails.virtualCard?.id;
      dispatch(getSingleUserCardDetailsBalanceAction(singleCardId));
    }
  }, [selectedSingleUserCardDetails]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedCardDetailsAction());
      dispatch(clearTagsAction());
      dispatch(clearDepartmentsAction());
      dispatch(clearDocTypesAction());
      dispatch(clearSingleCardAction());
      dispatch(clearCardTransactionsAction());
      dispatch(clearCardRequestsAction());
      dispatch(hideAllPopups());
      dispatch(setActiveTabAction(transactionTabs.EXPENSES));
    };
  }, []);

  return (
    <div className="h-full overflow-y-auto pe-6" id={cardDetailsSectionIds.RIGHT_SIDE_CONTAINER}>
      {!loaders.getSingleUserSelectedCardDetails && selectedSingleUserCardDetails && (
        <>
          <div
            className="top-0 z-20 bg-white pb-1 lg:sticky lg:px-0"
            id={cardDetailsSectionIds.CARD_DETAILS_HEADER}
          >
            <div className="mb-1 hidden pt-7 lg:block">
              <BackBtn
                className="mb-4"
                label={t('Back')}
                onClick={() => {
                  history.push('/dashboard/users-v2');
                }}
              />
            </div>
            <CardDetailsHeader
              cardDetails={selectedSingleUserCardDetails}
              cardDetailsBalance={selectedSingleUserCardDetailsBalance}
              inPage={inPages.singleUser}
            />
            <CardDetailsCards cardDetails={selectedSingleUserCardDetails} />
          </div>
          <div>
            <CardDetailsTable
              inPage={inPages.singleUser}
              cardDetails={selectedSingleUserCardDetails}
            />
          </div>
        </>
      )}

      {loaders.getSingleUserSelectedCardDetails && <AppSpinner />}
    </div>
  );
}

export default SingleUserCardDetailsPage;
