import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useParams } from 'react-router-dom';
import NqoodLogoAr from '../../assets/png/nqoodlet-ar-hor.png';
import NqoodLogo from '../../assets/png/nqoodlet-en-hor.png';
import { ReactComponent as Close } from '../../assets/svg/closeIcon.svg';
import { ReactComponent as Menu } from '../../assets/svg/menu.svg';
import { apiGetRequest } from '../../helpers/APIHelper';
import useAccountReady from '../../helpers/useAccountReady';
import useCapabilities from '../../helpers/useCapabilities';
import useIsLoggedIn from '../../helpers/useIsLoggedIn';
import { isDev, isTesting } from '../../helpers/Utils';
import { changeLang } from '../../redux/AppConfigReducer/AppConfigActions';
import HeaderUserName from '../Header/HeaderUserName';
import { AppIcon } from '../html/html';
import UserPicture from '../UserPicture';

function SidebarItem(props) {
  const { to, label, closeModel } = props;
  const params = useParams();
  const selected = params['*'].split('/').includes(to);
  return (
    <div
      className={`${
        selected ? 'border-primary' : 'border-transparent'
      } mb-2 py-4 ltr:border-l-8 rtl:border-r-8`}
    >
      <NavLink
        to={to}
        className="flex flex-col px-5 ltr:text-left rtl:text-right"
        onClick={closeModel}
      >
        <span className={`${selected ? 'font-bold' : 'text-gray-6'} text-lg capitalize`}>
          {label}
        </span>
      </NavLink>
    </div>
  );
}

function ProfileHeaderMenuItem({ label, to, closeModel, className, target }) {
  return (
    <div className={`mx-3 px-4 py-5 hover:bg-gray-100 ${className}`}>
      <Link
        to={to}
        className="flex flex-row items-baseline self-stretch"
        onClick={closeModel}
        target={target ?? '_self'}
      >
        <p className="text-sm">{label}</p>
      </Link>
    </div>
  );
}

function FixedTop() {
  const { dir } = useSelector((state) => state.appConfig);
  const { user } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation(['common']);
  const [currentUserCan] = useCapabilities();
  const loggedIn = useIsLoggedIn();
  const accountReady = useAccountReady();
  const [staticLinks, setStaticLinks] = useState(null);
  const dispatch = useDispatch();

  const fetchStaticPages = () => {
    apiGetRequest('static_pages').then((response) => {
      if (response.length > 0) {
        setStaticLinks(response);
      }
    });
  };

  useEffect(() => {
    fetchStaticPages();
  }, [i18n.language]);

  function toggleModalMainMenu() {
    const body = document.querySelector('body');
    const modal = document.querySelector('#mainMenu');
    modal.classList.toggle('opacity-0');
    modal.classList.toggle('pointer-events-none');
    body.classList.toggle('modal-active');

    document.querySelector('.headerFixed').classList.toggle('activeHeader');
  }

  function toggleModalProfileMenu() {
    const body = document.querySelector('body');
    const modal = document.querySelector('#profileMenu');
    modal.classList.toggle('opacity-0');
    modal.classList.toggle('pointer-events-none');
    body.classList.toggle('modal-active');

    document.querySelector('.headerFixed').classList.toggle('activeHeader');
  }

  return (
    <>
      <nav className="headerFixed inset-x-0 top-0 z-30 block bg-white lg:hidden">
        <div className="flex justify-between border-b border-gray-3 bg-white px-6 py-4">
          <button
            type="button"
            className="modal-open block w-full py-2 text-lg"
            onClick={toggleModalMainMenu}
          >
            <Menu className="center ltr:ml-0 rtl:mr-0" />
          </button>

          <Link className="block pt-4" to={loggedIn ? '/dashboard' : '/auth/login'}>
            {i18n.language !== 'ar' && (
              <img src={NqoodLogo} alt="logo" className={loggedIn ? 'h-auto w-60' : ''} />
            )}
            {i18n.language === 'ar' && (
              <img src={NqoodLogoAr} alt="logo" className={loggedIn ? 'h-auto w-60' : ''} />
            )}
          </Link>
          <button
            type="button"
            className="modal-open block w-full py-2 text-end text-lg"
            onClick={toggleModalProfileMenu}
          >
            <AppIcon
              iClass="XXLargeFont"
              className="fa-solid fa-circle-user"
              style={{ color: '#c1cbde' }}
            />
          </button>
        </div>
      </nav>

      <div
        id="mainMenu"
        style={{ top: '70px' }}
        className="modal pointer-events-none fixed left-0 z-50 flex h-full w-full items-center justify-center bg-white opacity-0"
      >
        <div className="modal-container fixed z-50 h-full w-full overflow-y-auto ">
          <button
            type="button"
            className="modal-close z-50 mr-4 mt-4 flex cursor-pointer flex-col items-center p-5 text-sm"
            onClick={toggleModalMainMenu}
          >
            <Close className="text-gray-6" />
          </button>

          <div className="modal-content container mx-auto h-auto pb-16 text-left">
            <nav className="sidebar flex w-full flex-none flex-col justify-start pt-10 text-sm text-gray-6">
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <SidebarItem to="" label={t('dashboard')} closeModel={toggleModalMainMenu} />
              {/* eslint-disable-next-line react/jsx-no-bind */}
              {currentUserCan('show_account') && accountReady && (
                <SidebarItem to="wallet" label={t('wallets')} closeModel={toggleModalMainMenu} />
              )}
              {/* eslint-disable-next-line react/jsx-no-bind */}
              {/* {currentUserCan('transaction_list_view') && (
                <SidebarItem
                  to="transactions"
                  label={t('transactions')}
                  closeModel={toggleModalMainMenu}
                />
              )} */}
              {/* eslint-disable-next-line react/jsx-no-bind */}
              {currentUserCan('transaction_list_view') && (
                <SidebarItem to="funds" label={t('requests')} closeModel={toggleModalMainMenu} />
              )}
              {/* eslint-disable-next-line react/jsx-no-bind */}
              {currentUserCan('list_account_employees') && (
                <SidebarItem
                  to={`${isDev() || isTesting() ? 'dashboard/users-v2' : 'dashboard/users'}`}
                  label={t('users')}
                  closeModel={toggleModalMainMenu}
                />
              )}
              {/* eslint-disable-next-line react/jsx-no-bind */}
              {currentUserCan('cards_view') && (
                <SidebarItem to="my_cards" label={t('my_cards')} closeModel={toggleModalMainMenu} />
              )}
            </nav>
          </div>
        </div>
      </div>
      <div
        id="profileMenu"
        style={{ top: '70px' }}
        className="modal pointer-events-none fixed left-0 z-50 flex h-full w-full items-center justify-center bg-white opacity-0"
      >
        <div className="modal-container fixed z-50 h-full w-full overflow-y-auto ">
          <button
            type="button"
            className="modal-close z-50 mr-4 mt-4 flex cursor-pointer flex-col items-center p-5 text-sm"
            onClick={toggleModalProfileMenu}
          >
            <Close />
          </button>

          <div className="modal-content container mx-auto h-auto pb-16 text-left">
            {loggedIn && (
              <>
                <div className="p-5">
                  <div className="flex w-full flex-row justify-start p-5">
                    <div className="relative">
                      <UserPicture
                        className="z-40"
                        width="w-10"
                        height="h-10"
                        initials={`${user && user.first_name[0]}${user && user.last_name[0]}`}
                      />
                    </div>
                    <div className="px-5">
                      <HeaderUserName user={user} />
                    </div>
                  </div>
                </div>
                {/* eslint-disable-next-line react/jsx-no-bind */}
                {accountReady && (
                  <ProfileHeaderMenuItem
                    label={t('view_profile')}
                    to="/dashboard/profile/user"
                    closeModel={toggleModalProfileMenu}
                    className="border-b border-gray-3 border-opacity-40"
                  />
                )}
                {/* eslint-disable-next-line react/jsx-no-bind */}
                {currentUserCan('show_account') && accountReady && (
                  <ProfileHeaderMenuItem
                    label={t('company_profile')}
                    to="/dashboard/profile/company"
                    closeModel={toggleModalProfileMenu}
                    className="border-b border-gray-3 border-opacity-40"
                  />
                )}
                {staticLinks &&
                  staticLinks.map((item) => (
                    <ProfileHeaderMenuItem
                      key={item.title}
                      target="_blank"
                      to={i18n.language === 'ar' ? item.link_ar : item.link}
                      label={i18n.language === 'ar' ? item.title_ar : item.title}
                      closeModel={toggleModalProfileMenu}
                      className="border-b border-gray-3 border-opacity-40"
                    />
                  ))}
                <div
                  onClick={() => {
                    dispatch(changeLang());
                    toggleModalProfileMenu();
                  }}
                  className="border-b border-gray-3 border-opacity-40 px-4 py-5 hover:bg-gray-100"
                >
                  <button
                    type="button"
                    className="flex flex-row items-baseline justify-between self-stretch"
                  >
                    <p className="px-3 text-sm">{t('change_lang')}</p>
                    <span />
                  </button>
                </div>{' '}
                <ProfileHeaderMenuItem label={t('logout')} to="/auth/logout" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FixedTop;
