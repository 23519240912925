import { useTranslation } from 'react-i18next';
import { history } from '../../../../AppRouter/history';
import { AppButton } from '../../../../components/html/html';

function CancelBtn({ className }) {
  const { t } = useTranslation('commonV2');

  return (
    <AppButton
      className={className}
      button="link"
      onClick={() => {
        history.push('/dashboard/my_cards');
      }}
    >
      {t('Cancel', { ns: 'commonV2' })}
    </AppButton>
  );
}

export default CancelBtn;
