import React from 'react';

const Tab = function (props) {
  const { setFilterStatusID, currentStatusID, text, statusID, textSm } = props;
  return (
    <button
      type="button"
      className={`pb-2 pt-1 capitalize ltr:pr-5 ltr:text-left rtl:pl-5 rtl:text-right ${
        textSm ? 'text-sm' : 'text-lg'
      }  ${currentStatusID === statusID ? 'tab_underline text-primary' : ''}`}
      onClick={() => {
        if (statusID === currentStatusID) {
          return;
        }
        setFilterStatusID(parseInt(statusID, 10));
      }}
    >
      {text}
    </button>
  );
};

export default Tab;
