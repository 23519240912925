/* eslint-disable no-unsafe-optional-chaining */
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ComponentToPrint from '../../../../../components/AppPrint/ComponentToPrint';
import AppSpinner from '../../../../../components/AppSpinner';
import {
  receiptPopupIconIds,
  walletDetailsSectionIds,
  walletTabs,
} from '../../../../../helpers/constants';
import WalletCardActivityPopupContent from './WalletCardActivityPopupContent';
import WalletFundActivityPopupContent from './WalletFundActivityPopupContent';
import WalletReceiptPopupHeader from './WalletReceiptPopupHeader';
import WalletRequestPopupContent from './WalletRequestPopupContent';
import WalletTransactionPopupContent from './WalletTransactionPopupContent';

function ViewWalletReceipt() {
  const { i18n } = useTranslation();
  const { isMobile } = useSelector((state) => state.appConfig);
  const { loaders } = useSelector((state) => state);
  const {
    selectedWalletMainActiveTab,
    selectedWalletSubActiveTab,
    singleWalletRequestDetails,
    singleWalletFundActivityDetails,
    singleWalletCardActivityDetails,

    showWalletReceiptPopup,
    singleWalletTransactionDetails,
  } = useSelector((state) => state.wallet);

  const walletRequestPrintWrapperRef = useRef();
  const walletTransactionPrintWrapperRef = useRef();
  const walletFundActivityPrintWrapperRef = useRef();
  const walletCardActivityPrintWrapperRef = useRef();

  const showWalletRequestPopupContent = useMemo(() => {
    return (
      selectedWalletMainActiveTab === walletTabs.REQUESTS &&
      singleWalletRequestDetails &&
      !loaders.getWalletSingleRequestDetailsAction
    );
  }, [selectedWalletMainActiveTab, walletTabs, singleWalletRequestDetails, loaders]);

  const showWalletTransactionPopupContent = useMemo(() => {
    return (
      selectedWalletMainActiveTab === walletTabs.TRANSACTIONS &&
      singleWalletTransactionDetails &&
      !loaders.getSingleWalletTransactionDetailsAction
    );
  }, [selectedWalletMainActiveTab, walletTabs, singleWalletTransactionDetails, loaders]);

  const showWalletFundActivityPopupContent = useMemo(() => {
    return (
      selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
      selectedWalletSubActiveTab === walletTabs.FUNDSACTIVITY &&
      singleWalletFundActivityDetails &&
      !loaders.getSingleWalletFundsActivityDetailsAction
    );
  }, [
    selectedWalletMainActiveTab,
    selectedWalletSubActiveTab,
    walletTabs,
    singleWalletFundActivityDetails,
    loaders,
  ]);

  const showWalletCardActivityPopupContent = useMemo(() => {
    return (
      selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
      selectedWalletSubActiveTab === walletTabs.CARDSACTIVITY &&
      singleWalletCardActivityDetails &&
      !loaders.getSingleWalletCardsActivityDetailsAction
    );
  }, [
    selectedWalletMainActiveTab,
    selectedWalletSubActiveTab,
    walletTabs,
    singleWalletCardActivityDetails,
    loaders,
  ]);

  const showWalletRequestPopupLoader = useMemo(() => {
    return (
      selectedWalletMainActiveTab === walletTabs.REQUESTS &&
      loaders.getWalletSingleRequestDetailsAction
    );
  }, [selectedWalletMainActiveTab, walletTabs, loaders]);

  const showWalletTransactionPopupLoader = useMemo(() => {
    return (
      selectedWalletMainActiveTab === walletTabs.TRANSACTIONS &&
      loaders.getSingleWalletTransactionDetailsAction
    );
  }, [selectedWalletMainActiveTab, walletTabs, loaders]);

  const showWalletFundActivityPopupLoader = useMemo(() => {
    return (
      selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
      selectedWalletSubActiveTab === walletTabs.FUNDSACTIVITY &&
      loaders.getSingleWalletFundsActivityDetailsAction
    );
  }, [selectedWalletMainActiveTab, selectedWalletSubActiveTab, walletTabs, loaders]);

  const showWalletCardActivityPopupLoader = useMemo(() => {
    return (
      selectedWalletMainActiveTab === walletTabs.ACTIVITIES &&
      selectedWalletSubActiveTab === walletTabs.CARDSACTIVITY &&
      loaders.getSingleWalletCardsActivityDetailsAction
    );
  }, [selectedWalletMainActiveTab, selectedWalletSubActiveTab, walletTabs, loaders]);

  if (showWalletReceiptPopup) {
    return (
      <div
        id={receiptPopupIconIds.RECEIPT_POPUP_CONTAINER}
        className="absolute end-0 top-5 z-50 w-full overflow-y-auto rounded-2xl border bg-white p-5 pt-0 shadow lg:w-[400px]"
        style={{
          height: isMobile
            ? '100%'
            : document.getElementById(walletDetailsSectionIds.RIGHT_SIDE_CONTAINER)?.clientHeight -
              document.getElementById(walletDetailsSectionIds.WALLET_DETAILS_HEADER)?.clientHeight -
              60,
        }}
      >
        {/* Wallet Receipt Popup Header */}
        <WalletReceiptPopupHeader
          walletRequestPrintWrapperRef={walletRequestPrintWrapperRef}
          walletTransactionPrintWrapperRef={walletTransactionPrintWrapperRef}
          walletCardActivityPrintWrapperRef={walletCardActivityPrintWrapperRef}
          walletFundActivityPrintWrapperRef={walletFundActivityPrintWrapperRef}
        />

        {/* ========================= Transactions ============================= */}

        {/* Wallet Transaction Popup Content */}
        {showWalletTransactionPopupContent && (
          <div ref={walletTransactionPrintWrapperRef} className="print:p-5">
            <ComponentToPrint reference={singleWalletTransactionDetails.id}>
              <WalletTransactionPopupContent />
            </ComponentToPrint>
          </div>
        )}

        {/* ========================= Requests ============================= */}

        {/* Wallet Request Popup Content */}
        {showWalletRequestPopupContent && (
          <div ref={walletRequestPrintWrapperRef} className="print:p-5">
            <ComponentToPrint reference={singleWalletRequestDetails.id}>
              <WalletRequestPopupContent />
            </ComponentToPrint>
          </div>
        )}

        {/* Wallet Fund Activity Popup Content */}
        {showWalletFundActivityPopupContent && (
          <div
            ref={walletFundActivityPrintWrapperRef}
            className="print:p-5"
            style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}
          >
            <ComponentToPrint reference={singleWalletFundActivityDetails.id}>
              <WalletFundActivityPopupContent />
            </ComponentToPrint>
          </div>
        )}

        {/* Wallet Card Activity Popup Content */}
        {showWalletCardActivityPopupContent && (
          <div
            ref={walletCardActivityPrintWrapperRef}
            className="print:p-5"
            style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}
          >
            <ComponentToPrint reference={singleWalletCardActivityDetails.id}>
              <WalletCardActivityPopupContent />
            </ComponentToPrint>
          </div>
        )}

        {/* Loaders */}
        {(showWalletRequestPopupLoader ||
          showWalletTransactionPopupLoader ||
          showWalletFundActivityPopupLoader ||
          showWalletCardActivityPopupLoader) && <AppSpinner />}
      </div>
    );
  }
}

export default ViewWalletReceipt;
