const INITIAL_STATE = {
  activeUserTabIndex: null,
  userListDepartments: null,
  usersList: null,
  showUsersListFilterPopup: null,
  showUsersListSearchPopup: null,
  singleUserDetails: null,
  activeUserDetailsTabIndex: null,
  singleUserCards: null,
  singleUserSelectedCard: null,
  selectedSingleUserCardDetails: null,
  selectedSingleUserCardDetailsBalance: null,
};

const UsersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_USER_TAB_INDEX': {
      return { ...state, activeUserTabIndex: action.payload };
    }

    case 'ADD_USERS_LIST_DEPARTMENTS': {
      return { ...state, userListDepartments: action.payload };
    }

    case 'CLEAR_USERS_LIST_DEPARTMENTS': {
      return { ...state, userListDepartments: null };
    }

    case 'ADD_USERS_LIST': {
      return { ...state, usersList: action.payload };
    }

    case 'CLEAR_USERS_LIST': {
      return { ...state, usersList: null };
    }

    case 'UPDATE_SINGLE_USER': {
      const updatedUsersData = state.usersList.data.map((ele) => {
        if (ele.id === action.payload.id) {
          return {
            ...ele,
            ...action.payload,
          };
        }
        return ele;
      });

      return {
        ...state,
        usersList: { ...state.usersList, data: updatedUsersData },
      };
    }

    case 'SHOW_USERS_LIST_FILTER_POPUP': {
      return { ...state, showUsersListFilterPopup: true };
    }

    case 'HIDE_USERS_LIST_FILTER_POPUP': {
      return { ...state, showUsersListFilterPopup: false };
    }

    case 'SHOW_USERS_LIST_SEARCH_POPUP': {
      return { ...state, showUsersListSearchPopup: true };
    }

    case 'HIDE_USERS_LIST_SEARCH_POPUP': {
      return { ...state, showUsersListSearchPopup: false };
    }

    case 'ADD_SINGLE_USER_DETAILS': {
      return { ...state, singleUserDetails: action.payload };
    }

    case 'CLEAR_SINGLE_USER_DETAILS': {
      return { ...state, singleUserDetails: null };
    }

    case 'UPDATE_SINGLE_USER_DETAILS': {
      return { ...state, singleUserDetails: action.payload };
    }

    case 'SET_ACTIVE_USER_DETAILS_TAB_INDEX': {
      return { ...state, activeUserDetailsTabIndex: action.payload };
    }

    case 'ADD_SINGLE_USER_CARDS': {
      return { ...state, singleUserCards: action.payload };
    }

    case 'SELECT_SINGLE_USER_CARD': {
      const { virtualCard, physicalCard } = action.payload;

      return { ...state, singleUserSelectedCard: { virtualCard, physicalCard } };
    }

    case 'CLEAR_SINGLE_USER_SELECTED_CARD': {
      return { ...state, singleUserSelectedCard: null };
    }

    case 'ADD_SINGLE_USER_SELECTED_CARD_DETAILS': {
      return { ...state, selectedSingleUserCardDetails: action.payload };
    }

    case 'UPDATE_SELECTED_SINGLE_USER_CARD': {
      const updatedSelectedSingleUserCardDetails = { ...state.selectedSingleUserCardDetails };
      const updatedSingleUserCards = state.singleUserCards.map((ele) => {
        if (ele.id === action.payload.id) {
          return action.payload;
        }
        return ele;
      });

      if (action.payload.is_physical) {
        updatedSelectedSingleUserCardDetails.physicalCard = action.payload;
      } else {
        updatedSelectedSingleUserCardDetails.virtualCard = action.payload;
      }
      return {
        ...state,
        selectedSingleUserCardDetails: updatedSelectedSingleUserCardDetails,
        singleUserCards: updatedSingleUserCards,
      };
    }

    case 'ADD_SINGLE_USER_CARD_DETAILS_BALANCE': {
      return {
        ...state,
        selectedSingleUserCardDetailsBalance: {
          amount: +action.payload.amount || 0,
          currency: action.payload.currency,
        },
      };
    }

    default:
      return state;
  }
};

export default UsersReducer;
