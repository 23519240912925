import i18n from 'i18next';
import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {inPages, requestStatusIds} from '../../helpers/constants';
import TooltipPackage from '../TooltipPackage';
import TranslatedData from '../TranslatedData';
import CardProgramSchemeLogo from './CardProgramSchemeLogo';
import {AppBody} from "../html/html";

function RequestCardDetailsAndDescription({ requestDetails, cardDetails, inPage }) {
  const { t } = useTranslation('cardsV2');
  const { auth } = useSelector((state) => state);
  const ref = useRef();

  return (
    <div className="rounded-lg border border-gray-300 p-3">
      <div className="mb-5 flex items-start justify-between">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-1 text-sm font-semibold">
            <span>{requestDetails.card_id?.name_on_card ?? ''}</span>

            {inPage === inPages.wallet && requestDetails?.requested_by?.id === auth?.user?.id && (
              <span className="text-xs text-primary">[{t('You')}]</span>
            )}
          </div>
          <AppBody pClass="Body2Bold" type="span">
            <TranslatedData
              en={requestDetails.department_id?.name}
              ar={requestDetails.department_id?.name_ar}
            />
          </AppBody>
        </div>
        <div className="flex items-center gap-1">
          <span>
            <CardProgramSchemeLogo
              cardProgramScheme={requestDetails?.card_id?.cardproduct.scheme}
            />
          </span>

          <span>{requestDetails.card_id?.last_four}</span>

          {requestDetails.status_id !== requestStatusIds.APPROVED &&
            requestDetails.status_id !== requestStatusIds.REJECTED && (
              <span>
                <TooltipPackage
                  tooltipTextSize="text-xxxs"
                  tooltipTextColor="text-black"
                  tooltipContentClassName="bg-slate-50 font-normal leading-normal"
                  place={i18n.language === 'en' ? 'top-end' : 'top-start'}
                  tooltipMessage={t(
                    'This request will be for both plastic and virtual cards, as they share the balance.',
                  )}
                  id={`info_request_double_card_${requestDetails?.id}`}
                />
              </span>
            )}
        </div>
      </div>

      <div>
        <div className="rounded-md bg-[#def1f2] p-4">
          <div className="mb-2 flex justify-between text-sm font-semibold text-cyan-700">
            <span>{t('Request Description:')}</span>
          </div>

          <textarea
            ref={ref}
            disabled
            rows={1}
            className="w-full rounded-md border border-cyan-700 bg-transparent p-1 text-sm disabled:border-0 disabled:opacity-70"
            value={requestDetails.reason}
          />
        </div>
      </div>
    </div>
  );
}

export default RequestCardDetailsAndDescription;
