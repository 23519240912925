/* eslint-disable no-unsafe-optional-chaining */
import { useSelector } from 'react-redux';
import UsersListFilterPopupContent from './UsersListFilterPopupContent';
import UsersListFilterPopupHeader from './UsersListFilterPopupHeader';

function UsersListFilterPopup({ onApply, filterTags }) {
  const { showUsersListFilterPopup } = useSelector((state) => state.users);

  return (
    showUsersListFilterPopup && (
      <div className="end-4 top-[41vh] z-30 h-fit min-h-[35vh] w-96 overflow-y-auto rounded-2xl border border-gray-3 bg-white p-5 pt-0 shadow lg:absolute lg:end-0 lg:top-8 lg:min-h-fit">
        {/* Header */}
        <div className="sticky top-0 z-10 mb-2 bg-white pb-3 pt-5">
          <UsersListFilterPopupHeader />
        </div>

        {/* Content */}
        <div>
          <UsersListFilterPopupContent filterTags={filterTags} onApply={onApply} />
        </div>
      </div>
    )
  );
}

export default UsersListFilterPopup;
