import { objToQueryStr } from 'javascript-functions';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import dummyCard from '../../../../assets/png/dummyPlaneCard.png';
import AppImageComponent from '../../../../components/AppImageComponent';
import { AppBody, AppIcon } from '../../../../components/html/html';
import { cardStatusKeys, transactionTabs } from '../../../../helpers/constants';
import { setActiveTabAction } from '../../../../redux/CardDetailsReducer/CardDetailsActions';
import { selectSingleUserCardAction } from '../../../../redux/UsersReducer/UsersActions';

function SingleUserNqoodCard({ virtualCard, physicalCard }) {
  const { auth } = useSelector((state) => state);
  const { singleUserSelectedCard } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const cardStatus = useMemo(() => {
    return virtualCard.status?.key.toUpperCase();
  }, [virtualCard]);

  const cardProgramStatus = useMemo(() => {
    return virtualCard.cardproduct.status?.toUpperCase();
  }, [virtualCard]);

  const isClosedCardProgram = useMemo(() => {
    return [cardStatusKeys.SUSPENDED, cardStatusKeys.DEACTIVATED].includes(cardProgramStatus);
  }, [cardProgramStatus]);

  const cardImageColorClass = useMemo(() => {
    if (
      cardStatus === cardStatusKeys.REPLACED ||
      isClosedCardProgram ||
      cardStatus === cardStatusKeys.INACTIVE
    ) {
      return 'grayscale';
    }

    return '';
  }, [auth, cardStatus, isClosedCardProgram]);

  const cardNameColor = useMemo(() => {
    if (
      cardStatus === cardStatusKeys.REPLACED ||
      isClosedCardProgram ||
      cardStatus === cardStatusKeys.INACTIVE
    ) {
      return 'gray';
    }

    return virtualCard.design?.color_hex;
  }, [virtualCard, isClosedCardProgram]);

  const firstCardName = useMemo(() => {
    return virtualCard.card_name?.split(' ')[0];
  }, [virtualCard]);

  const secondCardName = useMemo(() => {
    return virtualCard.card_name?.split(' ')[1];
  }, [virtualCard]);

  const isCardSelected = useMemo(() => {
    return singleUserSelectedCard?.virtualCard?.id === virtualCard?.id;
  }, [singleUserSelectedCard]);

  const handleSelectSingleUserCard = useCallback(() => {
    dispatch(setActiveTabAction(transactionTabs.EXPENSES));
    dispatch(selectSingleUserCardAction({ virtualCard, physicalCard }));

    history.push({
      search: objToQueryStr({
        virtualCardId: virtualCard.id,
        physicalCardId: physicalCard?.id || null,
      }),
    });
  }, [virtualCard, physicalCard]);

  const renderCardStatusIcon = useCallback(() => {
    // Load Icon
    if (cardStatus === cardStatusKeys.INACTIVE) {
      return (
        <div className="w-5">
          <AppIcon iClass="LargeFont" className="fa-kit fa-smile" />
        </div>
      );
    }

    // Replace Icon
    if (
      (cardStatus === cardStatusKeys.TERMINATED || cardStatus === cardStatusKeys.EXPIRED) &&
      !isClosedCardProgram
    ) {
      return (
        <div className="w-5">
          <AppIcon iClass="LargeFont" className="fa-kit fa-replace" />
        </div>
      );
    }

    // Lock Icon
    if (cardStatus === cardStatusKeys.SUSPENDED) {
      return (
        <div className="w-5">
          <AppIcon iClass="XLargeFont" className="fa-kit fa-lock" />
        </div>
      );
    }

    return <div className="w-5" />;
  });

  return (
    <div
      className="relative flex w-full cursor-pointer items-center gap-2"
      onClick={handleSelectSingleUserCard}
    >
      {isCardSelected && (
        <div className="absolute -start-3 z-20 h-1.5 w-1.5 rounded-full bg-primary" />
      )}
      {/* =========== Card Image =========== */}
      <div className="relative flex-1 rounded shadow-md">
        {/* =========== Virtual Card Image  =========== */}
        {virtualCard.design?.attachment && (
          <AppImageComponent
            className={`${cardImageColorClass} relative z-10 overflow-hidden rounded`}
            src={virtualCard.design?.attachment}
            alt={virtualCard.name_on_card}
            skeletonWidth={57}
            skeletonHeight={36}
          />
        )}

        {/* =========== Virtual Card Image Placeholder =========== */}
        {!virtualCard?.design?.attachment && (
          <div className="bg-gray-6">
            <img className="w-full opacity-0" src={dummyCard} alt={virtualCard.name_on_card} />
          </div>
        )}

        {/* =========== PhysicalCard Card Image  =========== */}
        {physicalCard?.design?.attachment && (
          <img
            className={`${cardImageColorClass} absolute left-0 top-4 overflow-hidden rounded lg:top-2`}
            src={physicalCard.design?.attachment}
            alt={physicalCard.name_on_card}
          />
        )}

        {/* ====================== Card Name ====================== */}
        <div
          style={{ direction: 'ltr' }}
          className="absolute left-[2px] top-1 z-20 flex items-center text-[4px] leading-[1]"
        >
          <i className="fa-kit fa-nqoodlet-icon-left text-[4px]" style={{ color: cardNameColor }} />
          <span
            className="pl-0.5"
            style={{
              color: cardNameColor,
            }}
          >
            {firstCardName && <span className="block">{firstCardName}</span>}
            {secondCardName && <span className="block">{secondCardName}</span>}

            {!virtualCard.card_name && (
              <span className="flex">
                <span className="font-black">Nqood</span>
                <span>let</span>
              </span>
            )}
          </span>
        </div>
      </div>
      <div className="flex flex-[3] items-center gap-2">
        <div className={`${isCardSelected ? 'text-primary' : ''} `}>{renderCardStatusIcon()}</div>
        <div>
          <AppBody
            pClass="Caption1Bold"
            className={`${isCardSelected ? 'text-primary' : 'text-gray-6'} `}
          >
            {virtualCard.last_four}
          </AppBody>
          <AppBody pClass="Caption1Bold" className={`${isCardSelected ? 'text-primary' : ''} `}>
            {virtualCard.card_name || virtualCard.name_on_card}
          </AppBody>
        </div>
      </div>
    </div>
  );
}

export default SingleUserNqoodCard;
