/* eslint-disable no-undefined */
import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import BackButton from '../../../components/Button/BackButton';
import {LargerTitle2, SubTitle} from '../../../components/Titles';
import Transactions from '../Transactions';

import LinkButton from '../../../components/Button/LinkButton';
import LoadingError from '../../../components/LoadingError';
import {apiGetRequest} from '../../../helpers/APIHelper';
import {setAction} from '../../../helpers/ActionsHelper';
import useCapabilities from '../../../helpers/useCapabilities';
import {SET_USERS} from '../../../redux/EmployeesReducer';
import Head from '../../Head';
import UserCards from './UserCards';
import UserDropdown from './UserDropdown';
import {AppBody} from "../../../components/html/html";

function UserDetails({ withUser }) {
  const params = useParams();
  const [selectedUser, setSelectedUser] = useState(null);
  const [cookies] = useCookies(['token']);
  const [paginationLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [notFound, setNotFound] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(['common']);
  const [currentUserCan] = useCapabilities();

  const fetchUser = (userId) => {
    setIsLoading(true);
    apiGetRequest(
      'employees',
      cookies.token,
      {
        paginate: paginationLimit,
        page: 1,
        id: userId,
      },
      [],
      {},
      true,
    )
      .then(([response]) => {
        if (response.length === 0) {
          setNotFound(true);
        } else {
          setNotFound(false);
          setSelectedUser(response[0]);
        }
        setErrored(false);
        setIsLoading(false);
        dispatch(setAction(SET_USERS, response));
      })
      .catch(() => {
        setErrored(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!withUser) {
      fetchUser(parseInt(params.id, 10));
    } else {
      fetchUser(withUser.id);
    }
  }, [i18n.language]);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  if (notFound && !isLoading) {
    return (
      <div className="m-auto flex w-full max-w-sm flex-col text-center lg:w-7/12">
        <img src="/images/NotFound.png" className="m-auto max-w-xs" alt="Not Found" />
      </div>
    );
  }

  if (!selectedUser) {
    return (
      <div className="mx-auto flex w-full flex-col lg:w-10/12">
        <Head title="Users Details" />

        <div className="grid flex-grow grid-cols-6 items-start justify-between self-stretch py-10">
          <div className="col-span-6 flex flex-col justify-start">
            {isLoading && <Skeleton containerClassName="w-full" count={3} />}
            <LoadingError show={errored && !isLoading} retryCallback={fetchUser} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto flex w-full flex-col lg:w-10/12">
      <Head title="Users Details" />

      <div className="grid flex-grow grid-cols-6 items-start justify-between self-stretch py-10">
        <div className="col-span-6 flex flex-col justify-start">
          {!withUser && (
            <div className="pb-5 ltr:text-left rtl:text-right">
              <BackButton />
            </div>
          )}
          <UserProfileTitle user={selectedUser} withUser={withUser !== undefined} />
          {!withUser && <UserProfile user={selectedUser} withUser={withUser !== undefined} />}
          {parseInt(selectedUser.card_count, 10) > 0 &&
            (currentUserCan('list_account_cards') || withUser) && (
              <UserCards user={selectedUser} withUser={withUser} />
            )}
          {parseInt(selectedUser.card_count, 10) === 0 &&
            ((currentUserCan('create_own_cards') && withUser) ||
              currentUserCan('create_account_cards')) && (
              <>
                <div className="m-auto my-5 flex flex-col justify-between lg:flex-row">
                  <AppBody pClass="Body1Bold" type="div" className="flex px-5">
                      <AppBody type="span" pClass="Body1Bold" className="mx-2 flex h-6 w-6 flex-col justify-center rounded-full bg-black text-center  text-white">
                      1
                      </AppBody>
                    {t('create_card1', { ns: 'common' })}
                  </AppBody>
                  <AppBody pClass="Body1Bold" type="div" className="flex px-5">
                    <AppBody pClass="Body1Bold" type="span" className="mx-2 flex h-6 w-6 flex-col justify-center rounded-full bg-black text-center text-white">
                      2
                    </AppBody>
                    {t('deposit', { ns: 'common' })}
                  </AppBody>
                  <AppBody pClass="Body1Bold" type="div" className="flex px-5">
                    <AppBody pClass="Body1Bold" type="span" className="mx-2 flex h-6 w-6 flex-col justify-center rounded-full bg-black text-center text-white">
                      3
                    </AppBody>
                    {t('start_Paying', { ns: 'common' })}
                  </AppBody>
                </div>
                {((currentUserCan('create_own_cards') && withUser) ||
                  currentUserCan('create_account_cards')) && (
                  <button
                    onClick={() => {
                      navigate(
                        `../${withUser ? 'my_cards' : 'users'}/create-new-card${
                          withUser ? '' : `?createCardUserId=${selectedUser.id}`
                        }`,
                      );
                    }}
                    className="m-auto mt-5 max-w-xs rounded-full border border-gray-3 bg-none px-5 py-1 font-bold"
                    type="button"
                  >
                    {t('create_card', { ns: 'common' })}
                  </button>
                )}
              </>
            )}
        </div>
      </div>
      {!withUser && parseInt(selectedUser.card_count, 10) > 0 && (
        <div className="pt-10">
          <Transactions isSplit={!!isMobile} userID={selectedUser.id} />
        </div>
      )}
    </div>
  );
}

// eslint-disable-next-line func-names
const UserProfileInfoItem = function (props) {
  const { title, info, directionLTR } = props;
  return (
    <div className="col-span-2 flex flex-col justify-start pb-5 lg:col-span-1 ltr:text-left rtl:text-right">
      <span className="pb-2 text-gray-6">{title}</span>
        <AppBody type="span" pClass="Body1Bold"
        style={{ direction: `${directionLTR ? 'ltr' : 'inherit'}` }}
      >
        {info || 'N/A'}
        </AppBody>
    </div>
  );
};

// eslint-disable-next-line func-names
const UserProfileTitle = function (props) {
  const { user, withUser } = props;
  const { user: authUser } = useSelector((state) => state.auth);

  return (
    <div className="flex flex-row justify-between pb-10">
      <LargerTitle2>{`${user.first_name} ${user.last_name}`}</LargerTitle2>
      {!withUser && (
        <UserDropdown
          text="..."
          user={user}
          className="h-6 w-6 rounded-full bg-primary text-center text-lg text-white"
          inDetails
          authUser={authUser}
        />
      )}
    </div>
  );
};

// eslint-disable-next-line func-names
const UserProfile = function (props) {
  const { user } = props;
  const { t, i18n } = useTranslation(['users']);
  const [maximize, setMaximize] = useState(true);
  let userStatus = null;

  if (user.status === 'Pending') {
    userStatus = t('state.pending');
  } else if (user.status === 'Active') {
    userStatus = t('state.active');
  } else if (user.status === 'Suspended') {
    userStatus = t('state.suspended');
  } else if (user.status === 'Deactivate') {
    userStatus = t('state.deactivate');
  } else if (user.status === 'Banned') {
    userStatus = t('state.banned');
  }
  return (
    <div className="flex flex-grow flex-col justify-start">
      <div className="flex flex-row justify-between pb-2">
        <SubTitle>{t('details.title')}</SubTitle>
        <LinkButton
          className="flex justify-end pt-2 lg:hidden"
          text={<span>&#8722;</span>}
          onClick={() => setMaximize(maximize !== true)}
        />
      </div>
      {maximize === true && (
        <div className="flex flex-col justify-start rounded-2xl border border-gray-3 px-10 py-10">
          <div className="grid grid-cols-4 ltr:text-left rtl:text-right">
            <UserProfileInfoItem
              title={t('add.inputs.name')}
              info={`${user.first_name} ${user.last_name}`}
            />
            <UserProfileInfoItem
              title={t('add.inputs.user_type')}
              info={user.roles ? t(user.roles.join(', ')) : ''}
            />
            <UserProfileInfoItem title={t('add.inputs.job_title')} info={user.job_title} />
            <UserProfileInfoItem
              title={t('add.inputs.department')}
              info={
                i18n.language === 'ar'
                  ? user.department && user.department.name_ar
                  : user.department && user.department.name
              }
            />
          </div>
          <div className="grid grid-cols-4 ltr:text-left rtl:text-right">
            <UserProfileInfoItem title={t('list.items.status')} info={userStatus} />
            <UserProfileInfoItem title={t('add.inputs.mobile')} info={user.mobile} directionLTR />
            <UserProfileInfoItem title={t('add.inputs.email')} info={user.email} />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
