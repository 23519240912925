import cookies from 'js-cookie';

const INITIAL_STATE = {
  isMobile: false,
  lang: cookies.get('lang') || 'en',
  dir: cookies.get('lang') === 'ar' ? 'rtl' : 'ltr',
};

const AppConfigReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_IS_MOBILE': {
      return { ...state, ...action.payload };
    }
    case 'CHANGE_LANG': {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default AppConfigReducer;
