import {useDispatch, useSelector} from 'react-redux';
import {hideToasterAction} from '../../redux/AppToastersReducer/AppToastersActions';
import {CloseIcon, DangerIcon, SuccessIcon, WarningIcon} from '../IconComponents';
import {AppBody} from "../html/html";

function AppToaster() {
  const { toasters } = useSelector((state) => state);
  const dispatch = useDispatch();

  const icons = {
    success: <SuccessIcon />,
    error: <DangerIcon />,
    warning: <WarningIcon />,
  };

  const classes = {
    success: 'bg-green-100 text-green-500',
    error: 'bg-red-100 text-red-500',
    warning: 'bg-orange-100 text-orange-500',
  };

  return (
    <div
      className={`${
        toasters.show ? 'translate-y-0' : 'translate-y-44'
      } fixed bottom-10 start-5 z-50 flex w-full max-w-xs items-center rounded-lg bg-white p-4 text-gray-6 shadow transition-transform ease-in-out`}
      role="alert"
    >
      {/* Icon */}
      <div
        className={`inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg ${
          classes[toasters.type]
        }`}
      >
        {icons[toasters.type]}
      </div>

      {/* Message */}
      <AppBody pClass="Body2Regular" type="div" className="ms-3">{toasters.message}</AppBody>

      {/* Close Btn */}
      <button
        onClick={() => {
          dispatch(hideToasterAction());
        }}
        type="button"
        className="-mx-1.5 -my-1.5 ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-white p-1.5 text-gray-6 hover:bg-stone-100 hover:text-black focus:ring-2 focus:ring-stone-300 "
      >
        <CloseIcon />
      </button>
    </div>
  );
}

export default AppToaster;
