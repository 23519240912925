import { randomId } from 'javascript-functions';
import React, { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../AppRouter/history';
import OTPInput from '../../../components/FormInputs/OTPInput';
import OTPResendTimer from '../../../components/FormInputs/OTPResendTimer';
import { AppBody, AppHeader } from '../../../components/html/html';
import { devLog, getServerErrMessage, isDev, setLocalData } from '../../../helpers/Utils';
import { loginActiveStep } from '../../../helpers/constants';
import { http } from '../../../http';
import { showToasterAction } from '../../../redux/AppToastersReducer/AppToastersActions';
import {
  setActiveLoginStepAction,
  setCachedLoginFormDataAction,
  setLoggedInUserAction,
  setRedirectURLAction,
} from '../../../redux/AuthReducer/AuthActions';

function LoginOTP() {
  const { t } = useTranslation(['loginV2', 'commonV2']);
  const [, setCookie] = useCookies(['token', 'lang']);
  const dispatch = useDispatch();
  const { loaders } = useSelector((state) => state);
  const { cachedLoginStepData } = useSelector((state) => state.auth.loginProcess);
  const { redirectURL } = useSelector((state) => state.auth);
  const [OTPKey, setOTPKey] = useState(randomId());

  const initPendo = useCallback((data) => {
    // eslint-disable-next-line no-undef
    pendo.initialize({
      visitor: {
        id: data.id,
        email: data.email,
        full_name: `${data.first_name} ${data.last_name}`,
        role: data.roles && data.roles.join(', '),
        company: data.company_name,
        department: data.department_name,
      },
      account: {
        id: data.company_id,
        name: data.company_name,
      },
    });
    // eslint-disable-next-line no-undef
    devLog(`Pendo Initilized for ${pendo.app_name}`);
  }, []);

  const getUserInfo = useCallback((loggedInUserData) => {
    http
      .get('users/analytics/basic/info', { loader: 'getUserInfo' })
      .then((res) => {
        initPendo(res);
        const userData = { ...loggedInUserData, ...res.data };
        dispatch(setLoggedInUserAction(userData));
        setLocalData('userData', userData);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, []);

  const handleLoginOTP = useCallback(
    ({ code }) => {
      devLog(`Logging In...`);

      const formData = new FormData();

      formData.append('email', cachedLoginStepData.email);
      formData.append('password', cachedLoginStepData.password);
      formData.append('sms_token', cachedLoginStepData.smsToken);
      formData.append('code', code);

      http
        .post('login', formData, { loader: 'handleLoginOTP' })
        .then((res) => {
          // ======== OLD LOGIC ======== //
          const now = Date.now();
          setCookie('token', res.data.token, {
            path: '/',
            expires: new Date(new Date(new Date(now).setSeconds(res.data.expires_at))),
          });
          setCookie('lang', res.data.lang, { path: '/' });

          // ======== NEW LOGIC ======== //
          if (redirectURL && redirectURL.includes('dashboard')) {
            history.push(redirectURL);
            dispatch(setRedirectURLAction(null));
          } else {
            history.push('/dashboard');
          }

          dispatch(setActiveLoginStepAction(loginActiveStep.loginAndBanner));
          getUserInfo(res.data);
        })
        .catch((err) => {
          dispatch(
            showToasterAction({
              type: 'error',
              message: getServerErrMessage(err),
            }),
          );
        });
    },
    [cachedLoginStepData, redirectURL],
  );
  const handleResend = useCallback(() => {
    devLog(`Resend OTP...`);
    setOTPKey(randomId());
    const formData = new FormData();

    formData.append('email', cachedLoginStepData.email);
    formData.append('password', cachedLoginStepData.password);

    http
      .post('login', formData, { loader: 'handleLoginOTP' })
      .then((res) => {
        dispatch(
          setCachedLoginFormDataAction({
            ...cachedLoginStepData,
            smsToken: res.data.token,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [cachedLoginStepData]);

  return (
    <div className="mx-auto mt-[23vh] max-w-[400px] px-0 pb-5 md:px-5">
      <AppHeader h="h3">{t('One - Time Password', { ns: 'commonV2' })}</AppHeader>

      <AppBody pClass="Body2Medium" className="mb-6 text-gray-6">
        <span className="me-1">
          {t('Please enter the verification code we sent to', { ns: 'commonV2' })}
        </span>
        <span className="block text-start" style={{ direction: 'ltr' }}>
          <span>{t('Mobile')} </span>
          <span className="font-bold">{cachedLoginStepData?.hashedMobile}</span>
        </span>
        <span>
          <span className="font-bold">{t('OR', { ns: 'commonV2' })} </span>
          <span className="block font-bold">{cachedLoginStepData?.email}</span>
        </span>
      </AppBody>

      <AppHeader className="mb-3" h="h5">
        {t('Enter The Code', { ns: 'commonV2' })}
      </AppHeader>

      <div className="mb-6">
        <OTPInput
          key={OTPKey}
          isLoading={loaders.handleLoginOTP}
          count={5}
          onComplete={(code) => {
            handleLoginOTP({ code });
          }}
        />
      </div>

      <div className="flex items-center gap-2">
        <AppBody pClass="Body2Medium" className="text-gray-6">
          {t("Didn't receive the one time code?", { ns: 'commonV2' })}
        </AppBody>
        <OTPResendTimer
          duration={isDev() ? 2 : 180}
          onResend={() => {
            handleResend();
          }}
        />
      </div>
    </div>
  );
}

export default LoginOTP;
