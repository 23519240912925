import { apiGetRequest } from './APIHelper';

/* Returns true when 5 minutes have passed since last userinfo fetch */
const shouldInvalidateLocalUserInfo = (userInfo) => {
  if (userInfo.stored_at) {
    const now = Date.now();
    return now - userInfo.stored_at >= 1000 * 60 * 5;
  }
  return true;
};

const isAccountReady = (apiData) => {
  let accountReady = false;

  accountReady =
    apiData.phone_verified &&
    apiData.email_verified &&
    apiData.yakeen_verified &&
    apiData.account_verified;

  if (!accountReady) {
    return false;
  }
  if (accountReady && apiData.account_id === 0) {
    return false;
  }
  accountReady = apiData.business_verified && apiData.address_verified && apiData.accept_terms;
  if (
    apiData.account &&
    apiData.account.status !== 'Activated' &&
    apiData.account.status !== 'Suspended'
  ) {
    return false;
  }
  return accountReady;
};

export const refreshUserData = async (token) => {
  let capabilities = localStorage.getItem('capabilities')
    ? JSON.parse(localStorage.getItem('capabilities'))
    : null;
  let userinfo = localStorage.getItem('userinfo')
    ? JSON.parse(localStorage.getItem('userinfo'))
    : null;
  let accountReady = false;
  // load me always when email not verified
  let emailVerify = false;
  if (userinfo !== null && userinfo.email_verified === false) {
    emailVerify = true;
  }
  if (
    userinfo === null ||
    capabilities === null ||
    shouldInvalidateLocalUserInfo(userinfo) ||
    emailVerify
  ) {
    const apiData = await apiGetRequest('users/me', token);
    if (apiData) {
      apiData.stored_at = Date.now();
      userinfo = apiData;
      localStorage.setItem('userinfo', JSON.stringify(apiData));
      accountReady = isAccountReady(apiData);
    }
  } else {
    accountReady = isAccountReady(userinfo);
  }

  if (accountReady) {
    capabilities = await apiGetRequest('users/role_permissions', token);

    if (capabilities) {
      localStorage.setItem('capabilities', JSON.stringify(capabilities));
    }
  }

  return [userinfo, capabilities, accountReady];
};
