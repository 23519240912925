import { useSelector } from 'react-redux';
import RequestFrom from '../../../../../components/SharedComponents/RequestFrom';

function MoneyRequestContent() {
  const {
    singleRequestDetails,
    selectedCardDetailsData,
    singleTransactionDetails,
    selectedActiveTab,
  } = useSelector((state) => state.cardDetails);

  return (
    <RequestFrom
      requestDetails={singleRequestDetails}
      cardDetails={selectedCardDetailsData}
      transactionDetails={singleTransactionDetails}
      activeTab={selectedActiveTab}
      fromCardActions
    />
  );
}

export default MoneyRequestContent;
