import React from 'react';
import { useSelector } from 'react-redux';
import SingleCardDetailsCardDesktop from './SingleCardDetailsDesktop';
import SingleCardDetailsCardMobile from './SingleCardDetailsMobile';

function SingleCardDetailsCard({ card, cardDetails }) {
  const { isMobile } = useSelector((state) => state.appConfig);

  if (isMobile) {
    return <SingleCardDetailsCardMobile card={card} cardDetails={cardDetails} />;
  }
  return <SingleCardDetailsCardDesktop card={card} cardDetails={cardDetails} />;
}

export default SingleCardDetailsCard;
