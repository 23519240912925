import { combineReducers } from 'redux';
import ActivitiesReducer from './ActivitiesReducer';
import AppConfigReducer from './AppConfigReducer';
import AppModalReducer from './AppModalReducer';
import AppToasterReducer from './AppToastersReducer';
import AuthReducer from './AuthReducer';
import CardDetailsReducer from './CardDetailsReducer';
import CardListReducer from './CardListReducer';
import CardReducer from './CardReducer';
import CreateNewCardReducer from './CreateNewCardReducer';
import EmployeesReducer from './EmployeesReducer';
import FundRequestsReducer from './FundRequestsReducer';
import { FundsReducer } from './FundsReducer';
import LoadersReducer from './LoadersReducer';
import NotificationsReducer from './NotificationsReducer';
import ProfileReducer from './ProfileReducer';
import StatisticsReducer from './StatisticsReducer';
import TransactionsReducer from './TransactionsReducer';
import UploadDocumentsReducer from './UploadDocumentsReducer';
import useMockReducer from './UseMockReducer';
import UsersReducer from './UsersReducer';
import WalletReducer from './WalletReducer';

const appRootReducer = combineReducers({
  useMock: useMockReducer,
  appConfig: AppConfigReducer,
  loaders: LoadersReducer,
  toasters: AppToasterReducer,
  modal: AppModalReducer,
  auth: AuthReducer,
  cardList: CardListReducer,
  cardDetails: CardDetailsReducer,
  createNewCard: CreateNewCardReducer,
  wallet: WalletReducer,
  uploadDocuments: UploadDocumentsReducer,
  profile: ProfileReducer,
  users: UsersReducer,
  funds: FundsReducer,
  notifications: NotificationsReducer,
  transactions: TransactionsReducer,
  statistics: StatisticsReducer,
  employees: EmployeesReducer,
  card: CardReducer,
  activities: ActivitiesReducer,
  fundRequests: FundRequestsReducer,
});

const errorCatchingReducer = (reducer) => (state, action) => {
  try {
    const newState = reducer(state, action);
    JSON.stringify(newState); // This will throw an error if there are circular references
    return newState;
  } catch (err) {
    console.error('Error in reducer:', err);
    console.error('Action causing the error:', action);
    return state; // Optionally, return the previous state to prevent the app from crashing
  }
};

const rootReducer = errorCatchingReducer(appRootReducer);

export default rootReducer;
