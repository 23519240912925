import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import NewButton from '../../../components/Button/NewButton';
import DataTable, { DataTableItem } from '../../../components/DataTable/DataTable';
import EmptyTable from '../../../components/DataTable/EmptyTable';
import LoadingError from '../../../components/LoadingError';
import { LargerTitle2 } from '../../../components/Titles';
import { apiGetRequest } from '../../../helpers/APIHelper';
import { setAction } from '../../../helpers/ActionsHelper';
import useCapabilities from '../../../helpers/useCapabilities';
import {
  RESET_EMPLOYEE_FILTER,
  SET_EMPLOYEE_FILTER,
  SET_USERS,
} from '../../../redux/EmployeesReducer';
import Head from '../../Head';
import UserDropdown from './UserDropdown';
import UserStatusBadge from './UserStatusBadge';
import { AppBody } from '../../../components/html/html';

const Users = function () {
  const { t, i18n } = useTranslation(['users']);
  const { users, filters } = useSelector((state) => state.employees);
  const [isLoading, setIsLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLimit] = useState(10);
  const [totalResults, setTotalResults] = useState(0);
  const [searchKeywords, setSearchKeywords] = useState('');
  const [searchFilter, setSearchFilters] = useState(null);
  const [invalidateData, setInvalidateData] = useState(0);
  const [userTypes, setUserTypes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [currentUserCan] = useCapabilities();
  const { user: authUser } = useSelector((state) => state.auth);

  const [cookies] = useCookies(['token']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const empStatus = [
    { key: 'Pending', value: t('state.pending') },
    { key: 'Active', value: t('state.active') },
    { key: 'Suspended', value: t('state.suspended') },
    { key: 'Banned', value: t('state.banned') },
    { key: 'Deactivate', value: t('state.deactivate') },
  ];

  const filterObject = [
    {
      key: 'role',
      label: t('list.items.role'),
      type: 'list',
      options: userTypes,
      value: null,
    },
    {
      key: 'department_id',
      label: t('list.items.department'),
      type: 'list',
      options: departments,
      value: null,
    },
    {
      key: 'job_title',
      label: t('list.items.job_title'),
      type: 'text',
      options: null,
      value: null,
    },
    {
      key: 'status',
      label: t('list.items.state'),
      type: 'list',
      options: empStatus,
      value: null,
    },
  ];

  const fetchUsers = (savedFilter = null) => {
    setIsLoading(true);
    apiGetRequest(
      'employees',
      cookies.token,
      {
        paginate: paginationLimit,
        page: currentPage,
        ...(searchKeywords.length > 0 && { keyword: searchKeywords }),
        ...(searchFilter ?? null),
        ...(savedFilter ?? null),
      },
      [],
      {},
      true,
    )
      .then(([response, pagination]) => {
        setErrored(false);
        setIsLoading(false);
        dispatch(setAction(SET_USERS, response));
        setTimeout(() => {
          setInvalidateData(invalidateData + 1);
        }, 1000 * 60 * 5);
        setTotalResults(pagination.total);
      })
      .catch(() => {
        setErrored(true);
        setIsLoading(false);
      });
  };

  const loadUserTypes = () => {
    apiGetRequest('employees/types/filter', cookies.token)
      .then((response) => {
        setUserTypes(response);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      });
  };

  const loadDepartment = () => {
    apiGetRequest('departments', cookies.token)
      .then((response) => {
        setDepartments(response);
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      });
  };
  useEffect(() => {
    loadUserTypes();
    if (filters.length !== 0) {
      loadDepartment();
    }
    // to keep filter when show details then back
    // setSearchFilters(null);
    // dispatch(setAction(RESET_EMPLOYEE_FILTER));
  }, [i18n.language, filters]);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    let savedFilter = null;
    if (filters.length > 0 && searchFilter === null) {
      savedFilter = filters
        .filter((obj) => obj.value !== null)
        .map(({ key, value }) => ({ [key]: value }))
        .reduce(
          (current, accum) => ({
            ...current,
            ...accum,
          }),
          {},
        );
    }
    fetchUsers(savedFilter);
  }, [currentPage, searchKeywords, invalidateData, searchFilter, i18n.language]);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <div className="flex flex-grow flex-col">
      <Head title="Users" />

      <div className="mb-10 flex flex-row items-start">
        <div className="flex-grow pb-3 ltr:text-left rtl:text-right">
          <LargerTitle2>{t('list.title')}</LargerTitle2>
        </div>
        {currentUserCan('create_account_employees') && (
          <NewButton
            className="flex flex-row"
            primary
            text=""
            onClick={() => {
              navigate('add');
            }}
          />
        )}
      </div>
      <div>
        <DataTable
          pagination
          setFilter
          filterState={filters}
          showFilterRowFlag={filters.length !== 0} // to keep filter when show details then back
          resetFilter={() => {
            setSearchFilters(null);
            dispatch(setAction(RESET_EMPLOYEE_FILTER));
          }}
          setFilterState={(object, type) => {
            dispatch(
              setAction(SET_EMPLOYEE_FILTER, {
                type,
                object,
              }),
            );
          }}
          applyFilters={(filtersToApply) => {
            setSearchFilters(
              filtersToApply
                .filter((obj) => obj.value !== null)
                .map(({ key, value }) => ({ [key]: value }))
                .reduce(
                  (current, accum) => ({
                    ...current,
                    ...accum,
                  }),
                  {},
                ),
            );
          }}
          filterObject={filterObject}
          setSearch={setSearchKeywords}
          currentCount={users.length}
          setPage={setCurrentPage}
          totalResults={totalResults}
          limit={paginationLimit}
          currentPage={currentPage}
          updating={isLoading}
        >
          {!isMobile && (
            <DataTableItem isHeader>
              <AppBody
                pClass="Caption1Bold"
                type="div"
                className="grid grid-cols-6 border-b border-gray-3 px-4 py-5 uppercase tracking-wider ltr:text-left rtl:text-right"
              >
                <div className="col-span-1">{t('list.items.user')}</div>
                <div className="col-span-1">{t('list.items.role')}</div>
                <div className="col-span-1">{t('list.items.department')}</div>
                <div className="col-span-1">{t('list.items.job_title')}</div>
                <div className="col-span-1">{t('list.items.card_count')}</div>
                <div className="col-span-1 text-center">{t('list.items.state')}</div>
              </AppBody>
            </DataTableItem>
          )}
          {users.length === 0 && !errored && <EmptyTable />}
          <LoadingError show={errored} retryCallback={fetchUsers} />
          {users.map((user, key) => (
            <DataTableItem
              key={user.id}
              onClick={() => {
                navigate(user.id);
              }}
            >
              {!isMobile && (
                <AppBody
                  type="div"
                  pClass="Body2Regular"
                  className="grid grid-cols-6 px-4 pb-5 pt-2 ltr:text-left rtl:text-right"
                >
                  <div className="col-span-1">
                    <Link
                      className="block text-sm ltr:font-light rtl:font-normal"
                      to={`/dashboard/users/${user.id}`}
                    >{`${user.first_name} ${user.last_name}`}</Link>
                  </div>
                  <div className="col-span-1">
                    <Link
                      className="block text-sm ltr:font-light rtl:font-normal"
                      to={`/dashboard/users/${user.id}`}
                    >
                      {t(user.roles.join(','))}
                    </Link>
                  </div>
                  <div className="col-span-1">
                    <Link
                      className="block text-sm ltr:font-light rtl:font-normal"
                      to={`/dashboard/users/${user.id}`}
                    >
                      {i18n.language === 'ar'
                        ? user.department && user.department.name_ar
                        : user.department && user.department.name}
                    </Link>
                  </div>
                  <div className="col-span-1">
                    <Link
                      className="block text-sm ltr:font-light rtl:font-normal"
                      to={`/dashboard/users/${user.id}`}
                    >
                      {user.job_title}
                    </Link>
                  </div>
                  <div className="col-span-1">
                    <Link
                      className="block text-sm ltr:font-light rtl:font-normal"
                      to={`/dashboard/users/${user.id}`}
                    >
                      {user.card_count}
                    </Link>
                  </div>
                  <div className="col-span-1 flex flex-row items-baseline justify-center">
                    <UserStatusBadge status={user.status} />
                    <UserDropdown
                      text="&#8942;"
                      user={user}
                      className="text-black ltr:pl-6 rtl:pr-6"
                      authUser={authUser}
                    />
                  </div>
                </AppBody>
              )}
              {isMobile && (
                <button
                  type="button"
                  tabIndex={user.id}
                  className={`flex grid w-full grid-cols-6 gap-0 px-2 py-5 ${
                    key === user.length - 1 ? '' : 'border-b border-gray-3'
                  }`}
                  onClick={() => {
                    navigate(`../users/${user.id}`);
                  }}
                >
                  <div className="col-span-4 flex flex-grow flex-col items-start ltr:pl-5 rtl:pr-5">
                    <div className="items-top flex flex-row justify-between self-stretch">
                      <span className="ltr:text-left rtl:text-right">{`${user.first_name} ${user.last_name}`}</span>
                    </div>
                    <AppBody
                      type="div"
                      pClass="Caption1Regular"
                      className="flex flex-grow flex-row justify-between self-stretch text-gray-6 ltr:text-left rtl:text-right"
                    >
                      <div>
                        <span className="block py-1">{`${t('list.items.card_count')} ${
                          user.card_count
                        }`}</span>
                        <span className="block py-1">{t(user.roles.join(','))}</span>
                        <span className="block py-1">
                          {i18n.language === 'ar'
                            ? user.department && user.department.name_ar
                            : user.department && user.department.name}
                          {' - '}
                          {user.job_title}
                        </span>
                      </div>
                    </AppBody>
                  </div>
                  <div className="col-span-2 flex h-full flex-col items-center justify-center px-2">
                    <UserStatusBadge status={user.status} />
                  </div>
                </button>
              )}
            </DataTableItem>
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default Users;
