import { useTranslation } from 'react-i18next';
import failureXAnimation from '../../../assets/lotties/failureXAnimation2.json';
import SuccessCheckAnimation from '../../../assets/lotties/SuccessCheckAnimation2.json';
import AppLottie from '../../../components/AppLottie';
import { AppHeader } from '../../../components/html/html';

function AddUserSuccessOrFail({ isSuccess, errCode }) {
  const { t } = useTranslation('usersV2');

  return (
    <div className="flex h-full items-center justify-center">
      <div>
        <div className="mb-14">
          <AppLottie
            animationData={isSuccess ? SuccessCheckAnimation : failureXAnimation}
            width={isSuccess ? 320 : 220}
          />
        </div>

        <AppHeader h="h4" className="mb-5 text-center">
          {isSuccess && t('User creation successful!')}
          {!isSuccess && t('User creation failed.')}
        </AppHeader>

        <AppHeader h="h5" className="text-center text-gray-6">
          {isSuccess &&
            t('The user will receive an email with instructions to complete their registration.')}
          {!isSuccess && (
            <>
              <span className="block">{t('Please contact our support team for assistance.')}</span>
              <span className="block">{t('We apologize for the inconvenience.')}</span>
              {errCode && (
                <span className="mt-2 block text-danger">
                  {t('Error Code:')} {errCode}
                </span>
              )}
            </>
          )}
        </AppHeader>
      </div>
    </div>
  );
}

export default AddUserSuccessOrFail;
