import { getLocalData } from '../../helpers/Utils';
import { loginActiveStep } from '../../helpers/constants';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_IS_LOGGEDIN = 'SET_IS_LOGGEDIN';
export const SET_USER_CAPABILITIES = 'SET_USER_CAPABILITIES';
export const SET_USER_ACCOUNT = 'SET_USER_ACCOUNT';
export const SET_REGISTRATION_SMS_TOKEN = 'SET_REGISTRATION_SMS_TOKEN';
export const SET_SELECTED_CR = 'SET_SELECTED_CR';
export const SET_IS_MANAGER = 'SET_IS_MANAGER';
export const SET_LOGIN_DATA = 'SET_LOGIN_DATA';
export const SET_LOGIN_SMS_TOKEN = 'SET_LOGIN_SMS_TOKEN';
export const SET_LOGIN_MOBILE = 'SET_LOGIN_MOBILE';
export const SET_RETURN_URL = 'SET_RETURN_URL';

const initialState = {
  redirectURL: null,
  loginProcess: {
    activeLoginStep: loginActiveStep.loginAndBanner,
    cachedLoginStepData: null,
    loggedInUser: getLocalData('userData'),
  },
  // old logic
  loggedin: false,
  returnUrl: '',
  user: null,
  capabilities: [],
  account: {},
  login: {
    smsToken: null,
    form: null,
    mobile: null,
  },
  registration: {
    form: null,
    smsToken: null,
    companyInformation: {
      selectedCR: null,
      isManager: 0,
    },
  },
  quickSignUp: {
    isFinishAndSuccess: false,
  },
};

export default function AuthReducer(state = initialState, action) {
  const draft = { ...state };
  switch (action.type) {
    case 'RESET_APP':
      return {
        ...initialState,
      };
    case 'SET_REDIRECT_URL':
      draft.redirectURL = action.payload;
      return draft;
    case 'SET_ACTIVE_LOGIN_STEP':
      draft.loginProcess.activeLoginStep = action.payload;
      return draft;
    case 'SET_CACHED_LOGIN_STEP_DATA':
      draft.loginProcess.cachedLoginStepData = action.payload;
      return draft;
    case 'SET_LOGGED_IN_USER':
      draft.loginProcess.loggedInUser = action.payload;
      return draft;
    case 'SET_FINISH_QUICK_SIGN_UP':
      draft.quickSignUp.isFinishAndSuccess = action.payload;
      return draft;
    case SET_SELECTED_CR:
      draft.registration.companyInformation.selectedCR = action.payload;
      return draft;
    case SET_IS_MANAGER:
      draft.registration.companyInformation.isManager = action.payload;
      return draft;
    case SET_REGISTRATION_SMS_TOKEN:
      draft.registration.smsToken = action.payload;
      return draft;
    case SET_USER_DATA:
      // update user info in local storage
      // localStorage.setItem('userinfo', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      };
    case SET_USER_CAPABILITIES:
      if (
        action.payload &&
        action.payload.role &&
        action.payload.role[0] &&
        action.payload.role[0].permissions
      ) {
        const mappedCapabilities = action.payload.role[0].permissions.map(
          (permission) => permission.name,
        );
        return {
          ...state,
          capabilities: mappedCapabilities,
        };
      }
      return {
        ...state,
      };

    case SET_IS_LOGGEDIN:
      return {
        ...state,
        loggedin: action.payload,
      };
    case SET_RETURN_URL:
      return {
        ...state,
        returnUrl: action.payload,
      };
    case SET_USER_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case SET_LOGIN_SMS_TOKEN:
      draft.login.smsToken = action.payload;
      return draft;
    case SET_LOGIN_MOBILE:
      draft.login.mobile = action.payload;
      return draft;
    case SET_LOGIN_DATA:
      draft.login.form = action.payload;
      return draft;
    default:
      return {
        ...state,
      };
  }
}
