import { appendURLParams } from 'javascript-functions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../AppRouter/history';
import { devLog, formatDate, generateCardIdQueryParams } from '../../helpers/Utils';
import { cardDetailsSectionIds, inPages, transactionTabs } from '../../helpers/constants';
import CardDetailsFilterTags from '../../modules/Dashboard/MyCards/CardDetails/CardDetailsPage/CardDetailsFilterTags';
import CardsRequestsTableBody from '../../modules/Dashboard/MyCards/CardDetails/CardDetailsPage/CardsRequestTableBody';
import CardsTableActionButtons from '../../modules/Dashboard/MyCards/CardDetails/CardDetailsPage/CardsTableActionButtons';
import CardsTransactionTableBody from '../../modules/Dashboard/MyCards/CardDetails/CardDetailsPage/CardsTransactionTableBody';
import FilterPopup from '../../modules/Dashboard/MyCards/CardDetails/FilterPopup';
import SearchPopup from '../../modules/Dashboard/MyCards/CardDetails/SearchPopup';
import ViewReceipt from '../../modules/Dashboard/MyCards/CardDetails/ViewReceipt';
import { hideModalAction } from '../../redux/AppModalReducer/AppModalActions';
import {
  getCardRequestsAction,
  getCardTransactionsAction,
  hideAllPopups,
  hideFilterPopupAction,
  setActiveTabAction,
} from '../../redux/CardDetailsReducer/CardDetailsActions';
import AppNoData from '../AppNoData';
import AppPagination from '../AppPagination';
import AppSpinner from '../AppSpinner';
import AppTabs from '../AppTabs';
import AppSelect from '../FormInputs/AppSelect';
import { DynamicElement } from '../html/html';
import TimeRangeFilter from './TimeRangeFilter';

function CardDetailsTable({ cardDetails, inPage }) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { cardTransactions, selectedActiveTab, cardRequests, userDetailsData } = useSelector(
    (state) => state.cardDetails,
  );

  const { singleUserDetails } = useSelector((state) => state.users);

  const { loaders, appConfig } = useSelector((state) => state);
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const dispatch = useDispatch();

  const tableTabs = useMemo(() => {
    const sharedSearchParams = {};
    // in ((users page)) using the user ID.
    // in ((my cards)) page using logged in user ID, or target user ID (Details Page without Sidebar)
    const targetUserId =
      inPage === inPages.singleUser || inPage === inPages.allTransactions
        ? singleUserDetails.id
        : userDetailsData?.id;

    sharedSearchParams['card_holders[0]'] = targetUserId;

    return [
      {
        key: transactionTabs.EXPENSES,
        label: t('Expenses'),
        searchParam: [{ is_expense: 1, ...sharedSearchParams }],
      },
      {
        key: transactionTabs.DECLINES,
        label: t('Declines'),
        searchParam: [{ status: 0, ...sharedSearchParams }],
      },
      {
        key: transactionTabs.REFUNDS,
        label: t('Refunds'),
        searchParam: [
          {
            is_refund_and_reversal: 1,
            ...sharedSearchParams,
          },
        ],
      },
      {
        key: transactionTabs.LOADS,
        label: t('Loads'),
        searchParam: [
          {
            'transaction_type[0]': 2,
            'transaction_type[1]': 3,
            ...sharedSearchParams,
          },
        ],
      },
      { key: transactionTabs.REQUESTS, label: t('Requests'), searchParam: null },
    ];
  }, [t, userDetailsData, singleUserDetails, inPage]);

  const [transactionsDates, setTransactionsDates] = useState({});
  const [requestsDates, setRequestsDates] = useState({});
  const [timeRangeParams, setTimeRangeParams] = useState({});
  const [paginationParams, setPaginationParams] = useState({ page: 1 });
  const [filtersPopupParams, setFiltersPopupParams] = useState({});
  const [activeTabParams, setActiveTabParams] = useState(tableTabs[0].searchParam[0]);
  const [searchPopupParams, setSearchPopupParams] = useState({});
  const [exportParams, setExportParams] = useState({});
  const [filterTags, setFilterTags] = useState({});

  const totalPages = useMemo(() => {
    return (
      (selectedActiveTab === transactionTabs.REQUESTS
        ? cardRequests?.paginator?.total_pages
        : cardTransactions?.paginator?.total_pages) || 1
    );
  }, [selectedActiveTab, cardRequests, cardTransactions]);

  const resetPagination = useCallback(() => {
    setPaginationParams({ page: 1 });
  }, []);

  const resetKeywordSearchParams = useCallback(() => {
    setSearchPopupParams({});
  }, []);

  const resetSearchParams = useCallback(() => {
    setTimeRangeParams({ days: 30 });
    resetPagination();
    resetKeywordSearchParams();
    setFiltersPopupParams({});
    setFilterTags({});
  }, []);

  const handleTabChange = useCallback((tab) => {
    const tabParams = {};

    if (tab.searchParam) {
      tab.searchParam.forEach((ele) => {
        Object.entries(ele).forEach(([key, value]) => {
          tabParams[key] = value;
        });
      });
    }

    resetSearchParams();
    setActiveTabParams(tabParams);
    dispatch(setActiveTabAction(tab.key));
    dispatch(hideAllPopups());
  }, []);

  const createKeywordTagObj = useCallback((keyword) => {
    return {
      filterQueryName: 'keyword',
      filterName: 'Search By',
      filterValue: keyword,
      formValue: null,
    };
  }, []);

  const handleSearch = useCallback(
    (keyword) => {
      setSearchPopupParams({ keyword });
      setFilterTags((prev) => {
        return {
          ...prev,
          keyword: createKeywordTagObj(keyword),
        };
      });

      if (isMobile) {
        dispatch(hideModalAction());
      }
    },
    [isMobile],
  );

  const handlePaginate = useCallback((page) => {
    setPaginationParams((prev) => {
      return {
        ...prev,
        page,
      };
    });
  }, []);

  const handleApplyTimeRangeFilter = useCallback(
    (queryObj) => {
      history.replace({ search: appendURLParams('timeRangeFilter', JSON.stringify(queryObj)) });

      setTimeRangeParams((prev) => {
        const prevSearchParams = { ...prev };
        const newQueryObj = { ...queryObj };

        if (newQueryObj.from_date && newQueryObj.to_date && prevSearchParams.days) {
          delete prevSearchParams.days;
        }
        if (prevSearchParams.from_date && prevSearchParams.to_date && newQueryObj.days) {
          delete prevSearchParams.from_date;
          delete prevSearchParams.to_date;
        }

        return { ...prevSearchParams, ...newQueryObj };
      });
      resetPagination();
    },
    [isMobile],
  );

  const handleApplyFilters = useCallback(
    (values) => {
      const queryObject = {};
      const tags = {};
      values.filters.forEach((ele) => {
        if (ele.filter.name === 'amount') {
          Object.entries(ele.subFilter).forEach(([key, { value }]) => {
            queryObject[key] = value;
          });

          tags.amount = {
            filterQueryName: 'amount',
            filterName: ele.filter.label,
            filterValue: `${queryObject.from_amount} - ${queryObject.to_amount}- ${queryObject.currency}`,
            formValue: ele,
          };
        } else {
          queryObject[ele.filter.queryName] = ele.subFilter.value;
          tags[ele.filter.queryName] = {
            filterQueryName: ele.filter.queryName,
            filterName: ele.filter.label,
            filterValue: ele.subFilter.label,
            formValue: ele,
          };
        }
      });

      setFiltersPopupParams({ ...queryObject });
      resetPagination();
      setFilterTags(() => {
        if (searchPopupParams.keyword) {
          return {
            keyword: createKeywordTagObj(searchPopupParams.keyword),
            ...tags,
          };
        }
        return tags;
      });

      if (isMobile) {
        dispatch(hideModalAction());
      }
    },
    [searchPopupParams, isMobile],
  );

  const handleRemoveSinglePopupFilter = useCallback((singleFilter) => {
    dispatch(hideFilterPopupAction());

    setFilterTags((prev) => {
      const updated = { ...prev };
      delete updated[singleFilter.filterQueryName];
      return updated;
    });

    if (singleFilter.filterQueryName === 'keyword') {
      resetKeywordSearchParams();
    } else {
      setFiltersPopupParams((prev) => {
        const updated = { ...prev };
        if (singleFilter.filterQueryName === 'amount') {
          delete updated.from_amount;
          delete updated.to_amount;
          delete updated.currency;
        } else {
          delete updated[singleFilter.filterQueryName];
        }
        return updated;
      });
    }
  }, []);

  const handleOnSearch = useCallback(
    (keyword) => {
      handleSearch(keyword);
      resetPagination();
    },
    [searchPopupParams],
  );

  const handleGetRequests = useCallback(
    (params) => {
      devLog(`Getting card REQUESTS of tab: [${selectedActiveTab}]...`);

      dispatch(
        getCardRequestsAction({
          params,
        }),
      );
    },
    [selectedActiveTab],
  );

  const handleGetTransactions = useCallback(
    (params) => {
      devLog(`Getting card TRANSACTIONS of tab: [${selectedActiveTab}]...`);
      dispatch(
        getCardTransactionsAction({
          params,
        }),
      );
    },
    [selectedActiveTab],
  );

  const updateTransactionTypeParams = useCallback(
    (params) => {
      devLog(`In: [${selectedActiveTab}], Clearing transaction_type[n] and getting values...`);
      const transactionTypeRegex = /^transaction_type\[\d+\]$/;
      const updatedParams = {};
      Object.entries(params).forEach(([key, value]) => {
        if (!transactionTypeRegex.test(key)) {
          updatedParams[key] = value;
        }
      });

      return updatedParams;
    },
    [selectedActiveTab],
  );

  const addConditionalReversalStatus = useCallback(
    (params) => {
      devLog(`In: [${selectedActiveTab}], Adding Conditional Status if Type is ((REVERSAL))...`);

      const updatedParams = { ...params };
      if (updatedParams['transaction_type[]'] === '1') {
        updatedParams.status = 1;
      }

      return updatedParams;
    },
    [selectedActiveTab],
  );

  // ! ================ This is FIRST Transaction Call ================ //
  const handleTimeRangeChange = useCallback((queryObj) => {
    devLog('>>>>>>>>>> This is FIRST Transaction Call <<<<<<<<<<');
    handleApplyTimeRangeFilter(queryObj);
  }, []);

  useEffect(() => {
    if (Object.values(timeRangeParams).length) {
      const cardIdParams =
        inPage === inPages.allTransactions ? {} : generateCardIdQueryParams(cardDetails);

      const params = {
        ...searchPopupParams,
        ...paginationParams,
        ...timeRangeParams,
        ...filtersPopupParams,
        ...activeTabParams,
        ...cardIdParams,
      };

      setExportParams(params);

      if (selectedActiveTab === transactionTabs.REQUESTS) {
        handleGetRequests(params);
      } else if (
        (selectedActiveTab === transactionTabs.REFUNDS ||
          selectedActiveTab === transactionTabs.LOADS) &&
        params['transaction_type[]']
      ) {
        let updatedParams = updateTransactionTypeParams(params);
        updatedParams = addConditionalReversalStatus(params);

        // Delete is_refund_and_reversal since we need only one of them
        delete updatedParams.is_refund_and_reversal;

        setExportParams(updatedParams);
        handleGetTransactions(updatedParams);
      } else if (selectedActiveTab !== transactionTabs.REQUESTS) {
        handleGetTransactions(params);
      }
    }
  }, [
    timeRangeParams,
    paginationParams,
    filtersPopupParams,
    searchPopupParams,
    activeTabParams,
    cardDetails,
    appConfig.lang,
  ]);

  useEffect(() => {
    const dates = {};
    if (cardTransactions) {
      cardTransactions.data.forEach((ele) => {
        const formattedDate = formatDate(ele.transaction_timestamp, 'DD MMM YYYY');
        dates[formattedDate] = formattedDate;
      });
      setTransactionsDates(dates);
    }
  }, [cardTransactions]);

  useEffect(() => {
    const dates = {};
    if (cardRequests) {
      cardRequests.data.forEach((ele) => {
        const formattedDate = formatDate(ele.created_at, 'DD MMM YYYY');
        dates[formattedDate] = formattedDate;
      });
      setRequestsDates(dates);
    }
  }, [cardRequests]);

  return (
    <>
      {/* Table Header */}
      <div
        className="bg-white lg:sticky lg:z-10"
        style={{
          top: document.getElementById(cardDetailsSectionIds.CARD_DETAILS_HEADER)?.clientHeight,
        }}
      >
        {/* View Receipt Popup */}
        <ViewReceipt cardDetails={cardDetails} inPage={inPage} />

        {/* Filter Popup */}
        {!isMobile && <FilterPopup filterTags={filterTags} onApply={handleApplyFilters} />}

        {/* Search Popup */}
        {!isMobile && <SearchPopup onSearch={handleOnSearch} />}

        {/* Mobile Tab DDL */}
        <div className="mb-2 mt-4 w-full lg:hidden">
          <AppSelect
            styleType="nude"
            className="rounded-2xl border-0 text-sm shadow xs:text-base"
            mobileSelect
            options={tableTabs.map((ele) => ({ ...ele, value: ele.label }))}
            value={{ value: tableTabs.find((ele) => ele.key === selectedActiveTab).label }}
            onChange={(val) => {
              handleTabChange(val);
            }}
          />
        </div>

        <div
          className={`${
            inPage !== inPages.allTransactions ? 'lg:border-t' : ''
          } lg-px-0  flex items-center justify-between border-gray-3 px-1 py-2 lg:border-b`}
        >
          {/* Table Tabs */}
          {!isMobile && (
            <AppTabs
              isDisabled={loaders.getCardRequestsAction || loaders.getCardTransactionsAction}
              defaultActiveTab={tableTabs.find((ele) => ele.key === selectedActiveTab)}
              onTabChange={(tab) => {
                if (tab.key !== selectedActiveTab) {
                  handleTabChange(tab);
                }
              }}
              tabs={tableTabs}
            />
          )}

          <CardsTableActionButtons
            isDisabled={loaders.getCardRequestsAction || loaders.getCardTransactionsAction}
            exportParams={exportParams}
            filterTags={filterTags}
            handleOnSearch={handleOnSearch}
            handleApplyFilters={handleApplyFilters}
            handleTimeRangeChange={handleTimeRangeChange}
          />
        </div>

        {/* Desktop Time Range Filter */}
        <div
          className={`hidden lg:block ${
            loaders.getCardRequestsAction || loaders.getCardTransactionsAction
              ? 'pointer-events-none opacity-50'
              : ''
          }`}
        >
          <TimeRangeFilter
            handleTimeRangeChange={handleTimeRangeChange}
            activeTab={selectedActiveTab}
          />
        </div>

        <CardDetailsFilterTags
          filterTags={filterTags}
          handleRemoveSinglePopupFilter={handleRemoveSinglePopupFilter}
        />
      </div>

      {/* Table Body (Transactions) */}
      <div>
        {selectedActiveTab !== transactionTabs.REQUESTS && !loaders.getCardTransactionsAction && (
          <DynamicElement element={isMobile ? 'div' : 'table'} className="w-full table-auto">
            <DynamicElement element={isMobile ? 'div' : 'tbody'}>
              <CardsTransactionTableBody
                activeTab={selectedActiveTab}
                cardTransactions={cardTransactions}
                transactionsDates={transactionsDates}
              />
            </DynamicElement>
          </DynamicElement>
        )}

        {/* Table Body (Requests) */}
        {selectedActiveTab === transactionTabs.REQUESTS && !loaders.getCardRequestsAction && (
          <DynamicElement element={isMobile ? 'div' : 'table'} className="w-full table-auto">
            <DynamicElement element={isMobile ? 'div' : 'tbody'}>
              <CardsRequestsTableBody cardRequests={cardRequests} requestsDates={requestsDates} />
            </DynamicElement>
          </DynamicElement>
        )}

        {/* Spinner */}
        {(loaders.getCardTransactionsAction || loaders.getCardRequestsAction) && (
          <div className="h-72">
            <AppSpinner />
          </div>
        )}

        {/* No Transactions Data */}
        {selectedActiveTab !== transactionTabs.REQUESTS &&
          !loaders.getCardTransactionsAction &&
          !cardTransactions?.data.length && <AppNoData className="h-72" />}

        {/* No Requests Data */}
        {selectedActiveTab === transactionTabs.REQUESTS &&
          !loaders.getCardRequestsAction &&
          !cardRequests?.data.length && <AppNoData className="h-72" />}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="sticky bottom-0 flex justify-end bg-white py-4">
          <AppPagination
            isDisabled={loaders.getCardRequestsAction || loaders.getCardTransactionsAction}
            currentPage={paginationParams.page}
            totalPages={totalPages}
            onPageChange={(page) => {
              handlePaginate(page);
            }}
          />
        </div>
      )}
    </>
  );
}

export default CardDetailsTable;
