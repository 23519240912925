import { bytesToSizes } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import animationData from '../../../assets/lotties/upload.json';
import { compressFile, isFilePDF } from '../../../helpers/Utils';
import AppLottie from '../../AppLottie';
import { AppBody } from '../../html/html';
import FilePreview from './FilePreview';
import ReplaceReceiptBtn from './ReplaceReceiptBtn';
import UploadPlaceholder from './UploadPlaceholder';

function AppUpload({
  onChange,
  uploadedFilePreview,
  isDisabled,
  isLoading,
  uploadError,
  placeHolder,
  height = 150,
  width = 150,
  maxSize = 2097152,
  showAccepted = true,
  noFileUploadedStateImage = null,
  accepts = ['jpg', 'jpeg', 'png', 'pdf'],
  acceptFilesErrorMessage = null,
}) {
  const { t } = useTranslation('commonV2');
  const ref = useRef();
  const [fileError, setFileError] = useState(null);
  const [localFilePreview, setLocalFilePreview] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [isPDF, setIsPDF] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);

  useEffect(() => {
    if (uploadedFilePreview?.path) {
      setLocalFilePreview(null);
      setFilePreview(uploadedFilePreview.path);
      setIsPDF(isFilePDF(uploadedFilePreview));
    }
  }, [uploadedFilePreview]);

  useEffect(() => {
    if (uploadError) {
      setLocalFilePreview(null);
      setFileError(uploadError);
    }
  }, [uploadError]);

  const acceptedFileTypes = useMemo(() => {
    return accepts.toString();
  }, [accepts]);

  const readableFileSize = useMemo(() => {
    return bytesToSizes(maxSize);
  }, [maxSize]);

  const handleFileUpload = useCallback(({ FILE, event }) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setFilePreview(null);
      setLocalFilePreview(reader.result);
      onChange({ FILE, event });
      setIsPDF(isFilePDF(FILE));
    });
    reader.readAsDataURL(FILE);
  }, []);

  const handleChange = (e) => {
    setFileError(null);

    const file = e.target.files[0];
    if (!file) return;

    const fileType = file.type.split('/')[1];
    const fileSize = file.size;

    if (!accepts.includes(fileType)) {
      const errorMessage = acceptFilesErrorMessage
        ? t(acceptFilesErrorMessage)
        : `${fileType || file.name} ${t('is not accepted file type')}`;
      setFileError(errorMessage);
      return;
    }

    if (fileSize > maxSize) {
      compressFile(file)
        .then((compressedFile) => handleFileUpload({ FILE: compressedFile, event: e }))
        .catch((err) => setFileError(err.message));
    } else {
      handleFileUpload({ FILE: file, event: e });
    }
  };

  const handleClick = (e) => {
    if (!e.target.closest('[data-id="preview-btn"]')) {
      ref.current.click();
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const mockEvent = { target: { files: e.dataTransfer.files } };
    handleChange(mockEvent);
    setIsDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleClick}
      className={`relative flex items-center justify-center overflow-hidden rounded-lg border-2 border-gray-4 bg-gray-1 ${
        isDragOver ? 'outline-dashed outline-2 outline-stone-600' : ''
      } ${!isDisabled ? 'cursor-pointer' : ''}`}
      style={{ height, width }}
    >
      <input
        ref={ref}
        disabled={isDisabled}
        type="file"
        className="hidden"
        onChange={handleChange}
        accept={accepts.map((ext) => `.${ext}`).join(', ')}
      />

      {/* no file uploaded state */}
      {!filePreview && (
        <>
          {!noFileUploadedStateImage && (
            <div className="mb-28">
              <AppLottie
                autoplay={false}
                animationData={animationData}
                height="150px"
                width="150px"
                isStopped={!isLoading}
              />
            </div>
          )}

          {noFileUploadedStateImage && noFileUploadedStateImage}
        </>
      )}

      {/* all file states previews */}
      <div className="absolute left-0 top-0 h-full w-full">
        <FilePreview
          filePreview={filePreview}
          localFilePreview={localFilePreview}
          isPDF={isPDF}
          isDisabled={isDisabled}
          height={height}
          width={width}
        />
      </div>

      {/* upload receipt text */}
      {!filePreview && !fileError && (
        <UploadPlaceholder
          showAccepted={showAccepted}
          placeHolder={placeHolder}
          acceptedFileTypes={acceptedFileTypes}
          readableFileSize={readableFileSize}
        />
      )}

      {/* replace receipt btn */}
      {filePreview && !isDisabled && <ReplaceReceiptBtn />}

      {/* upload error */}
      {fileError && (
        <AppBody
          pClass="Caption2Medium"
          type="div"
          className="absolute bottom-0 z-20 rounded  bg-red-50 p-2 text-red-600"
        >
          {fileError}
        </AppBody>
      )}
    </div>
  );
}

export default AppUpload;
