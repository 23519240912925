export const getInputStateClassNames = ({ valid = false, invalid = false }) => {
  const normalClasses = 'border-gray-3';
  const invalidClasses = 'border-danger';
  const validClasses = 'border-success';

  const sharedClasses =
    'w-full rounded-md border px-3 py-2 leading-tight text-black focus:border focus:outline-none focus:border-gray-3 focus:shadow focus:ring-0 disabled:cursor-not-allowed disabled:text-gray-6 disabled:opacity-60 placeholder:text-gray-6';

  if (invalid) return `${sharedClasses} ${invalidClasses}`;
  if (valid) return `${sharedClasses} ${validClasses}`;

  return `${sharedClasses} ${normalClasses}`;
};

export const getInputSizeClassName = ({ size = 'md' }) => {
  const inputSize = {
    sm: 'h-8',
    md: 'h-10',
    lg: 'h-12',
  };

  return inputSize[size];
};

export const getPasswordIconSize = ({ size = 'md' }) => {
  const iconSize = {
    sm: 16,
    md: 18,
    lg: 20,
  };

  return iconSize[size];
};
