import { settingsPopupContent, transactionTabs } from '../../helpers/constants';

const INITIAL_STATE = {
  selectedCardDetailsData: null,
  selectedCardDetailsBalance: null,
  cardTransactions: null,
  singleTransactionDetails: null,
  selectedActiveTab: transactionTabs.EXPENSES,
  cardRequests: null,
  singleRequestDetails: null,
  tags: null,
  docTypes: null,
  departments: null,
  showReceiptPopup: null,
  showRequestFormPopup: null,
  settingsPopupCardDetails: null,
  currentSettingsPopupContent: settingsPopupContent.settingsList,
  changedColorOrNameData: null,
  showSections: null,
  currentCardActionsPopupContent: null,
  showFilterPopup: null,
  transactionCurrencies: null,
  requestCurrencies: null,
  showSearchPopup: null,
  cardInfoDetails: null,
  userDetailsData: null,
  requestActionAnimation: {
    show: false,
    isSuccess: null,
    amount: null,
    message: null,
    reason: null,
  },
};

const CardDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // ====== Card Details ====== //
    case 'ADD_SELECTED_CARD_DETAILS': {
      return { ...state, selectedCardDetailsData: action.payload };
    }

    case 'CLEAR_SELECTED_CARD_DETAILS': {
      return { ...state, selectedCardDetailsData: null };
    }

    case 'UPDATE_SELECTED_CARD_DETAILS': {
      const updatedSelectedCardDetailsData = { ...state.selectedCardDetailsData };
      const updatedSettingsPopupCardDetails = state.settingsPopupCardDetails
        ? action.payload
        : null;

      if (action.payload.is_physical) {
        updatedSelectedCardDetailsData.physicalCard = action.payload;
      } else {
        updatedSelectedCardDetailsData.virtualCard = action.payload;
      }
      return {
        ...state,
        selectedCardDetailsData: updatedSelectedCardDetailsData,
        settingsPopupCardDetails: updatedSettingsPopupCardDetails,
      };
    }

    // ====== Card Balance ====== //
    case 'ADD_CARD_DETAILS_BALANCE': {
      return {
        ...state,
        selectedCardDetailsBalance: {
          amount: +action.payload.amount || 0,
          currency: action.payload.currency,
        },
      };
    }
    case 'CLEAR_CARD_DETAILS_BALANCE': {
      return {
        ...state,
        selectedCardDetailsBalance: null,
      };
    }

    // ====== Card Transactions ====== //
    case 'ADD_CARD_TRANSACTIONS': {
      return { ...state, cardTransactions: action.payload };
    }

    case 'CLEAR_CARD_TRANSACTIONS': {
      return { ...state, cardTransactions: null };
    }

    case 'UPDATE_CARD_TRANSACTIONS': {
      const updatedItems = state.cardTransactions.data.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });

      return {
        ...state,
        cardTransactions: { data: updatedItems },
      };
    }

    // ====== Card Transaction Details ====== //
    case 'ADD_SINGLE_TRANSACTION_DETAILS': {
      return { ...state, singleTransactionDetails: action.payload };
    }

    case 'CLEAR_SINGLE_TRANSACTION_DETAILS': {
      return { ...state, singleTransactionDetails: null };
    }

    case 'ADD_REQUEST_ACTION_ANIMATION': {
      return { ...state, requestActionAnimation: action.payload };
    }

    case 'CLEAR_REQUEST_ACTION_ANIMATION': {
      return {
        ...state,
        requestActionAnimation: {
          show: false,
          isSuccess: null,
          amount: null,
          message: null,
          reason: null,
        },
      };
    }

    // ====== Card Requests ====== //
    case 'ADD_CARD_REQUESTS': {
      return { ...state, cardRequests: action.payload };
    }

    case 'CLEAR_CARD_REQUESTS': {
      return { ...state, cardRequests: null };
    }

    case 'UPDATE_CARD_REQUESTS': {
      const updatedItems = state.cardRequests.data.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });

      return {
        ...state,
        cardRequests: { data: updatedItems },
      };
    }

    // ====== Card Request Details ====== //
    case 'ADD_SINGLE_REQUEST_DETAILS': {
      return { ...state, singleRequestDetails: action.payload };
    }

    case 'CLEAR_SINGLE_REQUEST_DETAILS': {
      return { ...state, singleRequestDetails: null };
    }

    // ====== Tags ====== //
    case 'ADD_TAGS': {
      return { ...state, tags: action.payload };
    }

    case 'CLEAR_TAGS': {
      return { ...state, tags: null };
    }

    // ====== Departments ====== //
    case 'ADD_DEPARTMENTS': {
      return { ...state, departments: action.payload };
    }

    case 'CLEAR_DEPARTMENTS': {
      return { ...state, departments: null };
    }

    // ====== Doc Types ====== //
    case 'GET_DOC_TYPES': {
      return { ...state, docTypes: action.payload };
    }

    case 'CLEAR_DOC_TYPES': {
      return { ...state, docTypes: null };
    }

    // ====== Currencies ====== //
    case 'ADD_TRANSACTION_CURRENCIES': {
      return { ...state, transactionCurrencies: action.payload };
    }

    case 'CLEAR_TRANSACTION_CURRENCIES': {
      return { ...state, transactionCurrencies: null };
    }
    case 'ADD_REQUEST_CURRENCIES': {
      return { ...state, requestCurrencies: action.payload };
    }

    case 'CLEAR_REQUEST_CURRENCIES': {
      return { ...state, requestCurrencies: null };
    }

    // ====== Receipt Popup ====== //
    case 'SHOW_RECEIPT_POPUP': {
      return { ...state, showReceiptPopup: true };
    }

    case 'HIDE_RECEIPT_POPUP': {
      return { ...state, showReceiptPopup: false };
    }

    case 'SHOW_SECTION': {
      return { ...state, showSections: { ...state.showSections, [action.payload]: true } };
    }

    case 'HIDE_SECTION': {
      return { ...state, showSections: { ...state.showSections, [action.payload]: false } };
    }

    case 'HIDE_ALL_SECTIONS': {
      return { ...state, showSections: null };
    }

    // ====== Request Form Popup ====== //
    case 'SHOW_REQUEST_FORM_POPUP': {
      return { ...state, showRequestFormPopup: true };
    }

    case 'HIDE_REQUEST_FORM_POPUP': {
      return { ...state, showRequestFormPopup: false };
    }

    // ====== Settings Popup ====== //
    case 'ADD_SETTINGS_POPUP_DATA': {
      return { ...state, settingsPopupCardDetails: action.payload };
    }

    case 'HIDE_SETTINGS_POPUP_DATA': {
      return { ...state, settingsPopupCardDetails: null };
    }

    case 'SET_CURRENT_SETTINGS_POPUP_CONTENT': {
      return { ...state, currentSettingsPopupContent: action.payload };
    }

    case 'UPDATE_SETTINGS_POPUP_DATA': {
      const updatedSelectedCardDetailsData = { ...state.selectedCardDetailsData };
      if (action.payload.is_physical) {
        updatedSelectedCardDetailsData.physicalCard = action.payload;
      } else {
        updatedSelectedCardDetailsData.virtualCard = action.payload;
      }
      return {
        ...state,
        selectedCardDetailsData: updatedSelectedCardDetailsData,
        settingsPopupCardDetails: action.payload,
      };
    }

    case 'SET_CHANGED_NAME_COLOR_DATA': {
      return { ...state, changedColorOrNameData: action.payload };
    }
    // ====== Details Table Tabs ====== //
    case 'SET_ACTIVE_TAB': {
      return { ...state, selectedActiveTab: action.payload };
    }

    // ====== Card Actions Popup ====== //
    case 'SET_CURRENT_CARD_ACTIONS_POPUP_CONTENT': {
      return { ...state, currentCardActionsPopupContent: action.payload };
    }

    case 'HIDE_CARD_ACTIONS_POPUP': {
      return { ...state, currentCardActionsPopupContent: null };
    }

    // ====== Filter Popup ====== //
    case 'SHOW_FILTER_POPUP': {
      return { ...state, showFilterPopup: true };
    }

    case 'HIDE_FILTER_POPUP': {
      return { ...state, showFilterPopup: false };
    }

    // ====== Search Popup ====== //
    case 'SHOW_SEARCH_POPUP': {
      return { ...state, showSearchPopup: true };
    }

    case 'HIDE_SEARCH_POPUP': {
      return { ...state, showSearchPopup: false };
    }

    // ====== CardInfo Popup ====== //
    case 'SHOW_CARD_INFO_POPUP': {
      return { ...state, cardInfoDetails: action.payload };
    }

    case 'HIDE_CARD_INFO_POPUP': {
      return { ...state, cardInfoDetails: null };
    }

    // ====== User Details Data ====== //
    case 'SET_USER_DETAILS_DATA': {
      return { ...state, userDetailsData: action.payload };
    }

    default:
      return state;
  }
};

export default CardDetailsReducer;
