import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import useCapabilities from '../../helpers/useCapabilities';
import DashboardBody from './DashboardBody';
import DashboardSkeleton from './DashboardSkeleton';
import Funds from './Funds';
import FundRequestForm from './Funds/FundRequestForm';
import Transactions from './Transactions';
import TransactionDetailsPage from './Transactions/TransactionDetails';
import Users from './Users';
import AddUser from './Users/AddUser';
import EditUser from './Users/EditUser';
import TopUpAccount from './Users/TopUpAccount';
import UserDetails from './Users/UserDetails';

import FixedTop from '../../components/MainMenu/FixedTop';
import { setAction } from '../../helpers/ActionsHelper';
import useRegisterStep from '../../helpers/UseRegisterStep';
import { refreshUserData } from '../../helpers/UserHelper';
import { SET_IS_LOGGEDIN, SET_USER_CAPABILITIES, SET_USER_DATA } from '../../redux/AuthReducer';
import Head from '../Head';
import NotFound from '../NotFound';
import CompanyEditAddress from '../Profile/CompanyProfile/CompanyEditAddress';
import CompanyProfileV2 from '../Profile/CompanyProfile/index';
import UserProfileV2 from '../Profile/UserProfile/index';
import UserChangePassword from '../Profile/UserProfile/UserChangePassword';
import UserEditAddress from '../Profile/UserProfile/UserEditAddress';
import EditExpireDocuments from './Expire-Documents/EditExpireDocuments';
import ExpireDocumentsMessage from './ExpireDocumentsMessage';
import FundDetails from './Funds/FundDetails';
import MyCards from './MyCards';
import CardDetailsPage from './MyCards/CardDetails/CardDetailsPage';
import CreateNewCard from './MyCards/CreateNewCard';
import SplitTransaction from './Transactions/Split/SplitTransaction';
import UsersV2 from './UsersV2';
import AddUserForm from './UsersV2/AddUserForm';
import SingleUserDetails from './UsersV2/SingleUserDetails';
import EditSingleUserForm from './UsersV2/SingleUserDetails/EditSingleUserForm';
import WalletV2 from './Wallet';
import AddFundingMethod from './Wallet/AddFundingMethod';
import DepositPage from './Wallet/CardProgramActions/DepositPage';
import TransferPage from './Wallet/CardProgramActions/TransferPage';
import WithdrawPage from './Wallet/CardProgramActions/WithdrawPage';
import CardProgramDetailsPage from './Wallet/CardProgramDetails/CardProgramDetailsPage';

function Dashboard() {
  const [cookies, , removeCookie] = useCookies(['token']);
  const [currentUserCan] = useCapabilities();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, SetUser] = useState(null);
  const [ready, SetReady] = useState(null);

  const asyncFn = async () => {
    try {
      const [userinfo, capabilities, accountReady] = await refreshUserData(cookies.token);

      dispatch(setAction(SET_USER_DATA, userinfo));
      dispatch(setAction(SET_IS_LOGGEDIN, true));
      dispatch(setAction(SET_USER_CAPABILITIES, capabilities));
      if (!accountReady) {
        navigate(useRegisterStep(userinfo));
      }
      SetReady(accountReady);
      SetUser(userinfo);
    } catch (err) {
      if (err.status && err.status === 401) {
        removeCookie('token', { path: '/' });
      }
    }
  };

  useEffect(() => {
    asyncFn();
  }, [cookies.token]);

  if (!user || !ready) {
    return <DashboardSkeleton />;
  }

  return (
    <>
      <Head title="Dashboard" />
      <FixedTop />

      <ExpireDocumentsMessage />

      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="404" element={<NotFound />} />
        <Route path="/" element={<DashboardBody user={user} />} />

        {/* ========== Wallet  ========== */}
        {currentUserCan('list_account_bank_funds') && (
          <Route path="wallet" element={<WalletV2 />} />
        )}
        {currentUserCan('list_account_bank_funds') && (
          <Route path="wallet/:cardProgramId" element={<CardProgramDetailsPage />} />
        )}
        <Route path="wallet/deposit" element={<DepositPage />} />
        <Route path="wallet/withdraw" element={<WithdrawPage />} />
        <Route path="wallet/transfer" element={<TransferPage />} />
        <Route path="wallet/add-funding-method" element={<AddFundingMethod />} />

        {/* =================== Profile =================== */}
        <Route path="profile">
          <Route path="user" element={<UserProfileV2 />} />
          <Route path="user/edit-address" element={<UserEditAddress />} />
          <Route path="user/change-password" element={<UserChangePassword />} />

          {currentUserCan('show_account') && (
            <>
              <Route path="company" element={<CompanyProfileV2 />} />
              <Route path="company/edit-address" element={<CompanyEditAddress />} />
            </>
          )}
        </Route>

        {/* Request */}
        <Route path="funds/*" element={<Funds />}>
          <Route path=":id" element={<Funds />} />
        </Route>
        <Route path="funds/details/:id" element={<FundDetails />} />
        {(currentUserCan('update_account_funds') || currentUserCan('update_own_funds')) && (
          <Route path="funds/edit/:id" element={<FundRequestForm isEdit />} />
        )}

        {/* ========== Users V2 ========== */}
        {currentUserCan('list_account_employees') && (
          <Route path="users-v2" element={<UsersV2 />} />
        )}
        {currentUserCan('create_account_employees') && (
          <Route path="users-v2/add" element={<AddUserForm />} />
        )}

        {currentUserCan('list_account_employees') && (
          <Route path="users-v2/:userId" element={<SingleUserDetails />} />
        )}

        {currentUserCan('update_account_employees') && (
          <Route path="users-v2/:userId/edit" element={<EditSingleUserForm />} />
        )}

        {/* Users */}
        {currentUserCan('list_account_employees') && <Route path="users/" element={<Users />} />}
        {currentUserCan('list_account_employees') && (
          <Route path="users/:id" element={<UserDetails />} />
        )}
        {currentUserCan('create_account_employees') && (
          <Route path="users/add" element={<AddUser />} />
        )}
        {currentUserCan('update_account_employees') && (
          <Route path="users/edit/:id" element={<EditUser />} />
        )}
        {currentUserCan('change_card_balance') && (
          <Route path="users/topup/:recipient_id/:card_id" element={<TopUpAccount />} />
        )}
        {currentUserCan('change_card_balance') && (
          <Route path="users/withdraw/:recipient_id/:card_id" element={<TopUpAccount withdraw />} />
        )}
        {(currentUserCan('transaction_list_view') ||
          currentUserCan('transactions_expense_view')) && (
          <Route
            path="users/transactions/details/:id"
            element={<TransactionDetailsPage isSplit />}
          />
        )}
        {currentUserCan('create_account_transaction_splits') && (
          <Route path="users/transactions/split/:id" element={<SplitTransaction />} />
        )}

        {/* ========== My Cards ========== */}
        <Route path="my_cards" element={<MyCards />} />

        {/* ========== Card Details ========== */}
        {currentUserCan('cards_view') && (
          <Route path="my_cards/:cardId" element={<CardDetailsPage />} />
        )}
        {currentUserCan('cards_view') && (
          <Route path="users/my_cards/:cardId" element={<CardDetailsPage />} />
        )}
        {currentUserCan('change_card_balance') && (
          <Route path="cards/topup/:recipient_id/:card_id" element={<TopUpAccount />} />
        )}
        {currentUserCan('change_card_balance') && (
          <Route path="cards/withdraw/:recipient_id/:card_id" element={<TopUpAccount withdraw />} />
        )}
        {(currentUserCan('transaction_list_view') ||
          currentUserCan('transactions_expense_view')) && (
          <Route
            path="cards/transactions/details/:id"
            element={<TransactionDetailsPage isSplit />}
          />
        )}
        {currentUserCan('create_own_transaction_splits') && (
          <Route path="cards/transactions/split/:id" element={<SplitTransaction />} />
        )}

        {/* Transaction */}
        {currentUserCan('transaction_list_view') && (
          <Route path="transactions" element={<Transactions />} />
        )}
        {(currentUserCan('transaction_list_view') ||
          currentUserCan('transactions_expense_view')) && (
          <Route path="transactions/details/:id" element={<TransactionDetailsPage />} />
        )}
        {(currentUserCan('create_account_transaction_splits') ||
          currentUserCan('create_own_transaction_splits')) && (
          <Route path="transactions/split/:id" element={<SplitTransaction />} />
        )}
        {(currentUserCan('edit_account_transaction_splits') ||
          currentUserCan('edit_own_transaction_splits')) && (
          <Route path="transactions/split/edit/:id" element={<SplitTransaction isEdit />} />
        )}
        {currentUserCan('transaction_list_view') && (
          <Route path="transactions/receipt/:noReceipt" element={<Transactions />} />
        )}
        {currentUserCan('transaction_list_view') && (
          <Route path="transactions/status/:status" element={<Transactions />} />
        )}

        {/* Expense */}
        {currentUserCan('transactions_expense_view') && (
          <Route path="expenses" element={<Transactions isSplit isExpense />} />
        )}
        {currentUserCan('transactions_expense_view') && (
          <Route path="expenses/details/:id" element={<TransactionDetailsPage isSplit />} />
        )}
        {currentUserCan('create_own_transaction_splits') && (
          <Route path="expenses/split/:id" element={<SplitTransaction />} />
        )}
        {currentUserCan('edit_own_transaction_splits') && (
          <Route path="expenses/split/edit/:id" element={<SplitTransaction isEdit />} />
        )}
        {currentUserCan('transactions_expense_view') && (
          <Route path="expenses/receipt/:noReceipt" element={<Transactions isSplit />} />
        )}
        {currentUserCan('transactions_expense_view') && (
          <Route path="expenses/status/:status" element={<Transactions isSplit />} />
        )}

        {/* Expire Documents */}
        {currentUserCan('update_own_expire_document') && (
          <Route path="expire-documents" element={<EditExpireDocuments stepNumber="0" />} />
        )}

        {/* ========== Create New Card ========== */}
        {currentUserCan('create_own_cards') && (
          <Route path="my_cards/create-new-card" element={<CreateNewCard />} />
        )}
        {currentUserCan('create_account_cards') && (
          <Route path="users/create-new-card" element={<CreateNewCard />} />
        )}
      </Routes>
    </>
  );
}

export default Dashboard;
