import { useDispatch } from 'react-redux';
import AppCloseButton from '../../../components/AppCloseButton';
import { hideModalAction } from '../../../redux/AppModalReducer/AppModalActions';
import AddUserSuccessOrFail from './AddUserSuccessOrFail';

function AddUserSuccessOrFailPopup({ isSuccess, handleBack, errCode }) {
  const dispatch = useDispatch();

  return (
    <div className="h-[90vh] w-[684px] rounded-2xl bg-white shadow">
      <div className="mr-9 mt-9 text-right">
        <AppCloseButton
          onClick={() => {
            dispatch(hideModalAction());
            if (isSuccess) {
              handleBack();
            }
          }}
        />
      </div>

      <AddUserSuccessOrFail isSuccess={isSuccess} errCode={errCode} />
    </div>
  );
}

export default AddUserSuccessOrFailPopup;
