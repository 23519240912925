import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { receiptPopupIconIds } from '../../helpers/constants';
import useCapabilities from '../../helpers/useCapabilities';
import { showModalAction } from '../../redux/AppModalReducer/AppModalActions';
import ScrollToGap from '../ScrollToGap';
import {AppButton, AppIcon} from '../html/html';
import CreateEditSplitReceipt from './CreateEditSplitReceipt';
import DeleteSplitConfirm from './DeleteSplitConfirm';
import SplitsList from './SplitsList';

function SplitReceipt({
  transactionDetails,
  showSections,
  tags,
  departments,
  getDepartmentsAction,
  getTagsAction,
  inPage,
}) {
  const [currentUserCan] = useCapabilities();
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const { auth } = useSelector((state) => state);

  const canSplitReceipt = useMemo(() => {
    const canCreateTransactionSplits = currentUserCan('create_account_transaction_splits');
    const canCreateOwnTransactionSplits =
      currentUserCan('create_own_transaction_splits') &&
      auth.user?.id === transactionDetails.user_id?.id;

    const isSplitsEmpty = !transactionDetails?.splits?.length;

    const isAuthTransaction = transactionDetails?.transaction_type?.toLowerCase() === 'auth';

    const isNotDeclined = transactionDetails?.status?.toLowerCase() !== 'declined';

    return (
      (canCreateTransactionSplits || canCreateOwnTransactionSplits) &&
      isSplitsEmpty &&
      isAuthTransaction &&
      isNotDeclined
    );
  }, [transactionDetails, auth]);

  const canEditReceipt = useMemo(() => {
    const canEditTransactionSplits = currentUserCan('edit_account_transaction_splits');
    const canEditOwnTransactionSplits =
      currentUserCan('edit_own_transaction_splits') &&
      auth.user?.id === transactionDetails.user_id?.id;

    return canEditTransactionSplits || canEditOwnTransactionSplits;
  }, [transactionDetails, auth]);

  const canDeleteReceipt = useMemo(() => {
    const canDeleteTransactionSplits = currentUserCan('delete_account_transaction_splits');
    const canDeleteOwnTransactionSplits =
      currentUserCan('delete_own_transaction_splits') &&
      auth.user?.id === transactionDetails.user_id?.id;

    return canDeleteTransactionSplits || canDeleteOwnTransactionSplits;
  }, [transactionDetails, auth]);

  const showSplitSection = useMemo(() => {
    return (showSections && showSections[receiptPopupIconIds.SPLIT_RECEIPT]) || false;
  }, [showSections]);

  const handleDeleteSplit = () => {
    dispatch(
      showModalAction(
        <DeleteSplitConfirm transactionDetails={transactionDetails} inPage={inPage} />,
      ),
    );
  };

  if (canSplitReceipt && showSplitSection) {
    return (
      <>
        <ScrollToGap id={receiptPopupIconIds.SPLIT_RECEIPT} />
        <CreateEditSplitReceipt
          transactionDetails={transactionDetails}
          tags={tags}
          departments={departments}
          getDepartmentsAction={getDepartmentsAction}
          getTagsAction={getTagsAction}
          inPage={inPage}
        />
      </>
    );
  }

  if (transactionDetails?.splits?.length && showSplitSection) {
    return (
      <>
        <ScrollToGap id={receiptPopupIconIds.SPLIT_RECEIPT} />
        <div className="my-4 flex justify-end gap-4 print:hidden">
          {canEditReceipt && (
            <AppButton
              onClick={() => {
                setIsEdit(!isEdit);
              }}
              button="link"
              className="flex gap-1 font-medium hover:underline"
            >
              <span>
                <AppIcon iClass="XLargeFont" className="fa-regular fa-pen-to-square" />
              </span>
              {!isEdit && <span>{t('Edit Split')}</span>}
              {isEdit && <span>{t('Cancel', { ns: 'commonV2' })}</span>}
            </AppButton>
          )}

          {canDeleteReceipt && (
            <AppButton
              button="link"
              className="flex gap-1 font-medium text-red-600 hover:underline"
              onClick={() => {
                handleDeleteSplit();
              }}
            >
              <span>
                <AppIcon iClass="XLargeFont" className="fa-regular fa-trash-can" />
              </span>
              <span>{t('Delete Split')}</span>
            </AppButton>
          )}
        </div>

        {isEdit && (
          <CreateEditSplitReceipt
            isEdit
            setIsEdit={setIsEdit}
            tags={tags}
            departments={departments}
            transactionDetails={transactionDetails}
            getDepartmentsAction={getDepartmentsAction}
            getTagsAction={getTagsAction}
            inPage={inPage}
          />
        )}
        {!isEdit && <SplitsList transactionDetails={transactionDetails} />}
      </>
    );
  }
}

export default SplitReceipt;
