import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {history} from '../../../../AppRouter/history';
import AppImageComponent from '../../../../components/AppImageComponent';
import {AppBody, AppButton, AppHeader} from '../../../../components/html/html';
import {generateMockCard, getServerErrMessage} from '../../../../helpers/Utils';
import {cardTypesKeys, createNewCardSteps} from '../../../../helpers/constants';
import {http} from '../../../../http';
import {showToasterAction} from '../../../../redux/AppToastersReducer/AppToastersActions';
import {
  setActiveCreateNewCardStepAction,
  setUserPrimaryCardAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';

function ManageCardControl() {
  const { useMock } = useSelector((state) => state);
  const { newCreatedCard, selectedCardType, selectedCardProgram } = useSelector(
    (state) => state.createNewCard,
  );
  const { loaders } = useSelector((state) => state);
  const { t, i18n } = useTranslation('cardsV2');

  const isCreatingPhysicalCard = useMemo(() => {
    return selectedCardType?.name === cardTypesKeys.PHYSICAL;
  }, [selectedCardType]);

  const cardControlOptions = useMemo(() => {
    return [
      {
        id: 'ecommerce',
        label: t('E-Commerce'),
        disabled: false,
      },
      {
        id: 'atm',
        label: t('Cash Withdrawal'),
        disabled: false,
      },
      {
        id: 'pos',
        label: t('Point of Sale'),
        disabled: false,
      },
    ];
  }, [i18n.language]);

  const initialCheckedItems = useMemo(() => {
    const checked = {};
    cardControlOptions.forEach((ele) => {
      checked[ele.id] = true;
    });

    return checked;
  }, [cardControlOptions]);

  const [checkedItems, setCheckedItems] = useState(initialCheckedItems);
  const dispatch = useDispatch();

  const handleCheckItem = useCallback(
    ({ item, checked }) => {
      setCheckedItems((prev) => {
        return {
          ...prev,
          [item.id]: checked,
        };
      });
    },
    [checkedItems],
  );

  const handleSaveCardControl = useCallback(() => {
    if (isCreatingPhysicalCard) {
      dispatch(setUserPrimaryCardAction(newCreatedCard));
      dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPlasticCard));
    } else {
      history.push('/dashboard/my_cards');
    }
    return;
    // eslint-disable-next-line no-unreachable
    const cardId = newCreatedCard?.id;

    http
      .put(`cardmanager/cards/${cardId}/control`, checkedItems, {
        loader: 'handleSaveCardControl',
        headers: {
          'Content-Type': 'application/json',
        },
        useMock: useMock.createVirtualCardMock || useMock.replaceVirtualCardMock,
        mockParams: generateMockCard({
          status: useMock.createVirtualCardMockStatus,
          isPhysical: false,
        }),
      })
      .then(() => {
        if (isCreatingPhysicalCard) {
          dispatch(setUserPrimaryCardAction(newCreatedCard));
          dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createPlasticCard));
        } else {
          history.push('/dashboard/my_cards');
        }
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [checkedItems, isCreatingPhysicalCard, newCreatedCard, useMock]);

  return (
    <div className="flex h-full items-center justify-center">
      <div className="max-w-[350px] px-5 sm:px-0">
        <AppHeader h="h3">{t('Card Successfully Activated')}</AppHeader>

        <div className="relative my-5">
          <div className="overflow-hidden rounded-lg shadow-lg">
            <AppImageComponent
              scheme={selectedCardProgram.scheme}
              alt="nqood card"
              skeletonWidth={350}
              skeletonHeight={207}
              src={newCreatedCard?.design?.attachment}
            />
          </div>
          <span className="absolute left-5 top-28 font-bold">{newCreatedCard?.last_four}</span>
          <span className="absolute bottom-4 left-5" style={{ direction: 'ltr' }}>
            <span className="block font-bold">{`${newCreatedCard?.cardholder.first_name} ${newCreatedCard?.cardholder.last_name}`}</span>
            <AppBody pClass="Body2Regular" type="span" className="block">
              {newCreatedCard?.card_name || ''}
            </AppBody>
          </span>
        </div>

        {/* <AppBody pClass="Body2Medium" className="text-gray-6 mb-5">
          {t(
            'You can customize where your card will work, you can edit this from the card page setting',
          )}
        </AppBody> */}

        {/* <div className="mb-6 overflow-hidden rounded-lg border border-gray-3 bg-white">
          {cardControlOptions.map((ele) => {
            return (
              <div
                key={ele.label}
                className={`flex w-full items-center justify-between border-b p-4 text-start font-normal last:border-0 hover:bg-stone-50 ${
                  ele.disabled ? 'cursor-not-allowed opacity-50' : ''
                }`}
              >
                <span>{ele.label}</span>
                <AppSwitchInput
                  disabled={false}
                  checked={checkedItems[ele.id]}
                  onChange={(checked) => {
                    handleCheckItem({ item: ele, checked });
                  }}
                />
              </div>
            );
          })}
        </div> */}

        <AppButton
          onClick={handleSaveCardControl}
          isLoading={loaders.handleSaveCardControl}
          button="black"
          className="mb-4 w-full"
          size="md"
        >
          {!isCreatingPhysicalCard && t('Go to Your Card')}
          {isCreatingPhysicalCard && t('Continue To Create Your Plastic Card')}
        </AppButton>

        {isCreatingPhysicalCard && (
          <AppButton
            onClick={() => {
              history.push('/dashboard/my_cards');
            }}
            disabled={loaders.handleSaveCardControl}
            button="link"
            className="mb-12 w-full"
          >
            {t('Skip to Your Cards')}
          </AppButton>
        )}
      </div>
    </div>
  );
}

export default ManageCardControl;
