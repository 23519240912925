import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { isDev, isTesting } from '../../helpers/Utils';
import useAccountReady from '../../helpers/useAccountReady';
import useCapabilities from '../../helpers/useCapabilities';
import { AppBody } from '../html/html';

function MenuItem(props) {
  const { to, label } = props;
  const location = useLocation();
  let selected = false;
  if (to === 'dashboard') {
    selected = location.pathname === '/dashboard';
  } else {
    selected = location.pathname.includes(`${to}`);
  }
  return (
    <li
      className={`inline-flex items-start justify-start gap-2.5 rounded-[30px] px-3 py-[5px] ${
        selected ? 'bg-primary text-white shadow-sm' : 'text-black'
      }`}
    >
      <NavLink to={to} className="flex flex-col items-center">
        <AppBody pClass="Caption1Bold" type="span" className="capitalize">
          {label}
        </AppBody>
      </NavLink>
    </li>
  );
}

function MainMenu() {
  const { t } = useTranslation(['common']);
  const [currentUserCan] = useCapabilities();
  const accountReady = useAccountReady();

  return (
    <div className="flex flex-grow flex-row flex-wrap items-center justify-center">
      <ul className="flex flex-wrap items-start gap-1">
        <MenuItem to="dashboard" label={t('dashboard')} />

        {currentUserCan('list_account_bank_funds') && accountReady && (
          <MenuItem to="dashboard/wallet" label={t('wallets')} />
        )}

        {/* {currentUserCan('transaction_list_view') && (
          <MenuItem to="dashboard/transactions" label={t('transactions')} />
        )} */}

        {currentUserCan('transaction_list_view') && (
          <MenuItem to="dashboard/funds" label={t('requests')} />
        )}

        {currentUserCan('list_account_employees') && (
          <MenuItem
            label={t('users')}
            to={`${isDev() || isTesting() ? 'dashboard/users-v2' : 'dashboard/users'}`}
          />
        )}

        {currentUserCan('cards_view') && <MenuItem to="dashboard/my_cards" label={t('my_cards')} />}
      </ul>
    </div>
  );
}

export default MainMenu;
