import { randomId } from 'javascript-functions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isFilePDF } from '../../helpers/Utils';
import { Headline2, PrintTitle } from '../Titles';
import SingleAttachmentFilePrint from './SingleAttachmentFilePrint';

function ReceiptUploadAndAttachmentsPrint({ transactionDetails }) {
  const { t } = useTranslation('cardsV2');
  const [attachmentData, setAttachmentData] = useState([]);
  const [splitData, setSplitData] = useState([]);

  useEffect(() => {
    if (!transactionDetails?.splits?.length) {
      setAttachmentData(transactionDetails.receipts || []);
      setSplitData([]);
    } else {
      setSplitData(transactionDetails.splits || []);
      if (transactionDetails.receipts.filter((rec) => rec.doc_type !== 'Receipt').length > 0) {
        setAttachmentData(transactionDetails.receipts.filter((rec) => rec.doc_type !== 'Receipt'));
      } else {
        setAttachmentData([]);
      }
    }
  }, [transactionDetails]);

  return (
    <div className="w-full">
      {/* Header */}
      {splitData.length > 0 && (
        <PrintTitle className="mb-3">{t('Split Attachment Uploaded')}</PrintTitle>
      )}

      {/* Looping Titles */}
      {splitData?.map((item, index) => {
        return item.receipts.map((split, i) => (
          <Headline2 key={randomId()}>
            <span className="block font-semibold">{i === 0 && `${t('Split')} ${index + 1}`}</span>
            <span className="text-gray-6">#{i + 1}</span> {t(split.doc_type)}
          </Headline2>
        ));
      })}

      {/* Header */}
      {attachmentData.length > 0 && (
        <PrintTitle className="my-3">{t('Attachment Uploaded')}</PrintTitle>
      )}
      {/* Looping Titles */}
      {attachmentData?.map((item, index) => {
        return (
          <Headline2 key={randomId()}>
            <span className="text-gray-6">#{index + 1}</span> {t(item.doc_type)}
          </Headline2>
        );
      })}
      {/* Looping Content */}
      {splitData?.map((item) => {
        return item.receipts.map((split) => (
          <SingleAttachmentFilePrint key={randomId()} isPDF={isFilePDF(split)} attachment={split} />
        ));
      })}
      {/* Looping Content */}
      {attachmentData?.map((item) => {
        return (
          <SingleAttachmentFilePrint key={randomId()} isPDF={isFilePDF(item)} attachment={item} />
        );
      })}
    </div>
  );
}

export default ReceiptUploadAndAttachmentsPrint;
