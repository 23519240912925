import { randomNumber } from 'javascript-functions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import masterCardLogo from '../../../../assets/png/Mastercard-logo.png';
import visaCardLogo from '../../../../assets/png/Visa-logo.png';
import AppNoData from '../../../../components/AppNoData';
import { LockIconComponent, NotAllowedIconComponent } from '../../../../components/SVGs/SVGs';
import TranslatedData from '../../../../components/TranslatedData';
import { getFilteredCards, getServerErrMessage } from '../../../../helpers/Utils';
import { cardSchemes, cardStatusKeys, transactionTabs } from '../../../../helpers/constants';
import { http } from '../../../../http';
import { showToasterAction } from '../../../../redux/AppToastersReducer/AppToastersActions';
import {
  clearSelectedCardDetailsAction,
  hideAllPopups,
  setActiveTabAction,
} from '../../../../redux/CardDetailsReducer/CardDetailsActions';
import {
  clearCardListAction,
  clearSingleCardAction,
} from '../../../../redux/CardListReducer/CardListActions';
import NqoodCard from './NqoodCard';
import NqoodDoubleCard from './NqoodDoubleCard';
import SideBarFilter from './SideBarFilter';

function CardListColumn() {
  const { userCardList } = useSelector((state) => state.cardList);
  const { loaders, auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation('cardsV2');

  const mobileToggleArchivedCardsOptions = useMemo(() => {
    return [
      { id: 'ActiveCards', value: t('Active Cards'), showFlag: false },
      { id: 'ArchivedCards', value: t('Archived Cards'), showFlag: true },
    ];
  }, [t]);

  const [showArchived, setShowArchived] = useState(false);
  const [notArchivedCards, setNotArchivedCards] = useState([]);
  const [archivedCards, setArchivedCards] = useState([]);
  const [cards, setCards] = useState([]);
  const [notFilteredCards, setNotFilteredCards] = useState([]);
  const [relatedCards, setRelatedCards] = useState({});
  const [cardProgramsFilterOptions, setCardProgramsFilterOptions] = useState([]);
  const [cardProgramFilterValue, setCardProgramFilterValue] = useState(null);
  const [activeMobileArchivedCardsTab, setActiveMobileArchivedCardsTab] = useState(
    mobileToggleArchivedCardsOptions[0],
  );

  const allCardsOption = useMemo(() => {
    return {
      label: (
        <span className="ps-4 text-xxs">
          <TranslatedData ar="كل البطاقات" en="All Cards" />
        </span>
      ),
      value: 'all',
    };
  }, []);

  const isLoading = useMemo(() => {
    return loaders.getCardPrograms || loaders.getCardListAction;
  }, [loaders]);

  const showToggleArchivedCardsButton = useMemo(() => {
    return Boolean(archivedCards.length);
  }, [archivedCards]);

  const resetDetailsPage = useCallback(() => {
    dispatch(hideAllPopups());
    dispatch(clearSelectedCardDetailsAction());
    dispatch(clearSingleCardAction());
    dispatch(setActiveTabAction(transactionTabs.EXPENSES));
  }, []);

  const serializeFilterOptions = useCallback((data) => {
    const options = [allCardsOption];

    data.forEach((ele) => {
      const isSuspendedCardProgram = cardStatusKeys.SUSPENDED === ele.status;
      const isDeactivatedCardProgram = cardStatusKeys.DEACTIVATED === ele.status;
      const isClosedCardProgram = isDeactivatedCardProgram || isSuspendedCardProgram;

      options.push({
        label: (
          <div className="flex items-center justify-between text-xxs leading-tight">
            <div className="flex items-center">
              {isSuspendedCardProgram && (
                <span>
                  <LockIconComponent />
                </span>
              )}
              {isDeactivatedCardProgram && (
                <span>
                  <NotAllowedIconComponent />
                </span>
              )}
              <span className={`inline-block ${!isClosedCardProgram ? 'ps-4' : 'ps-1'}`}>{`${
                ele.funding_source_type?.bank_name || '-'
              }-${ele.currency}`}</span>
            </div>
            <div>
              <img
                className="max-w-[23px]"
                src={ele.scheme === cardSchemes.MASTER ? masterCardLogo : visaCardLogo}
                alt="Card Scheme Logo"
              />
            </div>
          </div>
        ),
        value: ele.id,
      });
    });

    return options;
  }, []);

  const getCardPrograms = useCallback(() => {
    const userId = auth.user.id;
    http
      .get(`cardmanager/cardproducts`, {
        loader: `getCardPrograms`,
        params: {
          user_id: userId,
          'multiStatus[0]': 'ACTIVE',
          'multiStatus[1]': 'SUSPENDED',
          'multiStatus[2]': 'DEACTIVATED',
        },
      })
      .then((res) => {
        setCardProgramsFilterOptions(serializeFilterOptions(res.data));
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [auth]);

  const handleToggleAchievedCards = useCallback(() => {
    setShowArchived(!showArchived);
    resetDetailsPage();
  }, [showArchived, cardProgramsFilterOptions]);

  useEffect(() => {
    if (userCardList?.length) {
      const { related, archived, notArchived } = getFilteredCards(userCardList);

      setRelatedCards(related);
      setNotArchivedCards(notArchived);
      setArchivedCards(archived);
    }
  }, [userCardList]);

  useEffect(() => {
    const cardsToShow = showArchived ? archivedCards : notArchivedCards;

    setCards(cardsToShow);
    setNotFilteredCards(cardsToShow);
  }, [showArchived, archivedCards, notArchivedCards]);

  useEffect(() => {
    getCardPrograms();
  }, []);

  useEffect(() => {
    if (cardProgramFilterValue) {
      setCards(() => {
        if (cardProgramFilterValue.value === 'all') {
          return notFilteredCards;
        }
        return notFilteredCards.filter(
          (ele) => ele.cardproduct.id === cardProgramFilterValue.value,
        );
      });
    }
  }, [cardProgramFilterValue, notFilteredCards]);

  useEffect(() => {
    if (cardProgramsFilterOptions.length) {
      setCardProgramFilterValue(cardProgramsFilterOptions[0]);
    }
  }, [cardProgramsFilterOptions]);

  useEffect(() => {
    return () => {
      dispatch(clearCardListAction());
    };
  }, []);

  return (
    <>
      <div className="app-thin-scrollbar h-[76vh] overflow-y-scroll py-1 pe-3 ps-1 hover:pe-1 lg:h-[72vh]">
        {/* Card Program Filter */}
        <SideBarFilter
          cardProgramsFilterOptions={cardProgramsFilterOptions.filter(
            (ele) => ele?.value !== cardProgramFilterValue?.value,
          )}
          cardProgramFilterValue={cardProgramFilterValue}
          onFilterChange={(value) => {
            setCardProgramFilterValue(value);
          }}
        />

        {/* Mobile Toggle Tabs */}
        <div className="mb-4 flex items-center justify-around gap-2 rounded-lg bg-gray-5 p-1 font-bold lg:hidden">
          {mobileToggleArchivedCardsOptions.map((ele) => {
            const activeClasses =
              ele.id === activeMobileArchivedCardsTab.id ? 'text-white bg-primary' : '';
            return (
              <button
                onClick={() => {
                  setActiveMobileArchivedCardsTab(ele);
                  setShowArchived(ele.showFlag);
                }}
                key={ele.id}
                type="button"
                className={`w-full rounded-xl py-2 ${activeClasses}`}
              >
                {ele.value}
              </button>
            );
          })}
        </div>

        {/* related cards */}
        <div className="mb-3 flex w-full flex-col items-center gap-3">
          {!isLoading &&
            cards.map((card) => {
              return (
                relatedCards[card.id] && (
                  <NqoodDoubleCard
                    key={`${card.id}###${relatedCards[card.id].id}`}
                    physicalCard={card}
                    virtualCard={relatedCards[card.id]}
                  />
                )
              );
            })}
        </div>

        {/* single cards */}
        <div className="flex w-full flex-col items-center gap-3">
          {!isLoading &&
            cards.map((card) => !relatedCards[card.id] && <NqoodCard key={card.id} card={card} />)}
        </div>

        {/* loaders */}
        {isLoading && (
          <div className="w-full">
            <SkeletonTheme baseColor="#c2c2c2" highlightColor="#e6e6e6">
              <Skeleton height={90} count={randomNumber(3, 5)} className="mb-2" />
            </SkeletonTheme>
          </div>
        )}

        {/* no cards */}
        {!isLoading && !cards?.length && (
          <div className="flex h-full w-full flex-col items-center justify-center gap-3">
            <AppNoData />
          </div>
        )}
      </div>

      {showToggleArchivedCardsButton && (
        <button
          className="hidden w-full justify-center gap-1 rounded-md bg-gray-3 px-3 py-2 text-center text-xs font-semibold transition-all ease-in-out hover:bg-gray-2 disabled:cursor-not-allowed disabled:opacity-50 lg:flex"
          type="button"
          onClick={handleToggleAchievedCards}
        >
          {!showArchived && <span>{t('Show Archived  Cards')} </span>}
          {showArchived && <span>{t('Hide Archived Cards')} </span>}
          <span className="text-gray-6">{archivedCards.length}</span>
        </button>
      )}
    </>
  );
}

export default CardListColumn;
