import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import BackBtn from '../../../../components/BackBtn';
import CardDetailsTable from '../../../../components/SharedComponents/CardDetailsTable';
import { inPages } from '../../../../helpers/constants';

function UsersAllTransactions() {
  const { selectedSingleUserCardDetails } = useSelector((state) => state.users);
  const { t } = useTranslation('usersV2');

  return (
    <div className="pe-6">
      <div className="mb-14 hidden pt-7 lg:block">
        <BackBtn
          className="mb-4"
          label={t('Back')}
          onClick={() => {
            history.push('/dashboard/users-v2');
          }}
        />
      </div>
      <CardDetailsTable
        inPage={inPages.allTransactions}
        cardDetails={selectedSingleUserCardDetails}
      />
    </div>
  );
}

export default UsersAllTransactions;
