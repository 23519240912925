import { dummyArr } from 'javascript-functions';
import { capitalize } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import AppImageComponent from '../../../../components/AppImageComponent';
import BackBtn from '../../../../components/BackBtn';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import { generateMockCard, getServerErrMessage } from '../../../../helpers/Utils';
import { cardStatusKeys, cardTypesKeys, createNewCardSteps } from '../../../../helpers/constants';
import { http } from '../../../../http';
import { showToasterAction } from '../../../../redux/AppToastersReducer/AppToastersActions';
import {
  selectCardToReplaceAction,
  setActiveCreateNewCardStepAction,
} from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import CancelBtn from './CancelBtn';

function SelectReplaceCard() {
  const { loaders, useMock } = useSelector((state) => state);
  const { selectedCardProgram, createCardUserData, cardToReplace, selectedCardType } = useSelector(
    (state) => state.createNewCard,
  );
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const [replaceCards, setReplaceCards] = useState([]);

  const selectedClasses = useMemo(() => {
    return 'outline-4 outline-black shadow-lg';
  }, []);

  const unSelectedClasses = useMemo(() => {
    return 'outline-1 outline-transparent shadow';
  }, []);

  const getReplaceCards = useCallback(() => {
    http
      .get(`cardmanager/cards/replace`, {
        params: { user_id: createCardUserData?.id, card_product_id: selectedCardProgram?.id },
        loader: 'getReplaceCards',
        useMock: useMock.replacePlasticCardMock || useMock.replaceVirtualCardMock,
        mockParams: [
          generateMockCard({
            status: cardStatusKeys.EXPIRED,
            isPhysical: selectedCardType.name === cardTypesKeys.PHYSICAL,
          }),
          generateMockCard({
            status: cardStatusKeys.TERMINATED,
            isPhysical: selectedCardType.name === cardTypesKeys.PHYSICAL,
          }),
        ],
      })
      .then((res) => {
        let toReplaceCards = [];
        if (selectedCardType.name === cardTypesKeys.PHYSICAL) {
          toReplaceCards = res.data.filter((ele) => ele.is_physical);
        } else {
          toReplaceCards = res.data.filter((ele) => !ele.is_physical);
        }
        setReplaceCards(toReplaceCards);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [createCardUserData, selectedCardProgram, useMock, selectedCardType]);

  const showMaxMessage = useMemo(() => {
    if (selectedCardType.name === cardTypesKeys.PHYSICAL) {
      return selectedCardProgram.physical_can_replace_only || useMock.replacePlasticCardMock;
    }
    return selectedCardProgram.virtual_can_replace_only || useMock.replaceVirtualCardMock;
  }, [selectedCardProgram, useMock]);

  const showReplaceMessage = useMemo(() => {
    if (selectedCardType.name === cardTypesKeys.PHYSICAL) {
      return (
        selectedCardProgram.physical_can_create_and_replace || useMock.plasticCreateAndReplaceMock
      );
    }
    return (
      selectedCardProgram.virtual_can_create_and_replace || useMock.virtualCreateAndReplaceMock
    );
  }, [selectedCardProgram, useMock]);

  const isComingFromCreateOrReplaceStep = useMemo(() => {
    // user selected replace physical card
    if (
      (selectedCardType.name === cardTypesKeys.PHYSICAL &&
        selectedCardProgram.physical_can_create_and_replace) ||
      useMock.plasticCreateAndReplaceMock
    ) {
      return true;
    }

    // user selected replace virtual card
    if (
      (selectedCardType.name === cardTypesKeys.VIRTUAL &&
        selectedCardProgram.virtual_can_create_and_replace) ||
      useMock.virtualCreateAndReplaceMock
    ) {
      return true;
    }

    return false;
  }, [selectedCardType, selectedCardProgram, useMock, cardTypesKeys]);

  useEffect(() => {
    getReplaceCards();
  }, []);

  return (
    <div className="mx-auto mt-[10vh] w-full max-w-[700px] px-5 lg:w-2/3 lg:px-0 xl:w-1/2">
      <div className="mb-5">
        <BackBtn
          className="mb-4"
          onClick={() => {
            if (isComingFromCreateOrReplaceStep) {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.createOrReplaceCard));
            } else {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.selectCardType));
            }
            dispatch(selectCardToReplaceAction(null));
          }}
        />
      </div>
      <AppHeader h="h3">{t('Select Card To Replace It With A New One')}</AppHeader>
      <AppBody pClass="Body2Medium" className="text-gray-6">
        {showMaxMessage &&
          t(
            'You, Have reach the maximum limit to issue new card. you can issue a new card by replacing one of the following cards, the replaced card will be a new card',
          )}
        {showReplaceMessage &&
          t(
            'You can issue a new card by replacing one of the following cards, the replaced card will be a new card',
          )}
      </AppBody>

      <div className="mb-12 mt-6 grid grid-cols-2 gap-5">
        {!loaders.getReplaceCards &&
          replaceCards.map((ele, i) => {
            return (
              <button
                onClick={() => {
                  dispatch(selectCardToReplaceAction(ele));
                }}
                type="button"
                key={JSON.stringify(i)}
                disabled={ele.disabled}
                className={`rounded-lg outline ${
                  cardToReplace?.id === ele?.id ? selectedClasses : unSelectedClasses
                } `}
              >
                <div className="relative overflow-hidden rounded-md text-left text-xs shadow-md">
                  <AppImageComponent
                    className="blur-[2px]"
                    src={ele.design?.attachment}
                    alt={ele.name_on_card}
                    skeletonWidth={306}
                    skeletonHeight={180}
                  />
                  <span
                    className={`absolute bottom-3 left-4 ${ele.is_physical ? 'text-white' : ''}`}
                  >
                    <span className="block font-semibold">{t(capitalize(ele.status?.key))}</span>
                    <span className="block">{ele.last_four}</span>
                  </span>
                </div>
              </button>
            );
          })}

        {loaders.getReplaceCards &&
          dummyArr(4).map((_, i) => {
            return (
              <div key={JSON.stringify(i)}>
                <Skeleton height={180} />
              </div>
            );
          })}
      </div>

      <div className="flex justify-end gap-3 pb-5">
        <CancelBtn />

        <AppButton
          onClick={() => {
            dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.replaceCardConfirmation));
          }}
          disabled={!cardToReplace}
          button="black"
        >
          {t('Next', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default SelectReplaceCard;
