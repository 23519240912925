import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrintButton from '../../../../../../components/AppPrint/PrintButton';
import { AppBody, AppIcon } from '../../../../../../components/html/html';
import { elementScrollTo } from '../../../../../../helpers/Utils';
import { receiptPopupIconIds, transactionTabs } from '../../../../../../helpers/constants';
import useCapabilities from '../../../../../../helpers/useCapabilities';
import {
  hideRequestFormPopupAction,
  showReceiptSectionAction,
  showRequestFormPopupAction,
} from '../../../../../../redux/CardDetailsReducer/CardDetailsActions';

function TransactionsIcons({ transactionPrintWrapperRef }) {
  const {
    singleTransactionDetails,
    selectedActiveTab,
    showRequestFormPopup,
    requestActionAnimation,
  } = useSelector((state) => state.cardDetails);
  const [currentUserCan] = useCapabilities();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const isRequestMoneyActionAnimation = useMemo(() => {
    return requestActionAnimation.show;
  }, [requestActionAnimation]);

  const showSplitReceipt = useMemo(() => {
    const canCreateTransactionSplits = currentUserCan('create_account_transaction_splits');
    const canCreateOwnTransactionSplits =
      currentUserCan('create_own_transaction_splits') &&
      auth.user?.id === singleTransactionDetails?.user_id?.id;

    const isAuthTransaction = singleTransactionDetails?.transaction_type?.toLowerCase() === 'auth';

    const isNotDeclined = singleTransactionDetails?.status?.toLowerCase() !== 'declined';

    const isAmountMoreThanZero = Math.abs(singleTransactionDetails?.transaction_amount) > 0;

    const isExpenseTab = selectedActiveTab === transactionTabs.EXPENSES;

    return (
      (canCreateTransactionSplits || canCreateOwnTransactionSplits) &&
      isAuthTransaction &&
      isNotDeclined &&
      isAmountMoreThanZero &&
      isExpenseTab
    );
  }, [singleTransactionDetails, auth, selectedActiveTab]);

  const showAddAttachment = useMemo(() => {
    const userCanUploadReceipt =
      currentUserCan('upload_account_receipt') ||
      currentUserCan('edit_account_transaction_receipt');

    const userCanUploadOwnReceipt =
      (currentUserCan('upload_own_transaction_receipt') ||
        currentUserCan('edit_own_transaction_receipt')) &&
      auth.user?.id === singleTransactionDetails?.user_id?.id;

    const transactionCanUploadReceipt = singleTransactionDetails?.can_upload_reciept;
    const receiptsLessThanTen = singleTransactionDetails?.receipts.length <= 10;

    const isLoadsTab = selectedActiveTab !== transactionTabs.LOADS;
    const isDeclinesTab = selectedActiveTab !== transactionTabs.DECLINES;

    return (
      (userCanUploadReceipt || userCanUploadOwnReceipt) &&
      transactionCanUploadReceipt &&
      receiptsLessThanTen &&
      isLoadsTab &&
      isDeclinesTab
    );
  }, [singleTransactionDetails, auth, selectedActiveTab]);

  const showRequestMoney = useMemo(() => {
    const isDeclinesTab = selectedActiveTab === transactionTabs.DECLINES;

    const isInsufficient = singleTransactionDetails?.status_description === 'Insufficient funds';
    const isTxnDeclined = singleTransactionDetails?.status_description === 'Txn Declined';

    return (
      (isInsufficient || isTxnDeclined) &&
      isDeclinesTab &&
      !showRequestFormPopup &&
      !isRequestMoneyActionAnimation
    );
  }, [
    singleTransactionDetails,
    selectedActiveTab,
    showRequestFormPopup,
    isRequestMoneyActionAnimation,
  ]);

  const showReceiptPrint = useMemo(() => {
    return !showRequestFormPopup && singleTransactionDetails && !isRequestMoneyActionAnimation;
  }, [showRequestFormPopup, singleTransactionDetails, isRequestMoneyActionAnimation]);

  const attachmentsCount = useMemo(() => {
    return singleTransactionDetails?.receipts?.filter((trans) => trans.doc_type !== 'Receipt')
      .length;
  }, [singleTransactionDetails]);

  const showBackBtn = useMemo(() => {
    return showRequestFormPopup;
  }, [showRequestFormPopup]);

  return (
    <>
      {/* Back Btn */}
      {showBackBtn && (
        <button
          className="hover:text-primary rtl:rotate-180"
          type="button"
          onClick={() => {
            dispatch(hideRequestFormPopupAction());
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-arrow-left" />
        </button>
      )}

      {/* Upload Attachment Icon */}
      {showAddAttachment && (
        <button
          type="button"
          className="relative hover:text-primary"
          onClick={() => {
            dispatch(showReceiptSectionAction(receiptPopupIconIds.UPLOAD_ATTACHMENT));
            setTimeout(() => {
              elementScrollTo(
                receiptPopupIconIds.RECEIPT_POPUP_CONTAINER,
                receiptPopupIconIds.UPLOAD_ATTACHMENT,
              );
            }, 0);
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-paperclip-vertical" />
          <AppBody type="span" pClass="Caption1Regular" className="absolute -bottom-1 -end-2">
            {attachmentsCount}
          </AppBody>
        </button>
      )}

      {/* Split Receipt Icon */}
      {showSplitReceipt && (
        <button
          type="button"
          className="relative hover:text-primary"
          onClick={() => {
            dispatch(showReceiptSectionAction(receiptPopupIconIds.SPLIT_RECEIPT));
            setTimeout(() => {
              elementScrollTo(
                receiptPopupIconIds.RECEIPT_POPUP_CONTAINER,
                receiptPopupIconIds.SPLIT_RECEIPT,
              );
            }, 0);
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-split fa-rotate-270" />

          <AppBody type="span" pClass="Caption1Regular" className="absolute -bottom-1 -end-2">
            {singleTransactionDetails.splits?.length}
          </AppBody>
        </button>
      )}

      {/* Temp Icon */}
      {/* {selectedActiveTab === transactionTabs.EXPENSES && (
        <button type="button" className="relative hover:text-primary">
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-file-signature" />
          <AppBody type="span" pClass="Caption1Regular" className="absolute -bottom-1 -end-2">
            x
          </AppBody>
        </button>
      )} */}

      {/* Request Money Icon */}
      {showRequestMoney && (
        <button
          type="button"
          className="relative hover:text-primary"
          onClick={() => {
            dispatch(showRequestFormPopupAction());
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-money-bill-wave" />
        </button>
      )}

      {/* Print Receipt */}
      {showReceiptPrint && (
        <PrintButton
          ref={transactionPrintWrapperRef}
          documentTitle={`receipt_${singleTransactionDetails?.id}`}
        >
          <AppIcon iClass="XLargeFont" className="fa-regular fa-print hover:text-primary" />
        </PrintButton>
      )}
    </>
  );
}

export default TransactionsIcons;
