import React from 'react';
import { Helmet } from 'react-helmet';

function Head({ ...props }) {
  return (
    <Helmet>
      <title>
        NQOODLET
        {props.title ? ` | ${props.title}` : null}
      </title>
    </Helmet>
  );
}
export default Head;
