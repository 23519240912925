import { randomId } from 'javascript-functions';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toIntAndDecimalNumber } from '../../helpers/Utils';
import { cardActionsPopupContent, cardStatusKeys, inPages } from '../../helpers/constants';
import useCapabilities from '../../helpers/useCapabilities';
import CardActionsPopup from '../../modules/Dashboard/MyCards/CardDetails/CardActionsPopup';
import SingleCardProgramStatus from '../../modules/Dashboard/Wallet/SingleCardProgramStatus';
import { setCurrentCardActionsPopupContent } from '../../redux/CardDetailsReducer/CardDetailsActions';
import AppSpinner from '../AppSpinner';
import AppTooltip from '../AppTooltip';
import { AppBody, AppHeader, AppIcon } from '../html/html';

function CardDetailsHeader({ cardDetails, cardDetailsBalance, inPage }) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { loaders } = useSelector((state) => state);
  const [currentUserCan] = useCapabilities();
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const cardId = useMemo(() => {
    return cardDetails?.virtualCard ? cardDetails?.virtualCard?.id : cardDetails?.physicalCard?.id;
  }, [cardDetails]);

  const cardStatusKey = useMemo(() => {
    const key = cardDetails?.virtualCard
      ? cardDetails?.virtualCard?.status?.key
      : cardDetails?.physicalCard?.status?.key;

    return key.toUpperCase();
  }, [cardDetails]);

  const cardProgramStatus = useMemo(() => {
    const key = cardDetails?.virtualCard
      ? cardDetails?.virtualCard?.cardproduct?.status
      : cardDetails?.physicalCard?.cardproduct?.status;

    return key.toUpperCase();
  }, [cardDetails]);

  const isClosedCardProgram = useMemo(() => {
    return [cardStatusKeys.SUSPENDED, cardStatusKeys.DEACTIVATED].includes(cardProgramStatus);
  }, [cardProgramStatus]);

  const isLoadingBalance = useMemo(() => {
    return (
      loaders[`getCardDetailsBalanceAction[${cardId}]`] ||
      loaders[`getSingleUserCardDetailsBalanceAction[${cardId}]`]
    );
  }, [loaders, cardId]);

  const actionButtons = useMemo(() => {
    const isPending = cardStatusKey === cardStatusKeys.PENDING;
    const isActive = cardStatusKey === cardStatusKeys.ACTIVE;
    const isInActive = cardStatusKey === cardStatusKeys.INACTIVE;

    const isZeroOrLess = cardDetailsBalance?.amount <= 0;

    return [
      {
        id: randomId(),
        show: currentUserCan('change_card_balance'),
        label: (
          <>
            <span>
              <AppIcon className="fa-regular fa-circle-plus" />
            </span>
            <span className="hidden xxs:inline">{t('Card Load')}</span>
            <span className="xxs:hidden">{t('Load')}</span>
          </>
        ),
        onClick: () => {
          dispatch(
            setCurrentCardActionsPopupContent({
              content: cardActionsPopupContent.cardLoad,
              header: t('Card Load To'),
            }),
          );
        },
        disabled: isLoadingBalance || !isActive,
      },
      {
        id: randomId(),
        show: currentUserCan('change_card_balance'),
        label: (
          <>
            <span>
              <AppIcon className="fa-regular fa-circle-minus" />
            </span>
            <span className="hidden xxs:inline">{t('Card Unload')}</span>
            <span className="xxs:hidden">{t('Unload')}</span>
          </>
        ),
        onClick: () => {
          dispatch(
            setCurrentCardActionsPopupContent({
              content: cardActionsPopupContent.cardUnload,
              header: t('Card Unload To'),
            }),
          );
        },
        disabled: isLoadingBalance || isZeroOrLess || false,
      },
      {
        id: randomId(),
        show: currentUserCan('create_funds') && inPage === inPages.myCards,
        label: (
          <>
            <span>
              <AppIcon className="fa-regular fa-money-bill-wave" />
            </span>
            <span className="hidden xxs:inline">{t('Money Requests')}</span>
            <span className="xxs:hidden">{t('Request')}</span>
          </>
        ),
        onClick: () => {
          dispatch(
            setCurrentCardActionsPopupContent({
              content: cardActionsPopupContent.moneyRequest,
              header: t('Money Requests To'),
            }),
          );
        },
        disabled: isLoadingBalance || !isActive,
      },
      {
        id: randomId(),
        show: !isPending && !isInActive,
        label: (
          <>
            <span>
              <AppIcon className="fa-regular fa-file-arrow-down font-semibold" />
            </span>
            <span>{t('Statement')}</span>
          </>
        ),
        onClick: () => {
          dispatch(
            setCurrentCardActionsPopupContent({
              content: cardActionsPopupContent.statement,
              header: t('Download Statement'),
            }),
          );
        },
        disabled: isLoadingBalance || isPending || isInActive,
      },
    ];
  }, [isLoadingBalance, cardDetails, cardDetailsBalance, isMobile, cardStatusKey, inPage, t]);

  return (
    <div
      className={`${
        inPage === inPages.myCards ? 'pt-2' : ''
      } relative flex flex-col justify-between  lg:flex-row`}
    >
      {/* Card Actions Popup */}
      <CardActionsPopup
        cardDetails={cardDetails}
        cardDetailsBalance={cardDetailsBalance}
        inPage={inPage}
      />

      <div className="order-2 flex flex-col gap-1 lg:order-1">
        {/* ================ Card Details Name ================ */}
        <AppHeader h="h2">
          <span
            className={`${
              cardStatusKey === cardStatusKeys.REPLACED || isClosedCardProgram
                ? 'text-gray-6'
                : 'text-black'
            }`}
          >
            {cardDetails?.virtualCard?.card_name ?? cardDetails?.virtualCard?.name_on_card}
          </span>
        </AppHeader>

        {/* ================ Card Details Balance ================ */}
        <div
          className={`flex items-center gap-2 text-2xl font-semibold ${
            cardStatusKey === cardStatusKeys.REPLACED || isClosedCardProgram
              ? 'text-gray-6'
              : 'text-primary'
          }`}
        >
          {!isLoadingBalance && isClosedCardProgram && (
            <AppTooltip
              text={
                <>
                  <p>{t("You can't use this card.")}</p>
                  <p>{t('Please contact your admin')}</p>
                </>
              }
              id={cardId}
            >
              <SingleCardProgramStatus
                cardProgram={{ status: cardProgramStatus }}
                className="text-xl font-normal text-gray-6"
              />
            </AppTooltip>
          )}

          {!isLoadingBalance && (
            <span className="flex gap-2 rtl:justify-end" style={{ direction: 'ltr' }}>
              <span>{toIntAndDecimalNumber(cardDetailsBalance?.amount || 0).fullNumber}</span>
              <span>{cardDetailsBalance?.currency}</span>
            </span>
          )}

          {isLoadingBalance && (
            <span className="flex items-center gap-2">
              <span>
                <AppSpinner size={24} />
              </span>
              <span>{t('Loading Balance')}</span>
            </span>
          )}
        </div>
      </div>

      {/* ================ Card Actions Buttons ================ */}
      <AppBody
        pClass="Caption1Bold"
        type="div"
        className="order-1 mb-5 flex items-start justify-between gap-2 pt-5 sm:gap-5 lg:order-2 lg:mb-0 lg:justify-end"
      >
        {actionButtons.map((ele) => {
          return (
            ele.show && (
              <button
                key={ele.id}
                className={`flex items-center justify-end gap-1 rounded disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent ${
                  (cardStatusKey === cardStatusKeys.REPLACED || isClosedCardProgram) && ele.disabled
                    ? 'text-gray-6'
                    : 'text-primary'
                }`}
                type="button"
                onClick={ele.onClick}
                disabled={ele.disabled}
              >
                {ele.label}
              </button>
            )
          );
        })}
      </AppBody>
    </div>
  );
}

export default CardDetailsHeader;
