import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrintButton from '../../../../../../components/AppPrint/PrintButton';
import { elementScrollTo } from '../../../../../../helpers/Utils';
import { receiptPopupIconIds, transactionTabs } from '../../../../../../helpers/constants';
import useCapabilities from '../../../../../../helpers/useCapabilities';

import { AppBody, AppIcon } from '../../../../../../components/html/html';
import {
  hideWalletRequestFormPopupAction,
  showWalletReceiptSectionAction,
  showWalletRequestFormPopupAction,
} from '../../../../../../redux/WalletReducer/WalletActions';

function WalletTransactionsIcons({ walletTransactionPrintWrapperRef }) {
  const {
    singleWalletTransactionDetails,
    selectedWalletSubActiveTab,
    showWalletRequestFormPopup,
    walletRequestActionAnimation,
  } = useSelector((state) => state.wallet);
  const [currentUserCan] = useCapabilities();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const isRequestMoneyActionAnimation = useMemo(() => {
    return walletRequestActionAnimation.show;
  }, [walletRequestActionAnimation]);

  const showSplitReceipt = useMemo(() => {
    const canCreateTransactionSplits = currentUserCan('create_account_transaction_splits');
    const canCreateOwnTransactionSplits =
      currentUserCan('create_own_transaction_splits') &&
      auth.user?.id === singleWalletTransactionDetails?.user_id?.id;

    const isAuthTransaction =
      singleWalletTransactionDetails?.transaction_type?.toLowerCase() === 'auth';

    const isNotDeclined = singleWalletTransactionDetails?.status?.toLowerCase() !== 'declined';

    const isAmountMoreThanZero = Math.abs(singleWalletTransactionDetails?.transaction_amount) > 0;

    const isExpenseTab = selectedWalletSubActiveTab === transactionTabs.EXPENSES;

    return (
      (canCreateTransactionSplits || canCreateOwnTransactionSplits) &&
      isAuthTransaction &&
      isNotDeclined &&
      isAmountMoreThanZero &&
      isExpenseTab
    );
  }, [singleWalletTransactionDetails, auth, selectedWalletSubActiveTab]);

  const showAddAttachment = useMemo(() => {
    const userCanUploadReceipt =
      currentUserCan('upload_account_receipt') ||
      currentUserCan('edit_account_transaction_receipt');

    const userCanUploadOwnReceipt =
      (currentUserCan('upload_own_transaction_receipt') ||
        currentUserCan('edit_own_transaction_receipt')) &&
      auth.user?.id === singleWalletTransactionDetails?.user_id?.id;

    const transactionCanUploadReceipt = singleWalletTransactionDetails?.can_upload_reciept;
    const receiptsLessThanTen = singleWalletTransactionDetails?.receipts.length <= 10;

    const isLoadsTab = selectedWalletSubActiveTab !== transactionTabs.LOADS;
    const isDeclinesTab = selectedWalletSubActiveTab !== transactionTabs.DECLINES;

    return (
      (userCanUploadReceipt || userCanUploadOwnReceipt) &&
      transactionCanUploadReceipt &&
      receiptsLessThanTen &&
      isLoadsTab &&
      isDeclinesTab
    );
  }, [singleWalletTransactionDetails, auth, selectedWalletSubActiveTab]);

  const showRequestMoney = useMemo(() => {
    const isDeclinesTab = selectedWalletSubActiveTab === transactionTabs.DECLINES;

    const isInsufficient =
      singleWalletTransactionDetails?.status_description === 'Insufficient funds';
    const isTxnDeclined = singleWalletTransactionDetails?.status_description === 'Txn Declined';

    return (
      (isInsufficient || isTxnDeclined) &&
      isDeclinesTab &&
      !showWalletRequestFormPopup &&
      !isRequestMoneyActionAnimation
    );
  }, [
    singleWalletTransactionDetails,
    selectedWalletSubActiveTab,
    showWalletRequestFormPopup,
    isRequestMoneyActionAnimation,
  ]);

  const showReceiptPrint = useMemo(() => {
    return (
      !showWalletRequestFormPopup &&
      singleWalletTransactionDetails &&
      !isRequestMoneyActionAnimation
    );
  }, [showWalletRequestFormPopup, singleWalletTransactionDetails, isRequestMoneyActionAnimation]);

  const attachmentsCount = useMemo(() => {
    return singleWalletTransactionDetails?.receipts?.filter((trans) => trans.doc_type !== 'Receipt')
      .length;
  }, [singleWalletTransactionDetails]);

  const showBackBtn = useMemo(() => {
    return showWalletRequestFormPopup;
  }, [showWalletRequestFormPopup]);

  return (
    <>
      {/* Back Btn */}
      {showBackBtn && (
        <button
          className="hover:text-primary rtl:rotate-180"
          type="button"
          onClick={() => {
            dispatch(hideWalletRequestFormPopupAction());
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-arrow-left" />
        </button>
      )}

      {/* Upload Attachment Icon */}
      {showAddAttachment && (
        <button
          type="button"
          className="relative hover:text-primary"
          onClick={() => {
            dispatch(showWalletReceiptSectionAction(receiptPopupIconIds.UPLOAD_ATTACHMENT));
            setTimeout(() => {
              elementScrollTo(
                receiptPopupIconIds.RECEIPT_POPUP_CONTAINER,
                receiptPopupIconIds.UPLOAD_ATTACHMENT,
              );
            }, 0);
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-paperclip-vertical" />
          <AppBody type="span" pClass="Caption1Regular" className="absolute -bottom-1 -end-2">
            {attachmentsCount}
          </AppBody>
        </button>
      )}

      {/* Split Receipt Icon */}
      {showSplitReceipt && (
        <button
          type="button"
          className="relative hover:text-primary"
          onClick={() => {
            dispatch(showWalletReceiptSectionAction(receiptPopupIconIds.SPLIT_RECEIPT));
            setTimeout(() => {
              elementScrollTo(
                receiptPopupIconIds.RECEIPT_POPUP_CONTAINER,
                receiptPopupIconIds.SPLIT_RECEIPT,
              );
            }, 0);
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-split fa-rotate-270" />
          <AppBody type="span" pClass="Caption1Regular" className="absolute -bottom-1 -end-2">
            {singleWalletTransactionDetails.splits?.length}
          </AppBody>
        </button>
      )}

      {/* Temp Icon */}
      {/* {selectedWalletSubActiveTab === transactionTabs.EXPENSES && (
        <button type="button" className="relative hover:text-primary">
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-file-signature" />
          <AppBody type="span" pClass="Caption1Regular" className="absolute -bottom-1 -end-2">x</AppBody>
        </button>
      )} */}

      {/* Request Money Icon */}
      {showRequestMoney && (
        <button
          type="button"
          className="relative hover:text-primary"
          onClick={() => {
            dispatch(showWalletRequestFormPopupAction());
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-money-bill-wave" />
        </button>
      )}

      {/* Print Receipt */}
      {showReceiptPrint && (
        <PrintButton
          ref={walletTransactionPrintWrapperRef}
          documentTitle={`receipt_${singleWalletTransactionDetails?.id}`}
        >
          <AppIcon iClass="XLargeFont" className="fa-regular fa-print hover:text-primary" />
        </PrintButton>
      )}
    </>
  );
}

export default WalletTransactionsIcons;
