import { useSelector } from 'react-redux';
import RequestAttachmentsPrint from '../../../../../../components/SharedComponents/RequestAttachmentsPrint';
import RequestBalanceAndStatus from '../../../../../../components/SharedComponents/RequestBalanceAndStatus';
import RequestCardDetailsAndDescription from '../../../../../../components/SharedComponents/RequestCardDetailsAndDescription';
import RequestFrom from '../../../../../../components/SharedComponents/RequestFrom';
import RequestMainInfoData from '../../../../../../components/SharedComponents/RequestMainInfoData';
import { inPages } from '../../../../../../helpers/constants';

function RequestPopupContent() {
  const {
    showRequestFormPopup,
    singleRequestDetails,
    selectedCardDetailsData,
    singleTransactionDetails,
    selectedActiveTab,
  } = useSelector((state) => state.cardDetails);

  return (
    <div>
      {!showRequestFormPopup && (
        <>
          <div className="mb-10">
            <RequestBalanceAndStatus requestDetails={singleRequestDetails} />
          </div>

          <RequestMainInfoData inPage={inPages.myCards} requestDetails={singleRequestDetails} />

          <div className="mb-3">
            <RequestCardDetailsAndDescription
              inPage={inPages.myCards}
              requestDetails={singleRequestDetails}
              cardDetails={selectedCardDetailsData}
            />
          </div>

          <div className="hidden print:block">
            <RequestAttachmentsPrint requestDetails={singleRequestDetails} />
          </div>
        </>
      )}

      {showRequestFormPopup && (
        <div>
          <RequestFrom
            requestDetails={singleRequestDetails}
            cardDetails={selectedCardDetailsData}
            transactionDetails={singleTransactionDetails}
            activeTab={selectedActiveTab}
            fromCardRequests
          />
        </div>
      )}
    </div>
  );
}

export default RequestPopupContent;
