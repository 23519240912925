import './AppRadioInput.css';

function AppRadioInput({ id, value, checked, label, name, onChange }) {
  return (
    <div className="appRadioInput">
      <input
        className="focus:outline-0 focus:ring-0 focus:ring-offset-0"
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={value}>{label}</label>
    </div>
  );
}

export default AppRadioInput;
