import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import masterCardLogo from '../../assets/png/Mastercard-logo.png';
import visaCardLogo from '../../assets/png/Visa-logo.png';
import physicalCardChip from '../../assets/png/physicalCardChip.png';
import { cardSchemes, cardStatusKeys, settingsPopupContent } from '../../helpers/constants';
import SingleCardDetailsStatus from '../../modules/Dashboard/MyCards/CardDetails/CardDetailsPage/SingleCardDetailsStatus';
import CardInfoPopup from '../../modules/Dashboard/MyCards/CardDetails/CardInfoPopup';
import CardSettingsPopup from '../../modules/Dashboard/MyCards/CardDetails/CardSettingsPopup';
import { addSettingsPopupDataAction } from '../../redux/CardDetailsReducer/CardDetailsActions';
import NqoodLogo from '../NqoodLogo/index';
import { AppIcon } from '../html/html';

function SingleCardDetailsCardMobile({ card, cardDetails }) {
  const dispatch = useDispatch();

  const cardStatus = useMemo(() => {
    return card.status?.key.toUpperCase();
  }, [card, cardDetails]);

  const cardProgramStatus = useMemo(() => {
    return card.cardproduct.status?.toUpperCase();
  }, [card]);

  const isClosedCardProgram = useMemo(() => {
    return [cardStatusKeys.SUSPENDED, cardStatusKeys.DEACTIVATED].includes(cardProgramStatus);
  }, [cardProgramStatus]);

  const bankAndSchemeLogosClasses = useMemo(() => {
    if (cardStatus === cardStatusKeys.REPLACED || isClosedCardProgram) {
      return '';
    }

    if (cardStatus === cardStatusKeys.INACTIVE) {
      return 'grayscale blur-[2px]';
    }

    if (
      cardStatus !== cardStatusKeys.ACTIVE &&
      cardStatus !== cardStatusKeys.REPLACED &&
      !isClosedCardProgram
    ) {
      return 'blur-[2px]';
    }

    return '';
  }, [cardStatus, isClosedCardProgram]);

  const nqoodCardLogoShapeColor = useMemo(() => {
    if (
      cardStatus === cardStatusKeys.REPLACED ||
      cardStatus === cardStatusKeys.INACTIVE ||
      isClosedCardProgram
    ) {
      return '#525252';
    }

    return card.design?.color_hex;
  }, [card, isClosedCardProgram]);

  const nqoodCardLogoShapeBlurClasses = useMemo(() => {
    if (
      cardStatus !== cardStatusKeys.ACTIVE &&
      cardStatus !== cardStatusKeys.REPLACED &&
      !isClosedCardProgram
    ) {
      return 'blur-[2px]';
    }

    return '';
  }, [card, isClosedCardProgram]);

  const cardBackgroundColor = useMemo(() => {
    if (card?.is_physical && cardStatus === cardStatusKeys.INACTIVE) {
      return '#383838';
    }

    if (card?.is_physical && (cardStatus === cardStatusKeys.REPLACED || isClosedCardProgram)) {
      return '#858585';
    }

    return 'white';
  }, [card, isClosedCardProgram]);

  const showExpiryDate = useMemo(() => {
    return cardStatus === cardStatusKeys.ACTIVE || cardStatus === cardStatusKeys.SUSPENDED;
  }, [cardStatus]);

  return (
    <div className="relative w-full ps-0.5">
      <CardInfoPopup card={card} />

      {/* =========== Mobile Nqood Logo  =========== */}
      <div className="absolute inset-0 z-10 overflow-hidden rounded-2xl">
        <div
          className={`${nqoodCardLogoShapeBlurClasses} absolute -end-9 top-[18px] -translate-y-[50%]`}
        >
          <div className="rotate-90">
            <NqoodLogo size={60} color={nqoodCardLogoShapeColor} />
          </div>
        </div>
      </div>

      {/* ================ Card Body ================ */}
      <div
        style={{
          backgroundColor: cardBackgroundColor,
        }}
        className="relative flex h-16 justify-between rounded-2xl px-2 py-4 shadow xxs:p-4"
      >
        {/* ================ Settings Popup ================ */}
        <CardSettingsPopup card={card} />

        {/* ================ Card Data ================ */}
        <div className="flex w-full items-center">
          {/* ================ Bank Logo ================ */}
          <div
            className={`me-2 ${bankAndSchemeLogosClasses} ${card?.is_physical ? 'text-white' : ''}`}
          >
            <AppIcon
              iClass="LargeFont"
              className={card?.cardproduct?.funding_source_type?.bank_logo}
            />
          </div>

          {/* ================ Scheme Logo ================ */}
          <div className={` w-8 ${bankAndSchemeLogosClasses} `}>
            {card?.cardproduct.scheme === cardSchemes.MASTER && (
              <img src={masterCardLogo} alt="masterCard Logo" className="w-full" />
            )}
            {card?.cardproduct.scheme === cardSchemes.VISA && (
              <img src={visaCardLogo} alt="visa Logo" className="w-full" />
            )}
          </div>

          {/* ================ Last Four ================ */}
          {cardStatus !== cardStatusKeys.INACTIVE && (
            <div
              className={`ms-6 flex items-center justify-center text-sm ${
                card?.is_physical ? 'text-white' : ''
              }`}
            >
              {card?.last_four}
            </div>
          )}

          {/* ================ Physical Card Chip ================ */}
          {card?.is_physical && (
            <div className="ms-4 flex items-center justify-center">
              <img src={physicalCardChip} alt="chip" className="w-7" />
            </div>
          )}

          {/* ================ Expiry Date ================ */}
          {showExpiryDate && (
            <div
              className={`ms-12 flex items-center justify-center ${
                card?.is_physical ? 'text-white' : 'text-black'
              }`}
            >
              <span className="inline-block w-6 text-[8px] leading-3">VALID THRU</span>
              <span className="text-xs">{card.expiry_date}</span>
            </div>
          )}

          {/* ================ Card Status ================ */}
          <div className="me-3 ms-auto flex w-24 items-center justify-center">
            <SingleCardDetailsStatus card={card} cardDetails={cardDetails} />
          </div>
        </div>

        {/* ================ Card Option Button ================ */}
        <button
          className={`relative z-30 me-6 text-end ${card?.is_physical ? 'text-white' : ''}`}
          type="button"
          onClick={() => {
            console.log('fired');
            dispatch(
              addSettingsPopupDataAction({ card, step: settingsPopupContent.mobileSettingsList }),
            );
          }}
        >
          <AppIcon className="fa-regular fa-ellipsis-vertical" iClass="LargeFont" />
        </button>
      </div>
    </div>
  );
}

export default SingleCardDetailsCardMobile;
