/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import gregorian from 'react-date-object/calendars/gregorian';
import gregorian_ar from 'react-date-object/locales/gregorian_ar';
import gregorian_en from 'react-date-object/locales/gregorian_en';

import DatePicker from 'react-multi-date-picker';
import { useSelector } from 'react-redux';
import './AppDatePicker.css';

function AppDatePicker({
  onChange,
  maxDate,
  minDate,
  disabled,
  placeholder,
  calendarPosition,
  inputClass,
  ...rest
}) {
  const { appConfig } = useSelector((state) => state);

  const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const weekDaysAr = ['ح', 'ث', 'ث', 'ر', 'خ', 'ج', 'س'];

  return (
    <div id="AppDatePickerClassic">
      <DatePicker
        containerClassName="w-full"
        inputClass={
          inputClass ||
          'text-xs h-10 w-full rounded-xl text-black border-gray-3 focus:border-primary disabled:cursor-not-allowed disabled:opacity-50 focus:border-2'
        }
        calendar={gregorian}
        locale={appConfig.lang === 'en' ? gregorian_en : gregorian_ar}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        onChange={onChange}
        placeholder={placeholder}
        weekDays={appConfig.lang === 'en' ? weekDays : weekDaysAr}
        fixMainPosition
        calendarPosition={calendarPosition || 'left-start'}
        {...rest}
      />
    </div>
  );
}

export default AppDatePicker;
