import { objToQueryStr } from 'javascript-functions';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dummyCard from '../../../../assets/png/dummyPlaneCard.png';
import AppImageComponent from '../../../../components/AppImageComponent';
import AppSpinner from '../../../../components/AppSpinner';
import { AppIcon } from '../../../../components/html/html';
import { cardStatusKeys, transactionTabs } from '../../../../helpers/constants';
import {
  clearSelectedCardDetailsAction,
  hideAllPopups,
  setActiveTabAction,
} from '../../../../redux/CardDetailsReducer/CardDetailsActions';
import {
  getSingleColumnCardBalanceAction,
  selectSingleCardAction,
} from '../../../../redux/CardListReducer/CardListActions';
import SingleColumnCardStatus from '../CardDetails/CardDetailsPage/SingleColumnCardStatus';

function NqoodCard({ card, enableClickEvent = true, showBalanceBtn = true }) {
  const [showBalance, setShowBalance] = useState(false);
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();
  const { appConfig, loaders, auth, useMock } = useSelector((state) => state);
  const { singleSelectedCardData } = useSelector((state) => state.cardList);
  const navigate = useNavigate();

  const smallerResponsiveTextSize = 'text-sm sm:text-3xl lg:text-[8px] lg:leading-3';
  const smallResponsiveTextSize = 'text-base sm:text-4xl lg:text-[10px] lg:leading-3';
  const responsiveMargin = 'mb-1 sm:mb-3 lg:mb-[2px]';

  const isCardSelected = useMemo(() => {
    return enableClickEvent && singleSelectedCardData?.virtualCard?.id === card.id;
  }, [singleSelectedCardData, enableClickEvent]);

  const resetDetailsPage = useCallback(() => {
    dispatch(hideAllPopups());
    dispatch(clearSelectedCardDetailsAction());
    dispatch(setActiveTabAction(transactionTabs.EXPENSES));
  }, []);

  const handleSelectCard = useCallback(
    (e) => {
      if (enableClickEvent && !e.target.closest('[data-id="balance-btn"]')) {
        resetDetailsPage();

        navigate({ search: objToQueryStr({ virtualCardId: card.id, physicalCardId: null }) });

        // Navigate to details in case mobile
        if (appConfig?.isMobile) {
          navigate(`/dashboard/my_cards/${card.id}?physicalCardId=null`);
        } else {
          dispatch(selectSingleCardAction({ virtualCard: card, physicalCard: null }));
        }
      }
    },
    [appConfig],
  );

  const cardStatus = useMemo(() => {
    return card.status?.key.toUpperCase();
  }, [card]);

  const cardProgramStatus = useMemo(() => {
    return card.cardproduct.status?.toUpperCase();
  }, [card]);

  const isClosedCardProgram = useMemo(() => {
    return [cardStatusKeys.SUSPENDED, cardStatusKeys.DEACTIVATED].includes(cardProgramStatus);
  }, [cardProgramStatus]);

  const cardNameColor = useMemo(() => {
    if (
      cardStatus === cardStatusKeys.INACTIVE ||
      cardStatus === cardStatusKeys.REPLACED ||
      isClosedCardProgram
    ) {
      return 'gray';
    }

    return card.design?.color_hex;
  }, [card, isClosedCardProgram]);

  const firstCardName = useMemo(() => {
    return card.card_name?.split(' ')[0];
  }, [card]);

  const secondCardName = useMemo(() => {
    return card.card_name?.split(' ')[1];
  }, [card]);

  const cardImageColorClass = useMemo(() => {
    if (cardStatus === cardStatusKeys.REPLACED || isClosedCardProgram) {
      return 'grayscale';
    }

    if (cardStatus === cardStatusKeys.INACTIVE) {
      return 'grayscale blur-[2px] scale-[1.05]';
    }

    if (cardStatus !== cardStatusKeys.ACTIVE) {
      return 'blur-[2px] scale-[1.05]';
    }

    return '';
  }, [auth, cardStatus, isClosedCardProgram]);

  const showCardStatus = useMemo(() => {
    return (
      cardStatus === cardStatusKeys.SUSPENDED ||
      cardStatus === cardStatusKeys.INACTIVE ||
      cardStatus === cardStatusKeys.EXPIRED ||
      cardStatus === cardStatusKeys.TERMINATED
    );
  }, [cardStatus]);

  const showCardBalanceButton = useMemo(() => {
    return (
      cardStatus !== cardStatusKeys.INACTIVE &&
      cardStatus !== cardStatusKeys.TERMINATED &&
      cardStatus !== cardStatusKeys.REPLACED &&
      cardStatus !== cardStatusKeys.EXPIRED
    );
  }, [cardStatus]);

  return (
    <div
      className={`relative w-full cursor-grab overflow-hidden rounded-xl shadow lg:max-h-[103px] lg:max-w-[164px] lg:rounded-md ${
        isCardSelected ? 'outline outline-2 outline-gray-3' : ''
      }`}
      onClick={handleSelectCard}
    >
      {/* =========== MOCKS =========== */}
      <div className="absolute inset-1 z-20 flex flex-col gap-[2px] font-mono text-[10px] lowercase leading-[1]">
        {useMock.showCardCreationDates && (
          <p className="border border-dashed border-gray-3 bg-stone-100 p-1">
            Creation Date: {moment.unix(card.created_at).format('DD/MM/YYYY')}
          </p>
        )}
        {useMock.showCardModes && (
          <p className="border border-dashed border-gray-3 bg-stone-100 p-1">
            Card Type: {card?.card_mode}
          </p>
        )}

        {useMock.showCardStatus && (
          <p className="border border-dashed border-gray-3 bg-stone-100 p-1">
            Card Status: {card.status.key}
          </p>
        )}

        {useMock.showCardProgramStatus && (
          <p className="border border-dashed border-gray-3 bg-stone-100 p-1">
            Card Program Status: {card.cardproduct?.status}
          </p>
        )}
      </div>

      {/* =========== Card Image =========== */}
      {card.design?.attachment && (
        <AppImageComponent
          className={cardImageColorClass}
          src={card.design?.attachment}
          alt={card.name_on_card}
          skeletonWidth={appConfig.isMobile ? 350 : 165}
          skeletonHeight={appConfig.isMobile ? 220 : 105}
        />
      )}

      {/* =========== Card Image Placeholder =========== */}
      {!card?.design?.attachment && (
        <div className="bg-gray-6">
          <img className="w-full opacity-0" src={dummyCard} alt={card.name_on_card} />
        </div>
      )}

      {/* ====================== Card Name ====================== */}
      {!card.is_physical && (
        <div
          style={{ direction: 'ltr' }}
          className="absolute left-4 top-5 flex items-center text-sm leading-[1] xxs:text-xl sm:top-10 md:text-5xl lg:left-2 lg:top-3 lg:text-[9px]"
        >
          <i
            className="fa-kit fa-nqoodlet-icon-left text-4xl lg:text-sm"
            style={{ color: cardNameColor }}
          />
          <span
            className="pl-2 lg:pl-1"
            style={{
              color: cardNameColor,
            }}
          >
            {firstCardName && <span className="block">{firstCardName}</span>}
            {secondCardName && <span className="block">{secondCardName}</span>}

            {!card.card_name && (
              <span className="flex">
                <span className="font-black">Nqood</span>
                <span>let</span>
              </span>
            )}
          </span>
        </div>
      )}

      {/* =========== Card Status =========== */}
      {showCardStatus && (
        <div className="absolute bottom-1/2 left-1/2 -translate-x-1/2 translate-y-[50%]">
          <SingleColumnCardStatus card={card} />
        </div>
      )}

      <div style={{ direction: 'ltr' }} className="absolute bottom-[7%] left-[5%]">
        {/* ====================== Last four ====================== */}
        {cardStatus !== cardStatusKeys.INACTIVE && (
          <div
            className={`flex items-center ${smallResponsiveTextSize} ${responsiveMargin}`}
            style={{ direction: 'ltr' }}
          >
            <p className={card?.is_physical ? 'text-white' : 'text-black'}>
              {card.last_four || '-'}
            </p>
          </div>
        )}

        {/* ====================== Balance Button ====================== */}
        {showBalanceBtn && showCardBalanceButton && (
          <button
            type="button"
            className={`relative z-20 flex items-center gap-3 text-gray-6 lg:gap-1 ${smallerResponsiveTextSize} ${responsiveMargin}`}
            data-id="balance-btn"
            onClick={(e) => {
              e.preventDefault();
              setShowBalance(!showBalance);
              if (!card.balance) {
                dispatch(getSingleColumnCardBalanceAction(card));
              }
            }}
          >
            {!showBalance && <AppIcon iClass="EyeIcon" className="fa-regular fa-eye-slash" />}

            {showBalance && <AppIcon iClass="EyeIcon" className="fa-regular fa-regular fa-eye" />}

            <p>{t('Balance')}</p>
          </button>
        )}

        {showBalanceBtn &&
          cardStatus !== cardStatusKeys.INACTIVE &&
          cardStatus !== cardStatusKeys.TERMINATED &&
          cardStatus !== cardStatusKeys.REPLACED &&
          cardStatus !== cardStatusKeys.EXPIRED && (
            <div className="flex items-center" style={{ direction: 'ltr' }}>
              {/* ====================== Stars ====================== */}
              {!showBalance && (
                <span
                  className={`me-1 ${smallResponsiveTextSize} ${
                    card?.is_physical ? 'text-white' : 'text-black'
                  }`}
                >
                  ******
                </span>
              )}

              {loaders[`getSingleColumnCardBalanceAction[${card.id}]`] && (
                <div className="mr-2">
                  <AppSpinner size={appConfig.isMobile ? 28 : 12} />
                </div>
              )}

              {/* ====================== Balance Amount ====================== */}
              {card.balance && showBalance && (
                <span
                  className={`me-1 ${smallResponsiveTextSize} ${
                    card?.is_physical ? 'text-white' : 'text-black'
                  }`}
                >
                  {card.balance?.amount || 0}
                </span>
              )}

              {/* ====================== currency ====================== */}
              <span
                className={`${smallerResponsiveTextSize} ${
                  card?.is_physical ? 'text-white' : 'text-black'
                }`}
              >
                {card.cardproduct.currency}
              </span>
            </div>
          )}

        {/* ====================== Card Status ====================== */}
        {(cardStatus === cardStatusKeys.TERMINATED || cardStatus === cardStatusKeys.EXPIRED) && (
          <div className="flex items-center" style={{ direction: 'ltr' }}>
            <p
              className={`${smallResponsiveTextSize} pt-4 font-semibold ${
                card?.is_physical ? 'text-white' : 'text-black'
              }`}
            >
              {t(capitalize(card?.status?.key))}
            </p>
          </div>
        )}

        {/* ====================== The New Status ====================== */}
        {cardStatus === cardStatusKeys.INACTIVE && (
          <div className="flex items-center" style={{ direction: 'ltr' }}>
            <p
              className={`${smallResponsiveTextSize} pt-4 font-semibold ${
                card?.is_physical ? 'text-white' : 'text-black'
              }`}
            >
              {t('New')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default NqoodCard;
