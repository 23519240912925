/* eslint-disable no-unsafe-optional-chaining */
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import ComponentToPrint from '../../../../../components/AppPrint/ComponentToPrint';
import AppSpinner from '../../../../../components/AppSpinner';
import {
  cardDetailsSectionIds,
  inPages,
  receiptPopupIconIds,
  transactionTabs,
} from '../../../../../helpers/constants';
import ReceiptPopupHeader from './ReceiptPopupHeader';
import RequestPopupContent from './RequestPopupContent';
import TransactionsPopupContent from './TransactionsPopupContent';

function ViewReceipt({ cardDetails, inPage }) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { loaders } = useSelector((state) => state);
  const { singleTransactionDetails, selectedActiveTab, showReceiptPopup, singleRequestDetails } =
    useSelector((state) => state.cardDetails);
  const requestPrintWrapperRef = useRef();
  const transactionPrintWrapperRef = useRef();

  const showTransactionsPopupContent = useMemo(() => {
    return (
      selectedActiveTab !== transactionTabs.REQUESTS &&
      singleTransactionDetails &&
      !loaders.getSingleTransactionDetailsAction
    );
  }, [selectedActiveTab, transactionTabs, singleTransactionDetails, loaders]);

  const showTransactionsPopupLoader = useMemo(() => {
    return (
      selectedActiveTab !== transactionTabs.REQUESTS && loaders.getSingleTransactionDetailsAction
    );
  }, [selectedActiveTab, transactionTabs, loaders]);

  const showRequestPopupContent = useMemo(() => {
    return (
      selectedActiveTab === transactionTabs.REQUESTS &&
      singleRequestDetails &&
      !loaders.getSingleRequestDetailsAction
    );
  }, [selectedActiveTab, transactionTabs, singleRequestDetails, loaders]);

  const showRequestPopupLoader = useMemo(() => {
    return selectedActiveTab === transactionTabs.REQUESTS && loaders.getSingleRequestDetailsAction;
  }, [selectedActiveTab, transactionTabs, loaders]);

  const popupStyles = useMemo(() => {
    if (isMobile) {
      return { height: '95%' };
    }

    if (inPage === inPages.allTransactions) {
      return { height: '70vh' };
    }

    return {
      height:
        document.getElementById(cardDetailsSectionIds.RIGHT_SIDE_CONTAINER)?.clientHeight -
        document.getElementById(cardDetailsSectionIds.CARD_DETAILS_HEADER)?.clientHeight -
        10,
    };
  }, [inPage, isMobile, showReceiptPopup]);

  if (showReceiptPopup) {
    return (
      <div
        id={receiptPopupIconIds.RECEIPT_POPUP_CONTAINER}
        className="absolute end-0 top-5 z-50 w-full overflow-y-auto rounded-2xl border bg-white p-5 pt-0 shadow lg:w-[400px]"
        style={popupStyles}
      >
        {/* View Receipt Popup Header */}
        <ReceiptPopupHeader
          requestPrintWrapperRef={requestPrintWrapperRef}
          transactionPrintWrapperRef={transactionPrintWrapperRef}
        />

        {/* ========================= Transactions ============================= */}

        {/* Transactions Popup Content */}
        {showTransactionsPopupContent && (
          <div ref={transactionPrintWrapperRef} className="print:p-5">
            <ComponentToPrint reference={singleTransactionDetails.transaction_reference}>
              <TransactionsPopupContent cardDetails={cardDetails} />
            </ComponentToPrint>
          </div>
        )}

        {/* ========================= Requests ============================= */}

        {/* Request Popup Content */}
        {showRequestPopupContent && (
          <div ref={requestPrintWrapperRef} className="print:p-5">
            <ComponentToPrint reference={singleRequestDetails.id}>
              <RequestPopupContent />
            </ComponentToPrint>
          </div>
        )}

        {/* Loaders */}
        {(showRequestPopupLoader || showTransactionsPopupLoader) && <AppSpinner />}
      </div>
    );
  }
}

export default ViewReceipt;
