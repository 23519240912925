import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Details } from '../../../../components/Titles';
import useCapabilities from '../../../../helpers/useCapabilities';
import TransactionAttachmentDownload from '../TransactionAttachmentDownload';
import AttachmentEditForm from './AttachmentEditForm';

function AttachmentPreview({ attachment, transaction, setTransaction }) {
  const { t } = useTranslation(['transactions', 'common']);
  const { user } = useSelector((state) => state.auth);
  const [editAttachment, setEditAttachment] = useState(false);
  const [currentUserCan] = useCapabilities();

  return (
    <div className="col-span-3 lg:col-span-1">
      {(currentUserCan('upload_account_receipt') ||
        (currentUserCan('upload_own_transaction_receipt') && user.id === transaction.user_id?.id) ||
        currentUserCan('edit_account_transaction_receipt') ||
        (currentUserCan('edit_own_transaction_receipt') && user.id === transaction.user_id?.id)) &&
        editAttachment &&
        transaction.can_upload_reciept && (
          <AttachmentEditForm
            setEditAttachment={setEditAttachment}
            attachment={attachment}
            transaction={transaction}
            setTransaction={setTransaction}
          />
        )}

      {!editAttachment && (
        <div className="relative flex flex-col items-center">
          <div className="no_print mb-0 w-full px-3 ltr:text-right rtl:text-left">
            {(currentUserCan('upload_account_receipt') ||
              (currentUserCan('upload_own_transaction_receipt') &&
                user.id === transaction.user_id?.id) ||
              currentUserCan('edit_account_transaction_receipt') ||
              (currentUserCan('edit_own_transaction_receipt') &&
                user.id === transaction.user_id?.id)) &&
              transaction.can_upload_reciept && (
                <button
                  type="button"
                  className="align-baseline text-sm font-semibold text-primary ltr:text-left rtl:text-right"
                  onClick={() => {
                    setEditAttachment(true);
                  }}
                >
                  {t('edit', { ns: 'common' })}
                </button>
              )}
          </div>
          <TransactionAttachmentDownload
            attachmentId={attachment.id}
            attachmentPath={attachment.path}
            transaction={transaction}
          />
          <Details className="text-center">{t(attachment.doc_type)}</Details>
        </div>
      )}
    </div>
  );
}

export default AttachmentPreview;
