import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppButton, AppHeader, AppIcon } from '../../components/html/html';
import { uploadDocumentSteps } from '../../helpers/constants';
import {
  setActiveDocumentStepAction,
  setSingleDocumentCardDetailsAction,
} from '../../redux/UploadDocumentsReducer/UploadDocumentsActions';

function SingleDocumentCard({ documentCard }) {
  const { documentCards } = useSelector((state) => state.uploadDocuments);
  const dispatch = useDispatch();
  const { t } = useTranslation('uploadDocuments');

  const hasUploadedDocuments = useMemo(() => {
    return Boolean(documentCard.documents.filter((ele) => ele.path).length);
  }, [documentCard, documentCards]);

  const requiredDocumentsToUpload = useMemo(() => {
    return documentCard.documents.length;
  }, [documentCard, documentCards]);

  const uploadedDocumentsLength = useMemo(() => {
    return documentCard.documents.filter((ele) => ele.path).length;
  }, [documentCard, documentCards]);

  const statusColor = useMemo(() => {
    if (!hasUploadedDocuments) {
      return 'bg-primary';
    }
    if (hasUploadedDocuments && requiredDocumentsToUpload > uploadedDocumentsLength) {
      return 'bg-warn';
    }
    if (hasUploadedDocuments && requiredDocumentsToUpload === uploadedDocumentsLength) {
      return 'bg-success';
    }
    return 'bg-gray';
  }, [hasUploadedDocuments, requiredDocumentsToUpload, uploadedDocumentsLength]);

  return (
    <div className="relative overflow-hidden rounded-xl bg-white p-5 shadow-lg">
      <div className="flex items-end justify-between lg:items-center">
        <div className={`absolute start-0 top-0 h-full w-4 ${statusColor}`} />
        <div className="grid gap-1 ps-8">
          <AppHeader h="h5">{documentCard.name}</AppHeader>
          <AppHeader h="h6" className="text-gray-6">
            {documentCard.entity_type}-{documentCard.entity_number}
          </AppHeader>
          <AppHeader h="h6" className="text-gray-6">
            {documentCard.relation}
          </AppHeader>
        </div>
        <AppButton
          button="link"
          className="font-bold text-primary"
          bClass="ButtonHeader1"
          onClick={() => {
            dispatch(setSingleDocumentCardDetailsAction(documentCard));
            dispatch(setActiveDocumentStepAction(uploadDocumentSteps.documentCardDetails));
          }}
        >
          {!hasUploadedDocuments && t('Upload documents')}

          {hasUploadedDocuments &&
            uploadedDocumentsLength < requiredDocumentsToUpload &&
            t('Complete documents')}

          {hasUploadedDocuments && uploadedDocumentsLength === requiredDocumentsToUpload && (
            <AppIcon className="fa-regular fa-circle-check text-success" iClass="XXXLargeFont" />
          )}

          {uploadedDocumentsLength !== requiredDocumentsToUpload && (
            <AppIcon
              className="fa-regular fa-angle-right ms-2 rtl:rotate-180"
              iClass="XLargeFont"
            />
          )}
        </AppButton>
      </div>
    </div>
  );
}

export default SingleDocumentCard;
