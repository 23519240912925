/* eslint-disable no-undefined */
import {isNaN} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import ActionButton from '../../../components/Button/ActionButton';
import BackButton from '../../../components/Button/BackButton';
import {NumberInput, TextInput} from '../../../components/FormInputs/Inputs';
import {LargerTitle2, SectionTitle, Title} from '../../../components/Titles';
import {apiGetRequest, apiPostFormDataRequest} from '../../../helpers/APIHelper';
import {setAction} from '../../../helpers/ActionsHelper';
import {cardBalance} from '../../../helpers/CardHelper';
import {Balance} from '../../../helpers/CardProductHelper';
import useNotifications from '../../../helpers/useNotifications';
import Head from '../../Head';
import CreditCard from '../Cards/CreditCard';
import NqoodCardDesign from '../Cards/NqoodCardDesign';
import CardStatusBadge from './CardStatusBadge';
import {AppBody} from "../../../components/html/html";

const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';
const SET_SELECTED_ACCOUNT_TYPE = 'SET_SELECTED_ACCOUNT_TYPE';
const SET_CARDS = 'SET_CARDS';
const SET_COMPANY_BALANCE = 'SET_COMPANY_BALANCE';
const SET_SELECTED_USER = 'SET_SELECTED_USER';

const initialState = {
  selectedAccount: null,
  selectedAccountType: 'CARDHOLDER',
  cards: [],
  companyBalance: null,
  selectedUser: null,
};

const TopUpContext = React.createContext(initialState);
const TopUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ACCOUNT:
      return { ...state, selectedAccount: action.payload };
    case SET_SELECTED_ACCOUNT_TYPE:
      return { ...state, selectedAccountType: action.payload };
    case SET_CARDS:
      return { ...state, cards: action.payload };
    case SET_COMPANY_BALANCE:
      return {
        ...state,
        companyBalance: action.payload,
      };
    case SET_SELECTED_USER:
      return { ...state, selectedUser: action.payload };
    default:
      return { ...state };
  }
};

// eslint-disable-next-line func-names
const TopUpAccount = function ({ withdraw }) {
  const { t, i18n } = useTranslation(['topup', 'common']);
  const [localState, dispatch] = React.useReducer(TopUpReducer, initialState);
  const contextValue = useMemo(() => ({ state: localState, dispatch }), [localState]);
  const { selectedAccountType, selectedAccount } = localState;
  const navigate = useNavigate();
  const { users } = useSelector((state) => state.employees);
  const params = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [cookies] = useCookies(['token']);
  const [, addNotifications] = useNotifications();
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ mode: 'onBlur' });
  const selectedCard = params.card_id === '0' ? false : params.card_id;
  const watchAmount = watch(['fund_amount']);
  const stateData = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [selectedUser, setSelectedUser] = useState();
  const [card, setCard] = useState(null);
  const [companyBalance, setCompanyBalance] = useState(0);
  const [companyBalanceCurrency, setCompanyBalanceCurrency] = useState('');
  const [calculatedBalance, setCalculatedBalance] = useState(companyBalance);

  const fetchUser = (userId) => {
    apiGetRequest(
      'employees',
      cookies.token,
      {
        paginate: 4,
        page: 1,
        id: userId,
      },
      [],
      {},
      true,
    ).then(([response]) => {
      setSelectedUser(response && response.length === 1 ? response[0] : response);
    });
  };

  const fetchAccountBalance = () => {
    Balance(card?.cardproduct?.id, cookies.token).then((response) => {
      setCompanyBalance(response.amount);
      setCompanyBalanceCurrency(response.currency);
      dispatch(setAction(SET_COMPANY_BALANCE, companyBalance));
    });
  };

  useEffect(() => {
    if (!stateData.state) {
      apiGetRequest(`cardmanager/cards/show/${params.card_id}`, cookies.token).then((response) => {
        setCard(response);
        dispatch(setAction(SET_SELECTED_ACCOUNT, card));
        dispatch(setAction(SET_SELECTED_ACCOUNT_TYPE, 'CARD'));
      });
    }
  }, []);

  const asyncFn = async () => {
    if (users.length > 0) {
      const filteredUser = users.filter((u) => u.id === parseInt(params.recipient_id, 10));
      if (filteredUser.length > 0) {
        setSelectedUser(filteredUser[0]);
      } else {
        setSelectedUser(user);
      }
    } else if (users.length === 0) {
      await fetchUser(params.recipient_id);
    } else {
      setSelectedUser(user);
    }
  };

  useEffect(() => {
    asyncFn();
  }, []);

  useEffect(() => {
    if (card) {
      fetchAccountBalance();
    }
  }, [card]);

  useEffect(() => {
    if (selectedUser) {
      dispatch(setAction(SET_SELECTED_USER, selectedUser.id));
    }
  }, [selectedUser]);

  useEffect(() => {
    const parsedValue = parseFloat(watchAmount[0]);
    if (!isNaN(parsedValue)) {
      setCalculatedBalance(companyBalance - parseFloat(parsedValue));
    }
    // else {
    //     setCalculatedBalance(companyBalance);
    // }
  }, [params, watchAmount, selectedUser]);

  useEffect(() => {
    if (selectedCard !== false) {
      setCard(stateData.state?.card);
      dispatch(setAction(SET_SELECTED_ACCOUNT, card));
      dispatch(setAction(SET_SELECTED_ACCOUNT_TYPE, 'CARD'));
    }
  }, [selectedCard]);

  useEffect(() => {
    if (selectedCard === false) {
      dispatch(setAction(SET_SELECTED_ACCOUNT, selectedUser));
    }
  }, [selectedUser]);

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);

  useEffect(() => {
    setValue('currency', companyBalanceCurrency);
  }, [companyBalanceCurrency]);

  if (!user || !selectedUser) {
    return null;
  }
  if (selectedCard && !card) {
    return null;
  }

  const formData = (data) => {
    const form = new FormData();
    form.append('amount', data.fund_amount);
    form.append('currency', data.currency);
    form.append('is_deposit', +!withdraw);
    form.append('business_transfer', 1);

    if (selectedCard === false) {
      // if from user details
      form.append('sender_type', !withdraw ? 'CARDHOLDER' : selectedAccountType);
      form.append('sender_id', !withdraw ? user.id : selectedAccount.id);
      form.append('recipient_type', !withdraw ? selectedAccountType : 'CARDHOLDER');
      form.append('recipient_id', !withdraw ? selectedAccount.id : user.id);
    } else {
      form.append('sender_type', !withdraw ? 'CARDHOLDER' : 'CARD');
      form.append('sender_id', !withdraw ? user.id : card.id);
      form.append('recipient_type', !withdraw ? 'CARD' : 'CARDHOLDER');
      form.append('recipient_id', !withdraw ? card.id : user.id);
    }

    return form;
  };

  return (
    <TopUpContext.Provider value={contextValue}>
      <Head title="TopUp Account" />
      <div className="mx-auto flex w-full flex-col lg:w-10/12 ltr:text-left rtl:text-right">
        <div className="grid grid-cols-6">
          <TopHeader
            withdraw={withdraw}
            selectedUser={selectedUser}
            user={user}
            selectedCard={selectedCard}
            card={card}
          />
        </div>

        <form
          onSubmit={handleSubmit((data) => {
            setIsSaving(true);
            const form = formData(data);

            apiPostFormDataRequest('cardmanager/transfer', form, cookies.token)
              .then(() => {
                navigate(-1, { replace: true });
                addNotifications(t('submit_success'));
              })
              .catch((err) => {
                addNotifications((err && err.data && err.data.error) || t('submit_error'), false);
              })
              .finally(() => {
                setIsSaving(false);
              });
          })}
        >
          <div className="grid flex-grow grid-cols-6 items-start justify-between self-stretch">
            <div className="col-span-6 grid grid-cols-3 lg:col-span-3">
              <div className="col-span-3 lg:col-span-2">
                <AppBody type="label" pClass="Body2Bold" htmlFor="fund_amount">
                  {t('fund_amount')}
                  <NumberInput
                    min="1"
                    error={errors.fund_amount}
                    id="fund_amount"
                    {...register('fund_amount', {
                      required: t('validation.required.amount'),
                      min: {
                        value: 1,
                        message: t('validation.required.amount_min'),
                      },
                    })}
                  />
                  {!withdraw && !selectedCard && (
                    <AppBody type="div" pClass="Caption1Regular" className="flex flex-row items-baseline">
                      <p>{t('remaining_balance')}</p>
                      <p
                        className={`${
                          calculatedBalance < 0 ? 'text-red-700' : 'text-primary'
                        } break-all ltr:pl-5 rtl:pr-5`}
                      >
                        {` ${calculatedBalance} ${companyBalanceCurrency}`}
                      </p>
                    </AppBody>
                  )}
                </AppBody>
              </div>
              <div className="col-span-3 pt-7 lg:col-span-2">
                <AppBody type="label" pClass="Body2Bold" htmlFor="currency">
                  {t('currency')}
                  <div className="col-span-2">
                    <TextInput
                      className={`${
                        errors.fundCurrency ? 'border-red-400' : 'border-gray-3'
                      } mt-1 w-full rounded-full border  px-20 px-3 py-1.5 disabled:bg-gray-100`}
                      id="currency"
                      {...register('currency')}
                      defaultValue={companyBalanceCurrency}
                      disabled
                    />
                  </div>
                </AppBody>
              </div>
            </div>

            <div className="col-span-6 m-auto block grid-cols-3 pt-3 lg:col-span-3 lg:grid lg:pt-0">
              {selectedCard && card && (
                <NqoodCardDesign
                  name={card.name_on_card}
                  expiry={card.expiry_date}
                  number={card.last_four}
                  isBlack={!card?.design?.attachment}
                  nqoodCardDesign={card.design?.attachment}
                />
              )}
              {!selectedCard && selectedUser && (
                <CreditCard
                  name={`${selectedUser.first_name} ${selectedUser.last_name}`}
                  blackCard="true"
                />
              )}
            </div>

            <div className="col-span-8 mt-10 flex flex-row items-baseline justify-end pt-4">
              <ActionButton
                className="flex flex-row"
                text={t('cancel', { ns: 'common' })}
                onClick={() => {
                  navigate(-1);
                }}
              />
              <ActionButton
                className="flex flex-row"
                isLoading={isSaving}
                primary
                text={t('submit', { ns: 'common' })}
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </TopUpContext.Provider>
  );
};

function TopHeader({ withdraw, selectedUser, user, selectedCard, card }) {
  const { t, i18n } = useTranslation(['topup']);
  const [cookies] = useCookies(['token']);
  const [currency, setCurrency] = useState(null);
  const [balance, setBalance] = useState(null);

  const fetchCardBalance = (cardId) => {
    cardBalance(cardId, cookies.token).then((response) => {
      setCurrency(response?.currency);
      setBalance(response.amount ? parseFloat(response.amount) : '');
    });
  };

  useEffect(() => {
    if (card) {
      fetchCardBalance(card.id);
    }
  }, [card, i18n.language]);

  if (!selectedCard) {
    return null;
  }
  return (
    <div className="col-span-6 flex flex-col justify-start">
      <BackButton />
      {withdraw && <LargerTitle2>{t('withdraw_headline')}</LargerTitle2>}
      {!withdraw && (
        <LargerTitle2>{`${selectedUser.id === user.id ? t('headline') : t('add_balance')} ${
          selectedUser.first_name
        }${!selectedCard ? t('headline1') : t('headline2')}`}</LargerTitle2>
      )}

      <div
        className={`flex flex-col ${
          !selectedCard ? '' : 'rounded-2xl bg-gray-2'
        } mb-20 mt-10 items-baseline justify-between px-4 py-2`}
      >
        {/* Deposit  */}
        {!withdraw && selectedCard && <Title>{t('available_balance')}</Title>}
        {!withdraw && selectedCard && <SectionTitle>{`${balance} ${currency}`}</SectionTitle>}

        {/* Transfer money from user card to user balance  */}
        {withdraw && selectedCard && (
          <Title>
            {card.is_physical ? t('plastic_card') : t('virtual_card')}
            <AppBody type="span" pClass="Body1Bold" className="pt-r ml-1 pb-3 lg:text-xl ltr:text-left rtl:text-right">
              **
              {card.last_four}
            </AppBody>
            <span className="ml-3">
              {card.status && (
                <CardStatusBadge status={card.status.name} color={card.status.color} />
              )}
            </span>
          </Title>
        )}
        {withdraw && selectedCard && <SectionTitle>{`${balance} ${currency}`}</SectionTitle>}
      </div>
    </div>
  );
}
export default TopUpAccount;
