import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrintButton from '../../../../../../components/AppPrint/PrintButton';
import { requestStatusIds } from '../../../../../../helpers/constants';
import useCapabilities from '../../../../../../helpers/useCapabilities';
import {
    hideWalletRequestFormPopupAction,
    showWalletRequestFormPopupAction,
} from '../../../../../../redux/WalletReducer/WalletActions';
import {AppIcon} from "../../../../../../components/html/html";

function WalletRequestIcons({ walletRequestPrintWrapperRef }) {
  const {
    showWalletRequestFormPopup,
    singleWalletRequestDetails,
    approvedRejectedRequestAnimation,
  } = useSelector((state) => state.wallet);
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [currentUserCan] = useCapabilities();

  const showBackBtn = useMemo(() => {
    return showWalletRequestFormPopup;
  }, [showWalletRequestFormPopup]);

  const showEditRequest = useMemo(() => {
    const canUpdateRequest = currentUserCan('update_account_funds');
    const canUpdateOwnRequest =
      currentUserCan('update_own_funds') &&
      auth?.user?.id === singleWalletRequestDetails?.requested_by?.id;

    const isPendingRequest = singleWalletRequestDetails?.status_id === requestStatusIds.PENDING;
    const isApproveOrReject = approvedRejectedRequestAnimation.show;

    return (
      (canUpdateRequest || canUpdateOwnRequest) &&
      isPendingRequest &&
      !isApproveOrReject &&
      !showWalletRequestFormPopup
    );
  }, [
    singleWalletRequestDetails,
    showWalletRequestFormPopup,
    approvedRejectedRequestAnimation,
    auth,
  ]);

  const showRequestPrint = useMemo(() => {
    const isApproveOrReject = approvedRejectedRequestAnimation.show;

    return !showWalletRequestFormPopup && singleWalletRequestDetails && !isApproveOrReject;
  }, [showWalletRequestFormPopup, singleWalletRequestDetails]);

  return (
    <>
      {/* Back Btn */}
      {showBackBtn && (
        <button
          className="hover:text-primary rtl:rotate-180"
          type="button"
          onClick={() => {
            dispatch(hideWalletRequestFormPopupAction());
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-arrow-left" />
        </button>
      )}

      {/* Edit Request */}
      {showEditRequest && (
        <button
          type="button"
          className="relative hover:text-primary"
          onClick={() => {
            dispatch(showWalletRequestFormPopupAction());
          }}
        >
          <AppIcon iClass="XLargeFont" className="fa-regular fa-pen-to-square" />
        </button>
      )}

      {/* Print Request */}
      {showRequestPrint && (
        <PrintButton
          ref={walletRequestPrintWrapperRef}
          documentTitle={`request_${singleWalletRequestDetails?.id}`}
        >
          <AppIcon iClass="XLargeFont" className="fa-regular fa-print hover:text-primary" />
        </PrintButton>
      )}
    </>
  );
}

export default WalletRequestIcons;
