import {Turnstile} from '@marsidev/react-turnstile';
import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import 'react-phone-number-input/style.css';
import {Link, useNavigate} from 'react-router-dom';
import ActionButton from '../../../components/Button/ActionButton';
import CustomPhone from '../../../components/FormInputs/CustomPhone';
import {TextInput} from '../../../components/FormInputs/Inputs';
import RegisterStepBar from '../../../components/Stepbar/RegisterStepBar';
import {HeadlineCenter} from '../../../components/Titles';
import TooltipPackage from '../../../components/TooltipPackage';
import {apiGetRequest, apiPostFormDataRequest} from '../../../helpers/APIHelper';
import {
  EmailValidationPattern,
  NameValidationPattern,
  PasswordValidationPattern,
} from '../../../helpers/RegexPatterns';
import {refreshUserData} from '../../../helpers/UserHelper';
import useNotifications from '../../../helpers/useNotifications';
import Head from '../../Head';
import {AppBody} from "../../../components/html/html";

function SignupUserDetails() {
  const { t, i18n } = useTranslation(['signup', 'common']);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    getValues,
    control,
  } = useForm({ mode: 'onBlur' });
  const [, setCookie] = useCookies(['token']);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHcapthchaValid, setIsHcapthchaValid] = useState(false);
  const [, addNotification] = useNotifications();
  const navigate = useNavigate();
  const HoneyPot = document.querySelector('input.nogo');

  const submitForm = (data) => {
    if (HoneyPot.value && HoneyPot.value !== '') {
      addNotification(t('unknown_error', { ns: 'common' }), false);
    } else {
      setIsSubmitting(true);
      const form = new FormData();
      form.append('email', data.email);
      form.append('password', data.password);
      form.append('mobile', data.mobile);
      form.append('first_name', data.first_name);
      form.append('last_name', data.last_name);

      apiPostFormDataRequest('register', form)
        .then((response) => {
          setCookie('token', response.token, {
            path: '/',
          });
          refreshUserData(response.token).then(() => {
            navigate('/signup/verify_phone');
          });
        })
        .catch((err) => {
          addNotification(
            (err && err.data && err.data.error) || t('unknown_error', { ns: 'common' }),
            false,
          );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };
  const emailRegistered = async (email) => {
    try {
      await apiGetRequest('email/exist', null, {
        email,
      });
      return null;
    } catch (err) {
      return t('details.validation.patterns.email_exist');
    }
  };

  useEffect(() => {
    clearErrors();
  }, [i18n.language]);

  return (
    <div className="mx-auto flex w-full flex-col lg:w-8/12">
      <Head title="Signup" />
      <RegisterStepBar step={1} />

      <div className="centerTitle flex flex-col text-center">
        <HeadlineCenter>{t('details.headline')}</HeadlineCenter>

        <AppBody type="span" pClass="Body2Regular" className="relative m-auto  max-w-md pb-4 pt-2 text-center text-gray-6">
          {t('details.subtitle')}
          <TooltipPackage tooltipMessage={t('details.subtitle_more')} id="infoPreview" />
        </AppBody>

        <AppBody type="div" pClass="Body2Regular" className="mt-3 block flex flex-row border-t border-gray-3 pt-2 lg:hidden">
          <p className="text-gray-6">{t('details.already_have')}</p>
          <Link to="/auth/login" className=" font-bold ltr:pl-2 rtl:pr-2">
            {t('details.login')}
          </Link>
        </AppBody>
      </div>
      <form
        className="flex flex-grow flex-col items-center justify-start pt-10"
        onSubmit={handleSubmit(submitForm)}
      >
        <input type="text" className="nogo" tabIndex="-1" autoComplete="off" />
        <div className="grid w-full grid-cols-2 gap-5 pb-5">
          <AppBody type="label" pClass="Body2Bold"
            htmlFor="user_first_name"
            className=" col-span-2 flex flex-col lg:col-span-1"
          >
            {t('details.inputs.first_name')}
            <TextInput
              error={errors.first_name}
              id="user_first_name"
              {...register('first_name', {
                required: t('details.validation.required.first_name'),
                pattern: {
                  value: NameValidationPattern,
                  message: t('details.validation.patterns.first_name'),
                },
              })}
            />
          </AppBody>

          <AppBody type="label" pClass="Body2Bold"
            htmlFor="user_last_name"
            className=" col-span-2 flex flex-col lg:col-span-1"
          >
            {t('details.inputs.last_name')}
            <TextInput
              error={errors.last_name}
              id="user_last_name"
              {...register('last_name', {
                required: t('details.validation.required.last_name'),
                pattern: {
                  value: NameValidationPattern,
                  message: t('details.validation.patterns.last_name'),
                },
              })}
            />
          </AppBody>
        </div>

        <div className="flex flex-row justify-center self-stretch pb-5">
          <AppBody type="label" pClass="Body2Bold" htmlFor="mobile" className=" flex flex-grow flex-col">
            {t('details.inputs.mobile')}
            <CustomPhone
              name="mobile"
              control={control}
              errors={errors.mobile}
              patternError={t('details.validation.patterns.mobile')}
              requiredString={t('details.validation.required.mobile')}
            />
          </AppBody>
        </div>

        <div className="grid w-full grid-cols-2 gap-5 pb-5">
          <AppBody type="label" pClass="Body2Bold"
            htmlFor="user_email"
            className=" col-span-2 flex flex-col lg:col-span-1"
          >
            {t('details.inputs.email')}
            <TextInput
              error={errors.email}
              id="user_email"
              {...register('email', {
                required: t('details.validation.required.email'),
                pattern: {
                  value: EmailValidationPattern,
                  message: t('details.validation.patterns.email'),
                },
                validate: {
                  emailExist: (value) => emailRegistered(value),
                },
              })}
            />
          </AppBody>

          <AppBody type="label" pClass="Body2Bold"
            htmlFor="user_email_confirm"
            className=" col-span-2 flex flex-col lg:col-span-1"
          >
            {t('details.inputs.email_confirm')}
            <TextInput
              error={errors.email_confirm}
              id="user_email_confirm"
              {...register('email_confirm', {
                required: t('details.validation.required.email_confirm'),
                pattern: {
                  value: EmailValidationPattern,
                  message: t('details.validation.patterns.email'),
                },
                validate: {
                  emailEqual: (value) =>
                    value === getValues().email || t('details.validation.email_not_matching'),
                },
              })}
            />
          </AppBody>
        </div>

        <div className="grid w-full grid-cols-2 gap-5 pb-5">
          <AppBody type="label" pClass="Body2Bold"
            htmlFor="user_password"
            className=" col-span-2 flex flex-col lg:col-span-1"
          >
            {t('details.inputs.password')}
            <TextInput
              stars="true"
              error={errors.password}
              id="user_password"
              {...register('password', {
                required: t('details.validation.required.password'),
                pattern: {
                  value: PasswordValidationPattern,
                  message: t('details.validation.patterns.password'),
                },
                minLength: {
                  value: 8,
                  message: t('details.validation.min.password'),
                },
              })}
            />
          </AppBody>

          <AppBody type="label" pClass="Body2Bold"
            htmlFor="user_password_confirm"
            className=" col-span-2 flex flex-col lg:col-span-1"
          >
            {t('details.inputs.password_confirm')}
            <TextInput
              stars="true"
              error={errors.password_confirm}
              id="user_password_confirm"
              {...register('password_confirm', {
                required: t('details.validation.required.password_confirm'),
                validate: {
                  passwordEqual: (value) =>
                    value === getValues().password || t('details.validation.password_not_matching'),
                },
              })}
            />
          </AppBody>
        </div>

        <AppBody pClass="Caption1Regular" type="div"  className="self-stretch pb-10 text-gray-6 ltr:text-left rtl:text-right">
          {t('details.inputs.password_hint')}
        </AppBody>
        <div className="flex flex-row self-stretch">
          <input
            type="hidden"
            {...register('hcaptcha', {
              validate: {
                hcaptche: (value) => {
                  if (!isHcapthchaValid) {
                    return t('details.validation.required.hcaptcha');
                  }
                  return null;
                },
              },
            })}
          />
          <Turnstile
            id="hcaptcha_register"
            // defaultChecked={process.env.REACT_APP_ENV === 'Testing'}
            autoResetOnExpire
            siteKey="0x4AAAAAAACsFx_X-jL1J_nl"
            onError={() => {
              setIsHcapthchaValid(false);
            }}
            onSuccess={() => {
              setIsHcapthchaValid(true);
            }}
            language={i18n.language === 'ar' ? 'ar-EG' : 'auto'}
          />
          <AppBody pClass="Caption1Regular" className="pt-2 text-red-700">{errors.hcaptcha && errors.hcaptcha.message}</AppBody>
        </div>

        <div className="flex flex-row flex-wrap justify-between self-stretch">
          <AppBody type="div" pClass="Body2Regular" className="mt-10 flex hidden flex-row lg:block ">
            <span className="text-gray-6">
              {t('details.already_have')}{' '}
              <Link to="/auth/login" className=" font-bold ltr:pl-1 rtl:pr-1">
                {t('details.login')}
              </Link>
            </span>
          </AppBody>
          <div className="mt-10">
            <ActionButton
              className="flex flex-row"
              isLoading={isSubmitting}
              primary
              text={t('continue', { ns: 'common' })}
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default SignupUserDetails;
