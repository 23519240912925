import {useTranslation} from 'react-i18next';
import {AppBody} from "../../../components/html/html";

const UserStatusBadge = function (props) {
  const { t } = useTranslation(['users']);
  const { status } = props;
  if (status === 'Suspended') {
    return (
      <AppBody type="span" pClass="Caption1Regular"
        className="rounded-2xl bg-yellow-100 bg-opacity-10 px-5 py-1 text-center text-yellow-500"
        style={{ minWidth: '110px' }}
      >
        {t('state.suspended')}
      </AppBody>
    );
  }
  if (status === 'Deactivate') {
    return (
      <AppBody pClass="Caption1Regular" type="span"
        className="rounded-2xl bg-red-50 bg-opacity-10 px-5 py-1 text-center text-red-700"
        style={{ minWidth: '110px' }}
      >
        {t('state.deactivate')}
      </AppBody>
    );
  }
  if (status === 'Banned') {
    return (
      <AppBody type="span" pClass="Caption1Regular"
        className="rounded-2xl bg-black bg-opacity-10 px-5 py-1 text-center text-white"
        style={{ minWidth: '110px' }}
      >
        {t('state.banned')}
      </AppBody>
    );
  }
  if (status === 'Active') {
    return (
      <AppBody pClass="Caption1Regular" type="span"
        className="rounded-2xl bg-cyan-500 bg-opacity-10 px-5 py-1 text-center text-cyan-500"
        style={{ minWidth: '110px' }}
      >
        {t('state.active')}
      </AppBody>
    );
  }

  return (
    <AppBody pClass="Caption1Regular" type="span"
      className="rounded-2xl bg-gray-6 bg-opacity-10 px-5 py-1 text-center text-gray-6"
      style={{ minWidth: '110px' }}
    >
      {t('state.pending')}
    </AppBody>
  );
};

export default UserStatusBadge;
