import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PrintButton from '../../../../../../components/AppPrint/PrintButton';
import { requestStatusIds } from '../../../../../../helpers/constants';
import useCapabilities from '../../../../../../helpers/useCapabilities';
import {
    hideRequestFormPopupAction,
    showRequestFormPopupAction,
} from '../../../../../../redux/CardDetailsReducer/CardDetailsActions';
import {AppIcon} from "../../../../../../components/html/html";

function RequestIcons({ requestPrintWrapperRef }) {
  const { auth } = useSelector((state) => state);
  const { showRequestFormPopup, singleRequestDetails } = useSelector((state) => state.cardDetails);
  const [currentUserCan] = useCapabilities();
  const dispatch = useDispatch();

  const showEditRequest = useMemo(() => {
    const canUpdateRequest = currentUserCan('update_account_funds');
    const canUpdateOwnRequest =
      currentUserCan('update_own_funds') &&
      auth?.user?.id === singleRequestDetails?.requested_by?.id;

    const isPendingRequest = singleRequestDetails?.status_id === requestStatusIds.PENDING;

    const isActiveCard = singleRequestDetails?.card_id?.status?.key === 'ACTIVE';

    return (
      (canUpdateRequest || canUpdateOwnRequest) &&
      isPendingRequest &&
      isActiveCard &&
      !showRequestFormPopup
    );
  }, [singleRequestDetails, auth, showRequestFormPopup]);

  const showRequestPrint = useMemo(() => {
    return !showRequestFormPopup && singleRequestDetails;
  }, [showRequestFormPopup, singleRequestDetails]);

  const showBackBtn = useMemo(() => {
    return showRequestFormPopup;
  }, [showRequestFormPopup]);

  return (
    <>
      {/* Back Btn */}
      {showBackBtn && (
        <button
          className="hover:text-primary rtl:rotate-180"
          type="button"
          onClick={() => {
            dispatch(hideRequestFormPopupAction());
          }}
        >
          <AppIcon iClass="XXLargeFont" className="fa-regular fa-arrow-left" />
        </button>
      )}

      {/* Edit Request */}
      {showEditRequest && (
        <button
          type="button"
          className="relative hover:text-primary"
          onClick={() => {
            dispatch(showRequestFormPopupAction());
          }}
        >
          <AppIcon iClass="XLargeFont" className="fa-regular fa-pen-to-square" />
        </button>
      )}

      {/* Print Request */}
      {showRequestPrint && (
        <PrintButton
          ref={requestPrintWrapperRef}
          documentTitle={`request_${singleRequestDetails?.id}`}
        >
          <AppIcon iClass="XLargeFont" className="fa-regular fa-print hover:text-primary" />
        </PrintButton>
      )}
    </>
  );
}

export default RequestIcons;
