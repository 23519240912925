import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../../../AppRouter/history';
import BackBtn from '../../../../components/BackBtn';
import { AppBody, AppButton, AppHeader } from '../../../../components/html/html';
import TranslatedData from '../../../../components/TranslatedData';
import useCapabilities from '../../../../helpers/useCapabilities';
import { showModalAction } from '../../../../redux/AppModalReducer/AppModalActions';
import EditSingleUserPopup from './EditSingleUserPopup';
import UserDetailsActions from './UserDetailsActions';

function UserPersonalInformationContent() {
  const [currentUserCan] = useCapabilities();

  const { isMobile } = useSelector((state) => state.appConfig);
  const { singleUserDetails } = useSelector((state) => state.users);
  const { t } = useTranslation('usersV2');
  const dispatch = useDispatch();
  const { userId } = useParams();

  return (
    <>
      <div className="hidden pt-7 lg:block">
        <BackBtn
          className="mb-4 "
          label={t('Back')}
          onClick={() => {
            history.push('/dashboard/users-v2');
          }}
        />
      </div>
      <UserDetailsActions />
      <div className="grid gap-10 pe-4">
        {/* ============= Personal Information ============= */}
        <div className="rounded-2xl border border-gray-5 p-6">
          <AppHeader h="h6" className="mb-2">
            {t('Personal Information', { ns: 'commonV2' })}
          </AppHeader>

          {currentUserCan('update_account_employees') && (
            <div className="mb-2 flex justify-end">
              <AppButton
                button="link"
                className="font-bold text-primary"
                bClass="ButtonHeader1"
                onClick={() => {
                  if (!isMobile) {
                    dispatch(showModalAction(<EditSingleUserPopup />));
                  } else {
                    history.push(`/dashboard/users-v2/${userId}/edit`);
                  }
                }}
              >
                {t('Edit', { ns: 'commonV2' })}
              </AppButton>
            </div>
          )}
          <div className="grid gap-8 lg:grid-cols-2">
            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('Name', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">
                {singleUserDetails?.first_name} {singleUserDetails?.last_name}
              </AppBody>
            </div>

            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('Status', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">{t(singleUserDetails.status)}</AppBody>
            </div>

            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('Email Address', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">{singleUserDetails?.email}</AppBody>
            </div>

            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('Phone Number', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">
                <span style={{ direction: 'ltr' }} className="inline-block">
                  {singleUserDetails?.mobile}
                </span>
              </AppBody>
            </div>

            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('Job Title', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">{singleUserDetails?.job_title}</AppBody>
            </div>

            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('Department', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">
                <TranslatedData
                  ar={singleUserDetails?.department?.name_ar}
                  en={singleUserDetails?.department?.name}
                />
              </AppBody>
            </div>

            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('User Type', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">
                {!!singleUserDetails?.roles?.length &&
                  t(singleUserDetails.roles[0], { ns: 'commonV2' })}
              </AppBody>
            </div>
          </div>
        </div>

        {/* ============= Address ============= */}
        <div className="rounded-2xl border border-gray-5 p-6">
          <AppHeader h="h6" className="mb-8">
            {t('Address', { ns: 'commonV2' })}
          </AppHeader>

          <div className="grid gap-8 lg:grid-cols-2">
            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('Country', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">{singleUserDetails?.address?.country?.name}</AppBody>
            </div>
            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('City/State', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">{singleUserDetails?.address?.city?.name}</AppBody>
            </div>
            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('Postal Code', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">{singleUserDetails?.address?.postal_code}</AppBody>
            </div>
            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('Address Line 1', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">{singleUserDetails?.address?.address1}</AppBody>
            </div>
            <div>
              <AppBody pClass="Body2Bold" className="text-gray-6">
                {t('Address Line 2', { ns: 'commonV2' })}
              </AppBody>
              <AppBody pClass="Body1Bold">{singleUserDetails?.address?.address2}</AppBody>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserPersonalInformationContent;
