/* eslint-disable no-use-before-define */
import axios from 'axios';

import { history } from '../../AppRouter/history';
import {
  receiptPopupIconIds,
  requestsSharedParams,
  settingsPopupContent,
  transactionsSharedParams,
} from '../../helpers/constants';
import {
  devLog,
  getServerErrMessage,
  navigateDifferentUserCardIdsDetails,
} from '../../helpers/Utils';
import { http } from '../../http';
import { showToasterAction } from '../AppToastersReducer/AppToastersActions';
import { updateSingleColumnCardAction } from '../CardListReducer/CardListActions';
import store from '../store';
import { updateSelectedSingleUserCardAction } from '../UsersReducer/UsersActions';

// ====== Card Details Actions ====== //
export const getCardDetailsBalanceAction = (cardId) => (dispatch) => {
  devLog(`Getting card balance of Card ID [${cardId}]...`);
  http
    .get(`cardmanager/cards/balance/${cardId}`, {
      loader: `getCardDetailsBalanceAction[${cardId}]`,
    })
    .then((response) => {
      dispatch({
        type: 'ADD_CARD_DETAILS_BALANCE',
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch(
        showToasterAction({
          type: 'error',
          message: getServerErrMessage(err),
        }),
      );
    });
};

export const clearCardDetailsBalanceAction = () => (dispatch) => {
  devLog(`Card Details [Balance] Cleared!`);
  dispatch({
    type: 'CLEAR_CARD_DETAILS_BALANCE',
  });
};

const getDoubleCardDetailsAction =
  ({ physicalCard, virtualCard }) =>
  async (dispatch) => {
    try {
      const physicalCardRequest = http.get(`cardmanager/cards/show/${physicalCard.id}`, {
        loader: 'getSelectedCardDetailsAction',
      });
      const virtualCardRequest = http.get(`cardmanager/cards/show/${virtualCard.id}`, {
        loader: 'getSelectedCardDetailsAction',
      });

      const [physicalCardResponse, virtualCardResponse] = await axios.all([
        physicalCardRequest,
        virtualCardRequest,
      ]);

      const cardsData = {
        physicalCard: physicalCardResponse.data,
        virtualCard: virtualCardResponse.data,
      };

      Object.values(cardsData).forEach((ele) => {
        const cardId = ele.id;
        const cardUserId = ele.cardholder.user_id;
        const loggedInUserId = store.getState().auth.user.id;

        navigateDifferentUserCardIdsDetails({ cardId, cardUserId, loggedInUserId });
      });

      // getting balance of virtualCard card
      await dispatch(getCardDetailsBalanceAction(virtualCard.id));

      dispatch({
        type: 'ADD_SELECTED_CARD_DETAILS',
        payload: cardsData,
      });
    } catch (error) {
      history.push('/404');
    }
  };

const getSingleCardDetails =
  ({ physicalCard = null, virtualCard = null }) =>
  (dispatch) => {
    devLog(`Getting [${physicalCard ? 'Physical' : 'Virtual'}] Card Data...`);
    const singleCardId = physicalCard?.id || virtualCard?.id;
    http
      .get(`cardmanager/cards/show/${singleCardId}`, { loader: 'getSelectedCardDetailsAction' })
      .then((res) => {
        const cardId = res.data.id;
        const cardUserId = res.data.cardholder.user_id;
        const loggedInUserId = store.getState().auth.user.id;

        navigateDifferentUserCardIdsDetails({ cardId, cardUserId, loggedInUserId });

        dispatch({
          type: 'ADD_SELECTED_CARD_DETAILS',
          payload: {
            physicalCard: physicalCard && res.data,
            virtualCard: virtualCard && res.data,
          },
        });
      })
      .catch(() => {
        history.push('/404');
      });
  };

export const getSelectedCardDetailsAction =
  ({ physicalCard, virtualCard }) =>
  (dispatch) => {
    devLog(
      `Card selected!
      virtualId: [${virtualCard?.id || null}]
      -
      physicalId:[${physicalCard?.id || null}]`,
    );
    if (virtualCard?.id && physicalCard?.id) {
      devLog('Getting DOUBLE card details...');
      dispatch(getDoubleCardDetailsAction({ physicalCard, virtualCard }));
    } else {
      devLog('Getting SINGLE card details...');
      dispatch(getSingleCardDetails({ physicalCard, virtualCard }));
    }
  };

export const clearSelectedCardDetailsAction = () => (dispatch) => {
  devLog('Card DETAILS cleared!');
  dispatch({
    type: 'CLEAR_SELECTED_CARD_DETAILS',
  });
};

export const updateSelectedCardDetailsAction =
  ({ card }) =>
  (dispatch) => {
    if (window.location.href.includes('my_cards')) {
      devLog(`Update Card [Details] in (My Cards)`);
      dispatch({
        type: 'UPDATE_SELECTED_CARD_DETAILS',
        payload: card,
      });
      dispatch(updateSingleColumnCardAction(card));
    }
    if (window.location.href.includes('users-v2')) {
      devLog(`Update Card [Details] in (Users)`);
      dispatch(updateSelectedSingleUserCardAction(card));
    }
  };

// ====== Card Transactions Actions ====== //
export const getCardTransactionsAction =
  ({ params }) =>
  (dispatch) => {
    http
      .get(`transactions`, {
        loader: 'getCardTransactionsAction',
        params: {
          ...transactionsSharedParams,
          ...params,
        },
      })
      .then((response) => {
        dispatch({
          type: 'ADD_CARD_TRANSACTIONS',
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err.data?.error,
          }),
        );
      });
  };

export const clearCardTransactionsAction = () => (dispatch) => {
  devLog('Card TRANSACTIONS cleared!');
  dispatch({
    type: 'CLEAR_CARD_TRANSACTIONS',
  });
};

export const updateCardTransactionsAction = (transaction) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CARD_TRANSACTIONS',
    payload: transaction,
  });
};

// ====== Card Transaction Details Actions ====== //
export const getSingleTransactionDetailsAction = (transactionId) => (dispatch) => {
  devLog('Getting single TRANSACTION details...');
  http
    .get(`transactions/${transactionId}`, {
      loader: 'getSingleTransactionDetailsAction',
    })
    .then((res) => {
      const splitCount = res.data?.splits?.length || 0;

      const attachmentsCount =
        res.data?.receipts?.filter((ele) => ele.doc_type !== 'Receipt').length || 0;

      dispatch({
        type: 'ADD_SINGLE_TRANSACTION_DETAILS',
        payload: res.data,
      });

      if (splitCount) {
        devLog(
          `single TRANSACTION has [${splitCount} Splits], Showing [${receiptPopupIconIds.SPLIT_RECEIPT}] Section `,
        );
        dispatch(showReceiptSectionAction(receiptPopupIconIds.SPLIT_RECEIPT));
      }
      if (attachmentsCount) {
        devLog(
          `single TRANSACTION has [${attachmentsCount} Attachments], Showing [${receiptPopupIconIds.UPLOAD_ATTACHMENT}] Section `,
        );
        dispatch(showReceiptSectionAction(receiptPopupIconIds.UPLOAD_ATTACHMENT));
      }
    });
};

export const clearSingleTransactionDetailsAction = () => (dispatch) => {
  devLog('single TRANSACTION cleared!');
  dispatch({
    type: 'CLEAR_SINGLE_TRANSACTION_DETAILS',
  });
};

export const addRequestActionAnimationAction = (data) => (dispatch) => {
  dispatch({
    type: 'ADD_REQUEST_ACTION_ANIMATION',
    payload: data,
  });
};

export const clearRequestActionAnimationAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_REQUEST_ACTION_ANIMATION',
  });
};

// ====== All Popups ====== //
export const hideAllPopups = () => (dispatch) => {
  dispatch(hideCardActionsPopup());
  dispatch(hideFilterPopupAction());
  dispatch(hideReceiptPopupAction());
  dispatch(hideSettingsPopupDataAction());
  dispatch(hideSearchPopupAction());
  dispatch(hideCardInfoPopupAction());
};

// ====== Receipt Popup Actions ====== //
export const showReceiptSectionAction = (sectionName) => (dispatch) => {
  devLog(`Show Receipt [${sectionName}] section`);
  dispatch({
    type: 'SHOW_SECTION',
    payload: sectionName,
  });
};

export const hideReceiptSectionAction = (sectionName) => (dispatch) => {
  devLog(`Hide Receipt [${sectionName}] section`);
  dispatch({
    type: 'HIDE_SECTION',
    payload: sectionName,
  });
};

export const hideAllReceiptSectionAction = () => (dispatch) => {
  devLog(`Hide [ALL] Receipt sections`);
  dispatch({
    type: 'HIDE_ALL_SECTIONS',
  });
};

export const showReceiptPopupAction = () => (dispatch) => {
  devLog('Show RECEIPT popup');

  dispatch(hideAllPopups());
  dispatch({
    type: 'SHOW_RECEIPT_POPUP',
  });
};

export const hideReceiptPopupAction = () => (dispatch) => {
  devLog('Hide RECEIPT popup');

  dispatch({
    type: 'HIDE_RECEIPT_POPUP',
  });

  dispatch(hideRequestFormPopupAction());
  dispatch(clearSingleRequestDetailsAction());
  dispatch(clearSingleTransactionDetailsAction());
  dispatch(hideAllReceiptSectionAction());
  dispatch(clearRequestActionAnimationAction());
};

// ====== Card Requests Actions ====== //
export const getCardRequestsAction =
  ({ params }) =>
  (dispatch) => {
    http
      .get(`funds`, {
        loader: 'getCardRequestsAction',
        params: {
          ...requestsSharedParams,
          ...params,
        },
      })
      .then((response) => {
        dispatch({
          type: 'ADD_CARD_REQUESTS',
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err.data?.error,
          }),
        );
      });
  };

export const getSingleRequestDetailsAction = (fundId) => (dispatch) => {
  devLog('Getting single REQUEST details...');
  http
    .get(`funds/${fundId}`, {
      loader: 'getSingleRequestDetailsAction',
    })
    .then((response) => {
      dispatch({
        type: 'ADD_SINGLE_REQUEST_DETAILS',
        payload: response.data,
      });
    });
};

export const clearCardRequestsAction = () => (dispatch) => {
  devLog('Card REQUESTS cleared!');

  dispatch({
    type: 'CLEAR_CARD_REQUESTS',
  });
};

export const clearSingleRequestDetailsAction = () => (dispatch) => {
  devLog('single REQUEST cleared!');
  dispatch({
    type: 'CLEAR_SINGLE_REQUEST_DETAILS',
  });
};

export const updateCardRequestsAction = (request) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CARD_REQUESTS',
    payload: request,
  });
};

// ====== Request Form Popup Actions ====== //
export const showRequestFormPopupAction = () => (dispatch) => {
  devLog('Show REQUEST FORM popup');

  dispatch({
    type: 'SHOW_REQUEST_FORM_POPUP',
  });
};

export const hideRequestFormPopupAction = () => (dispatch) => {
  devLog('Hide REQUEST FORM popup');

  dispatch({
    type: 'HIDE_REQUEST_FORM_POPUP',
  });
};

// ====== Departments Actions ====== //
export const getDepartmentsAction = () => (dispatch) => {
  http
    .get(`departments`, {
      loader: 'getDepartmentsAction',
    })
    .then((response) => {
      dispatch({
        type: 'ADD_DEPARTMENTS',
        payload: response.data,
      });
    });
};

export const clearDepartmentsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_DEPARTMENTS',
  });
};

// ====== Tags Actions ====== //
export const getTagsAction = () => (dispatch) => {
  http
    .get(`tags`, {
      loader: 'getTagsAction',
    })
    .then((response) => {
      dispatch({
        type: 'ADD_TAGS',
        payload: response.data,
      });
    });
};

export const clearTagsAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_TAGS',
  });
};

// ====== Doc Types Actions ====== //
export const getDocTypesAction = () => (dispatch) => {
  http
    .get(`transactions/receipts-doc-types`, {
      loader: 'getDocTypesAction',
    })
    .then((res) => {
      dispatch({
        type: 'GET_DOC_TYPES',
        payload: res.data,
      });
    });
};

export const clearDocTypesAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_DOC_TYPES',
  });
};

// ====== Currencies Actions ====== //
export const getTransactionCurrenciesAction = () => (dispatch) => {
  http
    .get(`transactions/transaction-currencies`, {
      loader: 'getTransactionCurrenciesAction',
    })
    .then((response) => {
      dispatch({
        type: 'ADD_TRANSACTION_CURRENCIES',
        payload: response.data,
      });
    });
};

export const clearTransactionCurrenciesAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_TRANSACTION_CURRENCIES',
  });
};

export const getRequestCurrenciesAction = () => (dispatch) => {
  http
    .get(`funds/currencies`, {
      loader: 'getRequestCurrenciesAction',
    })
    .then((response) => {
      dispatch({
        type: 'ADD_REQUEST_CURRENCIES',
        payload: response.data,
      });
    });
};

export const clearRequestCurrenciesAction = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_REQUEST_CURRENCIES',
  });
};

// ====== Details Table Tabs Actions ====== //
export const setActiveTabAction = (activeTab) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_TAB',
    payload: activeTab,
  });
};

// ====== Settings Popup Actions ====== //
export const setCurrentSettingsPopupContent = (currentContent) => (dispatch) => {
  devLog(`Setting current SETTINGS POPUP content to: ${currentContent}`);

  dispatch({
    type: 'SET_CURRENT_SETTINGS_POPUP_CONTENT',
    payload: currentContent,
  });
};

export const addSettingsPopupDataAction =
  ({ card, step = settingsPopupContent.settingsList }) =>
  (dispatch) => {
    dispatch(setCurrentSettingsPopupContent(step));

    devLog(
      `Add SETTINGS popup data of ${card.is_physical ? '[PHYSICAL]' : '[VIRTUAL]'} card ID: [${
        card.id
      }]`,
    );

    dispatch(hideAllPopups());

    dispatch({
      type: 'ADD_SETTINGS_POPUP_DATA',
      payload: card,
    });
  };

export const hideSettingsPopupDataAction = () => (dispatch) => {
  devLog('Hide SETTINGS popup data');

  dispatch({
    type: 'HIDE_SETTINGS_POPUP_DATA',
  });
};

export const updateSettingsPopupDataAction =
  ({ card }) =>
  (dispatch) => {
    devLog(
      `Update SETTINGS popup data of the ${card.is_physical ? '[PHYSICAL]' : '[VIRTUAL]'} card`,
    );
    dispatch({
      type: 'UPDATE_SETTINGS_POPUP_DATA',
      payload: card,
    });
  };

export const setChangedNameOrColorData = (changedData) => (dispatch) => {
  devLog(`Set the CHANGED [Name], [Color], OR [BOTH] Data`);

  dispatch({
    type: 'SET_CHANGED_NAME_COLOR_DATA',
    payload: changedData,
  });
};

// ====== Card ActionsPopup Actions ====== //
export const setCurrentCardActionsPopupContent = (currentContent) => (dispatch) => {
  devLog(`Setting current [CARD ACTIONS POPUP] content to: ${currentContent.content}`);

  dispatch(hideAllPopups());
  dispatch({
    type: 'SET_CURRENT_CARD_ACTIONS_POPUP_CONTENT',
    payload: currentContent,
  });
};

export const hideCardActionsPopup = () => (dispatch) => {
  devLog(`HIDE [CARD ACTIONS POPUP]`);

  dispatch({
    type: 'HIDE_CARD_ACTIONS_POPUP',
  });
};

// ====== Filter Popup Actions ====== //
export const showFilterPopupAction = () => (dispatch) => {
  devLog(`Show Filter Popup`);

  dispatch(hideAllPopups());
  dispatch({
    type: 'SHOW_FILTER_POPUP',
  });
};

export const hideFilterPopupAction = () => (dispatch) => {
  devLog(`Hide Filter Popup`);
  dispatch({
    type: 'HIDE_FILTER_POPUP',
  });
};

// ====== Search Popup Actions ====== //
export const showSearchPopupAction = () => (dispatch) => {
  devLog(`Show Search Popup`);

  dispatch(hideAllPopups());
  dispatch({
    type: 'SHOW_SEARCH_POPUP',
  });
};

export const hideSearchPopupAction = () => (dispatch) => {
  devLog(`Hide Search Popup`);
  dispatch({
    type: 'HIDE_SEARCH_POPUP',
  });
};

// ====== Card Info Popup Actions ====== //
export const showCardInfoPopupAction = (card) => (dispatch) => {
  devLog(`Show CardInfo Popup`);

  dispatch(hideAllPopups());
  dispatch({
    type: 'SHOW_CARD_INFO_POPUP',
    payload: card,
  });
};

export const hideCardInfoPopupAction = () => (dispatch) => {
  devLog(`Hide CardInfo Popup`);
  dispatch({
    type: 'HIDE_CARD_INFO_POPUP',
  });
};

export const getUserDetailsDataAction = (userDetailsId) => (dispatch) => {
  devLog(`Getting [userDetailsId=${userDetailsId}] User Data...`);

  if (userDetailsId) {
    http
      .get(`employees`, {
        loader: `getUserDetailsDataAction`,
        params: { id: userDetailsId },
      })
      .then((res) => {
        dispatch({
          type: 'SET_USER_DETAILS_DATA',
          payload: res.data[0],
        });
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  } else {
    devLog(`No [userDetailsId] User, Using Logged In User Data...`);
    const loggedInUserData = store.getState().auth.user;
    dispatch({
      type: 'SET_USER_DETAILS_DATA',
      payload: loggedInUserData,
    });
  }
};
