import { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import Intercom from '@intercom/messenger-js-sdk';
import AppRouter from './AppRouter';
import { history } from './AppRouter/history';
import AppRoutes from './AppRoutes';
import AppModal from './components/AppModal';
import AppToaster from './components/AppToaster';
import Header from './components/Header/Header';
import UseMockButton from './components/UseMockButton';
import { setAction } from './helpers/ActionsHelper';
import UseRegisterStep from './helpers/UseRegisterStep';
import { refreshUserData } from './helpers/UserHelper';
import { hasAccessToken } from './helpers/Utils';
import './i18n/config';
import NotificationsArea from './modules/Notifications/NotificationsArea';
import { updateIsMobileState } from './redux/AppConfigReducer/AppConfigActions';
import { SET_IS_LOGGEDIN, SET_USER_CAPABILITIES, SET_USER_DATA } from './redux/AuthReducer';
import { logOutAction, setRedirectURLAction } from './redux/AuthReducer/AuthActions';

function App() {
  const { appConfig } = useSelector((state) => state);
  const { user } = useSelector((state) => state.auth);
  const [cookies, , removeCookie] = useCookies(['token', 'lang']);
  const { t, i18n } = useTranslation(['common']);
  const dispatch = useDispatch();

  // TODO:: Remove with OLD LOGIC
  const asyncFn = async () => {
    if (hasAccessToken(cookies.token)) {
      try {
        const [userinfo, capabilities, accountReady] = await refreshUserData(cookies.token);

        dispatch(setAction(SET_USER_DATA, userinfo));
        dispatch(setAction(SET_IS_LOGGEDIN, true));
        dispatch(setAction(SET_USER_CAPABILITIES, capabilities));

        if (!accountReady) {
          history.push(UseRegisterStep(userinfo));
        }

        if (window.location.pathname === '/' || window.location.pathname === '') {
          history.push('/dashboard');
        }
      } catch (err) {
        if (err.status && err.status === 401) {
          removeCookie('token', { path: '/' });
        }
      }
    }
  };

  const handleOnIdle = () => {
    if (hasAccessToken(cookies.token)) {
      removeCookie('token', { path: '/' });
    }

    // ======= New Logic ======= //
    dispatch(logOutAction());
  };

  const updateMedia = useCallback(() => {
    dispatch(updateIsMobileState(window.innerWidth <= 1023));
  }, []);

  useEffect(() => {
    document.lang = appConfig.lang;
    document.dir = appConfig.dir;
    document.body.dir = appConfig.dir;
    document.title = t('title');
    i18n.changeLanguage(appConfig.lang);
  }, [appConfig]);

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 minute
    // timeout: 2000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  // TODO:: Remove with OLD LOGIC
  useEffect(() => {
    if (!hasAccessToken(cookies.token)) {
      localStorage.removeItem('userinfo');
      localStorage.removeItem('capabilities');
      dispatch(setAction('RESET_APP'));
      dispatch(setAction(SET_IS_LOGGEDIN, false));

      const pathName = window.location.pathname;
      const searchQueries = window.location.search;

      if (
        pathName.includes('verify_email') ||
        pathName.includes('reset_verify_phone') ||
        pathName.includes('verify_phone') ||
        pathName.includes('logout') ||
        pathName.includes('forget_password') ||
        pathName.includes('reset_password') ||
        pathName.includes('forget_password_thanks') ||
        pathName.includes('compliance') ||
        pathName.includes('signup')
      ) {
        history.push(pathName);
      } else {
        if (!pathName.includes('auth')) {
          dispatch(setRedirectURLAction(pathName + searchQueries));
        }
        history.push('/auth/login');
      }
    }
  }, [cookies.token]);

  // TODO:: Remove with OLD LOGIC
  useEffect(() => {
    asyncFn();
  }, [cookies.token]);

  useEffect(() => {
    updateMedia();

    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  useEffect(() => {
    if (user) {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_ID,
        user_id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        created_at: user.created_at,
        phone: user.mobile,
        company: {
          id: user.account_id,
          name: user.company_name,
        },
        user_type: user.roles[0],
        hide_default_launcher: false,
        language_override: i18n.language === 'ar' ? 'ar' : 'en',
        alignment: i18n.language === 'ar' ? 'left' : 'right',
        user_hash: user.user_hash,
      });
    }
  }, [user, i18n.language]);

  return (
    <div className="relative mx-auto h-screen max-w-[95vw] sm:px-3 xl:max-w-[80vw]">
      {/* App Toasters */}
      <AppToaster />
      {/* App Modals */}
      <AppModal />

      {/* Mocks */}
      <UseMockButton />

      <NotificationsArea />
      <AppRouter history={history}>
        <Header />
        <AppRoutes />
      </AppRouter>
    </div>
  );
}

export default App;
