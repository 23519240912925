import { uploadDocumentSteps } from '../../helpers/constants';

const INITIAL_STATE = {
  activeDocumentStep: uploadDocumentSteps.OTP,
  documentCards: null,
  documentOTPData: null,
  singleDocumentCardDetails: null,
};

const UploadDocumentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_DOCUMENT_STEP': {
      return { ...state, activeDocumentStep: action.payload };
    }

    case 'SAVE_DOCUMENT_CARDS': {
      return { ...state, documentCards: action.payload };
    }

    case 'SAVE_DOCUMENT_OTP_DATA': {
      return { ...state, documentOTPData: action.payload };
    }

    case 'SET_SINGLE_DOCUMENT_CARD_DETAILS': {
      return { ...state, singleDocumentCardDetails: action.payload };
    }

    case 'UPDATE_SINGLE_DOCUMENT_CARD_DETAILS': {
      const updated = { ...state.singleDocumentCardDetails };
      updated.documents.forEach((ele) => {
        if (ele.id === action.payload.document.id) {
          // eslint-disable-next-line no-param-reassign
          ele.path = action.payload.document.path;
        }
      });

      return {
        ...state,
        singleDocumentCardDetails: updated,
      };
    }

    default:
      return state;
  }
};

export default UploadDocumentsReducer;
