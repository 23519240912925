/* eslint-disable no-undefined */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppImageComponent from '../../../../../../../components/AppImageComponent';
import AppTextInput from '../../../../../../../components/FormInputs/AppTextInput';
import { AppBody, AppButton } from '../../../../../../../components/html/html';
import { devLog, generateCardName, getServerErrMessage } from '../../../../../../../helpers/Utils';
import { settingsPopupContent } from '../../../../../../../helpers/constants';
import { http } from '../../../../../../../http';
import { showToasterAction } from '../../../../../../../redux/AppToastersReducer/AppToastersActions';
import {
  hideSettingsPopupDataAction,
  setChangedNameOrColorData,
  setCurrentSettingsPopupContent,
  updateSettingsPopupDataAction,
} from '../../../../../../../redux/CardDetailsReducer/CardDetailsActions';
import { updateSingleColumnCardAction } from '../../../../../../../redux/CardListReducer/CardListActions';
import { updateSelectedSingleUserCardAction } from '../../../../../../../redux/UsersReducer/UsersActions';

function ChangeColorOrName() {
  const changedData = useMemo(() => {
    return {
      nameOnly: 'nameOnly',
      colorOnly: 'colorOnly',
      bothColorAndName: 'bothColorAndName',
    };
  }, []);

  const { cardId: fromDetailsPage } = useParams();
  const { loaders } = useSelector((state) => state);
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);
  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const [selectedDesign, setSelectedDesign] = useState({});
  const [availableDesigns, setAvailableDesigns] = useState([]);
  const [cardName, setCardName] = useState('');
  const [firstCardName, setFirstCardName] = useState('');
  const [secondCardName, setSecondCardName] = useState('');
  const [oldCardName, setOldCardName] = useState('');
  const [oldDesign, setOldDesign] = useState('');
  const [theChangedData, setTheChangedData] = useState(changedData.bothColorAndName);

  const dispatch = useDispatch();

  const updateSingleUserList = useMemo(() => {
    return window.location.href.includes('users-v2');
  }, [window.location.href]);

  const getCardDesigns = useCallback(() => {
    const cardId = settingsPopupCardDetails.id;
    http
      .get(`cardmanager/cards/show/${cardId}`, {
        params: {
          all_designs: 1,
        },
        loader: 'getCardDesigns',
      })
      .then((res) => {
        setAvailableDesigns(res.data?.all_designs || []);
        setSelectedDesign(res.data?.design || {});

        setCardName(res.data?.card_name || '');

        const firstWord = res.data?.card_name?.split(' ')[0] || '';
        const secondWord = res.data?.card_name?.split(' ')[1] || '';

        setFirstCardName(firstWord);
        setSecondCardName(secondWord && secondWord);

        setOldCardName(res.data?.card_name || '');
        setOldDesign(res.data?.design);
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: err?.data?.error,
          }),
        );
      });
  }, [settingsPopupCardDetails]);

  const handleWhatDataChanged = useCallback(() => {
    let nothingChanged = false;

    if (cardName !== oldCardName && selectedDesign?.id === oldDesign?.id) {
      devLog('Name [ONLY] Changed!');
      setTheChangedData(changedData.nameOnly);
    }

    if (cardName === oldCardName && selectedDesign?.id !== oldDesign?.id) {
      devLog('Color [ONLY] Changed!');
      setTheChangedData(changedData.colorOnly);
    }

    if (cardName !== oldCardName && selectedDesign?.id !== oldDesign?.id) {
      devLog('Name & Color [BOTH] Changed!');
      setTheChangedData(changedData.bothColorAndName);
    }

    if (oldCardName === cardName && selectedDesign?.id === oldDesign?.id) {
      nothingChanged = true;
      devLog('[Nothing] Changed!, No need to call API');
      setTheChangedData(changedData.bothColorAndName);
    }

    return nothingChanged;
  }, [cardName, oldCardName, selectedDesign, oldDesign]);

  const updateCardDesign = useCallback(() => {
    const cardId = settingsPopupCardDetails.id;

    http
      .put(`cardmanager/cards/${cardId}/update-design`, null, {
        loader: 'handleUpdateCardDesign',
        params: {
          design_id: selectedDesign.id,
          card_name: cardName,
        },
      })
      .then((res) => {
        dispatch(
          updateSettingsPopupDataAction({
            card: res.data,
          }),
        );

        if (!fromDetailsPage && !updateSingleUserList) {
          devLog('In My Cards Page (With Sidebar), Updating Card List Col Data');
          dispatch(updateSingleColumnCardAction(res.data));
        }

        if (updateSingleUserList) {
          devLog('In Single User Page, Updating Single User Card List Data');
          dispatch(updateSelectedSingleUserCardAction(res.data));
        }

        dispatch(setCurrentSettingsPopupContent(settingsPopupContent.changeColorNameSuccess));
      })
      .catch((err) => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: getServerErrMessage(err),
          }),
        );
      });
  }, [cardName, selectedDesign, fromDetailsPage, updateSingleUserList]);

  const handleUpdateCardDesign = useCallback(() => {
    const nothingChanged = handleWhatDataChanged();

    // Nothing Changed State
    if (nothingChanged) {
      dispatch(setCurrentSettingsPopupContent(settingsPopupContent.changeColorNameSuccess));
    } else {
      updateCardDesign();
    }
  }, [selectedDesign, cardName]);

  const handleChangeName = useCallback((e) => {
    const { value } = e.target;
    const finalValue = generateCardName(value);
    const firstWord = finalValue.split(' ')[0];
    const secondWord = finalValue.split(' ')[1];

    setCardName(finalValue);

    setFirstCardName(firstWord);
    setSecondCardName(secondWord || '');
  }, []);

  useEffect(() => {
    if (firstCardName.length === 12) {
      if (secondCardName) {
        setCardName(`${firstCardName} ${secondCardName}`);
      } else {
        setCardName(`${firstCardName} `);
      }
    }
  }, [firstCardName, secondCardName]);

  useEffect(() => {
    getCardDesigns();
  }, []);

  useEffect(() => {
    return () => {
      const DATA = {
        changedData,
        theChangedData,
        cardName,
        selectedDesign,
      };
      dispatch(setChangedNameOrColorData(DATA));
    };
  }, [changedData, theChangedData, cardName, selectedDesign]);

  return (
    <div className="overflow-hidden rounded-lg border border-gray-3 p-4">
      {/* Change Color */}
      <div className="mb-3">
        <AppBody pClass="Body2Bold" className="mb-1">
          {t('Change Color', { ns: 'cardsV2' })}
        </AppBody>
        <div className="flex gap-3">
          <div className="relative h-[103px] w-[164px] overflow-hidden rounded-md shadow">
            <AppImageComponent
              src={selectedDesign?.attachment}
              skeletonWidth={164}
              skeletonHeight={103}
            />
            {/* ====================== Card Name ====================== */}
            <div
              style={{ direction: 'ltr' }}
              className="absolute left-2 top-3 flex items-center text-[9px]"
            >
              {!loaders.getCardDesigns && (
                <i
                  className="fa-kit fa-nqoodlet-icon-left text-sm"
                  style={{ color: selectedDesign?.color_hex }}
                />
              )}
              <span
                className="pl-1"
                style={{
                  color: selectedDesign?.color_hex,
                }}
              >
                {firstCardName && <span className="block">{firstCardName}</span>}
                {secondCardName && <span className="block">{secondCardName}</span>}

                {!cardName && !loaders.getCardDesigns && (
                  <span className="flex">
                    <span className="font-black">Nqood</span>
                    <span>let</span>
                  </span>
                )}
              </span>
            </div>

            {!loaders.getCardDesigns && (
              <div
                className="absolute bottom-3 start-4 flex items-center tracking-wide"
                style={{ direction: 'ltr' }}
              >
                <AppBody
                  pClass="Caption1Medium"
                  className={settingsPopupCardDetails?.is_physical ? 'text-white' : 'text-black'}
                >
                  {settingsPopupCardDetails?.last_four}
                </AppBody>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-2">
            {availableDesigns.map((ele) => {
              return (
                <button
                  type="button"
                  onClick={() => {
                    setSelectedDesign(ele);
                  }}
                  key={ele.id}
                  style={{ backgroundColor: ele.color_hex }}
                  className={`h-4 w-4 rounded-full shadow outline-stone-500 ${
                    selectedDesign.id === ele.id ? 'outline outline-2' : 'border'
                  }`}
                />
              );
            })}
          </div>
        </div>
      </div>

      {/* Change Name */}
      <div className="mb-4">
        <AppBody pClass="Body2Bold" className="mb-1">
          {t('Change Name', { ns: 'cardsV2' })}
        </AppBody>
        <AppTextInput
          maxLength={25}
          value={cardName}
          onChange={handleChangeName}
          disabled={loaders.getCardDesigns}
        />
      </div>

      {/* Buttons */}
      <div className="flex justify-end gap-3">
        <AppButton
          button="link"
          onClick={() => {
            dispatch(hideSettingsPopupDataAction());
          }}
        >
          {t('Cancel', { ns: 'commonV2' })}
        </AppButton>
        <AppButton
          isLoading={loaders.handleUpdateCardDesign}
          button="black"
          onClick={() => {
            handleUpdateCardDesign();
          }}
        >
          {t('Save', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default ChangeColorOrName;
