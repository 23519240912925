import { useSelector } from 'react-redux';

// TODO:: Refactor Permissions
const useCapabilities = () => {
  const currentUserCapabilities = useSelector((state) => state.auth.capabilities);

  // eslint-disable-next-line no-prototype-builtins
  const currentUserCan = (capability) => currentUserCapabilities.includes(capability);

  return [currentUserCan];
};

export default useCapabilities;
