const UseRegisterStep = (userinfo) => {
  let path = '/signup/thanks';

  if (userinfo.roles && userinfo.roles[0] === 'Account-Owner') {
    if (userinfo && !userinfo.phone_verified) {
      path = '/signup/verify_phone';
    } else if (userinfo && !userinfo.email_verified) {
      path = '/signup/verify_email_resend';
    } else if (userinfo && !userinfo.yakeen_verified) {
      path = '/signup/verify_identity';
    } else if (userinfo && !userinfo.account_verified) {
      path = '/signup/verify_company';
    } else if (userinfo && userinfo.contact && userinfo.account_id === 0) {
      path = '/signup/contact_thanks';
    } else if (userinfo && !userinfo.business_verified) {
      path = '/signup/verify_company/business_info';
    } else if (userinfo && !userinfo.address_verified) {
      path = '/signup/verify_company/company_address';
    } else if (
      userinfo &&
      userinfo.account &&
      userinfo.account.status === 'Verifying' &&
      !userinfo.account.second_contact
    ) {
      path = '/signup/verify_company/second_contact';
    } else if (
      userinfo &&
      userinfo.account &&
      userinfo.account.status === 'Verifying' &&
      (!userinfo.account.funding_method ||
        (userinfo.account.funding_method && userinfo.account.funding_method.length === 0))
    ) {
      path = '/signup/verify_company/funding_method';
    } else if (
      userinfo &&
      userinfo.account &&
      userinfo.account.status === 'Verifying' &&
      !userinfo.accept_terms
    ) {
      path = '/signup/verify_company/accept_terms';
    }
  } else if (userinfo && userinfo.account_verified && !userinfo.yakeen_verified) {
    path = '/signup/verify_identity_employee';
  } else if (userinfo && userinfo.account_verified && !userinfo.accept_terms) {
    path = '/signup/accept_terms';
  }

  return path;
};

export default UseRegisterStep;
