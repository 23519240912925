import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';
import { apiGetRequest } from '../../helpers/APIHelper';
import { setAction } from '../../helpers/ActionsHelper';
import { logOutAction } from '../../redux/AuthReducer/AuthActions';

function Logout() {
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies(['token']);
  const dispatch = useDispatch();
  const { t } = useTranslation(['logout']);

  useEffect(() => {
    apiGetRequest('users/logout', cookies.token).finally(() => {
      dispatch(setAction('RESET_APP'));
      removeCookie('token', { path: '/' });
      localStorage.removeItem('userinfo');
      localStorage.removeItem('capabilities');
      navigate('/auth/login');

      // ======= New Logic ======= //
      dispatch(logOutAction());
    });
  }, []);

  return (
    <div className="flex flex-grow flex-col items-center justify-center">
      <Spinner className="h-10 w-10 animate-spin lg:h-16 lg:w-16" />
      <p>{t('headline')}</p>
    </div>
  );
}

export default Logout;
