import Cookies from 'js-cookie';

import { http } from '../../http';
import store from '../store';

export const updateIsMobileState = (isMobile) => (dispatch) => {
  dispatch({
    type: 'UPDATE_IS_MOBILE',
    payload: { isMobile },
  });
};

export const changeLang = () => (dispatch) => {
  const { lang, dir } = store.getState().appConfig;
  const updatedLang = lang === 'en' ? 'ar' : 'en';
  const updatedDir = dir === 'rtl' ? 'ltr' : 'rtl';
  Cookies.set('lang', updatedLang);
  const isLoggedIn = Boolean(store.getState().auth.loginProcess.loggedInUser);

  if (isLoggedIn) {
    http.patch('users/lang', null, { params: { lang: updatedLang } });
  }

  dispatch({
    type: 'CHANGE_LANG',
    payload: { lang: updatedLang, dir: updatedDir },
  });
};
