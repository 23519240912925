import { useFormik } from 'formik';
import i18n from 'i18next';
import { getURLParams } from 'javascript-functions';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { devLog, generateCardIdQueryParams } from '../../helpers/Utils';
import { cardActionsPopupContent, cardStatusKeys, transactionTabs } from '../../helpers/constants';
import { http } from '../../http';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import {
  addRequestActionAnimationAction,
  getCardRequestsAction,
  getSingleRequestDetailsAction,
  hideRequestFormPopupAction,
  setCurrentCardActionsPopupContent,
  updateCardRequestsAction,
} from '../../redux/CardDetailsReducer/CardDetailsActions';
import {
  addWalletRequestActionAnimationAction,
  getSingleWalletRequestDetailsAction,
  hideWalletRequestFormPopupAction,
  updateWalletRequestsAction,
} from '../../redux/WalletReducer/WalletActions';
import AppNumberInput from '../FormInputs/AppNumberInput';
import AppTextArea from '../FormInputs/AppTextArea';
import AppUpload from '../FormInputs/AppUpload';
import TooltipPackage from '../TooltipPackage';
import { AppBody, AppButton, AppHeader } from '../html/html';
import AfterSuccessAmountCurrency from './AfterSuccessAmountCurrency';
import CardProgramSchemeLogo from './CardProgramSchemeLogo';

function RequestFrom({
  requestDetails,
  cardDetails,
  transactionDetails,
  activeTab,
  fromCardActions = false,
  fromCardRequests = false,
  fromCardTransactions = false,
  fromWalletRequests = false,
  fromWalletTransactions = false,
}) {
  const { loaders } = useSelector((state) => state);
  const { t } = useTranslation(['cardsV2', 'commonV2', 'walletV2']);
  const dispatch = useDispatch();

  const maxDescriptionCharNum = 150;
  const [formValues, setFormValues] = useState({
    reason: '',
    amount: '',
    attachments: '',
  });

  const handleFunds = useCallback(
    (formBody) => {
      const fundEndpoint = requestDetails ? `funds/${requestDetails.id}` : 'funds';
      http
        .post(fundEndpoint, formBody, {
          loader: 'handleFunds',
        })
        .then((res) => {
          if (fromCardTransactions) {
            dispatch(hideRequestFormPopupAction());
            dispatch(
              addRequestActionAnimationAction({
                show: true,
                isSuccess: true,
                amount: (
                  <AfterSuccessAmountCurrency
                    amount={res.data?.amount}
                    currency={res.data?.currency}
                  />
                ),
                message: t('Money Requested Successfully', { ns: 'cardsV2' }),
              }),
            );
          }

          if (fromCardRequests) {
            dispatch(hideRequestFormPopupAction());
            dispatch(
              showToasterAction({
                type: 'success',
                message: t('Fund request saved successfully.'),
              }),
            );
            dispatch(getSingleRequestDetailsAction(res?.data?.id));
            dispatch(updateCardRequestsAction(res.data));
          }

          if (fromWalletRequests) {
            dispatch(hideWalletRequestFormPopupAction());
            dispatch(
              showToasterAction({
                type: 'success',
                message: t('Fund request saved successfully.'),
              }),
            );
            dispatch(getSingleWalletRequestDetailsAction(res?.data?.id));
            dispatch(updateWalletRequestsAction(res.data));
          }

          if (fromWalletTransactions) {
            dispatch(hideWalletRequestFormPopupAction());
            dispatch(
              addWalletRequestActionAnimationAction({
                show: true,
                isSuccess: true,
                amount: (
                  <AfterSuccessAmountCurrency
                    amount={res.data?.amount}
                    currency={res.data?.currency}
                  />
                ),
                message: t('Money Requested Successfully', { ns: 'walletV2' }),
              }),
            );
          }

          if (fromCardActions) {
            if (activeTab === transactionTabs.REQUESTS) {
              const cardIdParams = generateCardIdQueryParams(cardDetails);
              const timeRangeFilter = JSON.parse(getURLParams('timeRangeFilter'));
              dispatch(
                getCardRequestsAction({
                  params: { page: 1, ...timeRangeFilter, ...cardIdParams },
                }),
              );
            }
            dispatch(
              setCurrentCardActionsPopupContent({
                content: cardActionsPopupContent.actionSuccess,
                amount: (
                  <AfterSuccessAmountCurrency
                    amount={res.data?.amount}
                    currency={res.data?.currency}
                  />
                ),
                message: t('Money Requested Successfully', { ns: 'cardsV2' }),
              }),
            );
          }
        })
        .catch((err) => {
          if (fromWalletTransactions) {
            dispatch(hideWalletRequestFormPopupAction());
            dispatch(
              addWalletRequestActionAnimationAction({
                show: true,
                isSuccess: false,
                reason: err?.data?.error,
                message: t('Money Request Failed', { ns: 'walletV2' }),
              }),
            );
          } else if (fromCardTransactions) {
            dispatch(hideWalletRequestFormPopupAction());
            dispatch(
              addRequestActionAnimationAction({
                show: true,
                isSuccess: false,
                reason: err?.data?.error,
                message: t('Money Request Failed', { ns: 'cardsV2' }),
              }),
            );
          } else if (fromCardActions) {
            dispatch(
              setCurrentCardActionsPopupContent({
                content: cardActionsPopupContent.actionFail,
                reason: err?.data?.error,
                message: t('Money Request Failed', { ns: 'cardsV2' }),
              }),
            );
          } else {
            dispatch(
              showToasterAction({
                type: 'error',
                message: err.data?.error,
              }),
            );
          }
        });
    },
    [
      requestDetails,
      fromCardActions,
      fromCardTransactions,
      fromCardRequests,
      fromWalletRequests,
      fromWalletTransactions,
    ],
  );

  const getRequestCurrency = useCallback(() => {
    if (fromCardActions) {
      return cardDetails?.virtualCard
        ? cardDetails?.virtualCard?.cardproduct?.currency
        : cardDetails?.physicalCard?.cardproduct?.currency;
    }

    if (fromCardRequests || fromWalletRequests) {
      return requestDetails?.currency;
    }

    if (fromWalletTransactions) {
      return transactionDetails?.transaction_currency;
    }

    if (fromCardTransactions) {
      if (cardDetails?.virtualCard?.id === transactionDetails?.card_id) {
        return cardDetails?.virtualCard?.cardproduct?.currency;
      }

      return cardDetails?.physicalCard?.cardproduct?.currency;
    }

    return null;
  }, [requestDetails, fromWalletRequests, cardDetails, fromWalletTransactions]);

  const getRequestCardId = useCallback(() => {
    if (fromCardActions && cardDetails?.physicalCard) {
      // send physical card id only if virtual card is !== active
      if (cardDetails?.virtualCard?.status?.key !== cardStatusKeys.ACTIVE) {
        devLog(`[Virtual] Card Status is: ${cardDetails?.virtualCard?.status?.key}`);
        devLog(`Sending [Physical] Card ID: ${cardDetails?.physicalCard?.id}`);
        return cardDetails?.physicalCard?.id;
      }
      return cardDetails?.virtualCard.id;
    }

    if (fromCardActions && !cardDetails?.physicalCard) {
      devLog(`Card is Only [Virtual], Sending ID: ${cardDetails?.virtualCard?.id}`);

      return cardDetails?.virtualCard.id;
    }

    if (fromCardRequests || fromWalletRequests) {
      devLog(`Sending [Request] Card ID: ${requestDetails?.card_id?.id}`);
      return requestDetails?.card_id?.id;
    }

    if (fromWalletTransactions) {
      return transactionDetails?.card_id;
    }

    if (fromCardTransactions) {
      if (cardDetails?.virtualCard?.id === transactionDetails?.card_id) {
        return cardDetails?.virtualCard?.id;
      }

      return cardDetails?.physicalCard?.id;
    }
    return null;
  }, [requestDetails, fromWalletRequests, cardDetails, fromWalletTransactions]);

  const getRequestCardLastFour = useCallback(() => {
    if (fromCardActions && cardDetails?.physicalCard) {
      // send physical card id only if virtual card is !== active
      if (cardDetails?.virtualCard?.status?.key !== cardStatusKeys.ACTIVE) {
        return cardDetails?.physicalCard?.last_four;
      }
      return cardDetails?.virtualCard.last_four;
    }

    if (fromCardActions && !cardDetails?.physicalCard) {
      return cardDetails?.virtualCard.last_four;
    }

    if (fromCardRequests || fromWalletRequests) {
      return requestDetails?.card_id?.last_four;
    }

    if (fromWalletTransactions) {
      return transactionDetails?.card_last4;
    }

    if (fromCardTransactions) {
      if (cardDetails?.virtualCard?.last_four === transactionDetails?.card_last4) {
        return cardDetails?.virtualCard?.last_four;
      }

      return cardDetails?.physicalCard?.last_four;
    }
    return null;
  }, [requestDetails, fromWalletRequests, cardDetails, fromWalletTransactions]);

  const onSubmit = (values) => {
    const formBody = new FormData();

    if (requestDetails) {
      formBody.append('_method', 'PUT');
    }

    formBody.append('currency', getRequestCurrency());
    formBody.append('card_id', getRequestCardId());
    formBody.append('card_last4', getRequestCardLastFour());

    formBody.append('reason', values.reason);
    formBody.append('amount', values.amount);

    if (values.attachments && !values.attachments.path) {
      formBody.append('attachments[]', values.attachments);
    }

    handleFunds(formBody);
  };

  const validationSchema = yup.object({
    amount: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
    reason: yup.string().required(t('This field is required!', { ns: 'commonV2' })),
  });

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if ((fromCardRequests || fromWalletRequests) && requestDetails) {
      setFormValues({
        amount: requestDetails?.amount || '',
        reason: requestDetails?.reason || '',
        attachments: requestDetails?.attachments[0],
      });
    }
  }, [fromCardRequests, requestDetails]);

  useEffect(() => {
    devLog(
      `Request form in page ${JSON.stringify({
        fromCardActions,
        fromCardTransactions,
        fromCardRequests,
        fromWalletRequests,
        fromWalletTransactions,
      })}`,
    );
  }, []);

  return (
    <div>
      {!fromCardActions && (
        <AppHeader h="h6" className="mb-4">
          {t('Request Money To')}
        </AppHeader>
      )}
      <div className="mb-5 flex items-start justify-between">
        <div className="flex flex-col gap-2">
          <AppBody pClass="Body2Bold" type="span">
            {requestDetails?.card_id?.card_name ||
              transactionDetails?.card_name ||
              cardDetails?.virtualCard?.card_name ||
              cardDetails?.physicalCard?.card_name}
          </AppBody>
        </div>
        <div className="flex items-center gap-1">
          <span>
            <CardProgramSchemeLogo
              cardProgramScheme={
                transactionDetails?.network ||
                requestDetails?.card_id?.cardproduct.scheme ||
                cardDetails?.virtualCard?.cardproduct?.scheme ||
                cardDetails?.physicalCard?.cardproduct?.scheme
              }
            />
          </span>
          <span>
            {transactionDetails?.card_last4 ||
              requestDetails?.card_id?.last_four ||
              cardDetails?.virtualCard?.last_four ||
              cardDetails?.physicalCard?.last_four}
          </span>

          {cardDetails?.physicalCard && cardDetails?.virtualCard && (
            <span>
              <TooltipPackage
                tooltipTextSize="text-xxxs"
                tooltipTextColor="text-black"
                tooltipContentClassName="bg-slate-50 font-normal leading-normal"
                place={i18n.language === 'en' ? 'top-end' : 'top-start'}
                tooltipMessage={t(
                  'This request will be for both plastic and virtual cards, as they share the balance.',
                )}
                id={`info_request_double_card_${cardDetails?.virtualCard?.id}`}
              />
            </span>
          )}
        </div>
      </div>

      <div className="rounded-lg border border-gray-3 p-3">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3 grid gap-4 pb-4">
            <label>
              <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                {t('Amount')}
              </AppBody>
              <AppNumberInput
                symbol={getRequestCurrency()}
                name="amount"
                value={formik.values.amount}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue('amount', e.target.value);
                }}
              />
              {formik.errors.amount && formik.touched.amount && (
                <AppBody pClass="Caption2Medium" className="mb-2 text-red-600">
                  {formik.errors.amount}
                </AppBody>
              )}
            </label>

            <label>
              <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                {t('Request description')}
              </AppBody>
              <AppTextArea
                name="reason"
                value={formik.values.reason}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue('reason', e.target.value);
                }}
                maxLength={maxDescriptionCharNum}
              />
              <div className="flex justify-between gap-1">
                {formik.errors.reason && formik.touched.reason && (
                  <AppBody pClass="Caption2Medium" className="mb-2 text-red-600">
                    {formik.errors.reason}
                  </AppBody>
                )}

                <div className="text-end text-xs text-gray-6">
                  {formik.values.reason.length} / {maxDescriptionCharNum}
                </div>
              </div>
            </label>

            <div>
              <AppBody type="span" pClass="Caption1Bold" className="mb-1 block">
                {t('Upload Receipt')}{' '}
                <AppBody pClass="Caption1Regular" type="span" className="text-gray-6">
                  ({t('Optional', { ns: 'commonV2' })})
                </AppBody>
              </AppBody>
              <AppUpload
                showAccepted={false}
                uploadedFilePreview={{
                  path: formik.values.attachments?.path,
                  type: formik.values.attachments?.type,
                }}
                height={130}
                width="100%"
                isDisabled={false}
                onChange={({ FILE }) => {
                  formik.setFieldValue('attachments', FILE);
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-end gap-4">
            <AppButton
              isLoading={loaders.handleFunds}
              loadingLabel={t('Saving', { ns: 'commonV2' })}
              disabled={!formik.isValid}
              button="black"
              type="submit"
            >
              {t('Save', { ns: 'commonV2' })}
            </AppButton>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RequestFrom;
