import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppBody, AppIcon } from '../../../../components/html/html';
import useCapabilities from '../../../../helpers/useCapabilities';
import {
  changeUserStatusAction,
  resendUserActivationEmailAction,
  sendChangeUserPasswordInvitationEmailAction,
} from '../../../../redux/UsersReducer/UsersActions';

function UserDetailsActions() {
  const [currentUserCan] = useCapabilities();
  const { singleUserDetails } = useSelector((state) => state.users);
  const [showChangeStatusPopup, setShowChangeStatusPopup] = useState(false);
  const { t } = useTranslation('usersV2');
  const dispatch = useDispatch();
  const { loaders, auth } = useSelector((state) => state);

  const isUserPending = useMemo(() => {
    return singleUserDetails.status === 'Pending';
  }, [singleUserDetails]);

  const isUserBanned = useMemo(() => {
    return singleUserDetails.status === 'Banned';
  }, [singleUserDetails]);

  const showChangeStatusButton = useMemo(() => {
    const canChangeStatus = currentUserCan('update_account');
    const notSameLoggedInUser = singleUserDetails.id !== auth.user.id;
    const notAccountOwner = !singleUserDetails.roles.includes('Account-Owner');
    return canChangeStatus && notSameLoggedInUser && notAccountOwner && !isUserPending;
  }, [singleUserDetails, auth, isUserPending]);

  const renderChangeStatusPopup = useCallback(() => {
    const isActive = singleUserDetails.status === 'Active';
    const isPending = singleUserDetails.status === 'Pending';
    const isSuspended = singleUserDetails.status === 'Suspended';
    const isDeactivated = singleUserDetails.status === 'Deactivate';

    return (
      <button
        type="button"
        className="relative me-1.5"
        onClick={() => {
          setShowChangeStatusPopup(!showChangeStatusPopup);
        }}
      >
        {showChangeStatusPopup && (
          <AppBody
            pClass="Caption1Regular"
            type="div"
            className="absolute end-0 top-6 z-10 grid min-w-36 rounded bg-white p-2 text-start shadow"
          >
            {(isSuspended || isDeactivated) && (
              <span
                className="block py-1 ps-2 hover:bg-gray-1"
                onClick={() => {
                  dispatch(
                    changeUserStatusAction({
                      userId: singleUserDetails.id,
                      status: 'Active',
                      fromDetails: true,
                    }),
                  );
                }}
              >
                {t('Reactivate')}
              </span>
            )}

            {(isActive || isDeactivated) && (
              <span
                className="block py-1 ps-2 hover:bg-gray-1"
                onClick={() => {
                  dispatch(
                    changeUserStatusAction({
                      userId: singleUserDetails.id,
                      status: 'Suspended',
                      fromDetails: true,
                    }),
                  );
                }}
              >
                {t('Suspend')}
              </span>
            )}

            {(isActive || isSuspended) && (
              <span
                className="block py-1 ps-2 hover:bg-gray-1"
                onClick={() => {
                  dispatch(
                    changeUserStatusAction({
                      userId: singleUserDetails.id,
                      status: 'Deactivate',
                      fromDetails: true,
                    }),
                  );
                }}
              >
                {t('Deactivate(v)')}
              </span>
            )}

            {isPending && (
              <span
                className="block py-1 ps-2 hover:bg-gray-1"
                onClick={() => {
                  dispatch(resendUserActivationEmailAction({ userId: singleUserDetails.id }));
                }}
              >
                {t('Resend Activation')}
              </span>
            )}
          </AppBody>
        )}
      </button>
    );
  }, [singleUserDetails, showChangeStatusPopup]);

  return (
    <div className="mb-4 flex justify-end gap-4 pe-6 pt-7 lg:mb-12">
      {!isUserBanned && showChangeStatusButton && (
        <div className="relative flex items-center gap-1">
          {!loaders[`changeUserStatusAction[${singleUserDetails.id}]`] && (
            <AppIcon iClass="LargeFont" className="fa-regular fa-user-pen text-primary" />
          )}

          {loaders[`changeUserStatusAction[${singleUserDetails.id}]`] && (
            <AppIcon
              iClass="BaseFont"
              className="fa-regular fa-loader me-1 animate-spin text-primary"
            />
          )}

          <button
            type="button"
            className="text-xs font-bold text-primary hover:underline"
            onClick={() => {
              setShowChangeStatusPopup(!showChangeStatusPopup);
            }}
          >
            {t('Change Status')}
          </button>
          {renderChangeStatusPopup()}
        </div>
      )}

      {!isUserBanned && (
        <div className="flex items-center gap-1">
          {/* Spinner Icons */}
          {loaders.sendChangeUserPasswordInvitationEmailAction && (
            <AppIcon
              iClass="BaseFont"
              className="fa-regular fa-loader me-1 animate-spin text-primary"
            />
          )}

          {isUserPending && loaders[`resendUserActivationEmailAction[${singleUserDetails.id}]`] && (
            <AppIcon
              iClass="BaseFont"
              className="fa-regular fa-loader me-1 animate-spin text-primary"
            />
          )}

          {/* Change Password Icon */}
          {!isUserPending && !loaders.sendChangeUserPasswordInvitationEmailAction && (
            <AppIcon iClass="LargeFont" className="fa-regular fa-arrows-retweet text-primary" />
          )}

          {/* Resend Invitation Icon */}
          {isUserPending &&
            !loaders[`resendUserActivationEmailAction[${singleUserDetails.id}]`] && (
              <AppIcon
                iClass="LargeFont"
                className="fa-regular fa-paper-plane-top -rotate-45 text-primary"
              />
            )}

          <button
            type="button"
            className="text-xs font-bold text-primary hover:underline"
            onClick={() => {
              if (isUserPending) {
                dispatch(
                  resendUserActivationEmailAction({
                    userId: singleUserDetails.id,
                    fromDetails: true,
                  }),
                );
              } else {
                dispatch(
                  sendChangeUserPasswordInvitationEmailAction({
                    userId: singleUserDetails.id_number,
                    email: singleUserDetails.email,
                  }),
                );
              }
            }}
          >
            {!isUserPending && t('Reset Password')}
            {isUserPending && t('Resend Invite')}
          </button>
        </div>
      )}
    </div>
  );
}
export default UserDetailsActions;
