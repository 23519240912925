import { randomNumber } from 'javascript-functions';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { history } from '../../../../AppRouter/history';
import AppNoData from '../../../../components/AppNoData';
import { AppBody, AppIcon } from '../../../../components/html/html';
import TooltipPackage from '../../../../components/TooltipPackage';
import useCapabilities from '../../../../helpers/useCapabilities';
import { getFilteredCards } from '../../../../helpers/Utils';
import SingleUserNqoodCard from './SingleUserNqoodCard';

function UserCardsDropDownContent() {
  const { loaders, auth } = useSelector((state) => state);
  const { singleUserCards, singleUserDetails } = useSelector((state) => state.users);
  const [notArchivedCards, setNotArchivedCards] = useState([]);
  const [archivedCards, setArchivedCards] = useState([]);
  const [relatedCards, setRelatedCards] = useState({});
  const { t } = useTranslation('usersV2');
  const [currentUserCan] = useCapabilities();

  const isUserActive = useMemo(() => {
    return singleUserDetails.status === 'Active';
  }, [singleUserDetails]);

  const canCreateNewCard = useMemo(() => {
    const notSuspendedUser = auth.status !== 'Suspended';
    const userCanCreateNewCard =
      currentUserCan('create_own_cards') || currentUserCan('create_account_cards');

    return notSuspendedUser && userCanCreateNewCard;
  }, [auth, singleUserDetails]);

  useEffect(() => {
    if (singleUserCards?.length) {
      const { related, archived, notArchived } = getFilteredCards(singleUserCards);

      setRelatedCards(related);
      setNotArchivedCards(notArchived);
      setArchivedCards(archived);
    }
  }, [singleUserCards]);

  return (
    <>
      {loaders.getSingleUserCardsAction && (
        <div className="pt-4">
          <Skeleton height={60} count={randomNumber(3, 5)} className="mb-2" />
        </div>
      )}

      {!loaders.getSingleUserCardsAction && !notArchivedCards.length && !archivedCards.length && (
        <div className="pt-4">
          <AppNoData className="h-full w-40 text-sm" message={t('This User Has No Cards.')} />
        </div>
      )}

      {!loaders.getSingleUserCardsAction &&
        (!!notArchivedCards.length || !!archivedCards.length) && (
          <>
            {/* ==================== Active Cards ==================== */}
            <div className="mb-5 ms-4 mt-4 grid h-96 gap-8 overflow-y-auto border-gray-3 ps-5 lg:h-full lg:gap-4 lg:overflow-y-visible ltr:border-l rtl:border-r">
              {notArchivedCards.map((card) => {
                if (relatedCards[card.id]) {
                  return (
                    <div className="mb-2" key={`${card.id}###${relatedCards[card.id].id}`}>
                      <SingleUserNqoodCard
                        virtualCard={relatedCards[card.id]}
                        physicalCard={card}
                      />
                    </div>
                  );
                }
                return (
                  !relatedCards[card.id] && (
                    <div key={card.id}>
                      <SingleUserNqoodCard virtualCard={card} physicalCard={null} />
                    </div>
                  )
                );
              })}
            </div>

            {/* ==================== Achieved Cards ==================== */}
            {!!archivedCards.length && (
              <AppBody pClass="Body2Bold" className="mb-1 ps-4">
                {t('Archived')}
              </AppBody>
            )}

            <div className="mb-8 ms-4 mt-4 grid h-96 gap-8 overflow-y-auto border-gray-3 ps-5 lg:h-full lg:gap-4 lg:overflow-y-visible ltr:border-l rtl:border-r">
              {archivedCards.map((card) => {
                if (relatedCards[card.id]) {
                  return (
                    <div className="mb-2" key={`${card.id}###${relatedCards[card.id].id}`}>
                      <SingleUserNqoodCard
                        virtualCard={relatedCards[card.id]}
                        physicalCard={card}
                      />
                    </div>
                  );
                }
                return (
                  !relatedCards[card.id] && (
                    <div key={card.id}>
                      <SingleUserNqoodCard virtualCard={card} physicalCard={null} />
                    </div>
                  )
                );
              })}
            </div>
          </>
        )}

      {canCreateNewCard && (
        <TooltipPackage
          tooltipContentClassName="font-normal leading-normal"
          place="top"
          tooltipMessage={t('This user must be active')}
          id="emp_create_card"
          disable={isUserActive}
        >
          <button
            id="emp_create_card"
            disabled={!isUserActive}
            type="button"
            className="ms-8 flex items-center justify-center gap-1 text-center text-xs font-bold text-primary hover:underline disabled:cursor-not-allowed disabled:text-gray-6 disabled:opacity-50 disabled:hover:no-underline"
            onClick={() => {
              if (isUserActive) {
                history.push(
                  `/dashboard/my_cards/create-new-card?createCardUserId=${singleUserDetails.id}`,
                );
              }
            }}
          >
            <AppIcon iClass="XSmall" className="fa-kit fa-credit-card" />
            <span>{t('Create a New Card', { ns: 'cardsV2' })}</span>
          </button>
        </TooltipPackage>
      )}
    </>
  );
}

export default UserCardsDropDownContent;
