import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppSpinner from '../../../../../components/AppSpinner';
import TimeRangeFilter from '../../../../../components/SharedComponents/TimeRangeFilter';
import { AppBody } from '../../../../../components/html/html';
import { downloadFile } from '../../../../../helpers/Utils';
import { transactionsSharedParams, transactionTabs } from '../../../../../helpers/constants';
import { http } from '../../../../../http';
import { showModalAction } from '../../../../../redux/AppModalReducer/AppModalActions';
import { showToasterAction } from '../../../../../redux/AppToastersReducer/AppToastersActions';
import {
  showFilterPopupAction,
  showSearchPopupAction,
} from '../../../../../redux/CardDetailsReducer/CardDetailsActions';
import FilterPopup from '../FilterPopup';
import SearchPopup from '../SearchPopup';

function CardsTableActionButtons({
  exportParams,
  handleOnSearch,
  filterTags,
  handleApplyFilters,
  isDisabled,
  handleTimeRangeChange,
}) {
  const { isMobile } = useSelector((state) => state.appConfig);
  const { selectedCardDetailsData, selectedActiveTab } = useSelector((state) => state.cardDetails);

  const { loaders } = useSelector((state) => state);
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();

  const handleExport = useCallback(() => {
    http
      .get(`transactions/export-specific-type`, {
        loader: 'handleExport',
        responseType: 'blob',
        params: {
          ...transactionsSharedParams,
          ...exportParams,
        },
      })
      .then((res) => {
        const cardName =
          selectedCardDetailsData?.virtualCard?.card_name ||
          selectedCardDetailsData?.virtualCard?.name_on_card;

        const cardLastFour = selectedCardDetailsData?.virtualCard?.last_four;

        const fileName = `MY_CARDS_${cardName}_${cardLastFour}_${selectedActiveTab}`;

        downloadFile({
          data: res,
          fileName,
          fileExtension: 'xlsx',
        });
      })
      .catch(() => {
        dispatch(
          showToasterAction({
            type: 'error',
            message: t('No Results Found.'),
          }),
        );
      });
  }, [exportParams, selectedActiveTab]);

  const tableActionButtons = useMemo(() => {
    return [
      {
        label: t('Search', { ns: 'commonV2' }),
        onClick: () => {
          if (isMobile) {
            dispatch(showModalAction(<SearchPopup onSearch={handleOnSearch} />));
          }
          dispatch(showSearchPopupAction());
        },
        show: true,
      },
      {
        label: t('Filter', { ns: 'commonV2' }),
        onClick: () => {
          if (isMobile) {
            dispatch(
              showModalAction(<FilterPopup filterTags={filterTags} onApply={handleApplyFilters} />),
            );
          }
          dispatch(showFilterPopupAction());
        },
        show: true,
      },
      {
        label: loaders.handleExport ? <AppSpinner size={16} /> : t('Export', { ns: 'commonV2' }),
        onClick: () => {
          handleExport();
        },
        disabled: loaders.handleExport,
        show: selectedActiveTab !== transactionTabs.REQUESTS,
      },
      // Mobile Time Range
      {
        label: t('Time Range', { ns: 'commonV2' }),
        onClick: () => {
          dispatch(
            showModalAction(
              <TimeRangeFilter
                handleTimeRangeChange={handleTimeRangeChange}
                activeTab={selectedActiveTab}
              />,
            ),
          );
        },
        show: isMobile,
      },
    ];
  }, [loaders.handleExport, selectedActiveTab, exportParams, isMobile, filterTags, t]);

  return (
    <AppBody
      type="div"
      pClass="Caption1Bold"
      className={`flex text-primary ${isDisabled ? 'pointer-events-none opacity-50' : ''}`}
    >
      {tableActionButtons.map((ele) => {
        return (
          ele.show && (
            <button
              disabled={ele.disabled}
              key={ele.label}
              onClick={ele.onClick}
              type="button"
              className="rounded-full px-3 py-2 text-primary hover:bg-stone-50 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent"
            >
              {ele.label}
            </button>
          )
        );
      })}
    </AppBody>
  );
}

export default CardsTableActionButtons;
