import { randomId } from 'javascript-functions';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../../AppRouter/history';
import AppSpinner from '../../../../../components/AppSpinner';
import { MasterCardLogoIcon, VisaCardLogoIcon } from '../../../../../components/IconComponents';
import TooltipPackage from '../../../../../components/TooltipPackage';
import { AppBody, AppHeader, AppIcon } from '../../../../../components/html/html';
import { toIntAndDecimalNumber } from '../../../../../helpers/Utils';
import { cardProgramActionsPopupContent, cardStatusKeys } from '../../../../../helpers/constants';
import useCapabilities from '../../../../../helpers/useCapabilities';
import { setCurrentCardProgramActionsPopupContent } from '../../../../../redux/WalletReducer/WalletActions';
import CardProgramActionsPopup from '../../CardProgramActions/CardProgramActionsPopup';
import SingleCardProgramStatus from '../../SingleCardProgramStatus';

function CardProgramDetailsHeader() {
  const { loaders, appConfig } = useSelector((state) => state);
  const {
    walletCardPrograms,
    singleSelectedCardProgramDetails,
    selectedCardProgramDetailsBalance,
  } = useSelector((state) => state.wallet);
  const { t } = useTranslation('walletV2');
  const dispatch = useDispatch();
  const [currentUserCan] = useCapabilities();

  const isClosedCardProgram = useMemo(() => {
    return [cardStatusKeys.SUSPENDED, cardStatusKeys.DEACTIVATED].includes(
      singleSelectedCardProgramDetails?.status?.toUpperCase(),
    );
  }, [singleSelectedCardProgramDetails]);

  const getActionButtonBorderColor = useCallback(
    (isDisabled) => {
      if (isClosedCardProgram && isDisabled) {
        return 'border-gray-3';
      }
      return 'border-primary';
    },
    [isClosedCardProgram],
  );

  const actionButtons = useMemo(() => {
    const isLoading = loaders.getCardProgramDetailsBalanceAction;

    const isActive = singleSelectedCardProgramDetails.status === cardStatusKeys.ACTIVE;

    const hasMoreThanActiveCardProgram =
      walletCardPrograms?.filter((ele) => ele.status.toUpperCase() === cardStatusKeys.ACTIVE)
        .length > 1;
    const hasBalance = Number(selectedCardProgramDetailsBalance?.amount || 0);

    return [
      {
        id: randomId(),
        show: currentUserCan('create_account_bank_funds') && currentUserCan('show_funding_method'),
        label: (
          <>
            <span className={`${getActionButtonBorderColor(!isActive)}`}>
              <AppIcon className="fa-regular fa-circle-plus" />
            </span>
            <span className="hidden sm:inline">{t('Deposit')}</span>
            <span className="sm:hidden">{t('Deposit')}</span>
          </>
        ),
        toolTipText: t('Add money to your card program'),
        onClick: () => {
          history.push(
            `/dashboard/wallet/deposit?selectedCardProgramId=${singleSelectedCardProgramDetails?.id}&selectedCardProgramCurrency=${singleSelectedCardProgramDetails?.currency}`,
          );
        },
        disabled: isLoading || !isActive,
      },
      {
        id: randomId(),
        show: currentUserCan('show_funding_method'),
        label: (
          <>
            <span className={`${getActionButtonBorderColor(!isActive || !hasBalance)}`}>
              <AppIcon className="fa-regular fa-circle-minus" />
            </span>
            <span className="hidden sm:inline">{t('Withdraw')}</span>
            <span className="sm:hidden">{t('Withdraw')}</span>
          </>
        ),
        toolTipText: t('Withdraw money from your card program'),
        onClick: () => {
          history.push(
            `/dashboard/wallet/withdraw?selectedCardProgramId=${singleSelectedCardProgramDetails?.id}&selectedCardProgramCurrency=${singleSelectedCardProgramDetails?.currency}`,
          );
        },
        disabled: isLoading || !isActive || !hasBalance,
      },
      {
        id: randomId(),
        show: true,
        label: (
          <>
            <span
              className={`${getActionButtonBorderColor(
                !hasMoreThanActiveCardProgram || !hasBalance || !isActive,
              )}`}
            >
              <AppIcon className="fa-regular fa-money-bill-transfer" />
            </span>
            <span className="hidden sm:inline">{t('Transfer')}</span>
            <span className="sm:hidden">{t('Transfer')}</span>
          </>
        ),
        toolTipText: t('Transfer money between your card programs'),
        onClick: () => {
          history.push(
            `/dashboard/wallet/transfer?selectedCardProgramId=${singleSelectedCardProgramDetails?.id}&selectedCardProgramCurrency=${singleSelectedCardProgramDetails?.currency}`,
          );
        },
        disabled: isLoading || !hasMoreThanActiveCardProgram || !hasBalance || !isActive,
      },
      {
        id: randomId(),
        show: true,
        label: (
          <>
            <span className={`${getActionButtonBorderColor()}`}>
              <AppIcon className="fa-regular fa-file-arrow-down font-semibold" />
            </span>
            <span className="hidden sm:inline">{t('Statement')}</span>
            <span className="sm:hidden">{t('Statement')}</span>
          </>
        ),
        toolTipText: t('Download your card program statement'),
        onClick: () => {
          dispatch(
            setCurrentCardProgramActionsPopupContent({
              content: cardProgramActionsPopupContent.statement,
              header: t('Download Statement'),
            }),
          );
        },
        disabled: isLoading,
      },
    ];
  }, [
    loaders,
    appConfig,
    isClosedCardProgram,
    singleSelectedCardProgramDetails,
    walletCardPrograms,
    selectedCardProgramDetailsBalance,
    t,
  ]);

  const renderCardProgramDescription = useCallback(() => {
    const slicedDescription = singleSelectedCardProgramDetails.description
      .split('-')
      .slice(0, 2)
      .join('-');
    return (
      <span className={`${isClosedCardProgram ? 'text-gray-6' : 'text-black'}`}>
        {slicedDescription}
      </span>
    );
  }, [isClosedCardProgram]);

  const renderScheme = useCallback(() => {
    if (singleSelectedCardProgramDetails?.scheme?.toUpperCase() === 'MASTER') {
      return <MasterCardLogoIcon />;
    }
    if (singleSelectedCardProgramDetails?.scheme?.toUpperCase() === 'VISA') {
      return <VisaCardLogoIcon />;
    }
    return null;
  }, [singleSelectedCardProgramDetails]);

  return (
    <div className="relative mb-1 flex flex-col justify-between gap-3 lg:flex-row">
      {/* Card Actions Popup */}
      <CardProgramActionsPopup />

      {/* ================ Card Program Details Description ================ */}
      <div
        className={`${
          isClosedCardProgram ? 'text-gray-6' : 'text-black'
        } order-2 flex flex-col gap-1 lg:order-1`}
      >
        <AppBody pClass="Caption1Regular" className="flex items-center gap-1">
          <span>{t('In Partnership with')}</span>
          <span>{singleSelectedCardProgramDetails?.funding_source_type?.bank_name}</span>
          <AppIcon
            iClass="XXLargeFont"
            className={`${singleSelectedCardProgramDetails?.funding_source_type?.bank_logo} ${
              isClosedCardProgram ? 'text-gray-6' : ''
            } ms-1`}
          />
        </AppBody>

        <AppHeader h="h2" className="flex items-center gap-2">
          {renderCardProgramDescription()}
          {renderScheme()}
        </AppHeader>

        {/* ================ Card Program Details Balance ================ */}
        <div
          className={`${
            isClosedCardProgram ? 'text-gray-6' : 'text-primary'
          } flex items-center gap-2 text-2xl font-semibold`}
        >
          {!loaders.getCardProgramDetailsBalanceAction && isClosedCardProgram && (
            <SingleCardProgramStatus
              cardProgram={singleSelectedCardProgramDetails}
              className="text-3xl font-normal text-gray-6"
            />
          )}

          {!loaders.getCardProgramDetailsBalanceAction && (
            <span className="flex gap-1 rtl:justify-end" style={{ direction: 'ltr' }}>
              <span>
                {toIntAndDecimalNumber(selectedCardProgramDetailsBalance?.amount || 0).fullNumber}
              </span>
              <span>{singleSelectedCardProgramDetails?.currency}</span>
            </span>
          )}

          {loaders.getCardProgramDetailsBalanceAction && (
            <span className="flex items-center gap-2">
              <span>
                <AppSpinner size={24} />
              </span>
              <span>{t('Loading Balance')}</span>
            </span>
          )}
        </div>
      </div>

      {/* ================ Card Program Actions Buttons ================ */}
      <AppBody
        type="div"
        pClass="Caption1Bold"
        className="order-1 mx-auto mb-6 flex w-full items-start justify-between gap-1 lg:order-2 lg:mx-0 lg:mb-0 lg:w-auto lg:justify-end lg:gap-3"
      >
        {actionButtons.map((ele) => {
          return (
            ele.show && (
              <TooltipPackage
                key={ele.id}
                tooltipContentClassName="font-normal leading-normal"
                id={ele.id}
                tooltipMessage={ele.toolTipText}
                place="bottom"
              >
                <button
                  className={`${
                    isClosedCardProgram && ele.disabled ? 'text-gray-6' : 'text-primary'
                  } flex items-center justify-end gap-1 rounded disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent lg:flex-row`}
                  type="button"
                  onClick={ele.onClick}
                  disabled={ele.disabled}
                >
                  {ele.label}
                </button>
              </TooltipPackage>
            )
          );
        })}
      </AppBody>
    </div>
  );
}

export default CardProgramDetailsHeader;
