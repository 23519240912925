import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { transactionTabs } from '../../helpers/constants';
import { toIntAndDecimalNumber } from '../../helpers/Utils';
import { AppHeader } from '../html/html';
import TranslatedData from '../TranslatedData';
import ReceiptStatus from './ReceiptStatus';

function ReceiptBalanceAndStatus({ transactionDetails }) {
  const { selectedActiveTab } = useSelector((state) => state.cardDetails);
  const { selectedWalletSubActiveTab } = useSelector((state) => state.wallet);

  const renderTransactionStatus = useCallback(() => {
    return (
      (transactionDetails.status || transactionDetails.status_ar) && (
        <ReceiptStatus color={transactionDetails?.hex_color}>
          <TranslatedData en={transactionDetails.status} ar={transactionDetails.status_ar} />
        </ReceiptStatus>
      )
    );
  }, [transactionDetails]);

  return (
    <>
      <div className="mb-4 flex items-center justify-center gap-2" style={{ direction: 'ltr' }}>
        <AppHeader h="h2" className="inline-block">
          {[selectedActiveTab, selectedWalletSubActiveTab].includes(transactionTabs.LOADS) && '+'}
          {toIntAndDecimalNumber(transactionDetails.transaction_amount).integerPart}
        </AppHeader>
        <span className="text-[34px]">
          {toIntAndDecimalNumber(transactionDetails.transaction_amount).decimalPart}
        </span>
        <span className="text-xl font-medium">{transactionDetails.transaction_currency}</span>
      </div>
      <div className="text-center">{renderTransactionStatus()}</div>
    </>
  );
}

export default ReceiptBalanceAndStatus;
