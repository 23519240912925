import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppCloseButton from '../../components/AppCloseButton';
import AppUpload from '../../components/FormInputs/AppUpload/index';
import { AppButton, AppHeader } from '../../components/html/html';
import { hideModalAction } from '../../redux/AppModalReducer/AppModalActions';

function UploadSingleDocumentPopup({ document, handleChange }) {
  const { loaders } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation('uploadDocuments');
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);

  return (
    <div className="w-[90vw] rounded-lg bg-white p-8 lg:w-[60vw] xl:w-[50vw]">
      <div className="mb-4 flex items-center justify-between">
        <AppHeader h="h6">{t(document.label, { ns: 'commonV2' })}</AppHeader>

        <AppCloseButton
          onClick={() => {
            dispatch(hideModalAction());
          }}
        />
      </div>

      <AppUpload
        acceptFilesErrorMessage={t('The file must be PDF size: 2MB', { ns: 'commonV2' })}
        isLoading={false}
        width="100%"
        height={165}
        showAccepted={false}
        onChange={({ FILE }) => {
          setFile(FILE);
          setError(false);
        }}
      />

      {error && (
        <span className="text-sm text-danger">
          {t('This field is required!', { ns: 'commonV2' })}
        </span>
      )}

      <div className="mt-4 text-end">
        <AppButton
          isLoading={loaders.handleFileUpload}
          disabled={error}
          onClick={() => {
            if (file) {
              handleChange(file);
            } else {
              setError(true);
            }
          }}
          size="md"
        >
          {t('Submit')}
        </AppButton>
      </div>
    </div>
  );
}

export default UploadSingleDocumentPopup;
