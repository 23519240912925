export const customStyles = {
  normal: {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '.75rem',
      opacity: state.isDisabled ? '.5' : '1',
      border: `1px solid ${state.isFocused ? '#C1CBDE' : '#C1CBDE'}`,
      boxShadow: 'none',
      '&:hover': {},
      height: '40px',
    }),
    valueContainer: (provided, state) => {
      return {
        ...provided,
        textWrap: state.isMulti ? 'nowrap' : 'wrap',
        flexWrap: state.isMulti ? 'nowrap' : 'wrap',
      };
    },
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
    }),
    option: (provided, state) => {
      return {
        ...provided,
        color: state.data.color,
        backgroundColor:
          state.isMulti && state.isSelected ? 'transparent' : provided.backgroundColor,
      };
    },
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
  },
  inForm: {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '.5rem',
      opacity: state.isDisabled ? '.5' : '1',
      '&:hover': {},
      height: '48px',
      border: `1px solid #C1CBDE`,
      boxShadow: 'none',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '90px',
    }),
  },
  nude: {
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      border: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
      opacity: state.isDisabled ? '.5' : '1',
    }),
    valueContainer: () => ({
      padding: 0,
      height: '20px',
    }),
    input: (provided) => ({
      ...provided,
      position: 'absolute',
      top: '5px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.data.color,
    }),
  },
  flippyArrow: {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '.5rem',
      opacity: state.isDisabled ? '.5' : '1',
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      borderColor: state.isFocused ? '#ccc' : provided.borderColor,
      '&:hover': {
        borderColor: state.isFocused ? '#ccc' : provided['&:hover'].borderColor,
      },
      minHeight: '25px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 4px 0 6px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '25px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: 'black',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      paddingLeft: state.selectProps.menuIsOpen ? 8 : 0,
      paddingRight: state.selectProps.menuIsOpen ? 0 : 8,
    }),
    menu: (provided) => ({
      ...provided,
      overflow: 'hidden',
      borderRadius: '.5rem',
      marginTop: 2,
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: '0px',
      paddingBottom: '0px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: 'bold',
    }),
    option: (provided, state) => ({
      ...provided,
      height: 32,
      padding: '8px 10px',
      backgroundColor: state.isSelected || state.isFocused ? '#F2F5FC' : 'white',
      boxShadow: `0px 4px 27px 0px rgba(0, 0, 0, 0.15)`,
      color: 'black',
      borderBottom: '.5px solid #C1CBDE',
      '&:last-of-type': {
        borderBottom: 'none',
      },
    }),
  },
  'normal-mobile': 'rounded-xl border border-gray-3 focus:border-gray-3 h-10',
  'inForm-mobile': 'rounded-lg border border-gray-3 h-12',
  'nude-mobile': 'border-0',
  'custom-mobile': '',
};
