import { dummyArr, randomNumber } from 'javascript-functions';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import AppNoData from '../../../components/AppNoData';
import SingleUserRow from './SingleUserRow';

function UsersListTable() {
  const { loaders } = useSelector((state) => state);
  const { usersList } = useSelector((state) => state.users);
  const [showOptionsPopup, setShowOptionsPopup] = useState({});

  return (
    <>
      {loaders.getUsersListAction && (
        <div className="grid gap-4">
          {dummyArr(randomNumber(3, 6)).map((_, i) => {
            return <Skeleton key={JSON.stringify(i)} height={100} />;
          })}
        </div>
      )}

      {!loaders.getUsersListAction && !usersList?.data?.length && <AppNoData />}

      {!loaders.getUsersListAction &&
        usersList?.data?.map((ele, i) => {
          const isLastEl = i === usersList.data.length - 1;

          return (
            <SingleUserRow
              item={ele}
              key={ele.id}
              isLastEl={isLastEl}
              setShowOptionsPopup={setShowOptionsPopup}
              showOptionsPopup={showOptionsPopup}
            />
          );
        })}
    </>
  );
}

export default UsersListTable;
