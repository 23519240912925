import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppImageComponent from '../../../../components/AppImageComponent';
import { AppButton, AppHeader } from '../../../../components/html/html';
import { createNewCardSteps } from '../../../../helpers/constants';
import { setActiveCreateNewCardStepAction } from '../../../../redux/CreateNewCardReducer/CreateNewCardActions';
import CancelBtn from './CancelBtn';

function ReplaceCardConfirmation() {
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();
  const { loaders } = useSelector((state) => state);
  const { cardToReplace } = useSelector((state) => state.createNewCard);

  return (
    <div className="mx-auto mt-[10vh] w-full max-w-[360px] px-5 lg:w-2/3 lg:px-0 xl:w-1/2">
      <AppHeader h="h3">
        <span>
          <span>{t('Are You Sure You Want To Replace This Card')} </span>
          <span>
            *{cardToReplace.last_four} <span className="inline-block rtl:scale-x-[-1]"> ?</span>
          </span>
        </span>
      </AppHeader>

      <div className="relative my-6 overflow-hidden rounded-xl shadow-lg">
        <AppImageComponent
          className="blur-[2px]"
          src={cardToReplace.design?.attachment}
          alt={cardToReplace.name_on_card}
          skeletonWidth={360}
          skeletonHeight={228}
        />
        <span
          className={`absolute bottom-4 left-6 ${cardToReplace.is_physical ? 'text-white' : ''}`}
        >
          <span className="block font-semibold">{t(capitalize(cardToReplace.status?.key))}</span>
          <span className="block">{cardToReplace.last_four}</span>
        </span>
      </div>

      <AppHeader h="h5" className="mb-3">
        {t(
          'When you replace the card, a new card will be issued for you, and this card cannot be replaced again.',
        )}
      </AppHeader>
      <ul className="mb-4 list-disc ps-5">
        <li>
          {t(
            'If you replace the primary card, the new card that will be issued for you will be the primary card',
          )}
        </li>
        <li>
          {t(
            'If you replace the plastic card, the new plastic card that will be issued for you will be linked with your active primary card, or when you replace the primary card if it is terminated or expired',
          )}
        </li>
        {!cardToReplace.is_physical && (
          <li>
            {t(
              'A replacement card will have the same color and name as the old one. You can update it later in the settings on the card page',
            )}
          </li>
        )}
      </ul>

      <div className="flex justify-end gap-3 pb-5">
        <CancelBtn />

        <AppButton
          size="md"
          onClick={() => {
            if (cardToReplace.is_physical) {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.shippingDetails));
            } else {
              dispatch(setActiveCreateNewCardStepAction(createNewCardSteps.sendOTP));
            }
          }}
          isLoading={loaders.replacePhysicalCard}
          button="black"
        >
          {t('Replace', { ns: 'commonV2' })}
        </AppButton>
      </div>
    </div>
  );
}

export default ReplaceCardConfirmation;
