import {AppBody} from "../../../components/html/html";

const CardStatusBadge = function (props) {
  const { status, color } = props;
  const divStyle = {
    minWidth: '105px',
    background: `${color}20`,
    color,
  };
  return (
    <AppBody pClass="Caption1Regular" type="span" className="inline-block rounded-2xl px-5 py-1 text-center" style={divStyle}>
      {status}
    </AppBody>
  );
};

export default CardStatusBadge;
