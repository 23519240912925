import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LinkButton from '../../../../components/Button/LinkButton';
import useCapabilities from '../../../../helpers/useCapabilities';

function SplitActions({ isSplit, setRemoveSplit, transaction }) {
  const [currentUserCan] = useCapabilities();
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation(['transactions', 'common']);
  const navigate = useNavigate();

  return (
    <div className="mt-10 flex flex-row items-center justify-end lg:px-10">
      {(currentUserCan('edit_account_transaction_splits') ||
        (currentUserCan('edit_own_transaction_splits') && user.id === transaction.user_id?.id)) && (
        <LinkButton
          isEdit
          className="rlt:font-bold px-2 text-base text-primary ltr:font-semibold"
          text={t('edit_split')}
          onClick={() => {
            navigate(
              `/dashboard/${isSplit ? 'expenses' : 'transactions'}/split/edit/${transaction.id}`,
            );
          }}
        />
      )}
      {(currentUserCan('delete_account_transaction_splits') ||
        (currentUserCan('delete_own_transaction_splits') &&
          user.id === transaction.user_id?.id)) && (
        <LinkButton
          isTrash
          className="rlt:font-bold px-2 text-base text-red-700 ltr:font-semibold"
          text={t('delete_split')}
          onClick={() => {
            setRemoveSplit(true);
          }}
        />
      )}
    </div>
  );
}

export default SplitActions;
