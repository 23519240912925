export const setActiveDocumentStepAction = (step) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_DOCUMENT_STEP',
    payload: step,
  });
};

export const saveDocumentCardsAction = (documentCards) => (dispatch) => {
  dispatch({
    type: 'SAVE_DOCUMENT_CARDS',
    payload: documentCards,
  });
};

export const saveDocumentsOTPDataAction = (data) => (dispatch) => {
  dispatch({
    type: 'SAVE_DOCUMENT_OTP_DATA',
    payload: data,
  });
};

export const setSingleDocumentCardDetailsAction = (cardDetails) => (dispatch) => {
  dispatch({
    type: 'SET_SINGLE_DOCUMENT_CARD_DETAILS',
    payload: cardDetails,
  });
};

export const updateSingleDocumentCardDetailsAction =
  ({ document }) =>
  (dispatch) => {
    dispatch({
      type: 'UPDATE_SINGLE_DOCUMENT_CARD_DETAILS',
      payload: { document },
    });
  };
