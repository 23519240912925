import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Spinner } from '../../../assets/svg/spinner.svg';
import LinkButton from '../../../components/Button/LinkButton';
import LoadingError from '../../../components/LoadingError';
import { cardBalance } from '../../../helpers/CardHelper';

function CardTotalBalance({ cardId }) {
  const { t } = useTranslation(['common']);
  const [cookies] = useCookies(['token']);
  const [isLoading, setIsLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [balance, setBalance] = useState(0);
  const [currency, setCurrency] = useState(null);
  const [showBalance, setShowBalance] = useState(false);
  const fetchCardBalance = () => {
    setIsLoading(true);
    cardBalance(cardId, cookies.token)
      .then((response) => {
        setCurrency(response?.currency);
        setBalance(response.amount ? parseFloat(response.amount) : '');
        setErrored(false);
      })
      .catch(() => {
        setErrored(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (showBalance) {
      fetchCardBalance();
    }
  }, [showBalance]);

  return (
    <>
      {showBalance && isLoading && (
        <Spinner className="inline-block h-6 w-6 animate-spin fill-gray" />
      )}
      {showBalance && errored && (
        <LoadingError show={errored} retryCallback={fetchCardBalance} simple />
      )}
      {!showBalance && (
        <LinkButton
          onClick={() => setShowBalance(true)}
          text={t('show_balance')}
          className="text-sm text-gray-6"
        />
      )}
      {showBalance && !isLoading && !errored && (
        <>
          {balance} {currency}
        </>
      )}
    </>
  );
}

export default CardTotalBalance;
