import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowForward } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../../../../AppRouter/history';
import { generateReplaceCardURL } from '../../../../../../../helpers/Utils';
import { cardStatusKeys, settingsPopupContent } from '../../../../../../../helpers/constants';
import useCapabilities from '../../../../../../../helpers/useCapabilities';
import { setCurrentSettingsPopupContent } from '../../../../../../../redux/CardDetailsReducer/CardDetailsActions';

function SettingsList() {
  const { settingsPopupCardDetails } = useSelector((state) => state.cardDetails);
  const { t } = useTranslation('cardsV2');
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const [currentUserCan] = useCapabilities();

  const isCardPhysical = useMemo(() => {
    return settingsPopupCardDetails.is_physical;
  }, [settingsPopupCardDetails]);

  const selectedCardStatus = useMemo(() => {
    return settingsPopupCardDetails?.status?.key?.toUpperCase();
  }, [settingsPopupCardDetails]);

  const selectedCardProgramStatus = useMemo(() => {
    return settingsPopupCardDetails?.cardproduct.status?.toUpperCase();
  }, [settingsPopupCardDetails]);

  const showPinOptions = useMemo(() => {
    return currentUserCan('show_card_secret') && auth.user?.id === settingsPopupCardDetails.user_id;
  }, [auth]);

  const isCardProgramClosed = useMemo(() => {
    return [cardStatusKeys.SUSPENDED, cardStatusKeys.DEACTIVATED].includes(
      selectedCardProgramStatus,
    );
  }, [auth]);

  const canChangeStatus = useMemo(() => {
    let canChangeAccountCardStatus = currentUserCan('change_account_card_status');
    if (
      canChangeAccountCardStatus &&
      settingsPopupCardDetails.status?.key.toUpperCase() === cardStatusKeys.INACTIVE &&
      settingsPopupCardDetails.is_physical &&
      auth.user?.id !== settingsPopupCardDetails.user_id
    ) {
      canChangeAccountCardStatus = false;
    }
    const canChangeOwnCardStatus =
      currentUserCan('change_own_card_status') &&
      auth.user?.id === settingsPopupCardDetails.user_id;

    return canChangeAccountCardStatus || canChangeOwnCardStatus;
  }, [auth, settingsPopupCardDetails]);

  const canRenewStatus = useMemo(() => {
    const canChangeAccountCardStatus = currentUserCan('renew_account_card');
    const canChangeOwnCardStatus =
      currentUserCan('renew_own_card') && auth.user?.id === settingsPopupCardDetails.user_id;

    return canChangeAccountCardStatus || canChangeOwnCardStatus;
  }, [auth, settingsPopupCardDetails]);

  const buttons = useMemo(() => {
    return [
      {
        label: t('Show PIN number'),
        contentName: settingsPopupContent.showPinNumber,
        disabled: selectedCardStatus !== cardStatusKeys.ACTIVE || isCardProgramClosed,
        show: isCardPhysical && showPinOptions,
      },
      {
        label: t('Change PIN number'),
        contentName: settingsPopupContent.changePinNumber,
        disabled: selectedCardStatus !== cardStatusKeys.ACTIVE || isCardProgramClosed,
        show: isCardPhysical && showPinOptions,
      },
      {
        label: t('Change Color or Name'),
        contentName: settingsPopupContent.changeColorOrName,
        disabled:
          selectedCardStatus === cardStatusKeys.TERMINATED ||
          selectedCardStatus === cardStatusKeys.REPLACED ||
          selectedCardStatus === cardStatusKeys.EXPIRED ||
          isCardProgramClosed,
        show: !isCardPhysical,
      },
      {
        label: t('Change Status'),
        contentName: settingsPopupContent.changeStatus,
        disabled:
          selectedCardStatus === cardStatusKeys.TERMINATED ||
          selectedCardStatus === cardStatusKeys.REPLACED ||
          selectedCardStatus === cardStatusKeys.EXPIRED ||
          isCardProgramClosed,
        show: canChangeStatus,
      },
      // {
      //   label: t('Card Control'),
      //   contentName: settingsPopupContent.cardControl,
      //   disabled: isCardProgramClosed,
      //   show: true,
      // },
      {
        label: t('Replace Card'),
        contentName: settingsPopupContent.cardControl,
        disabled:
          (selectedCardStatus !== cardStatusKeys.TERMINATED &&
            selectedCardStatus !== cardStatusKeys.EXPIRED) ||
          isCardProgramClosed,
        show: canRenewStatus,
        onClick: () => {
          history.push(generateReplaceCardURL(settingsPopupCardDetails.id));
        },
      },
    ];
  }, [selectedCardStatus, canChangeStatus, isCardPhysical, showPinOptions]);

  return (
    <div className="overflow-hidden rounded-lg border border-gray-3">
      {buttons.map((ele) => {
        return (
          ele.show && (
            <button
              key={ele.label}
              type="button"
              className="flex w-full justify-between border-b p-4 text-start text-xs font-normal text-black last:border-0 hover:bg-stone-50 disabled:cursor-not-allowed disabled:opacity-50"
              disabled={ele.disabled}
              onClick={() => {
                if (ele.onClick) {
                  ele.onClick();
                } else {
                  dispatch(setCurrentSettingsPopupContent(ele.contentName));
                }
              }}
            >
              <span>{ele.label}</span>
              <IoIosArrowForward size={14} className="text-gray-5 rtl:rotate-180" />
            </button>
          )
        );
      })}
    </div>
  );
}

export default SettingsList;
