import { useSelector } from 'react-redux';
import ActionFail from '../../../../../components/SharedComponents/ActionFail';
import ActionSuccess from '../../../../../components/SharedComponents/ActionSuccess';
import { cardActionsPopupContent } from '../../../../../helpers/constants';
import CardLoadContent from './CardLoadContent';
import CardUnLoadContent from './CardUnLoadContent';
import MoneyRequestContent from './MoneyRequestContent';
import StatementContent from './StatementContent';

function CardActionsPopupContent({ cardDetails, cardDetailsBalance, inPage }) {
  const { currentCardActionsPopupContent } = useSelector((state) => state.cardDetails);

  return (
    <div>
      {/* Card Load */}
      {currentCardActionsPopupContent.content === cardActionsPopupContent.cardLoad && (
        <CardLoadContent cardDetails={cardDetails} inPage={inPage} />
      )}

      {/* Card UnLoad */}
      {currentCardActionsPopupContent.content === cardActionsPopupContent.cardUnload && (
        <CardUnLoadContent
          cardDetails={cardDetails}
          cardDetailsBalance={cardDetailsBalance}
          inPage={inPage}
        />
      )}

      {/* Money Request */}
      {currentCardActionsPopupContent.content === cardActionsPopupContent.moneyRequest && (
        <MoneyRequestContent />
      )}

      {/* Statement */}
      {currentCardActionsPopupContent.content === cardActionsPopupContent.statement && (
        <StatementContent cardDetails={cardDetails} />
      )}

      {/* Action Success */}
      {currentCardActionsPopupContent.content === cardActionsPopupContent.actionSuccess && (
        <ActionSuccess requestAction={currentCardActionsPopupContent} />
      )}

      {/* Action Fail */}
      {currentCardActionsPopupContent.content === cardActionsPopupContent.actionFail && (
        <ActionFail requestAction={currentCardActionsPopupContent} />
      )}
    </div>
  );
}

export default CardActionsPopupContent;
