import { useTranslation } from 'react-i18next';
import failureXAnimation from '../../../../assets/lotties/failureXAnimation2.json';
import SuccessCheckAnimation from '../../../../assets/lotties/SuccessCheckAnimation2.json';
import AppLottie from '../../../../components/AppLottie';
import { AppHeader } from '../../../../components/html/html';

function EditUserSuccessOrFail({ isSuccess, errCode }) {
  const { t } = useTranslation('usersV2');

  return (
    <div className="flex h-full items-center justify-center">
      <div>
        <div className="mb-14">
          <AppLottie
            animationData={isSuccess ? SuccessCheckAnimation : failureXAnimation}
            width={isSuccess ? 320 : 220}
          />
        </div>

        <AppHeader h="h4" className="mb-5 text-center">
          {isSuccess && t('User information has been successfully updated')}
          {!isSuccess && t('User update failed.')}
        </AppHeader>

        <AppHeader h="h5" className="text-center text-gray-6">
          {!isSuccess && t('Please try again or contact support for assistance.')}
        </AppHeader>
        {!isSuccess && errCode && (
          <span className="mt-2 block text-danger">
            {t('Error Code:')} {errCode}
          </span>
        )}
      </div>
    </div>
  );
}

export default EditUserSuccessOrFail;
