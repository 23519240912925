export const transactionTabs = {
  EXPENSES: 'EXPENSES',
  DECLINES: 'DECLINES',
  REFUNDS: 'REFUNDS',
  LOADS: 'LOADS',
  REQUESTS: 'REQUESTS',
};

export const walletTabs = {
  REQUESTS: 'REQUESTS',
  TRANSACTIONS: 'TRANSACTIONS',
  ALL: 'ALL',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  EXPENSES: 'EXPENSES',
  DECLINES: 'DECLINES',
  REFUNDS: 'REFUNDS',
  LOADS: 'LOADS',
  ACTIVITIES: 'ACTIVITIES',
  FUNDSACTIVITY: 'FUNDSACTIVITY',
  CARDSACTIVITY: 'CARDSACTIVITY',
};

export const settingsPopupContent = {
  mobileSettingsList: 'mobileSettingsList',
  settingsList: 'settingsList',
  changeColorOrName: 'changeColorOrName',
  changeStatus: 'changeStatus',
  cardControl: 'cardControl',
  activateCard: 'activateCard',
  suspendCard: 'suspendCard',
  terminateCard: 'terminateCard',
  requestSent: 'requestSent',
  changeColorNameSuccess: 'changeColorNameSuccess',
  showPinNumber: 'showPinNumber',
  changePinNumber: 'changePinNumber',
  simplifiActivate: 'simplifiActivate',
  verifyLastFour: 'verifyLastFour',
};

export const captchaKey = '0x4AAAAAAACsFx_X-jL1J_nl';

export const receiptPopupIconIds = {
  UPLOAD_ATTACHMENT: 'UPLOAD_ATTACHMENT',
  SPLIT_RECEIPT: 'SPLIT_RECEIPT',
  RECEIPT_POPUP_CONTAINER: 'RECEIPT_POPUP_CONTAINER',
};

export const cardDetailsSectionIds = {
  RIGHT_SIDE_CONTAINER: 'RIGHT_SIDE_CONTAINER',
  CARD_DETAILS_HEADER: 'CARD_DETAILS_HEADER',
};

export const cardStatusKeys = {
  INACTIVE: 'INACTIVE', // issued
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  TERMINATED: 'TERMINATED',
  FAILED: 'FAILED',
  REPLACED: 'REPLACED',
  EXPIRED: 'EXPIRED',
  DEACTIVATED: 'DEACTIVATED',
  HIDDEN: 'HIDDEN',
};

export const cardActionsPopupContent = {
  cardLoad: 'cardLoad',
  cardUnload: 'cardUnload',
  moneyRequest: 'moneyRequest',
  statement: 'statement',
  actionSuccess: 'actionSuccess',
  actionFail: 'actionFail',
};
export const cardProgramActionsPopupContent = {
  statement: 'statement',
};

export const simplifiSharedConfig = {
  envPrefix: process.env.REACT_APP_ENV === 'Production' ? 'prod' : 'uat',
  showModalBorderCardDetail: false,
  showlabelCardDetail: true,
  style: {
    headingFontColor: '#2D2926',
    headingFontSize: '30px',
    headingFontWeight: '700',
    modalBtnColor: '#3842FF',
    modalBoderColor: '#fbfbfb',
    descriptionFontColor: '#2D2926',
    descriptionFontSize: '18px',
    descriptionFontWeight: '400',
    btnFontColor: '#FFFFFF',
    btnFontSize: '13px',
    btnRadius: '5px',
    btnFontWeight: '700',
    btnHeight: '37px',
    btnWidth: '161px',
    btnCopyFontColor: '#FFFFFF',
    btnCopyFontSize: '13px',
    btnCopyRadius: '5px',
    btnCopyFontWeight: '700',
    btnCopyHeight: '37px',
    btnCopyWidth: '100%', // until simplify replay to us '161px',
    btnCopyColor: '#2D2926',
    pinInputColor: '#FFFFFF',
    pinInputFontColor: '#2D2926',
    numericKeyboardPattern: '[0-9]*',
    timerPostion: 'left',
    timerFontColor: '#D69230',
    timerFontSize: '20px',
    timerFontWeight: '400',
    resendLinkDescrptionFontSize: '18px',
    resendLinkDescrptionFontWeight: '400',
    resendLinkDescrptionFontColor: '#2D2926',
    linkFontColor: '#3842FF',
    linkFontSize: '18px',
    linkFontWeight: '500',
    background: 'white',
    modalColor: '#fbfbfb',
    fontFamilyName: 'Inter',
    logoWidth: '100px',
    btnBoxShadowOnFocus: '0 0 0 1px rgba(0,0,0,1)',
    btnCopyBoxShadowOnFocus: '0 0 0 1px rgba(45,41,38,1)',
    btnBoxShadow: '0 0 0 1px rgb(56, 66, 255,1)',
    btnCopyBoxShadow: '0 0 0 1px rgba(45,41,38,1)',
  },
};

export const transactionsSharedParams = {
  paginate: 10,
  created_at: 'desc',
  'with[0]': 'tags',
  'with[1]': 'splits.tags',
};

export const requestsSharedParams = {
  paginate: 10,
  created_at: 'desc',
  'with[0]': 'status',
};

export const createNewCardSteps = {
  noUserCards: 'noUserCards',
  selectCardProgram: 'selectCardProgram',
  verifyAccount: 'verifyAccount',
  selectCardType: 'selectCardType',
  createOrReplaceCard: 'createOrReplaceCard',
  createPrimaryCard: 'createPrimaryCard',
  customizePrimaryCard: 'customizePrimaryCard',
  sendOTP: 'sendOTP',
  virtualCardActivation: 'virtualCardActivation',
  virtualCardActivationFailed: 'virtualCardActivationFailed',
  virtualCardCreationFailed: 'virtualCardCreationFailed',
  manualCardActivation: 'manualCardActivation',
  manageCardControl: 'manageCardControl',
  createPlasticCard: 'createPlasticCard',
  shippingDetails: 'shippingDetails',
  requestPlasticCardSuccess: 'requestPlasticCardSuccess',
  requestPlasticCardFailed: 'requestPlasticCardFailed',
  plasticCardCreation: 'plasticCardCreation',
  cardMaxLimit: 'cardMaxLimit',
  selectReplaceCard: 'selectReplaceCard',
  replaceCardConfirmation: 'replaceCardConfirmation',
};

export const cardTypesKeys = {
  PHYSICAL: 'PHYSICAL',
  VIRTUAL: 'VIRTUAL',
};

export const mockEndPoint = 'cardmanager/general/mock';

export const cardSchemes = {
  MASTER: 'MASTER',
  VISA: 'VISA',
};

export const loginActiveStep = {
  loginAndBanner: 'loginAndBanner',
  loginOTP: 'loginOTP',
};

export const requestStatusIds = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
};

export const walletDetailsSectionIds = {
  RIGHT_SIDE_CONTAINER: 'RIGHT_SIDE_CONTAINER',
  WALLET_DETAILS_HEADER: 'WALLET_DETAILS_HEADER',
};

export const inPages = {
  myCards: 'myCards',
  wallet: 'wallet',
  singleUser: 'singleUser',
  allTransactions: 'allTransactions',
};

export const uploadDocumentSteps = {
  OTP: 'OTP',
  documentList: 'documentList',
  documentCardDetails: 'documentCardDetails',
  documentUploadSuccess: 'documentUploadSuccess',
};

export const supportedCountries = [
  {
    name: 'Saudi Arabia',
    name_ar: 'المملكة العربية السعودية',
    dial_code: '+966',
    code: 'SA',
    flag: '🇸🇦',
  },
  {
    name: 'United Arab Emirates',
    name_ar: 'الإمارات العربية المتحدة',
    dial_code: '+971',
    code: 'AE',
    flag: '🇦🇪',
  },
  {
    name: 'Bahrain',
    name_ar: 'البحرين',
    dial_code: '+973',
    code: 'BH',
    flag: '🇧🇭',
  },
  {
    name: 'Oman',
    name_ar: 'عمان',
    dial_code: '+968',
    code: 'OM',
    flag: '🇴🇲',
  },
  {
    name: 'Qatar',
    name_ar: 'قطر',
    dial_code: '+974',
    code: 'QA',
    flag: '🇶🇦',
  },
  {
    name: 'Kuwait',
    name_ar: 'الكويت',
    dial_code: '+965',
    code: 'KW',
    flag: '🇰🇼',
  },
  {
    name: 'Egypt',
    name_ar: 'مصر',
    dial_code: '+201',
    code: 'EG',
    flag: '🇪🇬',
  },
  {
    name: 'Jordan',
    name_ar: 'الاردن',
    dial_code: '+962',
    code: 'JO',
    flag: '🇯🇴',
  },
];

export const usersStatus = {
  Pending: 'Pending',
  Active: 'Active',
  Suspended: 'Suspended',
  Deactivate: 'Deactivate',
  Banned: 'Banned',
};
