import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { inPages, receiptPopupIconIds } from '../../helpers/constants';
import useCapabilities from '../../helpers/useCapabilities';
import { http } from '../../http';
import { showModalAction } from '../../redux/AppModalReducer/AppModalActions';
import { showToasterAction } from '../../redux/AppToastersReducer/AppToastersActions';
import { getSingleTransactionDetailsAction } from '../../redux/CardDetailsReducer/CardDetailsActions';
import { getSingleWalletTransactionDetailsAction } from '../../redux/WalletReducer/WalletActions';
import AppUpload from '../FormInputs/AppUpload';
import ScrollToGap from '../ScrollToGap';
import {AppButton, AppIcon} from '../html/html';
import AddAttachmentPopup from './AddAttachmentPopup';
import DeleteAttachmentConfirm from './DeleteAttachmentConfirm';

function ReceiptAttachments({
  transactionDetails,
  docTypes,
  showSections,
  inPage,
  getDocTypesAction,
}) {
  const { loaders } = useSelector((state) => state);

  const { t } = useTranslation(['cardsV2', 'commonV2']);
  const { auth } = useSelector((state) => state);
  const [currentUserCan] = useCapabilities();
  const dispatch = useDispatch();
  const [editItem, setEditItem] = useState({
    id: null,
    fileToUpload: null,
    filePreview: null,
    docTypeId: null,
  });

  const uploadedReceipts = useMemo(() => {
    return transactionDetails.receipts.filter((ele) => ele.doc_type !== 'Receipt');
  }, [transactionDetails]);

  const canAddAttachment = useMemo(() => {
    const userCanUploadReceipt =
      currentUserCan('upload_account_receipt') ||
      currentUserCan('edit_account_transaction_receipt');

    const userCanUploadOwnReceipt =
      (currentUserCan('upload_own_transaction_receipt') ||
        currentUserCan('edit_own_transaction_receipt')) &&
      auth.user?.id === transactionDetails.user_id?.id;

    const transactionCanUploadReceipt = transactionDetails.can_upload_reciept;
    const receptsLessThanTen = transactionDetails.receipts.length <= 10;

    return (
      (userCanUploadReceipt || userCanUploadOwnReceipt) &&
      transactionCanUploadReceipt &&
      receptsLessThanTen
    );
  }, [transactionDetails, auth]);

  const showAddAttachmentSection = useMemo(() => {
    return (showSections && showSections[receiptPopupIconIds.UPLOAD_ATTACHMENT]) || false;
  }, [showSections]);

  const handleAttachmentSave = useCallback(() => {
    if (!editItem.fileToUpload && !editItem.docTypeId) {
      setEditItem(null);
    } else {
      const formBody = new FormData();

      formBody.append('transaction_id', transactionDetails.id);

      if (editItem.fileToUpload) formBody.append('receipt', editItem.fileToUpload);
      if (editItem.docTypeId) formBody.append('doc_type_id', editItem.docTypeId);

      http
        .post(`transactions/receipts/${editItem.id}`, formBody, { loader: 'handleAttachmentSave' })
        .then(() => {
          dispatch(
            showToasterAction({
              type: 'success',
              message: t('The attachment was updated successfully!'),
            }),
          );
          if (inPage === inPages.myCards) {
            dispatch(getSingleTransactionDetailsAction(transactionDetails.id));
          }
          if (inPage === inPages.wallet) {
            dispatch(getSingleWalletTransactionDetailsAction(transactionDetails.id));
          }
        });
    }
  }, [editItem]);

  const handleDeleteAttachment = (item) => {
    dispatch(
      showModalAction(
        <DeleteAttachmentConfirm
          inPage={inPage}
          item={item}
          transactionDetails={transactionDetails}
        />,
      ),
    );
  };

  useEffect(() => {
    if (!docTypes) {
      dispatch(getDocTypesAction());
    }
  }, [docTypes]);

  return (
    showAddAttachmentSection && (
      <div
        className={`print:hidden ${
          uploadedReceipts.length || canAddAttachment ? 'rounded-lg border border-gray-3 p-3' : ''
        }`}
      >
        <ScrollToGap id={receiptPopupIconIds.UPLOAD_ATTACHMENT} />
        {canAddAttachment && (
          <div className="flex items-center justify-between">
            {/* Add Attachment */}
            <p className="font-semibold">{t('Upload Attachment')}</p>
            <AppButton
              button="black"
              onClick={() => {
                dispatch(
                  showModalAction(
                    <AddAttachmentPopup
                      docTypes={docTypes}
                      transactionDetails={transactionDetails}
                      inPage={inPage}
                    />,
                  ),
                );
              }}
            >
              {t('Add', { ns: 'commonV2' })}
            </AppButton>
          </div>
        )}

        {uploadedReceipts.map((ele) => {
          const isEditItem = editItem?.id === ele.id;
          return (
            <div key={ele.id} className="my-4 flex items-center justify-between gap-3">
              <div className="flex items-center gap-2">
                {/* Delete btn */}
                {isEditItem && (
                  <button
                    type="button"
                    className="text-red-600"
                    onClick={() => {
                      handleDeleteAttachment(ele);
                    }}
                  >
                    <AppIcon iClass="XLargeFont" className="fa-regular fa-trash-can" />
                  </button>
                )}
                <AppUpload
                  uploadedFilePreview={!isEditItem && { path: ele.path, type: ele.type }}
                  height={100}
                  width={100}
                  isLoading={loaders?.handleAttachmentSave}
                  isDisabled={!isEditItem}
                  placeHolder={
                    <div className="flex gap-1 text-[8px] font-semibold">
                      <span>{t('Upload the', { ns: 'cardsV2' })}</span>
                      <span className="text-primary-dark">{t('receipt', { ns: 'cardsV2' })}</span>
                    </div>
                  }
                  onChange={({ FILE }) => {
                    setEditItem((prev) => {
                      return {
                        ...prev,
                        fileToUpload: FILE,
                      };
                    });
                  }}
                />
              </div>

              {!isEditItem && <div className="text-sm">{t(ele.doc_type)}</div>}

              {isEditItem && (
                <select
                  style={{ border: '2px solid lightgray' }}
                  disabled={loaders?.handleAttachmentSave}
                  className="w-full rounded bg-transparent px-2 py-1 text-xs text-gray-6 disabled:opacity-50"
                  onChange={(e) => {
                    setEditItem((prev) => {
                      return {
                        ...prev,
                        docTypeId: +e.target.value,
                      };
                    });
                  }}
                  defaultValue={docTypes?.find((el) => el.title === ele.doc_type).id}
                >
                  {docTypes?.map((o) => {
                    return (
                      <option key={o.id} value={o.id}>
                        {t(o.title)}
                      </option>
                    );
                  })}
                </select>
              )}

              <div>
                {!isEditItem && (
                  <AppButton
                    button="link"
                    className="text-primary"
                    onClick={() => {
                      setEditItem((prev) => {
                        return {
                          ...prev,
                          id: ele.id,
                        };
                      });
                    }}
                  >
                    {t('Edit', { ns: 'commonV2' })}
                  </AppButton>
                )}
                {isEditItem && (
                  <AppButton
                    disabled={loaders?.handleAttachmentSave}
                    button="link"
                    className="text-primary"
                    onClick={handleAttachmentSave}
                  >
                    {loaders?.handleAttachmentSave && t('Saving', { ns: 'commonV2' })}
                    {!loaders?.handleAttachmentSave && t('Save', { ns: 'commonV2' })}
                  </AppButton>
                )}
              </div>
            </div>
          );
        })}
      </div>
    )
  );
}

export default ReceiptAttachments;
