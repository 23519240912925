const CreditCard = function ({ name, blackCard }) {
  return (
    <div className={`relative ${blackCard ? 'col-span-3' : ''}`}>
      {/* return when simplify back own card */}
      {/* {!blackCard && <img src="/images/three_cards.png" className="h-48" />} */}
      {!blackCard && <img src="/images/three_cards_new.png" className="m-auto h-48" />}
      {blackCard && <img src="/images/black_card.png" className="h-48 max-w-full" />}
      <span className="absolute bottom-10 w-full text-center uppercase text-white">{name}</span>
    </div>
  );
};

export default CreditCard;
